
//PC_1.2 SQ_1
import axios, { Axios } from "axios";
import { JsonApi } from "./Client";
//this function contains the json from component and their azure function names in type
export const postInsuranceQuoteData = async (ReqData: any, type: any) => {

    console.log("Json:",ReqData)
    console.log("Type:",type)
    let data: any = "";
//PC_1.3 SQ_6
    switch (type) {
        case 'general':
            data = await axios.post(`${JsonApi.commercialinsuranceApi}&type=Commercial General Liability`, ReqData)
            break;
        case 'Advisor Contact 1':
            data = await axios.post(`${JsonApi.commercialinsuranceApi}&type=Advisor Contact 1`, ReqData)
            break;
        case 'Advisor Contact 2':
            data = await axios.post(`${JsonApi.commercialinsuranceApi}&type=Advisor Contact 2`, ReqData)
            break;
        case 'cauto':
            data = await axios.post(`${JsonApi.autoInsuranceApi}&type=Commercial Auto Insurance`, ReqData)
         
            break;
        case 'auto':
            data = await axios.post(`${JsonApi.autoInsuranceApi}&type=Auto Quote Insurance`, ReqData)
            break;
        case 'Home':
            data = await axios.post(`${JsonApi.personalInsuranceApi}&type=Home Insurance`, ReqData)
            break;
        case 'Flood':
            data = await axios.post(`${JsonApi.personalInsuranceApi}&type=Flood Insurance`, ReqData)
            break;
        case 'Other':
            
            data = await axios.post(`${JsonApi.personalInsuranceApi}&type=Other`, ReqData)
            console.log(data)
            break;
        case 'Life':
            data = await axios.post(`${JsonApi.FinancialinsruanceAPi}&type=Life Insurance`, ReqData)
            break;  
        case 'Medicare':
                data = await axios.post(`${JsonApi.personalInsuranceApi}&type=Medicare`, ReqData);
                console.log(data);
                break;
    
        default:
            break;

    }
    console.log("Final Out:",data.data)
    //SQ_15	PC_1.5,1.6
    return data.data


}