import errorLogo from "../images/Error-Image.svg"

export function ErrorPage(){
    return(
        <div className="container mt-5">
  <div className="row justify-content-center">
    {/* Thank you */}
    <div className="col-md-10 text-center mt-5 border-bottom pb-5">
      <img src={errorLogo} alt="loch-img" className="w-25" />
      <label className="font-bold font40 insurance-type w-100">404!</label>
      <p className="font32 font-bold mt-2">Page Not Found</p>
    </div>
    {/* Thank you */}
  </div>
</div>
    )
}