/*
   // Project: Gibraltar
   // Desc: This file contains the commercial Auto insurance component by using this component the user will able to request quote for their commercial auto insurance;
   // Author:Kalyanaraman Sivaraman
   // Created Date:04/07/2022
   //File Name : commercialAutoInsurance.tsx;
*/

//Pseudocode ID :CAUTO_01;
//Importing useState and useEffect  from react to use state variable in the component;
//Importing all the needed images
import React, { useEffect, useRef, useState } from "react";
import arrowWhite from '../images/Arrow-Left-White.svg';
import arrowRight from '../images/Arrow-right.svg';
import arrowLeft from '../images/Arrow-Left.svg';
import editIcon from '../images/Edit.svg';
import plusIcon from '../images/Plus-icon.svg';
import formHelp from '../images/Form-Help.svg';
import autoInsuranceIcon from '../images/Auto-Insurance.svg';
import arrowLeftWhiteIcon from '../images/Arrow-Left-White.svg';
import edit from "../images/Edit.svg";
import { postInsuranceQuoteData } from "./service";
import disabledPlus from "../images/Plus-Icon-Light.svg";
import PhoneInput from "react-number-format";
import Header from "./header";
import axios from "axios";
import Cancel_button from "../images/Cancel-button.svg";
import calender from '../images/Calender.svg'


//importing the service.ts functio to post the data;
//import { postInsuranceQuoteData } from "./service";
//Pseudocode ID :CAUTO_01;
function CommercialAutoInsuranceDrivers() {

    var QueryString = new URLSearchParams(window.location.search);
    let OwnerId = QueryString.get("agentId");
    let parameterId= QueryString.get("parameterId");

    //state variables;
    const [pages, setPages] = useState(["Personal Details", "Current Policy Information", "Additional Drivers", "Tell us about your vehicles", "Coverage Details", "Claim Details"]);
    const [errorIndexForLicence, setErrorIndexForLicence] = useState(-1);
    const [errorIndexForDateOfBirth, setErrorIndexForDateOfBirth] = useState(-1);
    const [errorVinIndex, setErrorVin] = useState(-1);
    const [makeErrorIndex, setMakeErrorIndex] = useState(-1);
    const [currentPage, setCurrentPage] = useState(1)
    const [isValidated, setIsValidated] = useState(false);
    const [jsonData, setJsonData] = useState([
        {
            firstName: "",
            lastName: "",
            email: "",
            mobilePhone: "",
            street: "",
            city: "",
            state: "",
            postalCode: "",
            nameOfTheCompany: "",
            maritalstatus: "",
            prefferedContactMethod: "",
            detailType: "Provide us all the details",
            isCurrentlyInsured: "",
            currentCarrier: "",
            renewalpremium: "",
            previousPolicyExpirationDate: "",
            toAddDriver: "",
            coverageNeeded: "",
            isUninsuredMotorist: "",
            deductable: "",
            personalCar: false,
            rentalCar: false,
            unInsuredCar: false,
            personalCarc: "No",
            rentalCarc: "No",
            unInsuredCarc: "No",
            hasClaims: "",
            claimDetails: "",
            towing: "",
            rentalReimbursement: "",
            additionalInsured: "",
            closingDate: "",
            lapseTime: ""
        }
    ]);
    const [previousStepsArray, setPreviousStepsArray] = useState([]);
    const [isValidFirstPage, setIsValidFirstPage] = useState(true);
    const [isValidForthPage, setIsValidForthPage] = useState(true);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidMobilePhone, setIsValidMobilePhone] = useState(true);
    const [isValidPostalCode, setIsValidPostalCode] = useState(true);
    const [driverId, setDriverId] = useState(1);
    const [size, setSize] = useState(0)
    const [additionalDriverArray, setAdditionalDriverArray] = useState([
        {
            driverId: 0,
            First_Name__c: "",
            Last_Name__c: "",
            Date_of_Birth__c: "",
            Gender__c: "",
            Relationship__c: "",
            Tickets_or_Accident__c: "",
            Commercial_Driver_s_License__c: "",
            Driver_s_License_Number__c: "",
            State__c: "",
            isSaved: false
        }
    ]);
    const [vehicleId, setVehicleId] = useState(1);
    const [additionalVehicleArray, setAdditionalVehicleArray] = useState([
        {
            vehicleId: 0,
            Name: "",
            Model__c: "",
            Year__c: "",
            Miles_to_work__c: "",
            VIN__c: "",
            Comp_Collision_Deductibles__c: "",
            isSaved: false
        }
    ]);
    const [openedVehicle, setOpenedVehicle] = useState(0);
    const [openedDriver, setOpenedDriver] = useState(0);
    const [isValidSecondPage, setIsValidSecondPage] = useState(true);
    const [isValidSixthPage, setIsValidSixthPage] = useState(true);
    const [emailCrossed, setEmailCrossed] = useState(false);
    const [phoneCrossed, setPhoneCrossed] = useState(false);
    const [zipCrossed, setZipCrossed] = useState(false);
    const [firstNameErrorIndex, setFirstNameErrorIndex] = useState(-1);
    const [lastNameErrorIndex, setLastNameErrorIndex] = useState(-1);
    const [licenceErrorIndex, setLicenceErrorIndex] = useState(-1);
    const [licenceLengthError, setLicenceLengthError] = useState(-1);
    const [yearIndex, setYearIndex] = useState(-1)
    const [Loading, setLoading] = useState(false);

    const [file, setfile] = useState();
    const [fileValid, setfileValid] = useState(false)
    const [fileLoading, setFileLoading] = useState(false)
    const [cancelvisible, setCancelvisible] = useState(false)
    const myref: any = useRef(null);
    const [filename, setfilename] = useState("")





    const [dateFieldFocused, setdateFieldFocused] = useState(false)
    const [Additonaldrivermob, setAdditonaldrivermob] = useState(false)
    const [Closingdatemob, setClosingdatemob] = useState(false)
    const[formatValid,setformatValid]=useState(false)

    //the folllowing useEffect will be invoked if the user changes the "Would you Like to?" data for eg: if the user chooses "Provide us all details" the following use Effect will be invoked;
    useEffect(() => { toClearDataOnDetailTypeChange() }, [jsonData[0].detailType])

    //the following function disablePastDate() will return the current date in  "YYYY-MM-DD" format this date is used to hide the past or futhure dates in the calender;
    function disablePastDate() {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };



    function Cancel_buttonClick() {
        setFileLoading(false)
        setfilename("")
        myref.current.value = ""

        setCancelvisible(false)
    }
    const [type, setType] = useState("")
    async function validateFile(e: any) {

        const convertBase64 = (files: Blob) => {

            return new Promise((resolve, reject) => {

                const fileReader = new FileReader()

                fileReader.readAsDataURL(files)



                fileReader.onload = (() => {

                    resolve(fileReader.result)

                })



                fileReader.onerror = ((error: any) => {

                    reject(error)

                })

            })

        }



        console.log(e.target.files[0])
        setSize(e.target.files[0].size)
        setType(e.target.files[0].type)


        if (e.target.files[0].type !== "application/pdf") {

            e.target.value = ""
            setFileLoading(false)
            setformatValid(true)
            setfileValid(false)
            setfilename("")
            setCancelvisible(false)

        }
        if (e.target.files[0].size <= 5242880) {
            setCancelvisible(true)

            const blobFile = e.target.files[0]

            const filename = e.target.files[0].name

            console.log(filename)

            let s = filename

            let a = s.replace('.pdf', "")

            console.log("filename-->", a)

            setfilename(a)

            setFileLoading(true)

            let base64: any = await convertBase64(blobFile)

            console.log(base64)

            let b = base64.replace('data:application/pdf;base64,', "")

            console.log("Base64", b)

            setFileLoading(false)

            setfile(b)

        }

        else if (e.target.files[0].size === 0 || e.target.files[0].size > 5242880) {
            e.target.value = '';
            setfileValid(true)
            setfilename("")
            setCancelvisible(false)
        }

    }



    //Pseudocode ID : CAUTO_25;
    //The following function will be invoked by the useEffect to clear all the state variable datas while user changes the detail type;
    function toClearDataOnDetailTypeChange() {

        let jsonDataSpread: any = [...jsonData];



        jsonData[0].firstName = jsonData[0].firstName;
        jsonData[0].lastName = jsonData[0].lastName;
        jsonData[0].email = jsonData[0].email;
        jsonData[0].mobilePhone = jsonData[0].mobilePhone;
        jsonData[0].street = jsonData[0].street;
        jsonData[0].city = jsonData[0].city;
        jsonData[0].state = jsonData[0].state;
        jsonData[0].detailType = jsonData[0].detailType;
        jsonData[0].postalCode = jsonData[0].postalCode;
        jsonData[0].nameOfTheCompany = jsonData[0].nameOfTheCompany;
        jsonData[0].prefferedContactMethod = jsonData[0].prefferedContactMethod;
        jsonData[0].maritalstatus = jsonData[0].maritalstatus;
        jsonData[0].isCurrentlyInsured = "";
        jsonData[0].currentCarrier = "";
        jsonData[0].renewalpremium = "";
        jsonData[0].previousPolicyExpirationDate = "";
        jsonData[0].toAddDriver = "";
        jsonData[0].coverageNeeded = "";
        jsonData[0].isUninsuredMotorist = "";
        jsonData[0].deductable = "";
        jsonData[0].personalCar = false;
        jsonData[0].rentalCar = false;
        jsonData[0].unInsuredCar = false;
        jsonData[0].personalCarc = "No";
        jsonData[0].rentalCarc = "No";
        jsonData[0].unInsuredCarc = "No";
        jsonData[0].hasClaims = "";
        jsonData[0].claimDetails = "";
        jsonData[0].towing = "";
        jsonData[0].rentalReimbursement = "";
        jsonData[0].additionalInsured = "";
        jsonData[0].closingDate = "";
        jsonData[0].lapseTime = "";

        setJsonData(jsonDataSpread);


        setAdditionalDriverArray([
            {
                driverId: 0,
                First_Name__c: "",
                Last_Name__c: "",
                Date_of_Birth__c: "",
                Gender__c: "",
                Relationship__c: "",
                Tickets_or_Accident__c: "",
                Commercial_Driver_s_License__c: "",
                Driver_s_License_Number__c: "",
                State__c: "",
                isSaved: false
            }
        ]);

        setAdditionalVehicleArray(
            [
                {
                    vehicleId: 0,
                    Name: "",
                    Model__c: "",
                    Year__c: "",
                    Miles_to_work__c: "",
                    VIN__c: "",
                    Comp_Collision_Deductibles__c: "",
                    isSaved: false
                }
            ]
        )

        setPreviousStepsArray([]);
    };



    //Pseudocode ID : CAUTO_03;
    //function for validating the form datas;
    function validation(page: number) {

        //   console.log(page, "Page")

        //errorCount is used to count the errors if the errorCount is 0 after the validation the page is routed to next page;
        let errorCount = 0;

        let spreadPreviousPageArray: any = [...previousStepsArray];

        //if block to validate firstPage;
        //Pseudocode ID:CAUTO_03;
        if (page === 1) {
            //        console.log("i am at firstPage")

            //validating first page datas;
            if (jsonData[0].firstName.trim() === "") {
                setIsValidFirstPage(false);
                errorCount++
            }
            if (jsonData[0].lastName.trim() === "") {
                setIsValidFirstPage(false);
                errorCount++
            }
            if (jsonData[0].email === "") {
                setIsValidFirstPage(false);
                errorCount++
            }
            if (jsonData[0].email !== "") {

                //email regex validation;
                //let emailRegex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

                let emailRegex = /^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)*[\w+-]+\.[a-zA-Z]{2,4}$/;


                if (!(jsonData[0].email.match(emailRegex))) {
                    setIsValidEmail(false);

                    errorCount++
                }
                if (jsonData[0].email.match(emailRegex)) {
                    setIsValidEmail(true);
                }
            }
            if (jsonData[0].mobilePhone === "") {
                setIsValidFirstPage(false);
                errorCount++
            }
            if (jsonData[0].mobilePhone !== "") {

                let mobileRegex = /^\d{10}$/;

                if (!(jsonData[0].mobilePhone.match(mobileRegex))) {
                    setIsValidMobilePhone(false);

                    errorCount++
                }
                if (jsonData[0].mobilePhone.match(mobileRegex)) {
                    setIsValidMobilePhone(true);
                }
            }
            if (jsonData[0].street.trim() === "") {
                setIsValidFirstPage(false);
                errorCount++
            }
            if (jsonData[0].city.trim() === "") {
                setIsValidFirstPage(false);
                errorCount++
            }
            if (jsonData[0].state.trim() === "") {
                setIsValidFirstPage(false);
                errorCount++
            }
            if (jsonData[0].postalCode === "") {
                setIsValidFirstPage(false);
                errorCount++
            }
            if (jsonData[0].postalCode !== "") {

                let postalCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/

                if (!(jsonData[0].postalCode.match(postalCodeRegex))) {
                    setIsValidPostalCode(false);

                    errorCount++
                }
                if (jsonData[0].postalCode.match(postalCodeRegex)) {
                    setIsValidPostalCode(true);
                }

            }
            if (jsonData[0].nameOfTheCompany.trim() === "") {
                setIsValidFirstPage(false);
                errorCount++
            }
            if (jsonData[0].detailType.trim() === "") {
                setIsValidFirstPage(false);
                errorCount++
            }

            if (errorCount !== 0) {
                setPhoneCrossed(true);
                return errorCount
            }
            //if the errorCount is equal to 0 the firstPage will be hidden from the user and the currentPage is increamented by 1;

            if (errorCount === 0) {
                setCurrentPage(currentPage + 1)
                previousButtonClick(page, "");
            }
            return;

        }
        //if block to validate second page;
        else if (page === 2 && jsonData[0].detailType === "Provide us all the details") {

            if (jsonData[0].previousPolicyExpirationDate !== "") {
                let userEnteredPreviousPolicyDate = new Date(jsonData[0].previousPolicyExpirationDate)
                let today = new Date(disablePastDate());

                if (userEnteredPreviousPolicyDate < today) {
                    setIsValidSecondPage(false);
                    errorCount++
                }
                else if (userEnteredPreviousPolicyDate >= today) {
                    setIsValidSecondPage(true);
                }
            }

            if (errorCount !== 0) {
                return errorCount
            }
            if (errorCount === 0) {
                setCurrentPage(currentPage + 1);
                previousButtonClick(page, "");
                return;
            }

        }


        //if block to validate third page;
        else if (page === 3 && jsonData[0].detailType === "Provide us all the details") {

            if (errorCount !== 0) {
                return errorCount
            }
            if (errorCount === 0) {
                setCurrentPage(currentPage + 1);
                previousButtonClick(page, "");
                return;
            }

        }


        //Pseudocode ID : CAUTO_19;
        //if block to validate fourth page;
        else if (page === 4 && jsonData[0].detailType === "Provide us all the details") {

            if (errorCount !== 0) {
                return errorCount
            }

            if (errorCount === 0) {

                setOpenedVehicle(-1);
                setIsValidForthPage(true);
                setCurrentPage(currentPage + 1);
                previousButtonClick(page, "");

                return;
            }

        }

        else if (page === 5 && jsonData[0].detailType === "Provide us all the details") {

            if (errorCount !== 0) {
                return errorCount
            }

            if (errorCount === 0) {
                setCurrentPage(currentPage + 1);

                previousButtonClick(page, "");

                return;

            }
        }
        else if (page === 6 && jsonData[0].detailType === "Provide us all the details") {

            if (jsonData[0].closingDate !== "") {
                let userEnteredClosingDate = new Date(jsonData[0].closingDate)
                let today = new Date(disablePastDate());

                if (userEnteredClosingDate < today) {
                    setIsValidSixthPage(false);
                    errorCount++
                }
                else if (userEnteredClosingDate >= today) {
                    setIsValidSixthPage(true);
                }
            }
            if (errorCount !== 0) {
                return errorCount
            }
            if (errorCount === 0) {
                postData();
            }
        }


        /* If user clicks on send button in the second page of the just give key points page the data will sended to the database */
        /* Pseudocode ID : CAUTO_09  & CAUTO_10*/

        else if (page === 2 && jsonData[0].detailType === "Just give key points") {
            if (errorCount !== 0) {
                return errorCount
            }
            if (errorCount === 0) {
                postData();
            }
        }
    }


    //Pseudocode ID : CAUTO_23;
    /* Handle change function is used to set the value of the each  field into the state variable json */
    function handleChange(e: any) {
        let name = "", value = ""

        let jsonDataSpread: any = [...jsonData];
        // console.log(e);
        if (e.target) {

            name = e.target.name;
            value = e.target.value
            if (e.target.type === "checkbox") {

                jsonDataSpread[0][e.target.placeholder] = (e.target.checked) ? "Yes" : "No";

                jsonDataSpread[0][name] = (e.target.checked) ? true : false;

            } else {

                if (e.target.name === "postalCode" || e.target.name === "lapseTime" || e.target.name === "renewalpremium") {
                    value = value.replace(/[^0-9]/g, '');
                }
                jsonDataSpread[0][name] = value;

            }
        }
        else {
            name = "mobilePhone"
            value = e.value;
            //   console.log(value, "value from if")
            jsonDataSpread[0][name] = value;
        }

        setJsonData(jsonDataSpread);

        if (name === "email" || name === "mobilePhone" || name === "postalCode") {
            if (phoneCrossed === true) {
                setIsValidFirstPage(false);
                if (name === "email") {
                    // let emailRegex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
                    let emailRegex = /^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)*[\w+-]+\.[a-zA-Z]{2,4}$/;


                    if (!(jsonData[0].email.match(emailRegex))) {
                        setIsValidEmail(false);

                    }
                    if (jsonData[0].email.match(emailRegex)) {
                        setIsValidEmail(true);
                    }
                } else if (name === "mobilePhone") {
                    let mobileRegex = /^\d{10}$/;

                    if (!(jsonData[0].mobilePhone.match(mobileRegex))) {
                        setIsValidMobilePhone(false);

                    }
                    if (jsonData[0].mobilePhone.match(mobileRegex)) {
                        setIsValidMobilePhone(true);
                    }
                } else if (name === "postalCode") {
                    let postalCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/

                    if (!(jsonData[0].postalCode.match(postalCodeRegex))) {
                        setIsValidPostalCode(false);
                    }
                    if (jsonData[0].postalCode.match(postalCodeRegex)) {
                        setIsValidPostalCode(true);
                    }
                }
            }
        }

    }

    //Pseudocode ID : CAUTO_24;
    function previousButtonClick(page: any, type: any) {

        if (type === "back") {
            setCurrentPage(currentPage - 1);
        }

        let spreadPreviousPageArray: any = [...previousStepsArray];


        if (!spreadPreviousPageArray.includes(pages[page - 1])) {
            spreadPreviousPageArray.push(pages[page - 1])
        }
        setPreviousStepsArray(spreadPreviousPageArray)
    }


    // function events(e:any,){


    //     if(e.target.value.length <= 5){
    //         handleChange(e)
    //     }else{
    //         return false;
    //     }
    // }

    //Pseudocode ID:CAUTO_02;
    /*Personal Details Page Block starts here*/
    /*this function will return the html to bind the firstPage here user can enter their personal details*/
    function personalDetailsPageBind() {
        //return part of the personalDetailsPageBind Function;
        return (
            <React.Fragment>
                <div className="col-md-12 mt-4">
                    <label className="font20 font-primary font-semibold border-bottom w-100 font-head pt-2 pb-2">General Info</label>
                </div>

                <div className="col-md-12 mt-3">

                    <div className="row">

                        {/* Firstname block starts here */}
                        <div className="col-md-6">
                            <label htmlFor="firstName" className="form-label font14 font-semibold">First Name*</label>
                            <input
                                type="text"
                                className="form-control pb-2 pt-2"
                                name="firstName"
                                value={jsonData[0].firstName}
                                onChange={(e) => { handleChange(e) }}
                                id="firstName"
                                maxLength={40} />
                            <label className="text-danger font14 w-100 font14 w-100">{(isValidFirstPage === false && jsonData[0].firstName.trim() === "") ? "Please enter the First Name" : null}</label>
                        </div>
                        {/* Firstname block ends here */}

                        {/* Lastname block starts here */}
                        <div className="col-md-6">
                            <label htmlFor="lastName" className="form-label font14 font-semibold">Last Name*</label>
                            <input
                                type="text"
                                className="form-control pb-2 pt-2"
                                id="lastName"
                                name="lastName"
                                value={jsonData[0].lastName}
                                onChange={(e) => { handleChange(e) }}
                                maxLength={80} />
                            <label className="text-danger font14 w-100">{(isValidFirstPage === false && jsonData[0].lastName.trim() === "") ? "Please enter the Last Name" : null}</label>
                        </div>

                        {/* Lastname Block ends here */}


                        <div className="col-md-6">
                            <label htmlFor="company" className="form-label font14 font-semibold">Name of the Company*</label>
                            <input
                                type="text"
                                className="form-control pb-2 pt-2 font14"
                                id="company"
                                value={jsonData[0].nameOfTheCompany}
                                name="nameOfTheCompany"
                                onChange={(e) => { handleChange(e) }}
                                maxLength={50} />

                            <label className="text-danger font14 w-100">{(isValidFirstPage === false && jsonData[0].nameOfTheCompany.trim() === "") ? "Please enter the Name of Your Company" : null}</label>
                        </div>

                        <div className="col-md-6">

                            <label htmlFor="marital-status" className="form-label w-100 font14 font-semibold">Marital Status</label>

                            <input
                                className="btn-check radio-checked"
                                type="radio"
                                name="maritalstatus"
                                id="Single"
                                checked={jsonData[0].maritalstatus === "Single"} value="Single"
                                onClick={(e) => { handleChange(e) }}
                                autoComplete="off"
                            />

                            <label className="btn btn-primary button-radio font14 font-bold" htmlFor="Single">Single</label>


                            <input
                                className="btn-check radio-checked"
                                type="radio"
                                name="maritalstatus"
                                id="Married"
                                checked={jsonData[0].maritalstatus === "Married"} value="Married"
                                onClick={(e) => { handleChange(e) }}
                                autoComplete="off"
                            />

                            <label className="btn btn-primary button-radio font14 font-bold ms-2" htmlFor="Married">Married</label>
                        </div>



                        {/* General Info Ends Here */}
                        {/* Communication Info Starts Here */}
                        <div className="col-md-12  mt-4">
                            <label className="font20 font-primary font-semibold font-head border-bottom w-100  pb-2">Communication Info</label>
                        </div>

                        <div className="col-md-12 mt-3">
                            <label htmlFor="email" className="form-label font14 font-semibold">Email*</label>
                            <input
                                type="text"
                                className="form-control pb-2 pt-2"
                                id="email"
                                value={jsonData[0].email}
                                name="email"
                                onChange={(e) => { handleChange(e) }}
                                maxLength={80} />
                            <label className="text-danger font14 w-100">{(isValidFirstPage === false && jsonData[0].email === "") ? "Please enter an Email" : (isValidEmail === false) ? "Please enter a valid Email" : null}</label>
                        </div>

                        <div className="col-md-12">
                            <label htmlFor="mobilePhone" className="form-label font14 font-semibold">Mobile Phone*</label>

                            <PhoneInput
                                type={"tel"}
                                format=

                                {
                                    (jsonData[0].mobilePhone.length === 10) ?
                                        "(###) ###-####" : "##########"
                                }

                                placeholder="XXXXXXXXXX"
                                className="form-control pb-2 pt-2"
                                value={jsonData[0].mobilePhone}
                                onValueChange={(e: any) => { handleChange(e) }}
                                id="mobilePhone"
                                name="mobilePhone" />


                            <label className="text-danger font14 w-100">{(isValidFirstPage === false && jsonData[0].mobilePhone === "") ? "Please enter the Mobile Number" : (isValidMobilePhone === false) ? "Please enter a valid Mobile Number" : null}</label>
                        </div>
                        <div className="col-md-12">
                            <label htmlFor="street" className="form-label font14 font-semibold">Street*</label>
                            <textarea
                                className="form-control pb-2 pt-2"
                                id="street"
                                rows={6}
                                value={jsonData[0].street}
                                name="street"
                                onChange={(e) => { handleChange(e) }}
                                style={{ resize: "none" }}
                                maxLength={120} />
                            <label className="text-danger font14 w-100">{(isValidFirstPage === false && jsonData[0].street === "") ? "Please enter the Street" : null}</label>
                        </div>

                        <div className="col-md-12">
                            <label htmlFor="label7" className="form-label font14 font-semibold">City*</label>
                            <input
                                type="text"
                                className="form-control pb-2 pt-2"
                                id="label7"
                                value={jsonData[0].city}
                                name="city"
                                onChange={(e) => { handleChange(e) }}
                                maxLength={40} />
                            <label className="text-danger font14 w-100">{(isValidFirstPage === false && jsonData[0].city === "") ? "Please enter the City" : null}</label>
                        </div>

                        <div className="col-md-6">
                            <label htmlFor="label8" className="form-label font14 font-semibold">State/Province*</label>
                            <select className="form-select pb-2 pt-2" id="label8" value={jsonData[0].state}
                                name="state"
                                onChange={(e) => { handleChange(e) }}>
                                <option value="">Select State/Province</option>
                                <option value="AK">AK</option>
                                <option value="AL">AL</option>
                                <option value="AR">AR</option>
                                <option value="AZ">AZ</option>
                                <option value="CA">CA</option>
                                <option value="CO">CO</option>
                                <option value="CT">CT</option>
                                <option value="DE">DE</option>
                                <option value="FL">FL</option>
                                <option value="GA">GA</option>
                                <option value="HI">HI</option>
                                <option value="IA">IA</option>
                                <option value="ID">ID</option>
                                <option value="IL">IL</option>
                                <option value="IN">IN</option>
                                <option value="KS">KS</option>
                                <option value="KY">KY</option>
                                <option value="LA">LA</option>
                                <option value="MA">MA</option>
                                <option value="MD">MD</option>
                                <option value="ME">ME</option>
                                <option value="MI">MI</option>
                                <option value="MN">MN</option>
                                <option value="MO">MO</option>
                                <option value="MS">MS</option>
                                <option value="MT">MT</option>
                                <option value="NC">NC</option>
                                <option value="ND">ND</option>
                                <option value="NE">NE</option>
                                <option value="NH">NH</option>
                                <option value="NJ">NJ</option>
                                <option value="NM">NM</option>
                                <option value="NV">NV</option>
                                <option value="NY">NY</option>
                                <option value="OH">OH</option>
                                <option value="OK">OK</option>
                                <option value="OR">OR</option>
                                <option value="PA">PA</option>
                                <option value="RI">RI</option>
                                <option value="SC">SC</option>
                                <option value="SD">SD</option>
                                <option value="TN">TN</option>
                                <option value="TX">TX</option>
                                <option value="UT">UT</option>
                                <option value="VA">VA</option>
                                <option value="VT">VT</option>
                                <option value="WA">WA</option>
                                <option value="WI">WI</option>
                                <option value="WV">WV</option>
                                <option value="WY">WY</option>
                            </select>
                            <label className="text-danger font14 w-100">{(isValidFirstPage === false && jsonData[0].state.trim() === "") ? "Please select the State/Province " : null}</label>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="label9" className="form-label font14 font-semibold">Zip/Postal Code*</label>
                            <input type="text" className="form-control pb-2 pt-2" id="label9" value={jsonData[0].postalCode}
                                name="postalCode"
                                maxLength={5}
                                onChange={(e) => { handleChange(e) }}
                            />
                            <label className="text-danger font14 w-100">{(isValidFirstPage === false && jsonData[0].postalCode === "") ? "Please enter the Zip/Postal Code" : (isValidPostalCode === false) ? "Please enter the valid Zip/Postal code" : null}</label>
                        </div>

                        <div className="col-md-12">

                            <label htmlFor="label10" className="form-label w-100 font14 font-semibold">Preferred Contact Method</label>

                            <input
                                className="btn-check radio-checked"
                                type="radio"
                                name="prefferedContactMethod"
                                id="phone"
                                checked={jsonData[0].prefferedContactMethod === "Phone"} value="Phone"
                                onClick={(e) => { handleChange(e) }}
                                autoComplete="off"
                            />

                            <label className="btn btn-primary button-radio font14 font-bold" htmlFor="phone">Phone</label>

                            <input
                                className="btn-check radio-checked"
                                type={"radio"}
                                autoComplete="off"
                                name="prefferedContactMethod"
                                id="emailr"
                                checked={jsonData[0].prefferedContactMethod === "Email"} value="Email"
                                onClick={(e) => { handleChange(e) }}
                            />

                            <label className="btn btn-primary ms-2 button-radio font14 font-bold" htmlFor="emailr">Email</label>

                            <input
                                className="btn-check radio-checked"
                                type={"radio"}
                                autoComplete="off"
                                name="prefferedContactMethod"
                                id="text"
                                checked={jsonData[0].prefferedContactMethod === "Text"} value="Text"
                                onClick={(e) => { handleChange(e) }}
                            />

                            <label className="btn btn-primary ms-2 button-radio font14 font-bold" htmlFor="text">Text</label>

                            <input
                                className="btn-check radio-checked"
                                autoComplete="off"
                                type={"radio"}
                                name="prefferedContactMethod"
                                id="mail"
                                checked={jsonData[0].prefferedContactMethod === "Mail"} value="Mail"
                                onClick={(e) => { handleChange(e) }}
                            />
                            <label className="btn btn-primary ms-2 button-radio font14 font-bold" htmlFor="mail">Mail</label>
                        </div>

                        {/* Communication Info Ends Here */}


                        <div className="col-md-12 mt-4">

                            <label htmlFor="Provide us all the details" className="form-label w-100 font14 font-semibold border-top pt-3 w-100">Would you like to?*</label>

                            <input
                                type="radio"
                                className="btn-check radio-checked px-4"
                                id="Provide us all the details"
                                autoComplete="off"
                                name="detailType"
                                value={"Provide us all the details"}
                                checked={jsonData[0].detailType === "Provide us all the details"}
                                onClick={(e) => { handleChange(e) }} />

                            <label className="btn btn-primary button-radio font14 font-bold px-4 button-radio2" htmlFor="Provide us all the details">Provide us all the details</label>

                            <input
                                type="radio"
                                className="btn-check radio-checked px-4"
                                id="Just give key points"
                                autoComplete="off"
                                name="detailType"
                                value={"Just give key points"}
                                checked={jsonData[0].detailType === "Just give key points"}
                                onClick={(e) => { handleChange(e) }} />

                            <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4 button-radio2 button-just" htmlFor="Just give key points">Just give key points</label>
                            <label className="text-danger font14 w-100">{(isValidFirstPage === false && jsonData[0].detailType === "") ? "Please Select Your Choice" : null}</label>

                        </div>

                    </div>
                </div>
            </React.Fragment>
        )
    }
    /*Personal Details Page Block ends here*/


    /* Currently Insured page Block starts here  */
    /* function to bind the currently Insured page the function will gets invoked if the current page is equal to 2 */
    //Pseudocode ID : CAUTO_07

    function currentlyInsuredPageForAll() {

        //return part of currently insured page;
        return (
            <React.Fragment>

                {/* General Info Starts here */}

                <div className="col-md-12">
                    <div className="row">

                        {/* General Info Ends Here */}
                        {/* Communication Info Starts Here */}

                        <div className="col-md-12 mt-3">
                            <label htmlFor="label10" className="form-label w-100 font14 font-semibold">Are you currently insured?</label>

                            <input
                                type="radio"
                                className="btn-check radio-checked"
                                id="label10"
                                autoComplete="off"
                                name="isCurrentlyInsured"
                                value={"Yes"}
                                checked={jsonData[0].isCurrentlyInsured === "Yes"}
                                onClick={(e) => { handleChange(e) }} />

                            <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor="label10">Yes</label>

                            <input
                                type="radio"
                                className="btn-check radio-checked"
                                id="label11"
                                autoComplete="off"
                                value={"No"}
                                name="isCurrentlyInsured"
                                checked={jsonData[0].isCurrentlyInsured === "No"}
                                onClick={(e) => { handleChange(e) }} />

                            <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor="label11">No</label>
                        </div>

                        {(jsonData[0].isCurrentlyInsured === "Yes") ?
                            <React.Fragment>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="currentCarrier" className="form-label font14 font-semibold">Current Carrier
                                        <span className="gib-tool-carrier">
                                            <img src={formHelp} className="ms-1 gib-tool-carrier" />
                                            <span className="tooltext-carrier">{"your current insurance provider"}</span>
                                        </span>
                                    </label>

                                    <input
                                        type="text"
                                        className="form-control  pb-2 pt-2 font14"
                                        id="currentCarrier"
                                        name="currentCarrier"
                                        value={jsonData[0].currentCarrier}
                                        onChange={(e) => { handleChange(e) }}
                                        maxLength={80} />

                                </div>

                                <div className="col-md-12 mt-3">
                                    <label className="form-label font14 font-semibold" htmlFor="renewal-premium">Renewal Premium</label>

                                    <input type="text" className="form-control" id="renewal-premium" name="renewalpremium" maxLength={18} value={jsonData[0].renewalpremium}
                                        onChange={(e) => { handleChange(e) }} />

                                </div>


                                <div className="col-md-12 mt-3 date-picker">
                                    <label className="form-label font14 font-semibold">Previous Policy Expiration Date</label>

                                    <input
                                        type="date"
                                        className="form-control pb-2 pt-2 font14 text-uppercase datecontrol"
                                        min={disablePastDate()}
                                        value={jsonData[0].previousPolicyExpirationDate}
                                        name="previousPolicyExpirationDate"
                                        onChange={(e) => { handleChange(e) }}
                                        onKeyDown={(e) => { e.preventDefault() }}
                                        onFocus={(e) => setdateFieldFocused(true)}
                                        placeholder={dateFieldFocused ? "" : "MM/DD/YYYY"}
                                    />
                                    <img src={calender} alt="calender-icon" className="calender-icon "></img>

                                    <label className="text-danger font14 w-100">{(isValidSecondPage === false) ? "Please enter the valid Previous Policy Expiry Date" : null}</label>
                                </div>
                                <div className="file-upload">
                                    <label htmlFor="expiryDate" className="form-label font14 mb-0 font-semibold w-100 mt-2">Current Insurance Dec Page</label>
                                    <label htmlFor="expiryDate" className="form-label font14 PDF-label w-100"> <span className="font-semibold">Note: </span>Please upload PDF file only with less than 5 MB</label>


                                    <input type="file" className=" form-control border-0 file-upload-input w-50 mt-2 px-3 font14 font-semibold" title="" accept=".pdf" id="formFile" onChange={(e) => validateFile(e)} ref={myref} />
                                    {/* <input type="file" className=" form-control border-0 file-upload-input w-50" accept=".pdf" id="formFile" onChange={(e) => validateFile(e)} ref={myref}  /> */}
                                    {/* <input type="file" className="px-3"  accept=".pdf" id="actual-btn" hidden onChange={(e) => validateFile(e)} ref={myref} /> */}
                                    {/* <label htmlFor="actual-btn" className="file-upload-input mt-2 px-3 font14 font-semibold">Choose File</label> */}
                                    {/* <label className="  font14 ms-2">{filename!==""?filename+".pdf":" No file chosen"}</label> */}
                                    <span className="gib-tool-choose">
                                        <label className="font14 ms-2">{(filename !== "" && filename.length < 20) ? filename + ".pdf" : (filename.length > 10 && filename !== "") ? (filename.substring(0, 20)) + "..." + ".pdf" : "No file chosen"}</label>
                                        {filename !== "" ? <span className="tooltext-choose">

                                            {(filename.length > 55) ? filename.substring(0, 54) + '\n' + filename.substring(55, 100) + ".pdf" : (filename + ".pdf")}</span> : null}
                                    </span>

                                    {fileLoading == true ?
                                        <div className="clearfix ">

                                            <div className="spinner-border float-end text-secondary loader-spinner" role="status">

                                                <span className="visually-hidden">Loading...</span>

                                            </div>

                                        </div> : null}
                                    {cancelvisible === true ?
                                        <button className="btn border-0  Choose-cancel-mobile w-65 float-end mt-2 " onClick={() => Cancel_buttonClick()} >
                                            <img src={Cancel_button} />
                                        </button> : null}

                                </div>
                                <label className="text-danger font14">
                                    {(fileValid === true && size > 5242880) ? "File size limit is exceeded" : null}
                                    {((fileValid === true && size === 0) ? "The uploaded file is empty" : null)}
                                    {((formatValid === true && type !== "application/pdf") ? "Please upload a file in suggested format" : null)}
                                </label>
                            </React.Fragment> : null}
                    </div>
                </div>
            </React.Fragment>

        )

    }

    /* Currently Insured page Block ends here  */

    /* additional driver details page block starts here */

    /*Pseudocode ID : CAUTO_11 */
    /*The following function will bind the driver details page*/
    //function to bind additional driver page;
    function additionalDriverDetailsPageBind() {

        //Pseudocode ID : CAUTO_12;
        //the following function will add new driver details form to the user display by pushing json structure in to the state variable;
        function addAdditionalDriver() {

            setDriverId(driverId + 1);
            setOpenedDriver(additionalDriverArray.length);
            setAdditionalDriverArray([...additionalDriverArray, {

                driverId: driverId,
                First_Name__c: "",
                Last_Name__c: "",
                Date_of_Birth__c: "",
                Gender__c: "",
                Relationship__c: "",
                Tickets_or_Accident__c: "",
                Commercial_Driver_s_License__c: "",
                Driver_s_License_Number__c: "",
                State__c: "",
                isSaved: false

            }])
        }

        //Pseudocode ID : CAUTO_13;     
        //function for getting the input from the user in the add additional driver forms;
        function handleChangeForDriverPage(e: any, index: any) {
            let { name, value } = e.target;

            let driverHandleChangeList: any = [...additionalDriverArray];

            if (e.target.type === "radio") {
                driverHandleChangeList[index][e.target.placeholder] = value;
            } else if (name === "Driver_s_License_Number__c") {
                value = value.replace(/[^A-Za-z0-9]+/g, '');
                driverHandleChangeList[index][name] = value;
            }
            else {
                driverHandleChangeList[index][name] = value;
            }

            setAdditionalDriverArray(driverHandleChangeList);

        }

        //PseudoCodeId: CAUTO_26;

        function saveFunctionFordriver(index: any) {

            let errorOccured = 0;
            if (additionalDriverArray[index].First_Name__c.trim() === "") {
                setFirstNameErrorIndex(index)
                errorOccured++
            }

            if (additionalDriverArray[index].Last_Name__c.trim() === "") {
                setLastNameErrorIndex(index);
                errorOccured++
            }
            if (additionalDriverArray[index].Commercial_Driver_s_License__c === "Yes") {
                if (additionalDriverArray[index].Driver_s_License_Number__c.trim() === "") {
                    setLicenceErrorIndex(index);
                    errorOccured++
                } if (additionalDriverArray[index].Driver_s_License_Number__c !== "") {
                    if (additionalDriverArray[index].Driver_s_License_Number__c.length < 1) {
                        setLicenceLengthError(index);
                        errorOccured++
                    }
                }
            }
            if (errorOccured !== 0) {
                setOpenedDriver(index);
            } else if (errorOccured === 0) {
                setFirstNameErrorIndex(-1);
                setLastNameErrorIndex(-1);
                setLicenceErrorIndex(-1);

                let newAddDriver = [...additionalDriverArray];

                newAddDriver[index].isSaved = true;

                setAdditionalDriverArray(newAddDriver);

                setOpenedDriver(-1)
            }
        }


        //Pseudocode ID : CAUTO_13;  
        //function for removing a driver form from the list of form;
        function removeAdditionalDriver(index: any) {

            let oldDriverList = [...additionalDriverArray];

            // let newDriverList: any = oldDriverList.filter(
            //   (oneByOne: any) => {
            //     return oneByOne.driverId !== index
            //   }
            // );
            setFirstNameErrorIndex(-1);
            setLastNameErrorIndex(-1);
            setLicenceErrorIndex(-1);

            let newDriverList: any = [];

            for (let i = 0; i < oldDriverList.length; i++) {
                if (oldDriverList[i].driverId !== index) {
                    newDriverList.push(oldDriverList[i]);
                }
            }

            //  console.log(newDriverList);

            setOpenedDriver(-1);

            setAdditionalDriverArray(newDriverList)
        }

        return (
            <React.Fragment>

                <div className="col-md-12 mt-3">

                    <label htmlFor="Provide us all the details" className="form-label w-100 font14 font-semibold ">Would you like to add any additional drivers?</label>

                    <input
                        type="radio"
                        className="btn-check radio-checked"
                        id="anyone-else-yes"
                        autoComplete="off"
                        name="toAddDriver"
                        value={"Yes"}
                        onClick={(e) => { handleChange(e) }}
                        checked={jsonData[0].toAddDriver === "Yes"} />
                    <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor="anyone-else-yes">Yes</label>

                    <input
                        type="radio"
                        className="btn-check radio-checked"
                        id="anyone-else-no"
                        autoComplete="off"
                        name="toAddDriver"
                        value={"No"}
                        onClick={(e) => { handleChange(e) }}
                        checked={jsonData[0].toAddDriver === "No"} />
                    <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor="anyone-else-no">No</label>
                </div>

                {(jsonData[0].toAddDriver === "Yes") ?
                    <React.Fragment>
                        <div className="col-md-12  mt-3">
                            <label className="font20 font-primary border-bottom w-100 font-semibold font-head pb-2">Named Driver</label>
                        </div>

                        {additionalDriverArray.map((singleDriver: any, index: any) => {

                            return (

                                <React.Fragment>
                                    {(index !== openedDriver) ?
                                        <React.Fragment>


                                            {(openedDriver === -1) ?
                                                <div className="col-md-12 bg-light p-3 mt-4">
                                                    <label className="font14 font-semibold" onClick={() => setOpenedDriver(index)} style={{ "cursor": "pointer" }}>{singleDriver.First_Name__c + " " + singleDriver.Last_Name__c}<img src={editIcon} alt="edit-icon" className="ms-2 edit-icon" style={{ "cursor": "pointer" }} onClick={() => setOpenedDriver(index)} /></label>
                                                </div> : <div className="col-md-12 bg-light p-3 mt-4">
                                                    <label className="font14 font-semibold" >{singleDriver.First_Name__c + " " + singleDriver.Last_Name__c}<img src={editIcon} alt="edit-icon" className="ms-2 edit-icon" /></label>
                                                </div>
                                            }

                                        </React.Fragment> : <React.Fragment>
                                            {(singleDriver.isSaved === true) ?
                                                <div className="col-md-12 bg-light p-3 mt-3">
                                                    <label className="font14 font-semibold">{singleDriver.First_Name__c + " " + singleDriver.Last_Name__c}<img src={editIcon} alt="edit-icon" className="ms-2 edit-icon" /></label>
                                                </div> : null
                                            }
                                            {
                                                (singleDriver.isSaved === false) ?
                                                    <div className="col-md-12  p-3 mt-3">
                                                    </div> : null
                                            }


                                            <div className={(singleDriver.isSaved === false) ? "col-md-12 px-0" : 'col-md-12 border border-top-0'}>
                                                <div className="row border mx-2">

                                                    <div className="col-md-6 mt-3">
                                                        <label htmlFor={"firstName" + index} className="form-label font14 font-semibold">First Name*</label>
                                                        <input
                                                            type="text"
                                                            className="form-control pb-2 pt-2"
                                                            name="First_Name__c"
                                                            id={"firstName" + index}
                                                            value={singleDriver.First_Name__c}
                                                            onChange={(e) => { handleChangeForDriverPage(e, index) }}
                                                            maxLength={40} />

                                                        <label className="text-danger font14 w-100">{(firstNameErrorIndex === index && singleDriver.First_Name__c.trim() === "") ? "Please enter the First Name" : null}</label>
                                                    </div>

                                                    <div className="col-md-6 mt-3 single-column-margin">
                                                        <label htmlFor={"lastName" + index} className="form-label font14 font-semibold single-column-margin">Last Name*</label>
                                                        <input
                                                            type="text"
                                                            className="form-control pb-2 pt-2"
                                                            name="Last_Name__c"
                                                            id={"lastName" + index}
                                                            value={singleDriver.Last_Name__c}
                                                            onChange={(e) => { handleChangeForDriverPage(e, index) }}
                                                            maxLength={80} />
                                                        <label className="text-danger font14 w-100">{(lastNameErrorIndex === index && singleDriver.Last_Name__c.trim() === "") ? "Please enter the Last Name" : null}</label>
                                                    </div>

                                                    <div className="col-md-6 mb-3 date-picker">
                                                        <label className="form-label font14 font-semibold">Date of Birth</label>
                                                        <input
                                                            type="date"
                                                            className="form-control pb-2 pt-2 font14 text-uppercase datecontrol" name="Date_of_Birth__c"
                                                            id={"dob" + index}
                                                            max={disablePastDate()}
                                                            value={singleDriver.Date_of_Birth__c}
                                                            onChange={(e) => { handleChangeForDriverPage(e, index) }}
                                                            onKeyDown={(e) => { e.preventDefault() }}
                                                            onFocus={(e) => setAdditonaldrivermob(true)}
                                                            placeholder={Additonaldrivermob ? "" : "MM/DD/YYYY"} />
                                                        <img src={calender} alt="calender-icon" className="calender-icon "></img>
                                                        {/* <label className="text-danger font14 w-100">{(errorIndexForDateOfBirth === index) ? "Please Enter a valid date" : null}</label> */}
                                                    </div>
                                                    <div className="col-md-6 mb-3 commercialsingle-margin">
                                                        <label className="form-label w-100 font14 font-semibold ">Gender</label>

                                                        <input
                                                            type="radio"
                                                            className="btn-check radio-checked"
                                                            placeholder="Gender__c"
                                                            id={"Male" + index}
                                                            value={"Male"}
                                                            checked={singleDriver.Gender__c === "Male"} onClick={(e) => { handleChangeForDriverPage(e, index) }}
                                                            autoComplete="off"
                                                            name={"gender" + index} />

                                                        <label
                                                            className="btn btn-primary button-radio font14 font-bold px-4" htmlFor={"Male" + index}>Male</label>

                                                        <input
                                                            type="radio"
                                                            className="btn-check radio-checked"
                                                            id={"Female" + index}
                                                            autoComplete="off"
                                                            name={"gender" + index}
                                                            value={"Female"}
                                                            placeholder="Gender__c"
                                                            checked={singleDriver.Gender__c === "Female"} onClick={(e) => { handleChangeForDriverPage(e, index) }} />
                                                        <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor={"Female" + index}>Female</label>

                                                    </div>

                                                    <div className="col-md-6 mb-3 commercialsingle-margin">
                                                        <label className="form-label w-100 font14 font-semibold">Marital Status</label>

                                                        <input
                                                            type="radio"
                                                            className="btn-check radio-checked"
                                                            placeholder="Relationship__c"
                                                            id={"Single" + index}
                                                            value={"Single"}
                                                            checked={singleDriver.Relationship__c === "Single"} onClick={(e) => { handleChangeForDriverPage(e, index) }}
                                                            autoComplete="off"
                                                            name={"Relationship__c"} />

                                                        <label
                                                            className="btn btn-primary button-radio font14 font-bold px-4" htmlFor={"Single" + index}>Single</label>

                                                        <input
                                                            type="radio"
                                                            className="btn-check radio-checked"
                                                            id={"Married" + index}
                                                            autoComplete="off"
                                                            name={"Relationship__c"}
                                                            value={"Married"}
                                                            placeholder="Relationship__c"
                                                            checked={singleDriver.Relationship__c === "Married"} onClick={(e) => { handleChangeForDriverPage(e, index) }} />
                                                        <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor={"Married" + index}>Married</label>

                                                    </div>

                                                    <div className="col-md-6 commercialsingle-margin mobile-bottom">

                                                        <label htmlFor="label10" className="form-label w-100 font14 font-semibold">Commercial Driver's License?</label>

                                                        <input
                                                            type="radio"
                                                            className="btn-check radio-checked"
                                                            name={"Commercial_Driver_s_License__c" + index}
                                                            id={"Commercial_Driver_s_License__c_Yes" + index}
                                                            value={"Yes"}
                                                            placeholder={"Commercial_Driver_s_License__c"}
                                                            onClick={(e) => { handleChangeForDriverPage(e, index) }}
                                                            checked={singleDriver.Commercial_Driver_s_License__c === "Yes"}
                                                            autoComplete="off" />

                                                        <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor={"Commercial_Driver_s_License__c_Yes" + index}>Yes</label>

                                                        <input
                                                            type="radio"
                                                            className="btn-check radio-checked"
                                                            name={"Commercial_Driver_s_License__c" + index}
                                                            id={"Commercial_Driver_s_License__c_No" + index}
                                                            value={"No"}
                                                            placeholder={"Commercial_Driver_s_License__c"}
                                                            onClick={(e) => { handleChangeForDriverPage(e, index) }}
                                                            checked={singleDriver.Commercial_Driver_s_License__c === "No"}
                                                            autoComplete="off" />

                                                        <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor={"Commercial_Driver_s_License__c_No" + index}>No</label>

                                                    </div>
                                                    {(singleDriver.Commercial_Driver_s_License__c === "Yes") ?
                                                        <React.Fragment>
                                                            <div className="col-md-12 mt-3">
                                                                <label htmlFor="label-DC" className="form-label font14 font-semibold">Commercial Driver's License Number*
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    // placeholder="XXXXXXXX"
                                                                    name="Driver_s_License_Number__c"
                                                                    id={"Driver_s_License_Number__c" + index}
                                                                    value={singleDriver.Driver_s_License_Number__c}
                                                                    onChange={(e) => { handleChangeForDriverPage(e, index) }} maxLength={19} />
                                                                {/* <label className="text-danger font14 w-100">{(errorIndexForLicence === index) ? "Please enter a valid Driving license Number" : null}</label> */}
                                                                <label htmlFor={"Driver_s_License_Number__c" + index} className="text-danger font14 w-100">
                                                                    {
                                                                        (licenceErrorIndex === index && singleDriver.Driver_s_License_Number__c.trim() === "") ? " Please enter the Commercial Driver's License Number" : (licenceLengthError === index && singleDriver.Driver_s_License_Number__c.length < 1) ? "Please enter a valid Commercial Driver's License Number" : null
                                                                    }
                                                                </label>

                                                            </div>

                                                        </React.Fragment> : null}



                                                    <div className="col-md-12 mobile-space">
                                                        <label htmlFor={"State__c" + index} className="form-label font14 font-semibold">State</label>
                                                        <select
                                                            className="form-select pb-2 pt-2 font14"
                                                            aria-label="Default select example"
                                                            name="State__c"
                                                            id={"State__c" + index}
                                                            value={singleDriver.State__c}
                                                            onChange={(e) => { handleChangeForDriverPage(e, index) }}>
                                                            <option value="">Select State</option>
                                                            <option value="AK">AK</option>
                                                            <option value="AL">AL</option>
                                                            <option value="AR">AR</option>
                                                            <option value="AZ">AZ</option>
                                                            <option value="CA">CA</option>
                                                            <option value="CO">CO</option>
                                                            <option value="CT">CT</option>
                                                            <option value="DE">DE</option>
                                                            <option value="FL">FL</option>
                                                            <option value="GA">GA</option>
                                                            <option value="HI">HI</option>
                                                            <option value="IA">IA</option>
                                                            <option value="ID">ID</option>
                                                            <option value="IL">IL</option>
                                                            <option value="IN">IN</option>
                                                            <option value="KS">KS</option>
                                                            <option value="KY">KY</option>
                                                            <option value="LA">LA</option>
                                                            <option value="MA">MA</option>
                                                            <option value="MD">MD</option>
                                                            <option value="ME">ME</option>
                                                            <option value="MI">MI</option>
                                                            <option value="MN">MN</option>
                                                            <option value="MO">MO</option>
                                                            <option value="MS">MS</option>
                                                            <option value="MT">MT</option>
                                                            <option value="NC">NC</option>
                                                            <option value="ND">ND</option>
                                                            <option value="NE">NE</option>
                                                            <option value="NH">NH</option>
                                                            <option value="NJ">NJ</option>
                                                            <option value="NM">NM</option>
                                                            <option value="NV">NV</option>
                                                            <option value="NY">NY</option>
                                                            <option value="OH">OH</option>
                                                            <option value="OK">OK</option>
                                                            <option value="OR">OR</option>
                                                            <option value="PA">PA</option>
                                                            <option value="RI">RI</option>
                                                            <option value="SC">SC</option>
                                                            <option value="SD">SD</option>
                                                            <option value="TN">TN</option>
                                                            <option value="TX">TX</option>
                                                            <option value="UT">UT</option>
                                                            <option value="VA">VA</option>
                                                            <option value="VT">VT</option>
                                                            <option value="WA">WA</option>
                                                            <option value="WI">WI</option>
                                                            <option value="WV">WV</option>
                                                            <option value="WY">WY</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-12 mt-4">

                                                        <label htmlFor={"Tickets_or_Accident__c" + index} className="form-label font14 font-semibold">We will be able to pull your driving history from public reports but are there any accidents or claims you would like to provide details on?</label>

                                                        <input
                                                            type="radio"
                                                            className="btn-check radio-checked"
                                                            placeholder="Tickets_or_Accident__c"
                                                            id={"Tickets_or_Accident__c_yes" + index}
                                                            value={"Yes"}
                                                            checked={singleDriver.Tickets_or_Accident__c === "Yes"} onClick={(e) => { handleChangeForDriverPage(e, index) }}
                                                            autoComplete="off"
                                                            name={"Tickets_or_Accident__c"} />

                                                        <label
                                                            className="btn btn-primary button-radio font14 font-bold px-4" htmlFor={"Tickets_or_Accident__c_yes" + index}>Yes</label>


                                                        <input
                                                            type="radio"
                                                            className="btn-check radio-checked"
                                                            placeholder="Tickets_or_Accident__c"
                                                            id={"Tickets_or_Accident__c_no" + index}
                                                            value={"No"}
                                                            checked={singleDriver.Tickets_or_Accident__c === "No"} onClick={(e) => { handleChangeForDriverPage(e, index) }}
                                                            autoComplete="off"
                                                            name={"Tickets_or_Accident__c"} />

                                                        <label
                                                            className="btn btn-primary button-radio font14 font-bold px-4 ms-2" htmlFor={"Tickets_or_Accident__c_no" + index}>No</label>


                                                    </div>


                                                    <div className="col-md-12 mb-4 mt-4">
                                                        <button type="button" className="btn  bt-save  border-0 font14 float-start px-5 py-2 save-button" onClick={() => {

                                                            saveFunctionFordriver(index);
                                                        }}>Save</button>
                                                        {(additionalDriverArray.length !== 1) ?
                                                            <button type="button" className="btn red-button  float-start ms-2 px-5 py-2 font14 border-0"
                                                                onClick={() => { removeAdditionalDriver(singleDriver.driverId) }}>Remove</button> : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }</React.Fragment>)
                        }
                        )
                        }
                        {(openedDriver === -1) ?
                            <div className="col-md-12 mt-3">
                                <a className="font14 font-semibold font-primary text-decoration-none" onClick={() => { addAdditionalDriver() }} style={{ cursor: "pointer" }}><span><img src={plusIcon} className="plus-icon" alt="plus" /></span> Add Another Driver</a>
                            </div> : <div className="col-md-12 mt-3">
                                <a className="font14 font-semibold font-primary-light text-decoration-none" style={{ pointerEvents: "none" }}><span><img src={disabledPlus} className="plus-icon" alt="plus" /></span> Add Another Driver</a>
                            </div>
                        }
                    </React.Fragment> : null
                }

                {/* Add another button */}
            </React.Fragment>
        )
    }

    /* additional driver details page block ends here */

    /* additional vehicle details page block starts here */
    //Pseudocode ID : CAUTO_15;
    function additionalVehicleDetailsPageBind() {

        //Pseudocode ID : CAUTO_16;
        //the following function will add New json to the state variable so the form will be increased due to the state variable length changed;
        function addAdditionalVehicle() {

            setOpenedVehicle(additionalVehicleArray.length);

            setVehicleId(vehicleId + 1);

            setAdditionalVehicleArray([...additionalVehicleArray, {
                vehicleId: vehicleId,
                Name: "",
                Model__c: "",
                Year__c: "",
                Miles_to_work__c: "",
                VIN__c: "",
                Comp_Collision_Deductibles__c: "",
                isSaved: false
            }])
        };

        //Pseudocode ID : CAUTO_18;
        //the following function will remove an vehicle form;
        function removeAdditionalVehicle(index: any) {

            let oldVehicleList = [...additionalVehicleArray];

            // let newVehicleList = oldVehicleList.filter((oneByOne: any) => {
            //   return oneByOne.vehicleId !== index;
            // })

            let newVehicleList = [];
            for (let i = 0; i < oldVehicleList.length; i++) {
                if (oldVehicleList[i].vehicleId !== index) {
                    newVehicleList.push(oldVehicleList[i]);
                }
            }
            setAdditionalVehicleArray(newVehicleList);
            setOpenedVehicle(-1)

        }

        //Pseudocode ID : CAUTO_17;
        //the following function will change the value of the state variable;
        function handleChangeForVehiclePage(e: any, index: any) {

            let { name, value } = e.target;
            let oldVehicleLists: any = [...additionalVehicleArray];

            //   console.log(name);


            if (name === "Year__c" || name === "VIN__c" || name === "Miles_to_work__c") {
                value = value.replace(/[^0-9]/g, '');
            }


            oldVehicleLists[index][name] = value;



            setAdditionalVehicleArray(oldVehicleLists);
            if (isValidated === true) {

                if (additionalVehicleArray[index].Year__c !== "") {
                    let currentDate = new Date();

                    let currentYear = currentDate.getFullYear();

                    //  console.log(currentYear, "Cyea")

                    let userEnteredDate = new Date(`${additionalVehicleArray[index].Year__c}-01-01`);

                    let years = userEnteredDate.getFullYear();

                    //     console.log(years, "yaer");

                    if (currentYear < years || additionalVehicleArray[index].Year__c.length < 4) {
                        setYearIndex(index);
                    } else if (currentYear >= years) {
                        setYearIndex(-1)
                    }
                }
            }
        }

        function saveFunctionForVehiclePage(index: any) {

            let errorOccured = 0;

            if (errorOccured === 0) {

                if (additionalVehicleArray[index].Name.trim() === "") {
                    setMakeErrorIndex(index);
                    errorOccured++
                } else if (additionalVehicleArray[index].Name !== "") {
                    setMakeErrorIndex(-1);
                }
                if (additionalVehicleArray[index].VIN__c !== "") {
                    if (additionalVehicleArray[index].VIN__c.length < 16) {
                        setErrorVin(index);
                        errorOccured++
                    } else if (additionalVehicleArray[index].VIN__c.length >= 16) {
                        setErrorVin(-1);
                    }
                }

                if (additionalVehicleArray[index].Year__c !== "") {
                    let currentDate = new Date();

                    let currentYear = currentDate.getFullYear();



                    let userEnteredDate = new Date(`${additionalVehicleArray[index].Year__c}-01-01`);

                    let years = userEnteredDate.getFullYear();



                    //    console.log(userEnteredDate);

                    if (currentYear < years || additionalVehicleArray[index].Year__c.length < 4) {
                        setYearIndex(index);
                        errorOccured++
                    } else if (currentYear >= years) {
                        setYearIndex(-1)
                    }
                }

                if (errorOccured !== 0) {
                    setOpenedVehicle(index);
                    setIsValidated(true);

                    return;
                } else if (errorOccured === 0) {

                    let newAddVehicle: any[] = [...additionalVehicleArray];

                    console.log("Before change", newAddVehicle)

                    newAddVehicle[index].isSaved = true;

                    console.log("After cahnge", newAddVehicle)

                    setAdditionalVehicleArray(newAddVehicle);


                    setOpenedVehicle(-1)
                }
            }
        }


        return (
            <React.Fragment>
                {/* label for  */}
                <div className="col-md-12 mt-3">
                    <label className="font20 font-primary font-semibold font-head pb-2 border-bottom w-100">
                        Vehicle Details
                    </label>
                </div>

                {/* tab that contain the car make and model in the top of the form  */}
                {additionalVehicleArray.map(
                    (singleVehicle: any, index: any) => {
                        return (
                            <React.Fragment>
                                {(index !== openedVehicle) ?
                                    <React.Fragment>
                                        {(openedVehicle === -1) ?
                                            <div className="col-md-12 bg-light p-3 mt-4">
                                                <label className="font14 font-semibold" style={{ "cursor": "pointer" }} onClick={() => setOpenedVehicle(index)}>
                                                    {singleVehicle.Name + " " + singleVehicle.Model__c}
                                                    <img src={editIcon} alt="edit-icon" className="ms-2 edit-icon" onClick={() => setOpenedVehicle(index)} style={{ "cursor": "pointer" }} />
                                                </label>
                                            </div>
                                            :
                                            <div className="col-md-12 bg-light p-3 mt-4">
                                                <label className="font14 font-semibold">
                                                    {singleVehicle.Name + " " + singleVehicle.Model__c}
                                                    <img src={editIcon} alt="edit-icon" className="ms-2 edit-icon" />
                                                </label>
                                            </div>
                                        }
                                    </React.Fragment> :
                                    <React.Fragment>
                                        {(singleVehicle.isSaved === true) ?
                                            <div className="col-md-12 bg-light p-3 mt-3">
                                                <label className="font14 font-semibold">
                                                    {singleVehicle.Name + " " + singleVehicle.Model__c}
                                                    <img src={editIcon} alt="edit-icon" className="ms-2 edit-icon" />
                                                </label>
                                            </div> : null
                                        }
                                        {
                                            (singleVehicle.isSaved === false) ?
                                                <div className="col-md-12  p-3 mt-3">
                                                </div> : null
                                        }

                                        <div className={(singleVehicle.isSaved === true) ? "col-md-12 border border-top-0" : "col-md-12"}>
                                            <div className="row border mx-2">


                                                {/* Make Block of the additional vehicle form starts here */}
                                                <div className="col-md-6 mt-3">

                                                    <label htmlFor={"Name" + index} className="form-label font14 font-semibold">Make*</label>
                                                    <input
                                                        type="text"
                                                        maxLength={50}
                                                        className="form-control pb-2 pt-2"
                                                        id={"Name" + index}
                                                        value={singleVehicle.Name}
                                                        onChange={(e) => { handleChangeForVehiclePage(e, index) }}
                                                        name={"Name"} />

                                                    <label className="text-danger font14 w-100">{(makeErrorIndex === index && singleVehicle.Name.trim() === "") ? "Please enter the Make" : null}</label>


                                                </div>
                                                {/* Make Block of the additional vehicle form ends here  */}


                                                <div className="col-md-6 mt-3 single-column-margin">

                                                    <label htmlFor={"Model__c" + index} className="form-label font14 font-semibold">Model</label>

                                                    <input
                                                        type="text" className="form-control pb-2 pt-2" name={"Model__c"}
                                                        id={"Model__c" + index}
                                                        value={singleVehicle.Model__c}
                                                        onChange={(e) => { handleChangeForVehiclePage(e, index) }}
                                                        maxLength={50} />

                                                </div>


                                                <div className="col-md-6 yearcommercial-auto">

                                                    <label htmlFor={"Year__c" + index} className="form-label font14 font-semibold">Year</label>

                                                    <input
                                                        type="text" className="form-control font14 text-uppercase pb-2 pt-2" name="Year__c"
                                                        maxLength={4}
                                                        id={"Year__c" + index}
                                                        value={singleVehicle.Year__c}
                                                        onChange={(e) => { handleChangeForVehiclePage(e, index) }} />
                                                    <label className="text-danger font14 w-100">{(yearIndex === index) ? "Please enter valid Year" : null}</label>
                                                </div>

                                                <div className="col-md-6">

                                                    <label htmlFor={"VIN__c" + index} className="form-label font14 font-semibold">VIN
                                                        <span className="gib-tooltip2">
                                                            <img src={formHelp} className="ms-1 gib-tooltip2" />
                                                            <span className="tooltiptext">{"vehicle identification number, found on bottom left windshield"}</span>
                                                        </span>
                                                    </label>

                                                    <input
                                                        type="text" className="form-control pb-2 pt-2 font14 text-uppercase pb-2 pt-2 milestoworkfont" name="VIN__c"
                                                        id={"VIN__c" + index}
                                                        value={singleVehicle.VIN__c}
                                                        onChange={(e) => { handleChangeForVehiclePage(e, index) }}
                                                        maxLength={17} />
                                                    <label className="text-danger font14 w-100">{(errorVinIndex === index && singleVehicle.VIN__c.length < 16) ? "Please enter a valid VIN Number" : null}</label>
                                                </div>

                                                <div className="col-md-6">

                                                    <label htmlFor={"Miles_to_work__c" + index} className="form-label font14 font-semibold milestoworkfont">Miles - Commute to Work/School</label>
                                                    <span className="gib-tool-miles">
                                                        <img src={formHelp} className="ms-1 gib-tool-miles" />
                                                        <span className="tooltext-miles font14 font-semibold">
                                                            {
                                                                "If vehicles used for pleasure enter 0"
                                                            }
                                                        </span>
                                                    </span>



                                                    <input
                                                        type="text" className="form-control font14 pb-2 pt-2" name={"Miles_to_work__c"}
                                                        id={"Miles_to_work__c" + index}
                                                        value={singleVehicle.Miles_to_work__c}
                                                        onChange={(e) => { handleChangeForVehiclePage(e, index) }}
                                                        maxLength={3} />

                                                </div>



                                                <div className="col-md-6 comp-margin">
                                                    <label className="form-label font14 font-semibold">Comp/Collision Deductibles
                                                        <span className="gib-tool-comp">
                                                            <img src={formHelp} className="ms-1 gib-tool-comp" />
                                                            <span className="tooltext-comp">{"Deductible is amount withheld when claim is paid"}</span>
                                                        </span>
                                                    </label>

                                                    <select
                                                        className="form-select pb-2 pt-2 font14" name="Comp_Collision_Deductibles__c"
                                                        id={"Comp_Collision_Deductibles__c" + index}
                                                        value={singleVehicle.Comp_Collision_Deductibles__c}
                                                        onChange={(e) => { handleChangeForVehiclePage(e, index) }}>
                                                        <option value="">Select</option>
                                                        <option value="$100">$100</option>
                                                        <option value="$500">$500</option>
                                                        <option value="$1000">$1000</option>
                                                        <option value="$2000">$2000</option>
                                                    </select>
                                                </div>









                                                {/* <input
                                                  type="text" className="form-control font14 pb-2 pt-2" name={"Tickets_or_Accident__c"}
                                                  id={"Tickets_or_Accident__c" + index}
                                                 value={singleVehicle.Tickets_or_Accident__c}
                                                 onChange={(e) => { handleChangeForVehiclePage(e, index) }}
                                                 maxLength={3} />

                                                  </div> */}




                                                <div className="col-md-12 mb-4 mt-4">

                                                    <button type="button" className="btn  bt-save  border-0 font14 float-start px-5 py-2 save-button" onClick={() => { saveFunctionForVehiclePage(index) }}>Save</button>

                                                    {(additionalVehicleArray.length! !== 1) ?
                                                        <button type="button" className="btn btn red-button red-button float-start ms-2 px-5 py-2 font14 border-0" onClick={() => { removeAdditionalVehicle(singleVehicle.vehicleId) }}>Remove</button> : null
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        )
                    })
                }
                {/* Add another button  starts*/}

                {(openedVehicle === -1) ?
                    <div className="col-md-12 mt-3">
                        <a className="font14 font-semibold font-primary text-decoration-none" onClick={() => { addAdditionalVehicle() }} style={{ cursor: "pointer" }} ><span><img src={plusIcon} className="plus-icon" alt="plus" /></span> Add Another Vehicle</a>
                    </div>
                    :
                    <div className="col-md-12 mt-3">
                        <a className="font14 font-semibold font-primary-light text-decoration-none" style={{ pointerEvents: "none" }} ><span><img src={disabledPlus} className="plus-icon" alt="plus" /></span> Add Another Vehicle</a>
                    </div>
                }

                {/* Add another button  ends*/}

            </React.Fragment>
        )

    }

    /* additional vehicle details page block ends here */

    /*coverage needed page block starts here*/
    //Pseudocode ID : CAUTO_20;

    function coverageDetailsPageBind() {
        return (
            <div className="col-md-12 mt-3">
                <div className="row">
                    <div className="col-md-12 mt-3">

                        <label htmlFor="coverageNeeded" className="form-label font14 font-semibold">Coverage Needed<span></span></label>

                        <select
                            className="form-select pb-2 pt-2 font14 "
                            aria-label="Default select example"
                            id="coverageNeeded"
                            name="coverageNeeded"
                            value={jsonData[0].coverageNeeded}
                            onChange={(e) => { handleChange(e) }}>

                            <option value="">Select</option>
                            <option value="50/100/50">50/100/50</option>
                            <option value="100/300/100">100/300/100</option>
                            <option value="250/500/100">250/500/100</option>
                            <option value="30/60/25">30/60/25</option>
                            <option value="Other">Other</option>
                            <option value="I don't know">I don't know</option>

                        </select>

                    </div>

                    <div className="col-md-12 mt-3">

                        <label htmlFor="label10" className="form-label w-100 font14 font-semibold">Do you desire uninsured motorist coverage?</label>

                        <input
                            className="btn-check radio-checked"
                            autoComplete="off"
                            type="radio"
                            name="isUninsuredMotorist"
                            id="noUnInsured"
                            value={"No"}
                            checked={jsonData[0].isUninsuredMotorist === "No"}
                            onClick={(e) => { handleChange(e) }} />

                        <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor="noUnInsured">No</label>

                        <input
                            className="btn-check radio-checked"
                            autoComplete="off"
                            type="radio"
                            name="isUninsuredMotorist"
                            id="yesUnInsured"
                            value={"Yes"}
                            checked={jsonData[0].isUninsuredMotorist === "Yes"}
                            onClick={(e) => { handleChange(e) }} />
                        <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor="yesUnInsured">Yes</label>
                    </div>

                    <div className="col-md-12 mt-3">

                        <label htmlFor="decuctible" className="form-label font14 font-semibold">Preferred Deductible<span></span></label>

                        <select
                            className="form-select pb-2 pt-2 font14 "
                            aria-label="Default select example"
                            name="deductable"
                            id="decuctible"
                            value={jsonData[0].deductable}
                            onChange={(e) => { handleChange(e) }}>
                            <option value="">Select</option>
                            <option value="$500">$500</option>
                            <option value="$1000">$1000</option>
                            <option value="None">None</option>
                        </select>
                    </div>

                    <div className="col-md-12 mt-3">
                        <label htmlFor="dls" className="form-label font14 font-semibold">Do any of the following apply to you?
                        </label>
                        <div className="form-check col-md-12">

                            <input
                                className="form-check-input"
                                type="checkbox"
                                name="personalCar"
                                id="personalCar"
                                placeholder="personalCarc"
                                checked={(jsonData[0].personalCarc === "Yes")}
                                onChange={(e) => { handleChange(e) }}
                            />

                            <label className="form-check-label font14" htmlFor="personalCar">Employees use personal car for business</label>

                        </div>

                        <div className="form-check col-md-12">

                            <input
                                className="form-check-input"
                                type="checkbox"
                                name="rentalCar"
                                id="rentalCar"
                                checked={(jsonData[0].rentalCarc === "Yes")}
                                onChange={(e) => { handleChange(e) }}
                                placeholder="rentalCarc"
                            />

                            <label className="form-check-label font14" htmlFor="rentalCar">Often rent vehicles for business purposes</label>

                        </div>

                        <div className="form-check col-md-12">

                            <input
                                className="form-check-input"
                                type="checkbox"
                                name="unInsuredCar"
                                id="uniCar"
                                placeholder="unInsuredCarc"
                                checked={(jsonData[0].unInsuredCarc === "Yes")}
                                onChange={(e) => { handleChange(e) }}
                            />

                            <label className="form-check-label font14" htmlFor="uniCar">Insure vehicle that do not belong to you
                            </label>

                        </div>

                    </div>
                    {/* General Info Ends Here */}
                    {/* Communication Info Starts Here */}

                </div>
            </div>
        )
    }


    //Pseudocode ID : CAUTO_21;

    /* claim details page bind */
    function claimdetailsPageBind() {

        return (
            <React.Fragment>
                {/* General Info Starts here */}

                <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-md-12 mt-3">

                            <label htmlFor="label10" className="form-label w-100 font14 font-semibold">We will be able to pull your driving history from public reports but are there any
                                accidents or claims you would like to provide details on?</label>

                            <input
                                type="radio"
                                className="btn-check radio-checked"
                                id="hasClaimsId"
                                autoComplete="off"
                                value={"No"}
                                onClick={(e) => { handleChange(e) }}
                                checked={(jsonData[0].hasClaims === "No")}
                                name="hasClaims" />

                            <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor="hasClaimsId">No</label>


                            <input
                                type="radio"
                                className="btn-check radio-checked"
                                id="hasNoClaimsId"
                                autoComplete="off"
                                name="hasClaims"
                                value={"Yes"}
                                checked={(jsonData[0].hasClaims === "Yes")}
                                onClick={(e) => { handleChange(e) }} />

                            <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor="hasNoClaimsId">Yes</label>

                        </div>


                        {(jsonData[0].hasClaims === "Yes") ?
                            <div className="col-md-12 mt-3">

                                <label htmlFor="claimDetails" className="form-label font14 font-semibold">Claim Details</label>

                                <textarea
                                    className="form-control pb-2 pt-2"
                                    id="claimDetails"
                                    rows={3}
                                    name="claimDetails"
                                    value={jsonData[0].claimDetails}
                                    onChange={(e) => { handleChange(e) }}
                                    maxLength={255}
                                    style={{ "resize": "none" }} />

                            </div> : null}

                        <div className="col-md-12 mt-3">

                            <label htmlFor="label10" className="form-label w-100 font14 font-semibold"> Towing?
                                <span className="gib-tool-towing">
                                    <img src={formHelp} className="ms-1 gib-tool-towing" />
                                    <span className="tooltext-towing">{"coverage for tow truck and road side assistance"}</span>
                                </span>
                            </label>
                            <input
                                type="radio"
                                className="btn-check radio-checked"
                                id="towingNo"
                                autoComplete="off"
                                name="towing"
                                checked={jsonData[0].towing === "No"}
                                value={"No"}
                                onClick={(e) => { handleChange(e) }} />

                            <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor="towingNo">No</label>


                            <input
                                type="radio"
                                className="btn-check radio-checked"
                                id="towingYes"
                                autoComplete="off"
                                name="towing"
                                checked={jsonData[0].towing === "Yes"}
                                value={"Yes"}
                                onClick={(e) => { handleChange(e) }} />

                            <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor="towingYes">Yes</label>


                        </div>

                        {/* General Info Ends Here */}
                        {/* Communication Info Starts Here */}
                        <div className="col-md-12 mt-3">

                            <label htmlFor="label10" className="form-label w-100 font14 font-semibold">Rental Reimbursement?</label>

                            <input
                                type="radio"
                                className="btn-check radio-checked"
                                id="reimburseNo"
                                name="rentalReimbursement"
                                autoComplete="off"
                                value={"No"}
                                checked={(jsonData[0].rentalReimbursement === "No")}
                                onClick={(e) => { handleChange(e) }} />
                            <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor="reimburseNo">No</label>


                            <input
                                type="radio"
                                className="btn-check radio-checked"
                                id="reimburseYes"
                                autoComplete="off"
                                name="rentalReimbursement"
                                value={"Yes"}
                                checked={(jsonData[0].rentalReimbursement === "Yes")}
                                onClick={(e) => { handleChange(e) }} />
                            <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor="reimburseYes">Yes</label>
                        </div>

                        <div className="col-md-12 mt-3">

                            <label htmlFor="additionalinsured" className="form-label font14 font-semibold">Additional Insured</label>
                            <select
                                className="form-select pb-2 pt-2 font14 "
                                aria-label="Default select example"
                                name="additionalInsured"
                                id="additionalinsured"
                                value={jsonData[0].additionalInsured}
                                onChange={(e) => { handleChange(e) }}>
                                <option value={""}>Select</option>
                                <option value={"No"}>No</option>
                                <option value={"Yes"}>Yes</option>
                            </select>
                        </div>

                        <div className="col-md-12 mt-3 date-picker">

                            <label
                                className="form-label font14 font-semibold">Closing Date</label>

                            <input
                                type="date"
                                className="form-control pb-2 pt-2 font14 text-uppercase datecontrol" name="closingDate"
                                value={jsonData[0].closingDate}
                                onChange={(e) => { handleChange(e) }}
                                onKeyDown={(e) => { e.preventDefault() }}
                                min={disablePastDate()}
                                onFocus={(e) => setClosingdatemob(true)}
                                placeholder={Closingdatemob ? "" : "MM/DD/YYYY"}
                            />
                            <img src={calender} alt="calender-icon" className="calender-icon "></img>
                            <label className="text-danger font14 w-100 datecontrol">{(isValidSixthPage === false) ? "Please enter a valid Date" : null}</label>

                        </div>

                        {/* Footer */}

                        {/* Footer */}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    //Pseudocode ID : CAUTO_05
    //function for  binding currently insured page if the user chooses just give key points in the firstPage;
    function currentlyInsuredPageForKey() {
        return (
            <React.Fragment>

                {/* General Info Starts here */}
                <div className="col-md-12 mt-3">
                    <div className="row">

                        {/* General Info Ends Here */}
                        {/* Communication Info Starts Here */}

                        <div className="col-md-12 mt-3">

                            <label htmlFor="label10" className="form-label w-100 font14 font-semibold">Are you currently insured?</label>

                            <input
                                type="radio"
                                className="btn-check radio-checked"
                                id="yesForInsured"
                                autoComplete="off"
                                name="isCurrentlyInsured"
                                value={"Yes"}
                                checked={jsonData[0].isCurrentlyInsured === "Yes"}
                                onClick={(e) => { handleChange(e) }} />

                            <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor="yesForInsured">Yes</label>

                            <input
                                type="radio"
                                className="btn-check radio-checked"
                                id="noForInsured"
                                autoComplete="off"
                                value={"No"}
                                name="isCurrentlyInsured"
                                checked={jsonData[0].isCurrentlyInsured === "No"}
                                onClick={(e) => { handleChange(e) }} />

                            <label className="btn btn-primary ms-2 px-4 button-radio font14 font-bold" htmlFor="noForInsured">No</label>

                        </div>


                        {(jsonData[0].isCurrentlyInsured === "No") ?
                            <div className="col-md-12 mt-3">

                                <label htmlFor="lapse" className="form-label font14 font-semibold">Length of lapse in coverage ?<span className="gib-tool-comp">
                                    <img src={formHelp} className="ms-1 gib-tool-comp" />
                                    <span className="tooltext-comp">{"the amount of time in months you have been uninsured"}</span>
                                </span></label>

                                <input
                                    type="text"
                                    className="form-control pb-2 pt-2 font14 text-uppercase" id="lapse"
                                    name="lapseTime"
                                    value={jsonData[0].lapseTime}
                                    onChange={(e) => { handleChange(e) }}
                                    maxLength={10}
                                />

                            </div> : null}

                    </div>
                </div>
            </React.Fragment>
        )
    }

    //PseudoCode_Id:CAUTO_10;
    /*function for posting the data to the data base via service.ts file function*/
    async function postData() {

        let newdriverArray;

        if (jsonData[0].detailType === "Provide us all the details" && jsonData[0].toAddDriver === "Yes") {

            newdriverArray = additionalDriverArray.map((values: any, index: any) => {
                return (
                    {
                        First_Name__c: (values.First_Name__c !== "") ? values.First_Name__c.trimStart().trimEnd() : null,
                        Last_Name__c: (values.Last_Name__c !== "") ? values.Last_Name__c.trimStart().trimEnd() : null,
                        Date_of_Birth__c: (values.Date_of_Birth__c !== "") ? values.Date_of_Birth__c : null,
                        Gender__c: (values.Gender__c !== "") ? values.Gender__c : null,
                        Relationship__c: (values.Relationship__c !== "") ? values.Relationship__c : null,
                        Tickets_or_Accidents__c: (values.Tickets_or_Accident__c !== "") ? values.Tickets_or_Accident__c : null,
                        Commercial_Driver_s_License__c: (values.Commercial_Driver_s_License__c !== "") ? values.Commercial_Driver_s_License__c : null,
                        Driver_s_License_Number__c: (values.Driver_s_License_Number__c !== "" && values.Commercial_Driver_s_License__c === "Yes") ? values.Driver_s_License_Number__c.trimStart().trimEnd() : null,
                        State__c: (values.State__c !== "") ? values.State__c : null
                    }
                )
            });
        } else {
            newdriverArray = [{
                First_Name__c: null,
                Last_Name__c: null,
                Date_of_Birth__c: null,
                Gender__c: null,
                Relationship__c: null,
                Tickets_or_Accident__c: null,
                Commercial_Driver_s_License__c: null,
                Driver_s_License_Number__c: null,
                State__c: null
            }]
        }


        let newVehicleArray = additionalVehicleArray.map(
            (value: any, index: any) => {
                return (
                    {
                        Name: (value.Name !== "") ? value.Name + " " + value.Model__c : null,
                        Make__c: value.Name.trimStart().trimEnd(),
                        Model__c: (value.Model__c !== "") ? value.Model__c.trimStart().trimEnd() : null,
                        Year__c: (value.Year__c !== "") ? value.Year__c : null,
                        Miles_to_work__c: (value.Miles_to_work__c !== "") ? value.Miles_to_work__c : null,
                        VIN__c: (value.VIN__c !== "") ? value.VIN__c.trimStart().trimEnd() : null,
                        Comp_Collision_Deductibles__c: (value.Comp_Collision_Deductibles__c !== "") ? value.Comp_Collision_Deductibles__c.trimStart().trimEnd() : null,

                    }
                )
            }
        )

        let commercialAutoInsuranceQuoteData: any = {
            "personalDetails": {
                "FirstName": (jsonData[0].firstName !== "") ? jsonData[0].firstName.trimStart().trimEnd() : null,
                "LastName": (jsonData[0].lastName !== "") ? jsonData[0].lastName.trimStart().trimEnd() : null,
                "Email": (jsonData[0].email !== "") ? jsonData[0].email : null,
                "MobilePhone": (jsonData[0].mobilePhone !== "") ? jsonData[0].mobilePhone : null,
                "Street": (jsonData[0].street !== "") ? jsonData[0].street.trimStart().trimEnd() : null,
                "City": (jsonData[0].city !== "") ? jsonData[0].city.trimStart().trimEnd() : null,
                "State": (jsonData[0].state !== "") ? jsonData[0].state : null,
                "PostalCode": (jsonData[0].postalCode !== "") ? jsonData[0].postalCode : null,
                "Name_of_Company__c": (jsonData[0].nameOfTheCompany !== "") ? jsonData[0].nameOfTheCompany.trimStart().trimEnd() : null,
                "CC_Person_Marital_Status__c": (jsonData[0].maritalstatus !== "") ? jsonData[0].maritalstatus : null,
                "Preferred_Contact_Method__c": (jsonData[0].prefferedContactMethod !== "") ? jsonData[0].prefferedContactMethod : null,
                "Complete_Quote__c": (jsonData[0].detailType !== "") ? jsonData[0].detailType : null,
                "OwnerId": OwnerId,
                "parameterId":parameterId
            },
            "currentPolicy": {
                "Current_Coverage__c": (jsonData[0].isCurrentlyInsured !== "") ? jsonData[0].isCurrentlyInsured : null,

                "Lapse_Time__c": (jsonData[0].lapseTime !== "" && jsonData[0].isCurrentlyInsured === "No" && jsonData[0].detailType === "Just give key points") ? jsonData[0].lapseTime : (jsonData[0].isCurrentlyInsured === "Yes" && jsonData[0].detailType === "Just give key points") ? null : null,

                "Current_Carrier__c": (jsonData[0].currentCarrier !== "" && jsonData[0].isCurrentlyInsured === "Yes" && jsonData[0].detailType === "Provide us all the details") ? jsonData[0].currentCarrier : (jsonData[0].isCurrentlyInsured === "No" && jsonData[0].detailType === "Provide us all the details") ? null : null,

                "Renewal_Premium__c": (jsonData[0].renewalpremium !== "" && jsonData[0].isCurrentlyInsured === "Yes" && jsonData[0].detailType === "Provide us all the details") ? jsonData[0].renewalpremium : (jsonData[0].isCurrentlyInsured === "No" && jsonData[0].detailType === "Provide us all the details") ? null : null,

                "Previous_Policy_Expiration_Date__c": (jsonData[0].previousPolicyExpirationDate !== "" && jsonData[0].isCurrentlyInsured === "Yes" && jsonData[0].detailType === "Provide us all the details") ? jsonData[0].previousPolicyExpirationDate : (jsonData[0].isCurrentlyInsured === "No" && jsonData[0].detailType === "Provide us all the details") ? null : null,

                VersionData: file === "" ? null : file,
                filename: filename === "" ? null : filename
            },
            "additionDrivers": {
                "Additional_Drivers__c": (jsonData[0].toAddDriver === "") ? "" : jsonData[0].toAddDriver,
                "AdditionalDriverArray": newdriverArray
            },
            "vehicleDetails": {
                "vehicleDetailsArray": newVehicleArray
            },
            "coverageDetails": {
                "Coverage_Needed__c": (jsonData[0].coverageNeeded !== "") ? jsonData[0].coverageNeeded : null,
                "Desire_Uninsured_Motorist__c": (jsonData[0].isUninsuredMotorist !== "") ? jsonData[0].isUninsuredMotorist : null,
                "Preferred_Deductible__c": (jsonData[0].deductable !== "") ? jsonData[0].deductable : null,
                "Employees_Use_Personal_Car_for_Business__c": jsonData[0].personalCar,
                "Rent_Vehicles_for_Business_Purposes__c": jsonData[0].rentalCar,
                "Insure_Car_Do_Not_Belong_to_You__c": jsonData[0].unInsuredCar
            },
            "claimDetails": {
                "Has_Claims__c": (jsonData[0].hasClaims !== "") ? jsonData[0].hasClaims : null,
                "Claim_Details__c": (jsonData[0].claimDetails !== "" && jsonData[0].hasClaims === "Yes") ? jsonData[0].claimDetails : (jsonData[0].hasClaims === "" || jsonData[0].hasClaims === "No") ? null : null,
                "Towing__c": (jsonData[0].towing !== "") ? jsonData[0].towing : null,
                "Rental_Reimbursement__c": (jsonData[0].rentalReimbursement !== "") ? jsonData[0].rentalReimbursement : null,
                "Additional_Insured__c": (jsonData[0].additionalInsured !== "") ? jsonData[0].additionalInsured : null,
                "Closing_Date__c": (jsonData[0].closingDate !== "") ? jsonData[0].closingDate : null
            }
        }

        setLoading(true);

        let data = await postInsuranceQuoteData(commercialAutoInsuranceQuoteData, "cauto");

        //   let data = await axios.post('https://gibraltar-swf-dev-af.azurewebsites.net/api/autoInsuranceApi?code=9p1erS1Bm0t4D5JvwZwauPObCAOBzooxmsfaz0SYFZjkS6C44Dq1vA==&type=Commercial Auto Insurance',commercialAutoInsuranceQuoteData);

        console.log(commercialAutoInsuranceQuoteData);


        window.location.href = '/thankyou';
        console.log(data.data);
    }

    const payLoadForHeader = {
        Name: "Commercial Auto Insurance",
        currentPg: currentPage,
        totalPages: (jsonData[0].detailType === "Just give key points") ? 2 : 6,
        pgTopic: pages,
        previousStepArry: previousStepsArray
    }

    const childToParent = (data: any) => {
        console.log(data, "page");
        setCurrentPage(data)
    }

    /*complete component return part starts here*/
    return (
        <div className="container">
            <div className="row mt-4">
                {/* Form starts here */}
                <div className="col-lg-8 col-md-12 col-sm-12">
                    <div className="row">
                        <div className="col-lg-11 col-md-12 col-sm-12">
                            <div className="row">

                                <Header value={payLoadForHeader} childToParent={(data: any) => childToParent(data)} />

                                {/* Form-Component */}
                                {(currentPage === 1) ? personalDetailsPageBind() : null}

                                {(currentPage === 2 && jsonData[0].detailType === "Provide us all the details") ? currentlyInsuredPageForAll() : null}

                                {(currentPage === 3 && jsonData[0].detailType === "Provide us all the details") ? additionalDriverDetailsPageBind() : null}

                                {(currentPage === 4 && jsonData[0].detailType === "Provide us all the details") ? additionalVehicleDetailsPageBind() : null}

                                {(currentPage === 5 && jsonData[0].detailType === "Provide us all the details") ? coverageDetailsPageBind() : null}

                                {(currentPage === 6 && jsonData[0].detailType === "Provide us all the details") ? claimdetailsPageBind() : null}

                                {(currentPage === 2 && jsonData[0].detailType === "Just give key points") ? currentlyInsuredPageForKey() : null}
                                {/* Footer */}
                                <div className="col-md-12 mt-4 mb-4">
                                    {(currentPage > 1) ? <React.Fragment>
                                        <button type="button" className="btn btn-outline-secondary font14 mt-4 px-5 previous-button mobile-button" onClick={() => { previousButtonClick(currentPage, "back") }}>
                                            <span className="previous-button-arrow me-1">
                                                <img src={arrowLeft} alt="auto" />
                                                <img src={arrowWhite} alt="auto" />
                                            </span>
                                            Previous</button> </React.Fragment> : null
                                    }
                                    {
                                        ((currentPage === 2 && jsonData[0].detailType === "Just give key points") || (currentPage === 6 && jsonData[0].detailType === "Provide us all the details")) ?
                                            <button
                                                type="button"
                                                className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button"
                                                onClick={() => { validation(currentPage) }}> Request Quote
                                            </button> :

                                            <button
                                                type="button"
                                                className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button"
                                                onClick={() => { validation(currentPage) }}
                                                disabled={(currentPage === 3 && openedDriver !== -1 && jsonData[0].toAddDriver === "Yes") || (currentPage === 4 && openedVehicle !== -1)}
                                            >
                                                Next Step
                                                <img src={arrowRight} alt="arrow" className="ms-2" />
                                            </button>
                                    }
                                </div>
                                {/* Footer */}
                                {/* Form-Component */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Form starts here */}
                {/* Right panel starts Here */}
                <div className="col-md-4 right-panel-margin hide-right">
                    <div className="row mx-0">
                        {(previousStepsArray.length !== 0) ?
                            <React.Fragment>
                                < div className="col-md-12 border bg-light p-2 previous-steps-box border-bottom-0">
                                    <label className="ps-2 font-16 font-bold">Previous Steps</label>
                                </div>

                                <div className="col-md-12 border border-top-0 previous-steps-box  px-0">

                                    {previousStepsArray.map((values: any, index: any) => {
                                        return (
                                            <React.Fragment>
                                                {/* <li className="list-unstyled px-0 font16 font-primary" onClick={() => { setCurrentPage(index + 1) }} title={`Go to step ${index + 1}`}>
                                                    <a className="dropdown-item py-2" href="#">
                                                        {values}<img src={edit} className="edit-icon ms-2" />
                                                    </a>
                                                </li> */}
                                                <li className="list-unstyled px-0 font16 font-primary">
                                                    <a className="dropdown-item py-2 gib-tooltip" href="#" onClick={() => { setCurrentPage(index + 1) }} >
                                                        {values}
                                                        <span className="gib-tooltip" ><img src={edit} className="edit-icon ms-2" />
                                                            <span className="tooltiptext">{"Go to step " + (index + 1)}</span>
                                                        </span>
                                                    </a>
                                                </li>
                                            </React.Fragment>
                                        )
                                    })
                                    }
                                </div></React.Fragment> : null
                        }
                        <div className={(previousStepsArray.length !== 0) ? "col-md-12 border bg-light previous-steps-box p-2 mt-4 border-bottom-0" : "col-md-12 border bg-light p-2 previous-steps-box border-bottom-0"}>
                            <label className="ps-2 font-16 font-bold">Need Help ?</label>
                        </div>
                        <div className="col-md-12 border border-top-0 previous-steps-box">
                            <label className="ps-2 w-100 mt-3 font16 font-semibold">The Gibraltar Group</label>
                            <label className="ps-2 w-100 mt-2 mb-3 font14">(281) 681-0331</label>
                        </div>
                        {/* Need Help */}
                        {/* Home button */}
                        <button type="button" className="btn btn-primary mt-4 py-2 primary-button border-0 font14 home-button" onClick={() => { window.location.href = '/' }}><img src={arrowLeftWhiteIcon} alt="auto" className="me-1" /> Back to Home</button>
                        {/* Home button */}
                    </div>
                </div>

            </div>
            {Loading === true ? <div id="root">

                <h1>Submitting Your Request</h1>
                <h3>Please Wait for few seconds....</h3>

                <div className="loader-wrapper">
                    <div className="loader"><span></span><span></span><span></span><span></span><span></span></div>
                </div>

            </div> : null}
        </div >
    )
}

//exporting the component;
export default CommercialAutoInsuranceDrivers;

