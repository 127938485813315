/*
   // Project: Gibraltar
   // Desc: This file contains the Thank you page component;
   // Author:Harshanth Raaj
   // Created Date:04/15/2022
   //File Name : thankYou.tsx;
*/

import react, { useState } from 'react';
import Loch from '../images/Loch-ness.jpg';
import '../App.css';
// import '../fonts'

export default function ThankYou() {
    
    return(
        <div className="container mt-5">
        <div className="row justify-content-center">
          {/* Thank you */}
          <div className="col-md-10 text-center mt-5 border-bottom pb-5">
            <label className="font-bold font40 insurance-type">Thank You!</label>
            <p className="font20 mt-2">Your form has been received and one of our representatives will be in touch very soon.</p>
          </div>
          {/* Thank you */}
          {/* Facts */}
         
          <div className="col-lg-5 col-md-5 col-sm-12  mt-5">
          <div className='thankyou-mobile'>
            <label className="font24 font-bold insurance-type">
              Did you know?
            </label>
            <p className="font20">An insurance company offered a cash reward to anyone who could capture the Loch Ness monster.</p>
            </div>
          </div>
          
          <div className="col-lg-5 col-md-5 col-sm-12 mt-5 mb-5">
            <img src={Loch} alt="loch-img" className="w-100" />
          </div>
          <div className="col-md-8 mt-4 mb-4">
            <p className="font18">In 1971, whiskey manufacturer <a href='https://us.cutty-sark.com/' className="text-primary text-decoration-none">Cutty Sark</a> offered an award of one million pounds ($2.4 million) to anyone who could capture the  <a href='https://en.wikipedia.org/wiki/Loch_Ness_Monster' className="text-primary text-decoration-none">Loch Ness Monster</a>.</p>
            <p className="font18">The company also asked <a href='https://www.lloyds.com/' className="text-primary text-decoration-none">Lloyd’s of London</a> to underwrite the contest.</p>
            <p className="font18">Lloyd’s agreed, under one condition: that it would get to keep Nessie.</p>
          </div>
          {/* Facts */}
        </div>
      </div>
    
    );
    
}


