/*
    /* Project: Gibraltar Insurance Forms 
    /* Desc: This file contains the source code for Auto insurance form. 
    /* Author:Karpagalakshmi Balakrishnan
    /* Created Date:4/6/2022
    /* Updated Date: 5/4/2022
    */
    import React, { useEffect, useRef, useState } from "react";
    import PhoneInput from "react-number-format";
    import { postInsuranceQuoteData } from "./service";
    import leftarrow from "../images/Arrow-Left.svg";
    import leftarrowwhite from "../images/Arrow-Left-White.svg";
    import rightarrow from "../images/Arrow-right.svg";
    import editicon from "../images/Edit.svg";
    import plusicon from "../images/Plus-icon.svg";
    import helpicon from "../images/Form-Help.svg";
    import plusiconlight from "../images/Plus-Icon-Light.svg";
    import Header from "./header";
    import "../App.css";
    import axios from "axios";
    import Cancel_button from "../images/Cancel-button.svg";
    import calender from '../images/Calender.svg'
    
    function AutoInsurance() {
      var QueryString = new URLSearchParams(window.location.search);
      var OwnerId = QueryString.get("agentId");
      let parameterId= QueryString.get("parameterId");
    
      //PC_Auto_2
      //Declaring state variables
      const [autoInsuranceDetails, setautoInsuranceDetails] = useState([
        {
          firstName: "",
          lastName: "",
          dateOfBirth: "",
          personMaritalStatus:"",
          mobilePhone: "",
          email: "",
          street: "",
          city: "",
          state: "",
          postalCode: "",
          driverLicenseNumber: "",
          driverLicenseState: "",
          preferredContactMethod: "",
          preferredDetails: "Provide us all the details",
          currentlyInsured: "",
          currentCarrier: "",
          policyExpirationDate: "",
          renewalPremium:"",
          policyexpireddate: "",
          additionalDrivers: "",
          coverageNeeded: "",
          unInsuredMotorist: "",
          personalInjuryCoverage: "",
          hasClaims: "",
          needTowing: "",
          reimbursement: "",
          claimDetails: "",
        },
      ]);
    
      const [yearRegex, setyearRegex] = useState(false);
      const [vinRegex, setvinRegex] = useState(false);
      const [validationAlert, setvalidationAlert] = useState(false);
      const [labelShowDriver, setlabelShowDriver] = useState([{ saveHide: false }]);
      const [labelShowVechicle, setlabelShowVechicle] = useState([
        { saveHide: false },
      ]);
      const [driversSaved, setdriversSaved] = useState(true);
      const [vechiclesSaved, setvechiclesSaved] = useState(true);
      const [dynamicpages, setdynamicpages] = useState([]);
      const [currentpage, setcurrentpage] = useState(1);
      const [totalpage, settotalpage] = useState(6);
      const [discount, setdiscount] = useState([]);
    
      const [discountstring, setdiscountstring] = useState("");
      const [drivers, setdrivers] = useState([
        {
          First_Name__c: "",
          Last_Name__c: "",
          Date_of_Birth__c: "",
          Gender__c: "",
          Relationship__c:"",
          Driver_s_License_Number__c: "",
          Qualified_Discounts__c: "",
          Tickets_or_Accidents__c:"",
          State__c: "",
          saved: false,
        },
      ]);
      const [vechicles, setvechicles] = useState([
        {
          Name: "",
          Model__c: "",
          Year__c: "",
          VIN__c: "",
          Miles_to_work__c:"",
          Comp_Collision_Deductibles__c: "",
         
          saved: false,
        },
      ]);
    
      const [firstNameReg, setfirstNameReg] = useState(false);
      const [Loading, setLoading] = useState(false);
      const [emailReg, setemailReg] = useState(false);
      const [phoneNoReg, setphoneNoReg] = useState(false);
      const [zipCodeReg, setzipCodeReg] = useState(false);
      const [drivervalidation, setdrivervalidation] = useState(false);
      const [driverlicensenumberRegex, setdriverlicensenumberRegex] =
        useState(false);
    
    
      const [file, setfile] = useState();
      const [fileValid, setfileValid] = useState(false)
      const [fileLoading, setFileLoading] = useState(false)
      const [cancelvisible, setCancelvisible] = useState(false)
      const myref: any = useRef(null);
      const [filename, setfilename] = useState("")
    
      // this state variable is for setting the date placeholder for mobile and ios
      const [dateFieldFocused, setdateFieldFocused] = useState(false)
    
      const [datefieldpreviouspolicymob, setdatefieldpreviouspolicymob] = useState(false)
    
      const [AddDob, setAddDob] = useState(false)
      const[formatValid,setformatValid]=useState(false)
    
    
      const pages = [
        "Personal Details",
        "Current Policy Information",
        "Additional Drivers",
        "Tell us about your vehicles",
        "Coverage Details",
        "Claim Details",
      ];
      const disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
      };
    
    
      function Cancel_buttonClick() {
        setFileLoading(false)
        setfilename("")
        myref.current.value = ""
    
        setCancelvisible(false)
      }
      const [size, setSize] = useState(0)
      const [type, setType] = useState("")
      async function validateFile(e: any) {
    
        const convertBase64 = (files: Blob) => {
    
          return new Promise((resolve, reject) => {
    
            const fileReader = new FileReader()
    
            fileReader.readAsDataURL(files)
    
    
    
            fileReader.onload = (() => {
    
              resolve(fileReader.result)
    
            })
    
    
    
            fileReader.onerror = ((error: any) => {
    
              reject(error)
    
            })
    
          })
    
        }
    
    
    
        console.log(e.target.files[0])
        setSize(e.target.files[0].size)
        setType(e.target.files[0].type)
    
    
        if (e.target.files[0].type !== "application/pdf") {
    
          e.target.value = ""
          setFileLoading(false)
          setformatValid(true)
          setfileValid(false)
          setfilename("")
          setCancelvisible(false)
    
        }
    
        if (e.target.files[0].size <= 5242880) {
          setCancelvisible(true)
          const blobFile = e.target.files[0]
    
          const filename = e.target.files[0].name
    
          console.log(filename)
    
          let s = filename
    
          let a = s.replace('.pdf', "")
    
          console.log("filename-->", a)
    
          setfilename(a)
    
          setFileLoading(true)
    
          let base64: any = await convertBase64(blobFile)
    
          console.log(base64)
    
          let b = base64.replace('data:application/pdf;base64,', "")
    
          console.log("Base64", b)
    
          setFileLoading(false)
    
          setfile(b)
    
        }
    
        else if (e.target.files[0].size === 0 || e.target.files[0].size > 5242880) {
          e.target.value = '';
          setfileValid(true)
          setfilename("")
          setCancelvisible(false)
        }
    
    
    
      }
    
    
      useEffect(() => {
        clearFields();
        if (autoInsuranceDetails[0].preferredDetails === "Just give key points") {
          settotalpage(5);
        } else {
          settotalpage(6);
        }
      }, [autoInsuranceDetails[0].preferredDetails]);
    
      //PC_Auto_24
      //This function is used to clear all the fields when the flow is changed from provide us all details to just give key points and viceversa
      function clearFields() {
        let autoInsurance = [...autoInsuranceDetails];
        autoInsurance[0].firstName = autoInsuranceDetails[0].firstName;
        autoInsurance[0].lastName = autoInsuranceDetails[0].lastName;
        autoInsurance[0].dateOfBirth = autoInsuranceDetails[0].dateOfBirth;
        autoInsurance[0].personMaritalStatus=autoInsurance[0].personMaritalStatus;
        autoInsurance[0].mobilePhone = autoInsuranceDetails[0].mobilePhone;
        autoInsurance[0].street = autoInsuranceDetails[0].street;
        autoInsurance[0].city = autoInsuranceDetails[0].city;
        autoInsurance[0].driverLicenseState =
          autoInsuranceDetails[0].driverLicenseState;
        autoInsurance[0].driverLicenseNumber =
          autoInsuranceDetails[0].driverLicenseNumber;
        autoInsurance[0].postalCode = autoInsuranceDetails[0].postalCode;
        autoInsurance[0].additionalDrivers = "";
        autoInsurance[0].claimDetails = "";
        autoInsurance[0].coverageNeeded = "";
        autoInsurance[0].currentCarrier = "";
        autoInsurance[0].currentlyInsured = "";
        autoInsurance[0].hasClaims = "";
        autoInsurance[0].needTowing = "";
        autoInsurance[0].personalInjuryCoverage = "";
        autoInsurance[0].policyExpirationDate = "";
        autoInsurance[0].policyexpireddate = "";
        autoInsurance[0].renewalPremium ="";
        autoInsurance[0].unInsuredMotorist = "";
        autoInsurance[0].reimbursement = "";
        setautoInsuranceDetails(autoInsurance);
        setdrivers([
          {
            First_Name__c: "",
            Last_Name__c: "",
            Date_of_Birth__c: "",
            Gender__c: "",
            Relationship__c:"",
            Driver_s_License_Number__c: "",
            Qualified_Discounts__c: "",
            Tickets_or_Accidents__c:"",
            State__c: "",
            saved: false,
          },
        ]);
        setvechicles([
          {
            Name: "",
            Model__c: "",
            Year__c: "",
            VIN__c: "",
            Comp_Collision_Deductibles__c: "",
            Miles_to_work__c:"",
           
            saved: false,
          },
        ]);
        setdynamicpages([]);
        setlabelShowDriver([{ saveHide: false }]);
        setlabelShowVechicle([{ saveHide: false }]);
        setvechiclesSaved(true);
        setdriversSaved(true);
        setdiscountstring("");
        setdrivervalidation(false);
      }
    
      //PC_Auto_23
      //When user enters any values in any field this method is invoked and values are set in the json array for the particular field
      function handlechange(e: any) {
        let name = "",
          value = "";
        let temparray: any = [...autoInsuranceDetails];
    
        if (e.target) {
          name = e.target.name;
          value = e.target.value;
          if (
            e.target.name === "postalCode" ||
            e.target.name === "policyexpireddate"||
            e.target.name === "renewalPremium"
          ) {
            value = value.replace(/[^0-9]/g, "");
          } else if (e.target.name === "email") {
            var regEx = /^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)*[\w+-]+\.[a-zA-Z]{2,4}$/;
            if (!value.match(regEx)) {
              setemailReg(true);
              //errorcode = true
            } else {
              setemailReg(false);
            }
          } else if (e.target.name === "postalCode") {
            if (value.length < 5) {
              setzipCodeReg(true);
            } else {
              setzipCodeReg(false);
            }
          } else if (e.target.name === "driverLicenseNumber") {
            value = value.replace(/[^A-Za-z0-9]+/g, "");
          }
          temparray[0][name] = value;
        } else {
          name = "mobilePhone";
          value = e.value;
          temparray[0][name] = value;
    
          var phoneFormat = /^\d{10}$/;
          if (!value.match(phoneFormat)) {
            setphoneNoReg(true);
            // errorcode = true
          } else {
            setphoneNoReg(false);
          }
        }
        setautoInsuranceDetails(temparray);
    
        //e.target.value=temparray.key;
      }
    
      //PC_Auto_3
      //Html structure for personal details page is returned here
      function personalDetails() {
        // setcurrentpage(1);
        return (
          <React.Fragment>
            {/* Form-Component */}
            {/* General Info Starts here */}
            <div className="col-md-12 mt-3">
              <label className="font20 font-primary font-semibold font-head pb-2 border-bottom w-100">
                General Info
              </label>
            </div>
            <div className="col-md-6 mt-3">
              <label
                htmlFor="firstName"
                className="form-label font14 font-semibold"
              >
                First Name*
              </label>
              <input
                type="text"
                className="form-control pt-2 pb-2"
                name="firstName"
                maxLength={40}
                id="firstName"
                value={autoInsuranceDetails[0].firstName}
                onChange={(e) => handlechange(e)}
                
              />
              {validationAlert === true ? (
                <label className="text-danger font14 w-100">
                  {(validationAlert === true &&
                    autoInsuranceDetails[0].firstName === "") ||
                    (validationAlert === true &&
                      autoInsuranceDetails[0].firstName.trim() == "")
                    ? "Please enter the First Name"
                    : null}
                </label>
              ) : null}
            </div>
            <div className="col-md-6 mt-3">
              <label htmlFor="lastName" className="form-label font14 font-semibold">
                Last Name*
              </label>
              <input
                type="text"
                className="form-control pt-2 pb-2"
                name="lastName"
                id="lastName"
                maxLength={80}
                value={autoInsuranceDetails[0].lastName}
                onChange={(e) => handlechange(e)}
                
              />
              {validationAlert === true ? (
                <label className="text-danger font14 w-100">
                  {(validationAlert === true &&
                    autoInsuranceDetails[0].lastName === "") ||
                    (validationAlert === true &&
                      autoInsuranceDetails[0].lastName.trim() === "")
                    ? "Please enter the Last Name"
                    : null}
                </label>
              ) : null}
            </div>
            <div className="col-md-6 mt-3 date-picker ">
              <label
                className="form-label font14 font-semibold"
              >
                Date of Birth*
              </label>
              <input
                type="date"
                className="form-control font14 text-uppercase pt-2 pb-2 datecontrol "
                name="dateOfBirth"
                value={autoInsuranceDetails[0].dateOfBirth}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                onChange={(e) => handlechange(e)}
                max={disablePastDate()}
                onFocus={(e) => setdateFieldFocused(true)}
                placeholder={dateFieldFocused ? "" : "MM/DD/YYYY"}
              />
              <img src={calender} alt="calender-icon" className="calender-icon "></img>
              {validationAlert === true &&
                autoInsuranceDetails[0].dateOfBirth === "" ? (
                <label className="text-danger font14 w-100">
                  Please enter the Date of Birth
                </label>
              ) : null}
            </div>
            <div className="col-md-6 mt-3 ">
            <label className="form-label w-100 font14 font-semibold">
                 Marital Status
              </label>
               <input
                type="radio"
                className="btn-check radio-checked px-4"
                name="personMaritalStatus"
                id="p_single"
                checked={autoInsuranceDetails[0].personMaritalStatus === "Single"}
                value="Single"
                onChange={(e) => handlechange(e)}
                autoComplete="off"
              />
              <label
                className="btn btn-primary button-radio font14 font-bold"
                htmlFor="p_single"
              >
                Single
              </label>
              <input
                type="radio"
                className="btn-check radio-checked px-4"
                name="personMaritalStatus"
                id="p_married"
                checked={autoInsuranceDetails[0].personMaritalStatus === "Married"}
                value="Married"
                onChange={(e) => handlechange(e)}
                autoComplete="off"
              />
              <label
                className="btn btn-primary ms-2 button-radio font14 font-bold"
                htmlFor="p_married"
              >
                Married 
              </label>
              </div>
            {/* General Info Ends Here */}
            {/* Communication Info Starts Here */}
            <div className="col-md-12 mt-4">
              <label className="font20 font-primary font-semibold font-head pb-2 border-bottom w-100">
                Communication Info
              </label>
            </div>
            <div className="col-md-12 mt-3">
              <label htmlFor="email" className="form-label font14 font-semibold">
                Email*
              </label>
              <input
                type="email"
                className="form-control pt-2 pb-2"
                name="email"
                maxLength={80}
                id="email"
                value={autoInsuranceDetails[0].email}
                onChange={(e) => handlechange(e)}
                
              />
              {validationAlert === true ? (
                <label className="text-danger font14 w-100">
                  {(validationAlert === true &&
                    autoInsuranceDetails[0].email === "") ||
                    (validationAlert === true &&
                      autoInsuranceDetails[0].email.trim() === "")
                    ? "Please enter an Email"
                    : validationAlert === true && emailReg === true
                      ? "Please enter a valid Email"
                      : null}
                </label>
              ) : null}
            </div>
            <div className="col-md-12 mt-3">
              <label
                htmlFor="mobilePhone"
                className="form-label font14 font-semibold"
              >
                Mobile Phone*
              </label>
              {autoInsuranceDetails[0].mobilePhone.length < 10 ? (
                <PhoneInput
                  type={"tel"}
                  className="form-control pt-2 pb-2"
                  maxLength={10}
                  value={autoInsuranceDetails[0].mobilePhone}
                  onValueChange={(e) => {
                    handlechange(e);
                  }}
                  id="mobilePhone"
                  name="mobilePhone"
                  placeholder="XXXXXXXXXX"
                />
              ) : (
                <PhoneInput
                  type={"tel"}
                  format={"(###) ###-####"}
                  className="form-control pt-2 pb-2"
                  maxLength={10}
                  value={autoInsuranceDetails[0].mobilePhone}
                  onValueChange={(e) => {
                    handlechange(e);
                  }}
                  id="mobilePhone"
                  name="mobilePhone"
                  placeholder="XXXXXXXXXX"
                />
              )}
              {validationAlert === true ? (
                <label className="text-danger font14 w-100">
                  {(validationAlert === true &&
                    autoInsuranceDetails[0].mobilePhone === "") ||
                    (validationAlert === true &&
                      autoInsuranceDetails[0].mobilePhone.trim() === "")
                    ? "Please enter the Mobile Number"
                    : validationAlert === true && phoneNoReg === true
                      ? "Please enter a valid Mobile Number "
                      : null}
                </label>
              ) : null}
            </div>
            <div className="col-md-12 mt-3">
              <label htmlFor="street" className="form-label font14 font-semibold">
                Street*
              </label>
              <textarea
                className="form-control text-area-input pt-2 pb-2"
                name="street"
                id="street"
                maxLength={120}
                value={autoInsuranceDetails[0].street}
                onChange={(e) => handlechange(e)}
                rows={6}
                defaultValue={""}
              />
              {(validationAlert === true &&
                autoInsuranceDetails[0].street === "") ||
                (validationAlert === true &&
                  autoInsuranceDetails[0].street.trim() === "") ? (
                <label className="text-danger font14 w-100">
                  Please enter the Street
                </label>
              ) : null}
            </div>
            <div className="col-md-12 mt-3">
              <label htmlFor="city" className="form-label font14 font-semibold">
                City*
              </label>
              <input
                type="text"
                className="form-control pt-2 pb-2"
                name="city"
                id="city"
                maxLength={40}
                value={autoInsuranceDetails[0].city}
                onChange={(e) => handlechange(e)}
                
              />
              {(validationAlert === true && autoInsuranceDetails[0].city === "") ||
                (validationAlert === true &&
                  autoInsuranceDetails[0].city.trim() === "") ? (
                <label className="text-danger font14 w-100">
                  Please enter the City
                </label>
              ) : null}
            </div>
            <div className="col-md-6 mt-3">
              <label htmlFor="state" className="form-label font14 font-semibold">
                State/Province*
              </label>
              <select
                className="form-select pt-2 pb-2"
                name="state"
                id="state"
                value={autoInsuranceDetails[0].state}
                onChange={(e) => handlechange(e)}
                
              >
                <option value="">Select State/Province</option>
                <option value="AK">AK</option>
                <option value="AL">AL</option>
                <option value="AR">AR</option>
                <option value="AZ">AZ</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DE">DE</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="HI">HI</option>
                <option value="IA">IA</option>
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="MA">MA</option>
                <option value="MD">MD</option>
                <option value="ME">ME</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MO">MO</option>
                <option value="MS">MS</option>
                <option value="MT">MT</option>
                <option value="NC">NC</option>
                <option value="ND">ND</option>
                <option value="NE">NE</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>
                <option value="NV">NV</option>
                <option value="NY">NY</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VA">VA</option>
                <option value="VT">VT</option>
                <option value="WA">WA</option>
                <option value="WI">WI</option>
                <option value="WV">WV</option>
                <option value="WY">WY</option>
              </select>
              {validationAlert === true && autoInsuranceDetails[0].state === "" ? (
                <label className="text-danger font14 w-100">
                  Please select the State/Province
                </label>
              ) : null}
            </div>
            <div className="col-md-6 mt-3">
              <label
                htmlFor="postalCode"
                className="form-label font14 font-semibold"
              >
                Zip/Postal Code*
              </label>
              <input
                type="text"
                maxLength={5}
                className="form-control pt-2 pb-2"
                name="postalCode"
                id="postalCode"
                value={autoInsuranceDetails[0].postalCode}
                onChange={(e) => handlechange(e)}
                
              />
              {validationAlert === true ? (
                <label className="text-danger font14 w-100">
                  {(validationAlert === true &&
                    autoInsuranceDetails[0].postalCode === "") ||
                    (validationAlert === true &&
                      autoInsuranceDetails[0].postalCode.trim() === "")
                    ? "Please enter the Zip/Postal Code"
                    : validationAlert === true &&
                      autoInsuranceDetails[0].postalCode.length < 5
                      ? "Please enter a valid Zip/Postal code"
                      : null}
                </label>
              ) : null}
            </div>
    
            <div className="col-md-12 mt-3 ">
              <label className="form-label w-100 font14 font-semibold">
                Preferred Contact Method
              </label>
              <input
                type="radio"
                className="btn-check radio-checked px-4"
                name="preferredContactMethod"
                id="phone"
                checked={autoInsuranceDetails[0].preferredContactMethod === "Phone"}
                value="Phone"
                onChange={(e) => handlechange(e)}
                autoComplete="off"
              />
              <label
                className="btn btn-primary button-radio font14 font-bold"
                htmlFor="phone"
              >
                Phone
              </label>
              <input
                type="radio"
                className="btn-check radio-checked px-4"
                name="preferredContactMethod"
                id="p_email"
                checked={autoInsuranceDetails[0].preferredContactMethod === "Email"}
                value="Email"
                onChange={(e) => handlechange(e)}
                autoComplete="off"
              />
              <label
                className="btn btn-primary ms-2 button-radio font14 font-bold"
                htmlFor="p_email"
              >
                Email
              </label>
              <input
                type="radio"
                className="btn-check radio-checked px-4"
                name="preferredContactMethod"
                id="text"
                checked={autoInsuranceDetails[0].preferredContactMethod === "Text"}
                value="Text"
                onChange={(e) => handlechange(e)}
                autoComplete="off"
              />
              <label
                className="btn btn-primary ms-2 button-radio font14 font-bold"
                htmlFor="text"
              >
                Text
              </label>
              <input
                type="radio"
                className="btn-check radio-checked px-4"
                name="preferredContactMethod"
                id="p_mail"
                checked={autoInsuranceDetails[0].preferredContactMethod === "Mail"}
                value="Mail"
                onChange={(e) => handlechange(e)}
                autoComplete="off"
              />
              <label
                className="btn btn-primary ms-2 button-radio font14 font-bold"
                htmlFor="p_mail"
              >
                Mail
              </label>
            </div>
            {/* Communication Info Ends Here */}
            <div className="col-md-12 mt-4">
              <label className="font20 font-primary font-semibold font-head pb-2 border-bottom w-100">
                License Info
              </label>
            </div>
            <div className="col-md-6 mt-3">
              <label
                htmlFor="driverLicenseState"
                className="form-label font14 font-semibold"
              >
                Driver's License State*
              </label>
              <select
                className="form-select pt-2 pb-2"
                name="driverLicenseState"
                id="driverLicenseState"
                value={autoInsuranceDetails[0].driverLicenseState}
                onChange={(e) => handlechange(e)}
              >
                <option value="">Select State</option>
                <option value="AK">AK</option>
                <option value="AL">AL</option>
                <option value="AR">AR</option>
                <option value="AZ">AZ</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DE">DE</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="HI">HI</option>
                <option value="IA">IA</option>
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="MA">MA</option>
                <option value="MD">MD</option>
                <option value="ME">ME</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MO">MO</option>
                <option value="MS">MS</option>
                <option value="MT">MT</option>
                <option value="NC">NC</option>
                <option value="ND">ND</option>
                <option value="NE">NE</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>
                <option value="NV">NV</option>
                <option value="NY">NY</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VA">VA</option>
                <option value="VT">VT</option>
                <option value="WA">WA</option>
                <option value="WI">WI</option>
                <option value="WV">WV</option>
                <option value="WY">WY</option>
              </select>
              {validationAlert === true &&
                autoInsuranceDetails[0].driverLicenseState === "" ? (
                <label className="text-danger font14 w-100">
                  Please select the Driver's License State
                </label>
              ) : null}
            </div>
            <div className="col-md-6 mt-3 mb-3">
              <label
                htmlFor="driverLicenseNumber"
                className="form-label font14 font-semibold"
              >
                Driver's License Number*
              </label>
              <input
                type="text"
                className="form-control pt-2 pb-2"
                name="driverLicenseNumber"
                maxLength={19}
                id="driverLicenseNumber"
                value={autoInsuranceDetails[0].driverLicenseNumber}
                onChange={(e) => handlechange(e)}
              />
              {validationAlert === true ? (
                <label htmlFor="" className="text-danger font14 w-100">
                  {(validationAlert === true &&
                    autoInsuranceDetails[0].driverLicenseNumber === "") ||
                    (validationAlert === true &&
                      autoInsuranceDetails[0].driverLicenseNumber.trim() === "")
                    ? "Please enter the Driver's License Number"
                    : validationAlert === true &&
                      autoInsuranceDetails[0].driverLicenseNumber.length < 1
                      ? "Please enter a valid Driver's License Number"
                      : null}
                </label>
              ) : null}
            </div>
            <div className="col-md-12">
              <label
                htmlFor="Provide us all the details"
                className="form-label w-100 font14 font-semibold border-top pt-3 w-100"
              >
                Would you like to?*
              </label>
              <input
                type="radio"
                className="btn-check radio-checked px-4"
                name="preferredDetails"
                id="alldetails"
                checked={
                  autoInsuranceDetails[0].preferredDetails ===
                  "Provide us all the details"
                }
                value="Provide us all the details"
                onChange={(e) => handlechange(e)}
                autoComplete="off"
              />
              <label
                className="btn btn-primary button-radio font14 font-bold px-4 button-radio2"
                htmlFor="alldetails"
              >
                Provide us all the details
              </label>
              <input
                type="radio"
                className="btn-check radio-checked px-4"
                name="preferredDetails"
                id="keypoints"
                checked={
                  autoInsuranceDetails[0].preferredDetails ===
                  "Just give key points"
                }
                value="Just give key points"
                onChange={(e) => handlechange(e)}
                autoComplete="off"
              />
              <label
                className="btn btn-primary ms-2 button-radio font14 font-bold px-4 button-radio2 button-just"
                htmlFor="keypoints"
              >
                Just give key points
              </label>
              {validationAlert === true &&
                autoInsuranceDetails[0].preferredDetails === "" ? (
                <label className="text-danger font14 w-100">
                  Please select your choice
                </label>
              ) : null}
            </div>
            {/* Form-Component */}
          </React.Fragment>
        );
      }
    
      //PC_Auto_4
      //Html structure for current policy details page is returned here
      function currentPolicyinfo() {
        //setcurrentpage(2);
        return (
          <React.Fragment>
            <div className="col-md-12 mt-3">
              <label
                htmlFor="label10"
                className="form-label w-100 font14 font-semibold"
              >
                Are you currently insured?*
              </label>
              <input
                type="radio"
                className="btn-check radio-checked px-4"
                name="currentlyInsured"
                id="Insured_Yes"
                value="Yes"
                checked={autoInsuranceDetails[0].currentlyInsured === "Yes"}
                onChange={(e) => handlechange(e)}
                autoComplete="off"
              />
              <label
                className="btn btn-primary button-radio font14 font-bold px-4"
                htmlFor="Insured_Yes"
              >
                Yes
              </label>
              <input
                type="radio"
                className="btn-check radio-checked px-4"
                name="currentlyInsured"
                id="Insured_No"
                value="No"
                checked={autoInsuranceDetails[0].currentlyInsured === "No"}
                onChange={(e) => handlechange(e)}
                autoComplete="off"
              />
              <label
                className="btn btn-primary ms-2 button-radio font14 font-bold px-4"
                htmlFor="Insured_No"
              >
                No
              </label>
              {validationAlert === true &&
                autoInsuranceDetails[0].currentlyInsured === "" ? (
                <label className="text-danger font14 w-100">
                  Please select your choice
                </label>
              ) : null}
            </div>
            {/* Communication Info Ends Here */}
            {autoInsuranceDetails[0].preferredDetails ===
              "Provide us all the details" ? (
              <div className="col-md-12 mt-3">
                <label
                  htmlFor="label-DC"
                  className="form-label font14 font-semibold w-100"
                >
                  Do you qualify for any of these discounts?
                </label>
                <div className="form-check form-check-inline gib-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="discount"
                    id="goodstudent"
                    checked={discountstring.includes("Good Student Discount")}
                    value="Good Student Discount"
                    onChange={(e) => {
                      onClickDiscount(e);
                    }}
                  />
                  <label
                    className="form-check-label checkbox-color font-semibold font14"
                    htmlFor="goodstudent"
                  >
                    Good Student Discount
                  </label>
                </div>
                <div className="form-check form-check-inline gib-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="discount"
                    id="advdiscount"
                    checked={discountstring.includes("Advanced Degree")}
                    value="Advanced Degree"
                    onChange={(e) => {
                      onClickDiscount(e);
                    }}
                  />
                  <label
                    className="form-check-label checkbox-color font-semibold  font14"
                    htmlFor="advdiscount"
                  >
                    Advanced Degree
                  </label>
                </div>
                <div className="form-check form-check-inline gib-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="discount"
                    id="studentaway"
                    checked={discountstring.includes("Student Away at School")}
                    value="Student Away at School"
                    onChange={(e) => {
                      onClickDiscount(e);
                    }}
                  />
                  <label
                    className="form-check-label checkbox-color font-semibold font14"
                    htmlFor="studentaway"
                  >
                    Student Away at School
                  </label>
                </div>
              </div>
            ) : null}
            {autoInsuranceDetails[0].preferredDetails ===
              "Provide us all the details" &&
              autoInsuranceDetails[0].currentlyInsured === "Yes" ? (
    
              <React.Fragment>
                <div className="col-md-12 mt-3">
                  <label
                    htmlFor="currentcarrier"
                    className="form-label font14 font-semibold"
                  >
                    Current Carrier
                    <span className="gib-tool-carrier">
                      <img src={helpicon} className="ms-1 gib-tool-carrier" />
                      <span className="tooltext-carrier">
                        {"your current insurance provider"}
                      </span>
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control font14 pt-2 pb-2"
                    name="currentCarrier"
                    maxLength={80}
                    id="currentcarrier"
                    value={autoInsuranceDetails[0].currentCarrier}
                    onChange={(e) => handlechange(e)}
                  />
                </div>
                <div className="col-md-12 mt-3">
                  <label
                    htmlFor="renewalpremium"
                    className="form-label font14 font-semibold"
                  >
                    Renewal Premium
                    
                  </label>
                  <input
                    type="text"
                    className="form-control font14 pt-2 pb-2"
                    name="renewalPremium"
                    maxLength={18}
                    id="renewalpremium"
                    value={autoInsuranceDetails[0].renewalPremium}
                    onChange={(e) => handlechange(e)}
                  />
                </div>
    
                <div className="col-md-12 mt-3 date-picker">
                  <label
                    className="form-label font14 font-semibold"
                  >
                    Previous Policy Expiration Date
                  </label>
                  <input
                    type="date"
                    className="form-control text-uppercase font14 pt-2 pb-2 datecontrol"
                    name="policyExpirationDate"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    min={disablePastDate()}
                    value={autoInsuranceDetails[0].policyExpirationDate}
                    onChange={(e) => handlechange(e)}
                    onFocus={(e) => setdatefieldpreviouspolicymob(true)}
                    placeholder={datefieldpreviouspolicymob ? "" : "MM/DD/YYYY"}
                  />
                  <img src={calender} alt="calender-icon" className="calender-icon "></img>
                </div>
    
                
    
    
    
                <div className="file-upload">
                  <label htmlFor="expiryDate" className="form-label font14 mb-0 font-semibold w-100 mt-4">Current Insurance Dec Page</label>
                  <label htmlFor="expiryDate" className="form-label font14 PDF-label w-100"> <span className="font-semibold">Note: </span>Please upload PDF file only with less than 5 MB</label>
    
    
                  <input type="file" className=" form-control border-0 file-upload-input w-50 mt-2 px-3 font14 font-semibold" accept=".pdf" id="formFile" title="" onChange={(e) => validateFile(e)} ref={myref} />
                  {/* <input type="file" className="px-3"  accept=".pdf" id="actual-btn" hidden onChange={(e) => validateFile(e)} ref={myref} /> */}
                  {/* <label htmlFor="actual-btn" className="file-upload-input mt-2 px-3 font14 font-semibold">Choose File</label> */}
                  {/* <label className="  font14 ms-2">{filename!==""?filename+".pdf":" No file chosen"}</label> */}
                  <span className="gib-tool-choose">
                    <label className="font14 ms-2">{(filename !== "" && filename.length < 20) ? filename + ".pdf" : (filename.length > 10 && filename !== "") ? (filename.substring(0, 20)) + "..." + ".pdf" : "No file chosen"}</label>
                    {filename !== "" ? <span className="tooltext-choose">
                      {filename + ".pdf"}
                    </span> : null}
    
                  </span>
                  {fileLoading == true ?
                    <div className="clearfix ">
    
                      <div className="spinner-border float-end text-secondary loader-spinner" role="status">
    
                        <span className="visually-hidden">Loading...</span>
    
                      </div>
    
                    </div> : null}
                  {cancelvisible === true ?
                    <button className="btn border-0  Choose-cancel-mobile w-65 float-end mt-2 " onClick={() => Cancel_buttonClick()} >
                      <img src={Cancel_button} />
                    </button> : null}
    
                </div>
                <label className="text-danger font14">
                  {(fileValid === true && size > 5242880) ? "File size limit is exceeded" : null}
                  {((fileValid === true && size === 0) ? "The uploaded file is empty" : null)}
                  {((formatValid === true && type !== "application/pdf") ? "Please upload a file in suggested format" : null)}
                </label>
              </React.Fragment>
    
            ) : null}
            {autoInsuranceDetails[0].currentlyInsured === "No" &&
              autoInsuranceDetails[0].preferredDetails === "Just give key points" ? (
              <div className="row">
                <div className="col-md-12 border-bottom mt-3">
                  <label className="font20 font-primary font-semibold font-head pb-2">
                    Coverage Lapse
                  </label>
                </div>
                <div className="col-md-12 mt-3">
                  <label
                    htmlFor="policyexpireddate"
                    className="form-label font14 font-semibold"
                  >
                    How many days has your policy been expired?
                  </label>
                  <input
                    type="text"
                    maxLength={10}
                    className="form-control font14 text-uppercase pt-2 pb-2"
                    name="policyexpireddate"
                    id="policyexpireddate"
                    value={autoInsuranceDetails[0].policyexpireddate}
                    onChange={(e) => handlechange(e)}
                  />
                </div>
              </div>
            ) : null}
          </React.Fragment>
        );
      }
    
      //PC_Auto_5
      //Html structure for Additional driver details page is returned here
      function additionalDriver() {
        //setcurrentpage(3);
        return (
          <React.Fragment>
            <div className="col-md-12 mt-3">
              <label className="form-label w-100 font14 font-semibold">
                Would you like to add any additional drivers?
              </label>
              <input
                type="radio"
                className="btn-check radio-checked px-4"
                name="additionalDrivers"
                id="driver_yes"
                value="Yes"
                checked={autoInsuranceDetails[0].additionalDrivers === "Yes"}
                onChange={(e) => handlechange(e)}
                autoComplete="off"
              />
              <label
                className="btn btn-primary button-radio font14 font-bold px-4"
                htmlFor="driver_yes"
              >
                Yes
              </label>
              <input
                type="radio"
                className="btn-check radio-checked px-4"
                name="additionalDrivers"
                id="driver_no"
                value="No"
                checked={autoInsuranceDetails[0].additionalDrivers === "No"}
                onChange={(e) => handlechange(e)}
                autoComplete="off"
              />
              <label
                className="btn btn-primary ms-2 button-radio font14 font-bold px-4"
                htmlFor="driver_no"
              >
                No
              </label>
            </div>
            {autoInsuranceDetails[0].preferredDetails ===
              "Provide us all the details" &&
              autoInsuranceDetails[0].additionalDrivers === "Yes" ? (
              <React.Fragment>
                <div className="col-md-12 border-bottom mt-4 mb-4">
                  <label className="font20 font-primary font-semibold font-head pb-2">
                    Named Driver
                  </label>
                </div>
    
                {drivers.map((value, index) => {
                  return (
                    <React.Fragment>
                      {labelShowDriver[index].saveHide === true ? (
                        <div
                          className="col-md-12 bg-light p-3 mb-4"
                          onClick={() => onClickSave(index, "edit")}
                        >
                          <label className="font14 font-semibold">
                            {value.First_Name__c} {value.Last_Name__c}
                            <img
                              src={editicon}
                              alt="edit-icon"
                              className="ms-2 edit-icon"
                            />
                          </label>
                        </div>
                      ) : value.saved === true ? (
                        <div
                          className="col-md-12 bg-light p-3 mt-4"
                          onClick={() => onClickSave(index, "edit")}
                        >
                          <label className="font14 font-semibold">
                            {value.First_Name__c} {value.Last_Name__c}
                            <img
                              src={editicon}
                              alt="edit-icon"
                              className="ms-2 edit-icon"
                            />
                          </label>
                        </div>
                      ) : null}
                      {labelShowDriver[index].saveHide === false ? (
                        <React.Fragment>
                          <div
                            className={
                              value.saved === true
                                ? "col-md-12 border-top-0 mb-4"
                                : "col-md-12"
                            }
                          >
                            <div className="row border mx-2">
                              <div className="col-md-6 mt-3">
                                <label
                                  htmlFor="d_fname"
                                  className="form-label font14 font-semibold"
                                >
                                  First Name*
                                </label>
                                <input
                                  type="text"
                                  className="form-control pt-2 pb-2"
                                  name="First_Name__c"
                                  maxLength={40}
                                  id="d_fname"
                                  value={value.First_Name__c}
                                  onChange={(e) => FieldHandlechange(e, index)}
                                />
                                {(drivervalidation === true &&
                                  value.First_Name__c === "") ||
                                  (drivervalidation === true &&
                                    value.First_Name__c.trim() === "") ? (
                                  <label className="text-danger font14 w-100">
                                    Please enter the First Name
                                  </label>
                                ) : null}
                              </div>
                              <div className="col-md-6 mt-3">
                                <label
                                  htmlFor="d_lname"
                                  className="form-label font14 font-semibold"
                                >
                                  Last Name*
                                </label>
                                <input
                                  type="text"
                                  className="form-control pt-2 pb-2"
                                  name="Last_Name__c"
                                  maxLength={80}
                                  id="d_lname"
                                  value={value.Last_Name__c}
                                  onChange={(e) => FieldHandlechange(e, index)}
                                />
                                {(drivervalidation === true &&
                                  value.Last_Name__c === "") ||
                                  (drivervalidation === true &&
                                    value.Last_Name__c.trim() === "") ? (
                                  <label className="text-danger font14 w-100">
                                    Please enter the Last Name
                                  </label>
                                ) : null}
                              </div>
                              <div className="col-md-6 mt-3 date-picker">
                                <label
                                  className="form-label font14 font-semibold"
                                >
                                  Date of Birth
                                </label>
                                <input
                                  type="date"
                                  className="form-control font14 text-uppercase pt-2 pb-2 datecontrol"
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                  name="Date_of_Birth__c"
                                  value={value.Date_of_Birth__c}
                                  onChange={(e) => FieldHandlechange(e, index)}
                                  max={disablePastDate()}
                                  onFocus={(e) => setAddDob(true)}
                                  placeholder={AddDob ? "" : "MM/DD/YYYY"}
                                />
                                <img src={calender} alt="calender-icon" className="calender-icon "></img>
                              </div>
                              <div className="col-md-6 mt-3">
                                <label className="form-label w-100 font14 font-semibold ">
                                  Gender
                                </label>
                                <input
                                  type="radio"
                                  className="btn-check radio-checked px-4"
                                  name={"Gender__c" + index}
                                  id={"Female" + index}
                                  value="Female"
                                  checked={value.Gender__c === "Female"}
                                  onChange={(e) => FieldHandlechange(e, index)}
                                  autoComplete="off"
                                />
                                <label
                                  className="btn btn-primary button-radio font14 font-bold px-4"
                                  htmlFor={"Female" + index}
                                >
                                  Female
                                </label>
                                <input
                                  type="radio"
                                  className="btn-check radio-checked px-4"
                                  name={"Gender__c" + index}
                                  id={"Male" + index}
                                  value="Male"
                                  checked={value.Gender__c === "Male"}
                                  onChange={(e) => FieldHandlechange(e, index)}
                                  autoComplete="off"
                                />
                                <label
                                  className="btn btn-primary ms-2 button-radio font14 font-bold px-4"
                                  htmlFor={"Male" + index}
                                >
                                  Male
                                </label>
                              </div>
    
                              <div className="col-md-6 mt-3">
                                <label className="form-label w-100 font14 font-semibold ">
                                  Marital Status
                                </label>
                                <input
                                  type="radio"
                                  className="btn-check radio-checked px-4"
                                  name={"Relationship__c" + index}
                                  id={"Single" + index}
                                  value="Single"
                                  checked={value.Relationship__c === "Single"}
                                  onChange={(e) => FieldHandlechange(e, index)}
                                  autoComplete="off"
                                />
                                <label
                                  className="btn btn-primary button-radio font14 font-bold px-4"
                                  htmlFor={"Single" + index}
                                >
                                  Single
                                </label>
                                <input
                                  type="radio"
                                  className="btn-check radio-checked px-4"
                                  name={"Relationship__c" + index}
                                  id={"Married" + index}
                                  value="Married"
                                  checked={value.Relationship__c === "Married"}
                                  onChange={(e) => FieldHandlechange(e, index)}
                                  autoComplete="off"
                                />
                                <label
                                  className="btn btn-primary ms-2 button-radio font14 font-bold px-4"
                                  htmlFor={"Married" + index}
                                >
                                  Married
                                </label>
                              </div>
        
                              <div className="col-md-6 mt-3">
                                <label
                                  htmlFor="d_state"
                                  className="form-label font14 font-semibold"
                                >
                                  Driver's License State
                                </label>
                                <select
                                  className="form-select font14 pt-2 pb-2"
                                  aria-label="Default select example"
                                  name="State__c"
                                  id="d_state"
                                  value={value.State__c}
                                  onChange={(e) => FieldHandlechange(e, index)}
                                >
                                  <option value="">Select State</option>
                                  <option value="AK">AK</option>
                                  <option value="AL">AL</option>
                                  <option value="AR">AR</option>
                                  <option value="AZ">AZ</option>
                                  <option value="CA">CA</option>
                                  <option value="CO">CO</option>
                                  <option value="CT">CT</option>
                                  <option value="DE">DE</option>
                                  <option value="FL">FL</option>
                                  <option value="GA">GA</option>
                                  <option value="HI">HI</option>
                                  <option value="IA">IA</option>
                                  <option value="ID">ID</option>
                                  <option value="IL">IL</option>
                                  <option value="IN">IN</option>
                                  <option value="KS">KS</option>
                                  <option value="KY">KY</option>
                                  <option value="LA">LA</option>
                                  <option value="MA">MA</option>
                                  <option value="MD">MD</option>
                                  <option value="ME">ME</option>
                                  <option value="MI">MI</option>
                                  <option value="MN">MN</option>
                                  <option value="MO">MO</option>
                                  <option value="MS">MS</option>
                                  <option value="MT">MT</option>
                                  <option value="NC">NC</option>
                                  <option value="ND">ND</option>
                                  <option value="NE">NE</option>
                                  <option value="NH">NH</option>
                                  <option value="NJ">NJ</option>
                                  <option value="NM">NM</option>
                                  <option value="NV">NV</option>
                                  <option value="NY">NY</option>
                                  <option value="OH">OH</option>
                                  <option value="OK">OK</option>
                                  <option value="OR">OR</option>
                                  <option value="PA">PA</option>
                                  <option value="RI">RI</option>
                                  <option value="SC">SC</option>
                                  <option value="SD">SD</option>
                                  <option value="TN">TN</option>
                                  <option value="TX">TX</option>
                                  <option value="UT">UT</option>
                                  <option value="VA">VA</option>
                                  <option value="VT">VT</option>
                                  <option value="WA">WA</option>
                                  <option value="WI">WI</option>
                                  <option value="WV">WV</option>
                                  <option value="WY">WY</option>
                                </select>
                              </div>
                              <div className="col-md-12 mt-3 mb-3">
                                <label
                                  htmlFor="d_driverlicenseno"
                                  className="form-label font14 font-semibold"
                                >
                                  Driver's License Number*
                                </label>
                                <input
                                  type="text"
                                  className="form-control pt-2"
                                  name="Driver_s_License_Number__c"
                                  maxLength={19}
                                  id="d_driverlicenseno"
                                  value={value.Driver_s_License_Number__c}
                                  onChange={(e) => FieldHandlechange(e, index)}
                                />
                                <label className="text-danger font14 w-100">
                                  {(drivervalidation === true &&
                                    value.Driver_s_License_Number__c === "") ||
                                    (drivervalidation === true &&
                                      value.Driver_s_License_Number__c.trim() === "")
                                    ? " Please enter the Driver's License Number"
                                    : drivervalidation === true &&
                                      value.Driver_s_License_Number__c.length < 1
                                      ? "Please enter a valid Driver's License Number"
                                      : null}
                                </label>
                              </div>
                              <div className="col-md-12 ">
                            <label
                              htmlFor="ticket"
                              className="form-label font14 font-semibold"
                            >
                              We will be able to pull your driving history from public reports but are there any accidents or claims you would like to provide details on?
                            </label>
                            <input
                                  type="radio"
                                  className="btn-check radio-checked px-4"
                                  name="Tickets_or_Accidents__c"
                                  id={"Yes" + index}
                                  value="Yes"
                                  checked={value.Tickets_or_Accidents__c === "Yes"}
                                  onChange={(e) => FieldHandlechange(e, index)}
                                  autoComplete="off"
                                />
                                <label
                                  className="btn btn-primary button-radio font14 font-bold px-4"
                                  htmlFor={"Yes" + index}
                                >
                                  Yes
                                </label>
                                <input
                                  type="radio"
                                  className="btn-check radio-checked px-4"
                                  name="Tickets_or_Accidents__c"
                                  id={"No" + index}
                                  value="No"
                                  checked={value.Tickets_or_Accidents__c === "No"}
                                  onChange={(e) => FieldHandlechange(e, index)}
                                  autoComplete="off"
                                />
                                <label
                                  className="btn btn-primary ms-2 button-radio font14 font-bold px-4"
                                  htmlFor={"No" + index}
                                >
                                  No
                                </label>
                          </div>
    
                              <div className="col-md-12 mb-3 mt-3">
                                <label
                                  htmlFor="label-DC"
                                  className="form-label font14 font-semibold w-100"
                                >
                                  Do you qualify for any of these discounts?
                                </label>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name={"Qualified_Discounts__c" + index}
                                    checked={value.Qualified_Discounts__c.includes(
                                      "Good Student"
                                    )}
                                    id={"goodstudent" + index}
                                    value="Good Student"
                                    onChange={(e) => FieldHandlechange(e, index)}
                                  />
                                  <label
                                    className="form-check-label checkbox-color font-semibold font14"
                                    htmlFor={"goodstudent" + index}
                                  >
                                    Good Student Discount
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name={"Qualified_Discounts__c" + index}
                                    checked={value.Qualified_Discounts__c.includes(
                                      "Advance Degree"
                                    )}
                                    id={"advdiscount" + index}
                                    value="Advance Degree"
                                    onChange={(e) => FieldHandlechange(e, index)}
                                  />
                                  <label
                                    className="form-check-label checkbox-color font-semibold font14"
                                    htmlFor={"advdiscount" + index}
                                  >
                                    Advanced Degree
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name={"Qualified_Discounts__c" + index}
                                    checked={value.Qualified_Discounts__c.includes(
                                      "Student Away at School"
                                    )}
                                    id={"studentaway" + index}
                                    value="Student Away at School"
                                    onChange={(e) => FieldHandlechange(e, index)}
                                  />
                                  <label
                                    className="form-check-label checkbox-color font-semibold font14"
                                    htmlFor={"studentaway" + index}
                                  >
                                    Student Away at School
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-12 mb-4 mt-4">
                                <button
                                  type="button"
                                  className="btn bt-save primary-button border-0 font14 float-start px-5 py-2 save-button"
                                  onClick={(e) => onClickSave(index, "save")}
                                >
                                  Save
                                </button>
                                {drivers.length > 1 ? (
                                  <button
                                    type="button"
                                    className="btn btn red-button float-start ms-2 px-5 py-2 font14 border-0"
                                    onClick={() => Removefield(index)}
                                  >
                                    Remove
                                  </button>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ) : null}
                    </React.Fragment>
                  );
                })}
    
                {/* Add another button */}
                {drivers.length < 6 && driversSaved === false ? (
                  <div className="col-md-12 mt-3">
                    <a
                      className="font14 font-semibold font-primary text-decoration-none"
                      onClick={() => {
                        Addfield();
                      }}
                    >
                      <span>
                        <img
                          src={plusicon}
                          className="plus-icon"
                          alt="plus"
                          onClick={() => {
                            Addfield();
                          }}
                        />
                      </span>{" "}
                      Add Another Driver
                    </a>
                  </div>
                ) : (
                  <div className="col-md-12 mt-3">
                    <a
                      className="font14 font-semibold font-primary-light text-decoration-none"
                      style={{ pointerEvents: "none" }}
                    >
                      <span>
                        <img src={plusiconlight} className="plus-icon" alt="plus" />
                      </span>{" "}
                      Add Another Driver
                    </a>
                  </div>
                )}
              </React.Fragment>
            ) : null}
            {/* Add another button */}
          </React.Fragment>
        );
      }
    
      //PC_Auto_6
      //Html structure for vechicle details page is returned here
      function vehicleDetails() {
        //setcurrentpage(4);
        return (
          <React.Fragment>
            <div className="col-md-12 mt-4 mb-4">
              <label className="font20 font-primary font-semibold font-head pb-2 border-bottom w-100">
                Vehicle Details
              </label>
            </div>
    
            {vechicles.map((value, index) => {
              return (
                <React.Fragment>
                  {labelShowVechicle[index].saveHide === true ? (
                    <div
                      className="col-md-12 bg-light p-3 mb-4"
                      onClick={() => onClickSave(index, "edit")}
                    >
                      <label className="font14 font-semibold">
                        {value.Name} {value.Model__c}
                        <img
                          src={editicon}
                          alt="edit-icon"
                          className="ms-2 edit-icon"
                        />
                      </label>
                    </div>
                  ) : value.saved === true ? (
                    <div className="col-md-12 bg-light p-3 mt-4">
                      <label className="font14 font-semibold">
                        {value.Name} {value.Model__c}
                        <img
                          src={editicon}
                          alt="edit-icon"
                          className="ms-2 edit-icon"
                        />
                      </label>
                    </div>
                  ) : null}
                  {labelShowVechicle[index].saveHide === false ? (
                    <React.Fragment>
                      <div
                        className={
                          value.saved === true
                            ? "col-md-12 border border-top-0 mb-4"
                            : "col-md-12"
                        }
                      >
                        <div className="row border mx-2">
                          <div className="col-md-6 mt-3">
                            <label
                              htmlFor={"make" + index}
                              className="form-label font14 font-semibold"
                            >
                              Make*
                            </label>
                            <input
                              type="text"
                              className="form-control pt-2 pb-2"
                              name="Name"
                              id={"make" + index}
                              value={value.Name}
                              maxLength={50}
                              onChange={(e) => FieldHandlechange(e, index)}
                            />
                            {(validationAlert === true && value.Name === "") ||
                              (validationAlert === true &&
                                value.Name.trim() === "") ? (
                              <label className="text-danger font14 w-100">
                                Please enter the Make
                              </label>
                            ) : null}
                          </div>
                          <div className="col-md-6 mt-3">
                            <label
                              htmlFor={"model" + index}
                              className="form-label font14 font-semibold"
                            >
                              Model
                            </label>
                            <input
                              type="text"
                              className="form-control pt-2 pb-2"
                              name="Model__c"
                              id={"model" + index}
                              value={value.Model__c}
                              maxLength={50}
                              onChange={(e) => FieldHandlechange(e, index)}
                            />
                          </div>
                          <div className="col-md-6 mt-3">
                            <label
                              htmlFor={"year" + index}
                              className="form-label font14 font-semibold"
                            >
                              Year
                            </label>
                            <input
                              type="text"
                              className="form-control font14 text-uppercase pt-2 pb-2"
                              name="Year__c"
                              maxLength={4}
                              id={"year" + index}
                              value={value.Year__c}
                              onChange={(e) => FieldHandlechange(e, index)}
                            />
                            {validationAlert === true && yearRegex === true ? (
                              <label className="text-danger font14 w-100">
                                Please enter a Valid Year
                              </label>
                            ) : null}
                          </div>
                         
                          <div className="col-md-6 mt-3">
                            <label
                              htmlFor={"vin" + index}
                              className="form-label font14 font-semibold"
                            >
                              VIN
                              <span className="gib-tooltip2">
                                <img src={helpicon} className="ms-1 gib-tooltip2" />
                                <span className="tooltiptext">
                                  {
                                    "vehicle identification number, found on bottom left windshield"
                                  }
                                </span>
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control font14 text-uppercase pt-2 pb-2"
                              name="VIN__c"
                              id={"vin" + index}
                              maxLength={17}
                              value={value.VIN__c}
                              onChange={(e) => FieldHandlechange(e, index)}
                            />
                            {validationAlert === true && vinRegex === true ? (
                              <label className="text-danger font14 w-100">
                                Please enter a valid VIN number
                              </label>
                            ) : null}
                          </div>
    
                          <div className="col-md-6 mt-3">
                            <label
                              htmlFor={"miles" + index}
                              className="form-label 
                              font14 font-semibold milestoworkfont"
                            >
                              Miles - Commute to Work/School
                              <span className="gib-tool-miles">
                                <img src={helpicon} className="ms-1 gib-tool-miles" />
                                <span className="tooltext-miles">
                                  {
                                   "If vehicles used for pleasure enter 0"
                                  }
                                </span>
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control font14 text-uppercase pt-2 pb-2"
                              name="Miles_to_work__c"
                              id={"Miles" + index}
                              maxLength={3}
                              value={value.Miles_to_work__c}
                              onChange={(e) => FieldHandlechange(e, index)}
                            />
                         
                          </div>
                          <div className="col-md-6 mt-3 mb-3 comp-margin">
                            <label
                              className="form-label font14 font-semibold"
                            >
                              Comp/Collision Deductibles
                              <span className="gib-tool-comp">
                                <img src={helpicon} className="ms-1 gib-tool-comp" />
                                <span className="tooltext-comp ">
                                  {
                                    "Deductible is amount withheld when claim is paid"
                                  }
                                </span>
                              </span>
                            </label>
                            <select
                              className="form-select font14 pt-2 pb-2"
                              aria-label="Default select example"
                              name="Comp_Collision_Deductibles__c"
                              id={"deductibles" + index}
                              value={value.Comp_Collision_Deductibles__c}
                              onChange={(e) => FieldHandlechange(e, index)}
                            >
                              <option value="">Select</option>
                              <option value="$100">$100</option>
                              <option value="$500">$500</option>
                              <option value="$1000">$1000</option>
                              <option value="$2000">$2000</option>
                            </select>
                          </div>
                         
                          <div className="col-md-6 mb-4 mt-4">
                            <button
                              type="button"
                              className="btn bt-save primary-button border-0 font14 float-start px-5 py-2 save-button"
                              onClick={(e) => onClickSave(index, "save")}
                            >
                              Save
                            </button>
                            {vechicles.length > 1 ? (
                              <button
                                type="button"
                                className="btn btn red-button float-start ms-2 px-5 py-2 font14 border-0"
                                onClick={() => Removefield(index)}
                              >
                                Remove
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              );
            })}
            {vechicles.length < 4 && vechiclesSaved === false ? (
              <div className="col-md-12 mt-3">
                <a
                  className="font14 font-semibold font-primary text-decoration-none"
                  onClick={() => {
                    Addfield();
                  }}
                >
                  <span>
                    <img
                      src={plusicon}
                      className="plus-icon"
                      alt="plus"
                      onClick={() => {
                        Addfield();
                      }}
                    />
                  </span>{" "}
                  Add Another Vehicle
                </a>
              </div>
            ) : (
              <div className="col-md-12 mt-3">
                <a
                  className="font14 font-semibold font-primary-light text-decoration-none"
                  style={{ pointerEvents: "none" }}
                >
                  <span>
                    <img src={plusiconlight} className="plus-icon" alt="plus" />
                  </span>{" "}
                  Add Another Vehicle
                </a>
              </div>
            )}
            <p className="mt-3">
              <span className="font16 note-color font-semibold font-primary">
                Note:
              </span>
              <span className="font16 ps-1 note-color font-primary">
                Maximum of four vehicles only allowed
              </span>
            </p>
          </React.Fragment>
        );
      }
    
      //PC_Auto_7
      //Html structure for coverage details page is returned here
      function coverageDetails() {
        // setcurrentpage(5);
        return (
          <React.Fragment>
            <div className="col-md-12 mt-3">
              <label
                htmlFor="coverageneeded"
                className="form-label font14 font-semibold"
              >
                Coverage Needed
              </label>
              <select
                className="form-select font14 "
                aria-label="Default select example"
                name="coverageNeeded"
                id="coverageneeded"
                value={autoInsuranceDetails[0].coverageNeeded}
                onChange={(e) => handlechange(e)}
              >
                <option value="">Select</option>
                <option value="50/100/50">50/100/50</option>
                <option value="100/300/100">100/300/100</option>
                <option value="250/500/100">250/500/100</option>
                <option value="30/60/25">30/60/25</option>
                <option value="Other">Other</option>
                <option value="I don't know">I don't know</option>
              </select>
            </div>
            <div className="col-md-12 mt-3">
              <label
                htmlFor="unInsuredMotorist"
                className="form-label font14 font-semibold"
              >
                Desire Uninsured Motorist
              </label>
              <select
                className="form-select font14 "
                aria-label="Default select example"
                name="unInsuredMotorist"
                id="unInsuredMotorist"
                value={autoInsuranceDetails[0].unInsuredMotorist}
                onChange={(e) => handlechange(e)}
              >
                <option value="">Select</option>
                <option value="Reject">Reject</option>
                <option value="30/60/25">30/60/25</option>
                <option value="50/100/50">50/100/50</option>
                <option value="100/300/100">100/300/100</option>
                <option value="250/500/100">250/500/100</option>
              </select>
            </div>
            <div className="col-md-12 mt-3">
              <label className="form-label w-100 font14 font-semibold ">
                Desire Personal Injury Coverage
              </label>
              <input
                type="radio"
                className="btn-check radio-checked px-4"
                name="personalInjuryCoverage"
                id="injury_no"
                value="No"
                checked={autoInsuranceDetails[0].personalInjuryCoverage === "No"}
                onChange={(e) => handlechange(e)}
                autoComplete="off"
              />
              <label
                className="btn btn-primary button-radio font14 font-bold px-4"
                htmlFor="injury_no"
              >
                No
              </label>
              <input
                type="radio"
                className="btn-check radio-checked px-4"
                name="personalInjuryCoverage"
                id="injury_Yes"
                value="Yes"
                checked={autoInsuranceDetails[0].personalInjuryCoverage === "Yes"}
                onChange={(e) => handlechange(e)}
                autoComplete="off"
              />
              <label
                className="btn btn-primary ms-2 button-radio font14 font-bold px-4"
                htmlFor="injury_Yes"
              >
                Yes
              </label>
            </div>
          </React.Fragment>
        );
      }
    
      //PC_Auto_8
      //Html structure for claim details page is returned here
      function claimDetail() {
        //setcurrentpage(6);
        return (
          <React.Fragment>
            {autoInsuranceDetails[0].preferredDetails ===
              "Provide us all the details" ? (
              <React.Fragment>
                <div className="col-md-12 mt-3">
                  <label className="form-label w-100 font14 font-semibold">
                    We will be able to pull your driving history from public reports
                    but are there any accidents or claims you would like to provide
                    details on?
                  </label>
                  <input
                    type="radio"
                    className="btn-check radio-checked px-4"
                    name="hasClaims"
                    id="claim_no"
                    value="No"
                    checked={autoInsuranceDetails[0].hasClaims === "No"}
                    onChange={(e) => handlechange(e)}
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-primary button-radio font14 font-bold px-4"
                    htmlFor="claim_no"
                  >
                    No
                  </label>
                  <input
                    type="radio"
                    className="btn-check radio-checked px-4"
                    name="hasClaims"
                    id="claim_yes"
                    value="Yes"
                    checked={autoInsuranceDetails[0].hasClaims === "Yes"}
                    onChange={(e) => handlechange(e)}
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-primary ms-2 button-radio font14 font-bold px-4"
                    htmlFor="claim_yes"
                  >
                    Yes
                  </label>
                </div>
                {autoInsuranceDetails[0].hasClaims === "Yes" ? (
                  <div className="col-md-12 mt-3">
                    <label
                      htmlFor="claimDetails"
                      className="form-label font14 font-semibold"
                    >
                      Claim Details
                    </label>
                    <textarea
                      className="form-control pt-2 pb-2"
                      name="claimDetails"
                      id="claimdetails"
                      value={autoInsuranceDetails[0].claimDetails}
                      onChange={(e) => handlechange(e)}
                      
                      rows={3}
                      maxLength={255}
                      style={{ resize: "none" }}
                    />
                  </div>
                ) : null}
                <div className="col-md-12 mt-3">
                  <label
                    htmlFor="label10"
                    className="form-label w-100 font14 font-semibold"
                  >
                    Need Towing
                    <span className="gib-tool-towing">
                      <img src={helpicon} className="ms-1 gib-tool-towing" />
                      <span className="tooltext-towing">
                        {"coverage for tow truck and road side assistance"}
                      </span>
                    </span>
                  </label>
                  <input
                    type="radio"
                    className="btn-check radio-checked px-4"
                    name="needTowing"
                    id="tow_no"
                    value="No"
                    checked={autoInsuranceDetails[0].needTowing === "No"}
                    onChange={(e) => handlechange(e)}
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-primary  button-radio font14 font-bold px-4"
                    htmlFor="tow_no"
                  >
                    No
                  </label>
                  <input
                    type="radio"
                    className="btn-check radio-checked px-4"
                    name="needTowing"
                    id="tow_yes"
                    value="Yes"
                    checked={autoInsuranceDetails[0].needTowing === "Yes"}
                    onChange={(e) => handlechange(e)}
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-primary ms-2 button-radio font14 font-bold px-4"
                    htmlFor="tow_yes"
                  >
                    Yes
                  </label>
                </div>
                <div className="col-md-12 mt-3">
                  <label
                    htmlFor="label10"
                    className="form-label w-100 font14 font-semibold"
                  >
                    Would you like rental reimbursement?
                  </label>
                  <input
                    type="radio"
                    className="btn-check radio-checked px-4"
                    name="reimbursement"
                    id="reimburse_no"
                    value="No"
                    checked={autoInsuranceDetails[0].reimbursement === "No"}
                    onChange={(e) => handlechange(e)}
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-primary  button-radio font14 font-bold px-4"
                    htmlFor="reimburse_no"
                  >
                    No
                  </label>
                  <input
                    type="radio"
                    className="btn-check radio-checked px-4"
                    name="reimbursement"
                    id="reimburse_yes"
                    value="Yes"
                    checked={autoInsuranceDetails[0].reimbursement === "Yes"}
                    onChange={(e) => handlechange(e)}
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-primary ms-2 button-radio font14 font-bold px-4"
                    htmlFor="reimburse_yes"
                  >
                    Yes
                  </label>
                </div>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        );
      }
    
      var tempdiscount: any = [];
      //PC_Auto_20
      //When user clicks on any checkbox then it Invokes onClickDiscounct() function
      function onClickDiscount(event: any) {
        tempdiscount = discount;
        if (event.target.checked === true) {
          tempdiscount.push(event.target.value);
        } else {
          let index = tempdiscount.indexOf(event.target.value);
          tempdiscount.splice(index, 1);
        }
        setdiscount(tempdiscount);
        var discounts = discount.join(";");
        setdiscountstring(discounts);
      }
    
      //PC_Auto_22
      //When save button is clicked from additional drivers page or vechicle details page this function is invoked
      function onClickSave(index: any, click: any) {
        var templabelShow: any = [];
        if (currentpage === 3) {
          if (click === "save") {
            if (
              drivers[index].First_Name__c === "" ||
              drivers[index].First_Name__c.trim() === ""
            ) {
              setdrivervalidation(true);
              return false;
            }
            if (
              drivers[index].Last_Name__c === "" ||
              drivers[index].Last_Name__c.trim() === ""
            ) {
              setdrivervalidation(true);
              return false;
            }
            if (
              drivers[index].Driver_s_License_Number__c === "" ||
              drivers[index].Driver_s_License_Number__c.trim() === ""
            ) {
              setdrivervalidation(true);
              return false;
            }
            if (drivers[index].Driver_s_License_Number__c.length < 1) {
              setdrivervalidation(true);
              return false;
            }
            templabelShow = [...labelShowDriver];
            templabelShow[index].saveHide = true;
            var tempdrivers = [...drivers];
            tempdrivers[index].saved = true;
            setdrivervalidation(false);
            setdrivers(tempdrivers);
            setlabelShowDriver(templabelShow);
            for (let i = 0; i < templabelShow.length; i++) {
              if (templabelShow[i].saveHide === false) {
                setdriversSaved(true);
                return false;
              } else {
                setdriversSaved(false);
              }
            }
          }
          if (click === "edit") {
            for (let i = 0; i < labelShowDriver.length; i++) {
              if (labelShowDriver[i].saveHide !== true) {
                return false;
              }
            }
            templabelShow = [...labelShowDriver];
            for (let i = 0; i < templabelShow.length; i++) {
              if (i == index) {
                templabelShow[i].saveHide = false;
              } else {
                templabelShow[i].saveHide = true;
              }
            }
            setlabelShowDriver(templabelShow);
            for (let i = 0; i < templabelShow.length; i++) {
              if (templabelShow[i].saveHide === false) {
                setdriversSaved(true);
                return false;
              } else {
                setdriversSaved(false);
              }
            }
          }
        }
        if (currentpage === 4) {
          if (click === "save") {
            var year = Number(vechicles[index].Year__c);
            const today = new Date();
            const yyyy = today.getFullYear();
            if (vechicles[index].Year__c !== "") {
              if (!(1900 < year && yyyy >= year)) {
                setvalidationAlert(true);
                setyearRegex(true);
                return false;
              }
            } else {
              setyearRegex(false);
              setvalidationAlert(false);
            }
            if (
              vechicles[index].Name === "" ||
              vechicles[index].Name.trim() === ""
            ) {
              setvalidationAlert(true);
              return false;
            } else {
              setvalidationAlert(false);
            }
            if (vechicles[index].VIN__c !== "") {
              if (vechicles[index].VIN__c.length < 16) {
                setvalidationAlert(true);
                setvinRegex(true);
                return false;
              } else {
                setvinRegex(false);
                setvalidationAlert(false);
              }
            }
    
            setvalidationAlert(false);
            templabelShow = [...labelShowVechicle];
            templabelShow[index].saveHide = true;
            setlabelShowVechicle(templabelShow);
            var tempvechicles = [...vechicles];
            tempvechicles[index].saved = true;
            setvechicles(tempvechicles);
            for (let i = 0; i < templabelShow.length; i++) {
              if (templabelShow[i].saveHide === false) {
                setvechiclesSaved(true);
                return false;
              } else {
                setvechiclesSaved(false);
              }
            }
          }
          if (click === "edit") {
            for (let i = 0; i < labelShowVechicle.length; i++) {
              if (labelShowVechicle[i].saveHide !== true) {
                return false;
              }
            }
            templabelShow = [...labelShowVechicle];
            for (let i = 0; i < templabelShow.length; i++) {
              if (i == index) {
                templabelShow[i].saveHide = false;
              } else {
                templabelShow[i].saveHide = true;
              }
            }
            setlabelShowVechicle(templabelShow);
          }
          for (let i = 0; i < templabelShow.length; i++) {
            if (templabelShow[i].saveHide === false) {
              setvechiclesSaved(true);
              return false;
            } else {
              setvechiclesSaved(false);
            }
          }
        }
      }
      //PC_Auto_17
      //When the user clicks on add vechicle or add driver button then Addfield() function is invoked to add fields
      function Addfield() {
        setdrivervalidation(false);
        setvalidationAlert(false);
        if (currentpage === 3) {
          setdrivers([
            ...drivers,
            {
              First_Name__c: "",
              Last_Name__c: "",
              Date_of_Birth__c: "",
              Gender__c: "",
              Relationship__c:"",
              Driver_s_License_Number__c: "",
              Qualified_Discounts__c: "",
              Tickets_or_Accidents__c:"",
              State__c: "",
              saved: false,
            },
          ]);
          setlabelShowDriver([...labelShowDriver, { saveHide: false }]);
          setdriversSaved(true);
        }
        if (currentpage === 4) {
          setvechicles([
            ...vechicles,
            {
              Name: "",
              Model__c: "",
              Year__c: "",
              Miles_to_work__c:"",
              VIN__c: "",
              Comp_Collision_Deductibles__c: "",
          
              saved: false,
            },
          ]);
          setlabelShowVechicle([...labelShowVechicle, { saveHide: false }]);
          setvechiclesSaved(true);
        }
      }
      //PC_Auto_18
      //When the user clicks on remove drivers or remove vechicles button then Removefield(index) function is invoked
      function Removefield(index: any) {
        if (currentpage === 3) {
          let newvariable = [...drivers];
          newvariable.splice(index, 1);
          setdrivers(newvariable);
          let newlabelvariable = [...labelShowDriver];
          newlabelvariable.splice(index, 1);
          setlabelShowDriver(newlabelvariable);
          setdriversSaved(false);
        }
        if (currentpage === 4) {
          let newvariable = [...vechicles];
          newvariable.splice(index, 1);
          setvechicles(newvariable);
          let newlabelvariable = [...labelShowVechicle];
          newlabelvariable.splice(index, 1);
          setlabelShowVechicle(newlabelvariable);
          setvechiclesSaved(false);
        }
      }
      //PC_Auto_16
      //When user clicks on back button onClickBack function is called and previous page is shown
      function onClickBack() {
        var tempdynamicpages: any = [];
        tempdynamicpages = [...dynamicpages];
        switch (currentpage) {
          case 2:
            setcurrentpage(1);
            var isFound: any = previousSteps("Current Policy Information");
            if (isFound === true) {
              tempdynamicpages.push("Current Policy Information");
            }
            break;
          case 3:
            //setformtype("Current Policy information");
            setcurrentpage(2);
            var isFound: any = previousSteps("Additional Drivers");
            if (isFound === true) {
              tempdynamicpages.push("Additional Drivers");
            }
            break;
          case 4:
            setcurrentpage(3);
            var isFound: any = previousSteps("Tell us about your vehicles");
            if (isFound === true) {
              tempdynamicpages.push("Tell us about your vehicles");
            }
            break;
          case 5:
            setcurrentpage(4);
            var isFound: any = previousSteps("Coverage Details");
            if (isFound === true) {
              tempdynamicpages.push("Coverage Details");
            }
            break;
          case 6:
            //setformtype("Coverage Details");
            setcurrentpage(5);
            var isFound: any = previousSteps("Claim Details");
            if (isFound === true) {
              tempdynamicpages.push("Claim Details");
            }
            break;
        }
        setdynamicpages(tempdynamicpages);
      }
      //PC_Auto_19
      //When user changes any values in fields of driver and vechicle details then FieldHandlechange(event,index) function is Invoked
      var tempdriverdiscount: any = [];
      var tempdriverdiscountstring: string;
      function FieldHandlechange(e: any, index: any) {
        if (currentpage === 3) {
          if (e.target.name=== "Gender__c" + index) {
            let newdrivers: any = [...drivers];
            newdrivers[index].Gender__c = e.target.value;
            setdrivers(newdrivers);
          }
          else if(e.target.name==="Relationship__c" + index){
            let newdrivers: any = [...drivers];
            newdrivers[index].Relationship__c = e.target.value;
            setdrivers(newdrivers);
          }
          else if(e.target.name=="Tickets_or_Accidents__c"){
            let newdrivers: any = [...drivers];
            newdrivers[index][e.target.name] =  e.target.value;
            setdrivers( newdrivers);
          } 
          else if(e.target.id=== "Yes" + index || e.target.id=== "No" + index ){
            let newdrivers: any = [...drivers];
            newdrivers[index].Tickets_or_Accidents__c = e.target.value;
            setdrivers( newdrivers);
          }
          else if (e.target.type === "checkbox") {
            let newdrivers: any = [...drivers];
            tempdriverdiscountstring = newdrivers[index].Qualified_Discounts__c;
            if (tempdriverdiscountstring != "") {
              tempdriverdiscount = tempdriverdiscountstring.split(";");
            }
            if (e.target.checked === true) {
              tempdriverdiscount.push(e.target.value);
            } else {
              let discount_index = tempdriverdiscount.indexOf(e.target.value);
              tempdriverdiscount.splice(discount_index, 1);
            }
            tempdriverdiscountstring = tempdriverdiscount.join(";");
    
            //newdrivers[index].driverDiscount = tempdriverdiscount;
            newdrivers[index].Qualified_Discounts__c = tempdriverdiscountstring;
            setdrivers(newdrivers);
          } else {
            let newdrivers: any = [...drivers];
            if (e.target.name === "Driver_s_License_Number__c") {
              e.target.value = e.target.value.replace(/[^A-Za-z0-9]+/g, "");
            }
            newdrivers[index][e.target.name] = e.target.value;
            setdrivers(newdrivers);
          }
        }
        if (currentpage === 4) {
          let newvechicles: any = [...vechicles];
          var year = Number(e.target.value);
          const today = new Date();
          const yyyy = today.getFullYear();
    
          if (e.target.name === "Year__c" || e.target.name === "VIN__c" || e.target.name === "Miles_to_work__c") {
            let value = e.target.value;
            value = value.replace(/[^0-9]/g, "");
            if (e.target.name === "Year__c") {
              if (value !== "") {
                if (1900 < year && yyyy >= year) {
                  setyearRegex(false);
                } else {
                  setyearRegex(true);
                }
              } else {
                setyearRegex(false);
              }
            }
          
          else if (e.target.name === "VIN__c") {
              if (value !== "") {
                if (value.length < 16) {
                  setvinRegex(true);
                } else {
                  setvinRegex(false);
                }
              } else {
                setvinRegex(false);
              }
            }
            newvechicles[index][e.target.name] = value;
            setvechicles(newvechicles);
          } 
         
          
          else {
            newvechicles[index][e.target.name] = e.target.value;
            setvechicles(newvechicles);
          }
        }
      }
    
      //PC_Auto_15
      //when next button is clicked Validation function is called after validating fields next page is shown
      function validation(value: number) {
        var tempdynamicpages: any = [];
        tempdynamicpages = [...dynamicpages];
        switch (currentpage) {
          case 1:
            var errorcode = false;
            if (
              autoInsuranceDetails[0].firstName === "" ||
              autoInsuranceDetails[0].firstName.trim() === ""
            ) {
              setvalidationAlert(true);
              return false;
            }
            if (
              autoInsuranceDetails[0].lastName === "" ||
              autoInsuranceDetails[0].lastName.trim() === ""
            ) {
              setvalidationAlert(true);
              return false;
            }
            if (autoInsuranceDetails[0].dateOfBirth === "") {
              setvalidationAlert(true);
              return false;
            }
            if (
              autoInsuranceDetails[0].email === "" ||
              autoInsuranceDetails[0].email.trim() === ""
            ) {
              setvalidationAlert(true);
              return false;
            }
            if (autoInsuranceDetails[0].email !== "") {
              var regEx =
                /^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)*[\w+-]+\.[a-zA-Z]{2,4}$/;
              if (!autoInsuranceDetails[0].email.match(regEx)) {
                setemailReg(true);
                setvalidationAlert(true);
                errorcode = true;
              } else {
                setemailReg(false);
              }
            }
            if (autoInsuranceDetails[0].mobilePhone === "") {
              setvalidationAlert(true);
    
              return false;
            }
            if (autoInsuranceDetails[0].mobilePhone !== "") {
              var phoneFormat = /^\d{10}$/;
              if (!autoInsuranceDetails[0].mobilePhone.match(phoneFormat)) {
                setphoneNoReg(true);
                setvalidationAlert(true);
                errorcode = true;
              } else {
                setphoneNoReg(false);
              }
            }
            if (
              autoInsuranceDetails[0].street === "" ||
              autoInsuranceDetails[0].street.trim() === ""
            ) {
              setvalidationAlert(true);
              return false;
            }
            if (
              autoInsuranceDetails[0].city === "" ||
              autoInsuranceDetails[0].city.trim() === ""
            ) {
              setvalidationAlert(true);
              return false;
            }
            if (
              autoInsuranceDetails[0].state === "" ||
              autoInsuranceDetails[0].state.trim() === ""
            ) {
              setvalidationAlert(true);
              return false;
            }
            if (
              autoInsuranceDetails[0].postalCode === "" ||
              autoInsuranceDetails[0].postalCode.trim() === ""
            ) {
              setvalidationAlert(true);
              return false;
            }
            if (autoInsuranceDetails[0].postalCode !== "") {
              var zipFormat = /^\d{5}(-\d{4})?$/;
              if (!autoInsuranceDetails[0].postalCode.match(zipFormat)) {
                setzipCodeReg(true);
                setvalidationAlert(true);
    
                errorcode = true;
              } else {
                setzipCodeReg(false);
              }
            }
            if (autoInsuranceDetails[0].driverLicenseState === "") {
              setvalidationAlert(true);
              return false;
            }
            if (
              autoInsuranceDetails[0].driverLicenseNumber === "" ||
              autoInsuranceDetails[0].driverLicenseNumber.trim() === ""
            ) {
              setvalidationAlert(true);
              return false;
            }
            if (autoInsuranceDetails[0].driverLicenseNumber !== "") {
              var driverLicenseNumberFormat =
                autoInsuranceDetails[0].driverLicenseNumber.length;
              if (driverLicenseNumberFormat < 1) {
                setdriverlicensenumberRegex(true);
                setvalidationAlert(true);
                errorcode = true;
              } else {
                setdriverlicensenumberRegex(false);
              }
            }
            if (errorcode === true) {
              return false;
            }
            setvalidationAlert(false);
            if (value === 0) {
              setcurrentpage(currentpage + 1);
            } else {
              setcurrentpage(value);
            }
            var isFound: any = previousSteps("Personal Details");
            if (isFound === true) {
              tempdynamicpages.push("Personal Details");
            }
    
            break;
          case 2:
            if (autoInsuranceDetails[0].currentlyInsured === "") {
              setvalidationAlert(true);
              return false;
            }
            setvalidationAlert(false);
            if (value === 0) {
              setcurrentpage(currentpage + 1);
            } else {
              setcurrentpage(value);
            }
            var isFound: any = previousSteps("Current Policy Information");
            if (isFound === true) {
              tempdynamicpages.push("Current Policy Information");
            }
            break;
          case 3:
            if (value === 0) {
              setcurrentpage(currentpage + 1);
            } else {
              setcurrentpage(value);
            }
            var isFound: any = previousSteps("Additional Drivers");
            if (isFound === true) {
              tempdynamicpages.push("Additional Drivers");
            }
            break;
          case 4:
            var makevalidation;
            vechicles.map((value, index) => {
              if (value.Name === "") {
                setvalidationAlert(true);
                makevalidation = true;
                return false;
              }
            });
            if (makevalidation === true) {
              return false;
            }
            setvalidationAlert(false);
            if (value === 0) {
              setcurrentpage(currentpage + 1);
            } else {
              setcurrentpage(value);
            }
            var isFound: any = previousSteps("Tell us about your vehicles");
            if (isFound === true) {
              tempdynamicpages.push("Tell us about your vehicles");
            }
            break;
          case 5:
            if (value === 0) {
              setcurrentpage(currentpage + 1);
            } else {
              setcurrentpage(value);
            }
            var isFound: any = previousSteps("Coverage Details");
            if (isFound === true) {
              tempdynamicpages.push("Coverage Details");
            }
            break;
          case 6:
            if (value === 0) {
              setcurrentpage(currentpage + 1);
            } else {
              setcurrentpage(value);
            }
            var isFound: any = previousSteps("Claim Details");
            if (isFound === true) {
              tempdynamicpages.push("Claim Details");
            }
        }
        setdynamicpages(tempdynamicpages);
      }
    
      //PC_Auto_25
      //This function is used to check whether the value is already exist in the previousstepsarray
      function previousSteps(value: string) {
        for (let i = 0; i < dynamicpages.length; i++) {
          if (dynamicpages[i] === value) {
            return false;
          }
        }
        return true;
      }
    
      //PC_Auto_21
      //When user clicks on Request Quote button then this function is invoked
      async function requestQuote() {
        var newdriverArray;
        var expirationdate;
        var currentcarrier;
        var claimdetails;
        var renweprem
        if (autoInsuranceDetails[0].additionalDrivers === "No") {
          newdriverArray = [
            {
              First_Name__c: null,
              Last_Name__c: null,
              Date_of_Birth__C: null,
              Gender__c: null,
              Relationship__c:null,
              Qualified_Discounts__c: null,
              Driver_s_License_Number__c: null,
              Tickets_or_Accidents__c: null,
              State__c: null,
            },
          ];
        } else {
          newdriverArray = drivers.map((values: any, index: any) => {
            return {
              First_Name__c:
                values.First_Name__c !== ""
                  ? values.First_Name__c.trimStart().trimEnd()
                  : null,
              Last_Name__c:
                values.Last_Name__c !== ""
                  ? values.Last_Name__c.trimStart().trimEnd()
                  : null,
               
              Date_of_Birth__C:
                values.Date_of_Birth__c !== "" ? values.Date_of_Birth__c : null,
              Gender__c: values.Gender__c !== "" ? values.Gender__c : null,
              Relationship__c:values.Relationship__c !==""?values.Relationship__c:null,
              Tickets_or_Accidents__c:values.Tickets_or_Accidents__c!=""?values.Tickets_or_Accidents__c:null,
              Qualified_Discounts__c:
                values.Qualified_Discounts__c !== ""
                  ? values.Qualified_Discounts__c
                  : null,
              Driver_s_License_Number__c:
                values.Driver_s_License_Number__c !== ""
                  ? values.Driver_s_License_Number__c.trimStart().trimEnd()
                  : null,
              State__c: values.State__c !== "" ? values.State__c : null,
            };
          });
        }
    
        if (autoInsuranceDetails[0].currentlyInsured === "No") {
          currentcarrier = null;
          expirationdate = null;
          renweprem = null;
        } else {
          currentcarrier =
            autoInsuranceDetails[0].currentCarrier !== ""
              ? autoInsuranceDetails[0].currentCarrier
              : null;
          expirationdate =
            autoInsuranceDetails[0].policyExpirationDate !== ""
              ? autoInsuranceDetails[0].policyExpirationDate
              : null;
              renweprem = autoInsuranceDetails[0].renewalPremium !== ""? autoInsuranceDetails[0].renewalPremium : null;
        }
        if (autoInsuranceDetails[0].hasClaims === "No") {
          claimdetails = null;
        } else {
          claimdetails =
            autoInsuranceDetails[0].claimDetails !== ""
              ? autoInsuranceDetails[0].claimDetails
              : null;
        }
    
        let newVehicle = vechicles.map((value: any, index: any) => {
          return {
            Name:
              value.Name !== ""
                ? value.Name.trimStart().trimEnd() + " " + value.Model__c
                : null,
            Make__c: value.Name !== "" ? value.Name.trimStart().trimEnd() : null,
            Model__c: value.Model__c !== "" ? value.Model__c : null,
            Year__c: value.Year__c !== "" ? value.Year__c : null,
            VIN__c: value.VIN__c !== "" ? value.VIN__c : null,
            Miles_to_work__c:value.Miles_to_work__c!=""?value.Miles_to_work__c.trimStart().trimEnd():null,
            Comp_Collision_Deductibles__c:
              value.Comp_Collision_Deductibles__c !== ""
                ? value.Comp_Collision_Deductibles__c
                : null,
               
          };
        });
    
        let data = {
          personalDetails: {
            FirstName:
              autoInsuranceDetails[0].firstName !== ""
                ? autoInsuranceDetails[0].firstName.trimStart().trimEnd()
                : null,
            LastName:
              autoInsuranceDetails[0].lastName !== ""
                ? autoInsuranceDetails[0].lastName.trimStart().trimEnd()
                : null,
            Birthdate__c:
              autoInsuranceDetails[0].dateOfBirth !== ""
                ? autoInsuranceDetails[0].dateOfBirth
                : null,
            CC_Person_Marital_Status__c:
            autoInsuranceDetails[0].personMaritalStatus !== ""
                ?  autoInsuranceDetails[0].personMaritalStatus
                : null
            ,
            Email:
              autoInsuranceDetails[0].email !== ""
                ? autoInsuranceDetails[0].email.trimStart().trimEnd()
                : null,
            MobilePhone:
              autoInsuranceDetails[0].mobilePhone !== ""
                ? autoInsuranceDetails[0].mobilePhone.trimStart().trimEnd()
                : null,
            Street:
              autoInsuranceDetails[0].street !== ""
                ? autoInsuranceDetails[0].street.trimStart().trimEnd()
                : null,
            City:
              autoInsuranceDetails[0].city !== ""
                ? autoInsuranceDetails[0].city.trimStart().trimEnd()
                : null,
            State:
              autoInsuranceDetails[0].state !== ""
                ? autoInsuranceDetails[0].state.trimStart().trimEnd()
                : null,
            PostalCode:
              autoInsuranceDetails[0].postalCode !== ""
                ? autoInsuranceDetails[0].postalCode.trimStart().trimEnd()
                : null,
            Drivers_License_Number__c:
              autoInsuranceDetails[0].driverLicenseNumber !== ""
                ? autoInsuranceDetails[0].driverLicenseNumber.trimStart().trimEnd()
                : null,
            Drivers_License_State__c:
              autoInsuranceDetails[0].driverLicenseState !== ""
                ? autoInsuranceDetails[0].driverLicenseState
                : null,
            Preferred_Contact_Method__c:
              autoInsuranceDetails[0].preferredContactMethod !== ""
                ? autoInsuranceDetails[0].preferredContactMethod
                : null,
            Complete_Quote__c:
              autoInsuranceDetails[0].preferredDetails !== ""
                ? autoInsuranceDetails[0].preferredDetails
                : null,
            OwnerId: OwnerId,
            parameterId:parameterId
          },
          currentPolicy: {
            Current_Coverage__c:
              autoInsuranceDetails[0].currentlyInsured !== ""
                ? autoInsuranceDetails[0].currentlyInsured
                : null,
            Qualified_Discounts__c: discountstring !== "" ? discountstring : null,
            Current_Carrier__c: currentcarrier,
           Renewal_Premium__c: renweprem,
            Previous_Policy_Expiration_Date__c: expirationdate,
            VersionData: file === "" ? null : file,
            filename: filename === "" ? null : filename,
            Lapse_Time__c:
              autoInsuranceDetails[0].policyexpireddate !== ""
                ? autoInsuranceDetails[0].policyexpireddate
                : null,
          },
          additionDrivers: {
            Additional_Drivers__c:
              autoInsuranceDetails[0].additionalDrivers !== ""
                ? autoInsuranceDetails[0].additionalDrivers
                : null,
            AdditionalDriverArray: newdriverArray,
          },
          vehicleDetails: {
            vehicleDetailsArray: newVehicle,
          },
          coverageDetails: {
            Coverage_Needed__c:
              autoInsuranceDetails[0].coverageNeeded !== ""
                ? autoInsuranceDetails[0].coverageNeeded
                : null,
            Desire_Uninsured_Motorist__c:
              autoInsuranceDetails[0].unInsuredMotorist !== ""
                ? autoInsuranceDetails[0].unInsuredMotorist
                : null,
            Desire_Personal_Injury_Coverage__c:
              autoInsuranceDetails[0].personalInjuryCoverage !== ""
                ? autoInsuranceDetails[0].personalInjuryCoverage
                : null,
          },
          claimDetails: {
            Has_Claims__c:
              autoInsuranceDetails[0].hasClaims !== ""
                ? autoInsuranceDetails[0].hasClaims
                : null,
            Claim_Details__c: claimdetails,
            Towing__c:
              autoInsuranceDetails[0].needTowing !== ""
                ? autoInsuranceDetails[0].needTowing
                : null,
            Rental_Reimbursement__c:
              autoInsuranceDetails[0].reimbursement !== ""
                ? autoInsuranceDetails[0].reimbursement
                : null,
          },
        };
    
        console.log("All Data ------------->",data)
        setLoading(true);
        let datasFromApi: any = await postInsuranceQuoteData(data, "auto");
        console.log(datasFromApi);
         window.location.href = "/thankyou";
      }
      const Formname = "Auto Insurance";
    
      const headerinfo = {
        Name: Formname,
        totalPages: totalpage,
        pgTopic: pages,
        currentPg: currentpage,
        previousStepArry: dynamicpages
      };
    
      function nextButtonHide() {
        if (currentpage === 3) {
          if (
            autoInsuranceDetails[0].additionalDrivers === "Yes" &&
            autoInsuranceDetails[0].preferredDetails ===
            "Provide us all the details"
          ) {
            let templabelShow = [...labelShowDriver];
            for (let i = 0; i < templabelShow.length; i++) {
              if (templabelShow[i].saveHide === false) {
                return false;
                break;
              }
            }
          }
    
          return true;
        } else if (currentpage === 4) {
          let templabelShow = [...labelShowVechicle];
          for (let i = 0; i < templabelShow.length; i++) {
            if (templabelShow[i].saveHide === false) {
              return false;
              break;
            }
          }
          return true;
        } else {
          return true;
        }
      }
      const childToParent = (data: any) => {
        console.log(data, "page");
        setcurrentpage(data)
      }
    
      return (
        <div className="container mt-5">
          <div className="row mt-4">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="row">
                <div className='col-lg-11 col-md-12 col-sm-12'>
                  <div className='row'>
                    <Header value={headerinfo} childToParent={(data: any) => childToParent(data)} />
                    {/* PC_Auto_9 */}
                    {currentpage === 1 ? personalDetails() : null}
                    {/* PC_Auto_10 */}
                    {currentpage === 2 ? currentPolicyinfo() : null}
                    {/* PC_Auto_11 */}
                    {currentpage === 3 ? additionalDriver() : null}
                    {/* PC_Auto_12 */}
                    {currentpage === 4 ? vehicleDetails() : null}
                    {/* PC_Auto_13 */}
                    {currentpage === 5 ? coverageDetails() : null}
                    {/* PC_Auto_14 */}
                    {currentpage === 6 ? claimDetail() : null}
                    {/* {formtype !== "personal" ? <button onClick={() => onClickBack()}>Back</button> : null}
                        {formtype !== "claimdetails" ? <button onClick={() => validation()}>Next</button> : <button onClick={() => requestQuote()}>Request quote</button>} */}
                    {/* Footer */}
                    <div className="col-md-12 mt-4 mb-4">
                      {currentpage !== 1 ? (
                        <button
                          type="button"
                          className="btn btn-outline-secondary font14 mt-4 px-5 previous-button mobile-button"
                          onClick={() => onClickBack()}
                        >
                          <span className="previous-button-arrow me-1">
                            <img src={leftarrow} alt="auto" />
                            <img src={leftarrowwhite} alt="auto" />
                          </span>
                          Previous
                        </button>
                      ) : null}
                      {(currentpage === 6 &&
                        autoInsuranceDetails[0].preferredDetails ===
                        "Provide us all the details") ||
                        (currentpage === 5 &&
                          autoInsuranceDetails[0].preferredDetails ===
                          "Just give key points") ? (
                        <button
                          type="button"
                          className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button"
                          style={{ cursor: "pointer" }}
                          onClick={() => requestQuote()}
                        >
                          Request Quote
                        </button>
                      ) : nextButtonHide() === true ? (
                        <button
                          type="button"
                          className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button"
                          onClick={() => validation(0)}
                        >
                          Next Step
                          <img src={rightarrow} className="ms-2" />
                        </button>
                      ) : (
                        <button
                          type="button"
                          disabled={true}
                          className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button"
                          onClick={() => validation(0)}
                        >
                          Next Step
                          <img src={rightarrow} className="ms-2" />
                        </button>
                      )}
                    </div>
                    {Loading == true ? (
                      <div id="root">
                        <h1>Submitting Your Request</h1>
    
                        <h3>Please Wait for few seconds....</h3>
    
                        <div className="loader-wrapper">
                          <div className="loader">
                            <span></span> <span></span> <span></span> <span></span>{" "}
                            <span></span>{" "}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {/* Footer */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 right-panel-margin hide-right">
              <div className="row">
                {/* Previous Steps */}
                {dynamicpages.length > 0 ? (
                  <React.Fragment>
                    <div className="col-md-12 border bg-light p-2 previous-steps-box border-bottom-0">
                      <label className="ps-2 font-16 font-bold">
                        Previous Steps
                      </label>
                    </div>
                    <div className="col-md-12 border border-top-0 previous-steps-box px-0">
                      {dynamicpages.map((value: string, index: number) => {
                        return (
                          <li className="list-unstyled px-0 font16 font-primary">
                            <a
                              className="dropdown-item py-2 gib-tooltip"
                              onClick={() => setcurrentpage(index + 1)}
                            >
                              {value}
                              <span className="gib-tooltip">
                                {" "}
                                <img src={editicon} className="edit-icon ms-2" />
                                <span className="tooltiptext">
                                  {"Go to Step" + " " + (index + 1)}
                                </span>
                              </span>
                            </a>
                          </li>
                        );
                      })}
                    </div>
                  </React.Fragment>
                ) : null}
    
                {/* Need Help */}
                <div
                  className={
                    dynamicpages.length !== 0
                      ? "col-md-12 border bg-light p-2 mt-4 previous-steps-box border-bottom-0"
                      : "col-md-12 border bg-light p-2 previous-steps-box border-bottom-0"
                  }
                >
                  <label className="ps-2 font-16 font-bold">Need Help ?</label>
                </div>
                <div className="col-md-12 border border-top-0 previous-steps-box">
                  <label className="ps-2 w-100 mt-3 font16 font-semibold">
                    The Gibraltar Group
                  </label>
                  <label className="ps-2 w-100 mt-2 mb-3 font14">
                    (281) 681-0331
                  </label>
                </div>
                {/* Need Help */}
                {/* Home button */}
                <button
                  type="button"
                  className="btn btn-primary mt-4 py-2 primary-button border-0 font14 home-button mb-4"
                  onClick={() => {
                    window.location.href = "/";
                  }}
                >
                  <img src={leftarrowwhite} alt="auto" className="me-1" /> Back to
                  Home
                </button>
    
                {/* Home button */}
              </div>
            </div>
          </div>
        </div>
      );
    }
    
    export default AutoInsurance;
    