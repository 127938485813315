/*
   // Project: Gibraltar
   // Desc: This file contains the General Liability insurance component by using this component the user will able to request quote for their General Liability;
   // Author:Dhamotharan P
   // Created Date:04/04/2022
   //File Name : generalLiability.tsx;
*/

//pc_glf_1
//importing the useState
//importing the needed images
import React, { useEffect, useRef, useState } from "react";
import { postInsuranceQuoteData } from "./service";
import '../App.css';
import previousLogo from '../images/Arrow-Left-White.svg';
import previousColor from '../images/Arrow-Left.svg';
import edit from '../images/Edit.svg';
import nextLogo from '../images/Arrow-right.svg';
import Header from "./header";
import PhoneInput from "react-number-format";
import formHelp from '../images/Form-Help.svg';
import axios from "axios";
import Cancel_button from "../images/Cancel-button.svg";
import calender from '../images/Calender.svg'
//pc_glf_2
//declaring state variables for every fields as an empty strings
export default function GeneralLiabilityForm() {

    var QueryString = new URLSearchParams(window.location.search);
    var OwnerId = QueryString.get("agentId");
    let parameterId= QueryString.get("parameterId");

    const [generalLiability, setGeneralLiability] = useState([{
        // state variables in General information page
        FirstName: "",
        LastName: "",
        Email: "",
        Mobile: "",
        Street: "",
        City: "",
        State: "",
        Zip: "",
        ContactMethod: "",
        Quote: "Provide us all the details",
        MeetingTime: "",
        MeetingDay: "",
        // state variables in Industry details page
        Company: "",
        OperationDetails: "",
        Industry: "",
        JobTitle: "",
        Venture: "",
        InBusiness: "",
        // state variables in Coverage details page
        Insured: "",
        Carrier: "",
        ExpirationDate: "",
        // state variables in Employee details page
        Employees: "",
        NumberOfEmployees: "",
        TotalPayroll: "",
        // state variable in Revenue details page   
        GrossReceipts: ""
    }])

    useEffect(() => { toClearDataOnDetailTypeChange(); }, [generalLiability[0].Quote]);

    //This function is to clear all the fields in form when the flow is changed from provide us all details to just give key points
    function toClearDataOnDetailTypeChange() {

        let spreader = [...generalLiability];

        spreader[0].FirstName = spreader[0].FirstName
        spreader[0].LastName = spreader[0].LastName
        spreader[0].Email = spreader[0].Email
        spreader[0].Mobile = spreader[0].Mobile
        spreader[0].Street = spreader[0].Street
        spreader[0].City = spreader[0].City
        spreader[0].State = spreader[0].State
        spreader[0].Zip = spreader[0].Zip
        spreader[0].ContactMethod = spreader[0].ContactMethod
        spreader[0].Quote = spreader[0].Quote
        spreader[0].MeetingTime = spreader[0].MeetingTime
        spreader[0].MeetingDay = spreader[0].MeetingDay
        spreader[0].Company = ""
        spreader[0].OperationDetails = ""
        spreader[0].Industry = ""
        spreader[0].JobTitle = ""
        spreader[0].Venture = ""
        spreader[0].InBusiness = ""
        spreader[0].Insured = ""
        spreader[0].Carrier = ""
        spreader[0].ExpirationDate = ""
        spreader[0].Employees = ""
        spreader[0].NumberOfEmployees = ""
        spreader[0].TotalPayroll = ""
        spreader[0].GrossReceipts = ""


        setGeneralLiability(spreader);
    }


    const [currentPages, setCurrentPages] = useState(1);
    const [dateFieldFocused, setdateFieldFocused] = useState(false)

    const [pages, setPages] = useState([
        "Personal Details",
        "Industry Details",
        "Coverage Details",
        "Employee Details",
        "Revenue Details"
    ]);

    const [validFirst, setValidFirst] = useState(true);


    const [previousStepArry, setPreviousStepArry] = useState([]);

    //these state variables for hide and show the fields
    const [hide1, setHide1] = useState(false);
    const [hide2, setHide2] = useState(false);
    const [hide3, setHide3] = useState(false);
    const [hide4, setHide4] = useState(false);
    const [validCrossed, setValidCrossed] = useState(false);
    //const [Inbusinessvalidation,setInbusinessvalidation]=useState(false)

    //declaring a common single state variable with boolean value 'false' 
    const [Alert, setAlert] = useState(false);
    //these state variables for regular expression validation

    const [emailvalidate, setEmailValidate] = useState(false)
    const [mobilevalidate, setMobileValidate] = useState(false)
    const [zipvalidate, setZipValidate] = useState(false)
    const [businessperiod, setBusinessPeriod] = useState(false)
    const [grossReceipt, setGrossReceipt] = useState(false)

    const [file, setfile] = useState();
    const [fileValid, setfileValid] = useState(false)
    const [fileLoading, setFileLoading] = useState(false)
    const [cancelvisible, setCancelvisible] = useState(false)
    const myref: any = useRef(null);
    const [filename, setfilename] = useState("")

    var states = ["AK", "AL", "AR", "AZ", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "IA", "ID", "IL", "IN", "KS", "KY", "LA", "MA", "MD", "ME", "MI", "MN", "MO", "MS", "MT", "NC", "ND", "NE", "NH", "NJ", "NM", "NV", "NY", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VA", "VT", "WA", "WI", "WV", "WY"];

    const [Loading, setLoading] = useState(false)

    function disablePastDate() {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };


    function buttonClickFunction(page: any, type: any) {

        if (type === "back") {
            setCurrentPages(currentPages - 1)
        } else if (type === "next") {
            setCurrentPages(currentPages + 1)
        }


        let finishedPagesSpread: any = [...previousStepArry]




        if (!(finishedPagesSpread.includes(pages[page - 1]))) {
            finishedPagesSpread.push(pages[page - 1]);
        }

        setPreviousStepArry(finishedPagesSpread);
    }

    //This function is invoked when user enters any value in any fields
    function handlechange(e: any) {


        let name = "", value = ""
        let jsonDataSpread: any = [...generalLiability];
        function dotcheck(value: string) {

            var count = 0;
            var valuearr = value.split('');
            for (let i = 0; i < valuearr.length; i++) {
                if (valuearr[i] === '.') {
                    count++
                }
            }
            if (count > 1) {
                return true
            }
        }

        if (e.target) {
            name = e.target.name;
            value = e.target.value
            if (name === "Zip") {
                value = value.replace(/[^0-9]/g, '');
            }
            else if (e.target.name === "InBusiness") {
                if (generalLiability[0].InBusiness.includes('.')) {
                    if (dotcheck(value)) {
                        value = value.replace(value, generalLiability[0].InBusiness)
                    }
                    else {
                        value = value.replace(/[^0-9\.]/g, '')
                    }
                }
                else {
                    value = value.replace(/[^0-9\.]/g, '')
                }
            }
            else if (name === "GrossReceipts") {
                value = value.replace(/[^0-9]/g, '');
            }
            if (e.target.name === "InBusiness") {
                if (value.includes(".")) {
                    e.target.maxLength = 4
                }
                else {
                    e.target.maxLength = 3
                }
            }
            jsonDataSpread[0][name] = value;

            if (validFirst === false) {
                if (name === "Email") {
                    var mailFormat = /^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)*[\w+-]+\.[a-zA-Z]{2,4}$/;
                    if (!(generalLiability[0].Email.match(mailFormat))) {
                        setEmailValidate(true);

                    }
                    if ((generalLiability[0].Email.match(mailFormat))) {
                        setEmailValidate(false);
                        //return false;
                    }
                }
                else if (name === "Zip") {
                    var zipFormat = /\b\d{5}\b/g;
                    if (!(generalLiability[0].Zip.match(zipFormat))) {
                        setZipValidate(true);

                    }
                    if ((generalLiability[0].Zip.match(zipFormat))) {
                        setZipValidate(false);
                        //return false;
                    }
                }
                // else if (name === "InBusiness") {
                //     var businessFormat = /[^0-9]/g;
                //     if (!(generalLiability[0].InBusiness.match(businessFormat))) {
                //         setBusinessPeriod(true);
                //     }
                //     if ((generalLiability[0].InBusiness.match(businessFormat))) {
                //         setBusinessPeriod(false);
                //         // return false;
                //     }
                // }


                else if (name === "GrossReceipts") {
                    var grossformat = /[^0-9]/g;
                    if (!(generalLiability[0].GrossReceipts.match(grossformat))) {
                        setGrossReceipt(true);
                    }
                    if ((generalLiability[0].GrossReceipts.match(grossformat))) {
                        setGrossReceipt(false);
                    }
                }
            }

        }


        else {
            name = "Mobile"
            value = e.value;
            jsonDataSpread[0][name] = value;
        }

        setGeneralLiability(jsonDataSpread);

        if (name === "Mobile") {
            if (validCrossed === true) {
                var phoneFormat = /^\d{10}$/;
                if (!(generalLiability[0].Mobile.match(phoneFormat))) {
                    setMobileValidate(true);
                }
                else if ((generalLiability[0].Mobile.match(phoneFormat))) {
                    setMobileValidate(false);
                }
            }
        }

    }

    function Cancel_buttonClick() {
        setFileLoading(false)
        setfilename("")
        myref.current.value = ""

        setCancelvisible(false)
    }
    const [size, setSize] = useState(0)
    const [type, setType] = useState("")
    const[formatValid,setformatValid]=useState(false)
    async function validateFile(e: any) {

        const convertBase64 = (files: Blob) => {

            return new Promise((resolve, reject) => {

                const fileReader = new FileReader()

                fileReader.readAsDataURL(files)



                fileReader.onload = (() => {

                    resolve(fileReader.result)

                })



                fileReader.onerror = ((error: any) => {

                    reject(error)

                })

            })

        }



        console.log("File Uploaded-->", e.target.files[0])
        setSize(e.target.files[0].size)
        setType(e.target.files[0].type)
   

        if (e.target.files[0].type !== "application/pdf") {

            e.target.value = ""
            setFileLoading(false)
            setformatValid(true)
            setfileValid(false)
            setfilename("")
            setCancelvisible(false)

        }
        if (e.target.files[0].size <= 5242880) {
            setCancelvisible(true)
            const blobFile = e.target.files[0]

            const filename = e.target.files[0].name

            console.log(filename)

            let s = filename

            let a = s.replace('.pdf', "")

            console.log("filename-->", a)

            setfilename(a)

            setFileLoading(true)

            let base64: any = await convertBase64(blobFile)

            console.log(base64)

            let b = base64.replace('data:application/pdf;base64,', "")

            console.log("Base64", b)

            setFileLoading(false)

            setfile(b)

        }

        else if (e.target.files[0].size === 0 || e.target.files[0].size >  5242880) {
            e.target.value = '';
            setfileValid(true)
            setfilename("")
            setCancelvisible(false)


        }


    }

    function validateForm1() {

        var errorcount = 0
        if (generalLiability[0].FirstName.trim() === "") {
            setAlert(true);
            errorcount++
        }
        if (generalLiability[0].LastName.trim() === "") {
            setAlert(true);
            errorcount++
        }
        if (generalLiability[0].Email === "") {
            setAlert(true);
            errorcount++
        }
        //regex validation for Email
        if (generalLiability[0].Email !== "") {
            var mailFormat = /^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)*[\w+-]+\.[a-zA-Z]{2,4}$/;
            if (!(generalLiability[0].Email.match(mailFormat))) {
                setEmailValidate(true);
                setAlert(true);
                errorcount++
            }
            if ((generalLiability[0].Email.match(mailFormat))) {
                setEmailValidate(false);
                setAlert(false);

            }
        }
        if (generalLiability[0].Mobile === "") {
            setAlert(true);
            errorcount++

        }
        //regex validation for Mobile Phone
        if (generalLiability[0].Mobile !== "") {
            var phoneFormat = /^\d{10}$/;
            if (!(generalLiability[0].Mobile.match(phoneFormat))) {
                setMobileValidate(true);
                setAlert(true);
                errorcount++
            }
            if ((generalLiability[0].Mobile.match(phoneFormat))) {
                setMobileValidate(false);
                setAlert(false);
                //return false;
            }
        }
        if (generalLiability[0].Street.trim() === "") {
            setAlert(true);
            errorcount++

        }
        if (generalLiability[0].City.trim() === "") {
            setAlert(true);
            errorcount++

        }
        if (generalLiability[0].State === "") {
            setAlert(true);
            errorcount++

        }
        if (generalLiability[0].Zip === "") {
            setAlert(true);
            errorcount++

        }
        //regex validation for Zip code
        if (generalLiability[0].Zip !== "") {
            var zipFormat = /\b\d{5}\b/g;
            if (!(generalLiability[0].Zip.match(zipFormat))) {
                setZipValidate(true);
                setAlert(true)
                errorcount++
            }
            if ((generalLiability[0].Zip.match(zipFormat))) {
                setZipValidate(false);
                setAlert(false)
                //return false;
            }
        }

        if (generalLiability[0].Quote === "") {
            setAlert(true);
            errorcount++

        }

        if (errorcount !== 0) {
            setValidFirst(false);
            setAlert(true)
            setValidCrossed(true);
        }

        if (errorcount === 0) {
            setAlert(false)
            if (generalLiability[0].Quote !== "Just give key points") {

                //  setCurrentpage("industrydetails");

                buttonClickFunction(currentPages, "next");

            } else if (generalLiability[0].Quote === "Just give key points") {
                postData();
            }
        }
    }


    //field validation for industrydetails
    function validateForm2() {
        var errorcount = 0
        if (generalLiability[0].Industry === "") {
            setAlert(true);
            errorcount++

        }
        if (generalLiability[0].JobTitle.trim() === "") {
            setAlert(true);
            errorcount++

        }
        if (generalLiability[0].Venture === "") {
            setAlert(true);
            errorcount++

        }

        if (generalLiability[0].Venture === "no") {
            if (generalLiability[0].InBusiness === "") {
                setAlert(true);
                errorcount++
            }

            if (generalLiability[0].InBusiness !== "") {
                var businessFormat = /((\d+)((\.\d{1,2})?))$/;
                if (!(generalLiability[0].InBusiness.match(businessFormat))) {
                    setBusinessPeriod(true);
                    errorcount++
                }
                if ((generalLiability[0].InBusiness.match(businessFormat))) {
                    setBusinessPeriod(false);
                    // return false;
                }
            }
        }

        if (hide2 === true) {
            if (generalLiability[0].InBusiness === "") {
                setAlert(true);
                errorcount++
            }

        }

        //       if (hide2 === true) {
        //     if (generalLiability[0].InBusiness === "") {
        //         setAlert(true);
        //         errorcount++
        //     }
        // }

        //regex validation for how long have you been in business
        if (errorcount === 0) {
            setAlert(false)
            buttonClickFunction(currentPages, "next");

        }

    }

    //field validation for coverage details
    function validateForm3() {
        var errorcount = 0
        if (hide3 === true) {

            if (generalLiability[0].Carrier.trim() === "") {
                setAlert(true);
                errorcount++

            }
            if (generalLiability[0].ExpirationDate === "") {
                setAlert(true);
                errorcount++

            }

        }
        if (errorcount === 0) {
            setAlert(false);
            buttonClickFunction(currentPages, "next");
        }
    }
    //this hides the validation once the valid data is given
    const Gname = "General Liability Insurance"
    const tPages = generalLiability[0].Quote === "Just give key points" ? 1 : 5
    const Info = { Name: Gname, totalPages: tPages, pgTopic: pages, currentPg: currentPages, previousStepArry: previousStepArry }


    //pc_glf_3
    //function for general information page 
    const personalInfo = () => {
        return (<React.Fragment>

            {/* Steps indicator header */}
            {/* Header */}
            {/* Form-Component */}
            {/* General Info Starts here */}
            <div className="col-md-12  mt-4">
                <label className="font20 font-primary font-semibold font-head pb-2 border-bottom w-100">General Info</label>
            </div>
            <div className="col-md-6 mt-4">
                <label htmlFor="label1" className="form-label font14 font-semibold">First Name*</label>
                <input type="text" className="form-control pb-2 pt-2" id="label1" name="FirstName" maxLength={40} value={generalLiability[0].FirstName} onChange={(e) => { handlechange(e) }} />
                <label className="text-danger font14">
                    {
                        (Alert === true && generalLiability[0].FirstName.trim() === "") ? "Please enter the First Name" : null
                    }
                </label>
            </div>
            <div className="col-md-6 mt-4 single-column-margin">
                <label htmlFor="label2" className="form-label font14 font-semibold">Last Name*</label>
                <input type="text" className="form-control pb-2 pt-2" id="label2" name="LastName" maxLength={80} value={generalLiability[0].LastName} onChange={(e) => { handlechange(e) }} />
                <label className="text-danger font14">
                    {
                        (Alert === true && generalLiability[0].LastName.trim() === "") ? "Please enter the Last Name" : null
                    }
                </label>
            </div>

            {/* General Info Ends Here */}
            {/* Communication Info Starts Here */}
            <div className="col-md-12 mt-2">
                <label className="font20 font-primary font-semibold font-head pb-3 border-bottom w-100">Communication Info</label>
            </div>
            <div className="col-md-12 mt-3">
                <label htmlFor="label4" className="form-label font14 font-semibold">Email*</label>
                <input type="email" className="form-control pb-2 pt-2" id="label4" name="Email" maxLength={80} value={generalLiability[0].Email} onChange={(e) => { handlechange(e) }} />
                <label className="text-danger font14">
                    {
                        (Alert === true && generalLiability[0].Email === "") ? "Please enter an Email" :
                            (Alert === true && emailvalidate === true) ? "Please enter a valid Email " : null
                    }
                </label>
            </div>
            <div className="col-md-12">
                <label htmlFor="label5" className="form-label font14 font-semibold">Mobile Phone*</label>

                {generalLiability[0].Mobile.length < 10 ?
                    <PhoneInput type="tel" placeholder="XXXXXXXXXX" className="form-control pb-2 pt-2" id="label5" name="Mobile" value={generalLiability[0].Mobile} onValueChange={(e) => { handlechange(e) }} /> :


                    <PhoneInput type="tel" format={"(###) ###-####"} placeholder="XXXXXXXXXX" className="form-control pb-2 pt-2" id="label5" name="Mobile" value={generalLiability[0].Mobile} onValueChange={(e) => { handlechange(e) }} />}

                <label className="text-danger font14">
                    {
                        (Alert === true && generalLiability[0].Mobile === "") ? "Please enter the Mobile Number" :
                            (Alert === true && mobilevalidate === true) ? "Please enter a valid Mobile Number" : null
                    }
                </label>
            </div>
            <div className="col-md-12">
                <label htmlFor="label6" className="form-label font14 font-semibold">Street*</label>
                <textarea className="form-control text-area-input" id="label6" rows={6} maxLength={120} defaultValue={""} name="Street" value={generalLiability[0].Street} onChange={(e) => { handlechange(e) }} />
                <label className="text-danger font14">
                    {
                        (Alert === true && generalLiability[0].Street.trim() === "") ? "Please enter the Street" : null
                    }
                </label>
            </div>
            <div className="col-md-12 ">
                <label htmlFor="label7" className="form-label font14 font-semibold">City*</label>
                <input type="text" className="form-control pb-2 pt-2" id="label7" name="City" maxLength={40} value={generalLiability[0].City} onChange={(e) => { handlechange(e) }} />
                <label className="text-danger font14">
                    {
                        (Alert === true && generalLiability[0].City.trim() === "") ? "Please enter the City" : null
                    }
                </label>
            </div>
            <div className="col-md-6 ">
                <label htmlFor="label8" className="form-label font14 font-semibold ">State/Province*</label>
                <select
                    className="form-select pb-2 pt-2"
                    name="State" id="label8"
                    // style={
                    //     (generalLiability[0].State === "")?{
                    //       "color":"grey"
                    //     }:{
                    //         "color":"black"
                    //     }
                    // }
                    value={generalLiability[0].State} onChange={(e) => { handlechange(e) }}>

                    <option value="">Select State/Province</option>
                    <option value="AK">AK</option>
                    <option value="AL">AL</option>
                    <option value="AR">AR</option>
                    <option value="AZ">AZ</option>
                    <option value="CA">CA</option>
                    <option value="CO">CO</option>
                    <option value="CT">CT</option>
                    <option value="DE">DE</option>
                    <option value="FL">FL</option>
                    <option value="GA">GA</option>
                    <option value="HI">HI</option>
                    <option value="IA">IA</option>
                    <option value="ID">ID</option>
                    <option value="IL">IL</option>
                    <option value="IN">IN</option>
                    <option value="KS">KS</option>
                    <option value="KY">KY</option>
                    <option value="LA">LA</option>
                    <option value="MA">MA</option>
                    <option value="MD">MD</option>
                    <option value="ME">ME</option>
                    <option value="MI">MI</option>
                    <option value="MN">MN</option>
                    <option value="MO">MO</option>
                    <option value="MS">MS</option>
                    <option value="MT">MT</option>
                    <option value="NC">NC</option>
                    <option value="ND">ND</option>
                    <option value="NE">NE</option>
                    <option value="NH">NH</option>
                    <option value="NJ">NJ</option>
                    <option value="NM">NM</option>
                    <option value="NV">NV</option>
                    <option value="NY">NY</option>
                    <option value="OH">OH</option>
                    <option value="OK">OK</option>
                    <option value="OR">OR</option>
                    <option value="PA">PA</option>
                    <option value="RI">RI</option>
                    <option value="SC">SC</option>
                    <option value="SD">SD</option>
                    <option value="TN">TN</option>
                    <option value="TX">TX</option>
                    <option value="UT">UT</option>
                    <option value="VA">VA</option>
                    <option value="VT">VT</option>
                    <option value="WA">WA</option>
                    <option value="WI">WI</option>
                    <option value="WV">WV</option>
                    <option value="WY">WY</option>
                </select>
                <label className="text-danger font14">
                    {
                        (Alert === true && generalLiability[0].State === "") ? "Please select the State/Province" : null
                    }
                </label>
            </div>
            <div className="col-md-6">
                <label htmlFor="label9" className="form-label font14 font-semibold">Zip/Postal Code*</label>
                <input type="text" className="form-control pb-2 pt-2" id="label9" name="Zip" maxLength={5} value={generalLiability[0].Zip} onChange={(e) => { handlechange(e) }} />
                <label className="text-danger font14">
                    {
                        (Alert === true && generalLiability[0].Zip === "") ? "Please enter the Zip/Postal Code" :
                            (Alert === true && zipvalidate === true) ? "Please enter the valid Zip/Postal Code" : null
                    }
                </label>
            </div>
            <div className="col-md-8">
                <label className="form-label w-100 font14 font-semibold">Preferred Contact Method</label>
                <input type="radio" className="btn-check radio-checked" id="Phone" autoComplete="off" name="ContactMethod" value="Phone" checked={generalLiability[0].ContactMethod === "Phone"} onChange={(e) => { handlechange(e) }} />
                <label className="btn btn-primary  button-radio font14 font-bold" htmlFor="Phone">Phone</label>
                <input type="radio" className="btn-check px-4 radio-checked" id="Email" autoComplete="off" name="ContactMethod" value="Email" checked={generalLiability[0].ContactMethod === "Email"} onChange={(e) => { handlechange(e) }} />
                <label className="btn btn-primary ms-2 button-radio  font14 font-bold" htmlFor="Email">Email</label>
                <input type="radio" className="btn-check px-4 radio-checked" id="Text" autoComplete="off" name="ContactMethod" value="Text" checked={generalLiability[0].ContactMethod === "Text"} onChange={(e) => { handlechange(e) }} />
                <label className="btn btn-primary ms-2 button-radio font14 font-bold" htmlFor="Text">Text</label>
                <input type="radio" className="btn-check px-4 radio-checked" id="Mail" autoComplete="off" name="ContactMethod" value="Mail" checked={generalLiability[0].ContactMethod === "Mail"} onChange={(e) => { handlechange(e) }} />
                <label className="btn btn-primary ms-2 button-radio font14 font-bold" htmlFor="Mail">Mail</label>
            </div>
            {/* Communication Info Ends Here */}
            <div className="col-md-12">
                <label className="border-bottom w-100" ></label>
            </div>
            <div className="col-md-12">
                <label className="form-label w-100 font14 font-semibold pt-3 w-100">Would you like to?*</label>
                <input type="radio" className="btn-check radio-checked px-4" id="Provide us all the details" autoComplete="off" name="Quote" value="Provide us all the details" checked={generalLiability[0].Quote === "Provide us all the details"} onChange={(e) => { handlechange(e) }} onClick={() => { setHide1(false) }} />
                <label className="btn btn-primary button-radio font14 font-bold px-4 button-radio2" htmlFor="Provide us all the details">Provide us all the details</label>
                <input type="radio" className="btn-check radio-checked px-4" id="Just give key points" autoComplete="off" name="Quote" value="Just give key points" checked={generalLiability[0].Quote === "Just give key points"} onChange={(e) => { handlechange(e) }} onClick={() => { setHide1(true) }} />
                <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4 button-radio2 button-just" htmlFor="Just give key points">Just give key points</label>
                <label className="text-danger font14">
                    {
                        (Alert === true && generalLiability[0].Quote === "") ? "Please select your Choice" : null
                    }
                </label>
            </div>
            {hide1 === true ?
                <div>
                    <div className="col-md-12 mt-4">
                        <label className="font20 font-primary font-semibold font-head pb-2">Schedule a time to talk</label>
                    </div>
                    <div className="col-md-12 mt-2">
                        <label className="form-label w-100 font14 font-semibold mb-0">Preferred Meeting Time</label>

                        <input
                            type="radio"
                            className="btn-check radio-checked"
                            id="Morning"
                            autoComplete="off"
                            name="MeetingTime"
                            value="Morning"
                            checked={generalLiability[0].MeetingTime === "Morning"}
                            onChange={(e) => { handlechange(e) }} />

                        <label className="btn btn-primary button-radio font14 font-bold mt-2 me-2" htmlFor="Morning">Morning</label>
                        <input type="radio" className="btn-check radio-checked px-4" id="Noon" autoComplete="off" name="MeetingTime" value="Noon" checked={generalLiability[0].MeetingTime === "Noon"} onChange={(e) => { handlechange(e) }} />
                        <label className="btn btn-primary me-2 button-radio font14 font-bold mt-2" htmlFor="Noon">Noon</label>
                        <input type="radio" className="btn-check radio-checked px-4" id="Afternoon" autoComplete="off" name="MeetingTime" value="Afternoon" checked={generalLiability[0].MeetingTime === "Afternoon"} onChange={(e) => { handlechange(e) }} />
                        <label className="btn btn-primary me-2 button-radio font14 font-bold mt-2" htmlFor="Afternoon">Afternoon</label>
                        <input type="radio" className="btn-check radio-checked px-4" id="Evening" autoComplete="off" name="MeetingTime" value="Evening" checked={generalLiability[0].MeetingTime === "Evening"} onChange={(e) => { handlechange(e) }} />
                        <label className="btn btn-primary button-radio font14 font-bold evening-button mt-2" htmlFor="Evening">Evening</label>
                    </div>
                    <div className="col-md-12 mt-4">
                        <label className="form-label w-100 font14 font-semibold mb-0">Preferred Meeting Day</label>
                        <input type="radio" className="btn-check radio-checked px-4" id="Monday" value="Monday" checked={generalLiability[0].MeetingDay === "Monday"} name="MeetingDay" onChange={(e) => { handlechange(e) }} autoComplete="off" />
                        <label className="btn btn-primary me-2 button-radio font14 mt-2 font-bold " htmlFor="Monday">Mon</label>
                        <input type="radio" className="btn-check radio-checked px-4" id="Tuesday" value="Tuesday" checked={generalLiability[0].MeetingDay === "Tuesday"} name="MeetingDay" onChange={(e) => { handlechange(e) }} autoComplete="off" />
                        <label className="btn btn-primary me-2 button-radio font14 mt-2 font-bold" htmlFor="Tuesday">Tue</label>
                        <input type="radio" className="btn-check radio-checked px-4" id="Wednesday" value="Wednesday" checked={generalLiability[0].MeetingDay === "Wednesday"} name="MeetingDay" onChange={(e) => { handlechange(e) }} autoComplete="off" />
                        <label className="btn btn-primary me-2 button-radio font14 mt-2 font-bold " htmlFor="Wednesday">Wed</label>
                        <input type="radio" className="btn-check radio-checked px-4" id="Thursday" value="Thursday" checked={generalLiability[0].MeetingDay === "Thursday"} name="MeetingDay" onChange={(e) => { handlechange(e) }} autoComplete="off" />
                        <label className="btn btn-primary me-2 button-radio font14 mt-2 font-bold" htmlFor="Thursday">Thu</label>
                        <input type="radio" className="btn-check radio-checked px-4" id="Friday" value="Friday" checked={generalLiability[0].MeetingDay === "Friday"} name="MeetingDay" onChange={(e) => { handlechange(e) }} autoComplete="off" />
                        <label className="btn btn-primary me-2 button-radio font14 mt-2 font-bold" htmlFor="Friday">Fri</label>
                        <input type="radio" className="btn-check radio-checked px-4" id="Saturday" value="Saturday" checked={generalLiability[0].MeetingDay === "Saturday"} name="MeetingDay" onChange={(e) => { handlechange(e) }} autoComplete="off" />
                        <label className="btn btn-primary me-2 button-radio font14 mt-2 sat-button font-bold" htmlFor="Saturday">Sat</label>
                        <input type="radio" className="btn-check radio-checked px-4" id="Sunday" value="Sunday" checked={generalLiability[0].MeetingDay === "Sunday"} name="MeetingDay" onChange={(e) => { handlechange(e) }} autoComplete="off" />
                        <label className="btn btn-primary button-radio font14 sun-button mt-2 font-bold" htmlFor="Sunday">Sun</label>
                    </div>
                </div> : null}
            {/* Footer */}
            <div className="col-md-12 mt-5 mb-4">
                {hide1 === true ?
                    <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end px-4 mobile-button" onClick={() => { validateForm1() }}>Request Quote</button> : null}
                {hide1 === false ?
                    <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end px-4 mobile-button" onClick={() => { validateForm1() }}>Next Step<img src={nextLogo} className="ms-2" /></button> : null}
            </div>
            {/* Footer */}
            {/* Form-Component */}
            {Loading == true ? <div id="root">
                <h1>Submitting Your Request</h1>
                <h3>Please Wait for few seconds....</h3>
                <div className="loader-wrapper">
                    <div className="loader"><span></span> <span></span> <span></span> <span></span> <span></span> </div>
                </div>
            </div> : null}
        </React.Fragment>
        );
    }
    //pc_glf_4
    //function for industry details page
    const industryDetails = () => {
        return (<React.Fragment>

            {/* Steps indicator header */}
            {/* Header */}
            {/* Form-Component */}
            {/* General Info Starts here */}
            <div className="col-md-12 mt-3 ">
                <label className="font20 font-primary font-semibold font-head pb-3 border-bottom w-100">Tell us about your business</label>
            </div>
            <div className="col-md-12  mt-3">
                <label htmlFor="label1" className="form-label font14 font-semibold">Name of the Company</label>
                <input type="text" maxLength={50} className="form-control pb-2 pt-2" id="label1" name="Company" value={generalLiability[0].Company} onChange={(e) => { handlechange(e) }} />
            </div>

            <div className="col-md-12 mt-3">
                <label htmlFor="label6" className="form-label font14 font-semibold">Daily Operations Details</label>
                <textarea className="form-control text-area-input" id="label6" rows={6} maxLength={500} defaultValue={""} name="OperationDetails" value={generalLiability[0].OperationDetails} onChange={(e) => { handlechange(e) }} />
            </div>
            <div className="col-md-6 mt-3">
                <label htmlFor="dls1" className="form-label font14 font-semibold">What is your industry?*</label>
                <select className="form-select pb-2 pt-2" aria-label="Default select example" id="dls1" name="Industry" value={generalLiability[0].Industry} onChange={(e) => { handlechange(e) }}>
                    <option value={""}>Select</option>
                    <option value={"Healthcare"}>Healthcare</option>
                    <option value={"Finance"}>Finance</option>
                    <option value={"Banking"}>Banking</option>
                    <option value={"Technology"}>Technology</option>
                    <option value={"Other"}>Other</option>
                </select>
                <label className="text-danger font14">
                    {
                        (Alert === true && generalLiability[0].Industry === "") ? "Please select your choice" : null
                    }
                </label>
            </div>
            <div className="col-md-6 mt-3">
                <label htmlFor="label-DC" className="form-label font14 font-semibold">What is your job title?*</label>
                <input type="text" className="form-control pb-2 pt-2" id="label-DC" name="JobTitle" maxLength={50} value={generalLiability[0].JobTitle} onChange={(e) => { handlechange(e) }} />
                <label className="text-danger font14">
                    {
                        (Alert === true && generalLiability[0].JobTitle.trim() === "") ? "Please enter your Job Title" : null
                    }
                </label>
            </div>
            <div className="col-md-6">
                <label htmlFor="Provide us all the details" className="form-label w-100 font14 font-semibold ">Is this a new venture?*</label>
                <input type="radio" className="btn-check radio-checked" id="no" autoComplete="off" name="Venture" value="no" checked={generalLiability[0].Venture === "no"} onChange={(e) => { handlechange(e) }} onClick={() => { setHide2(true) }} />
                <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor="no">No</label>
                <input type="radio" className="btn-check radio-checked" id="yes" autoComplete="off" name="Venture" value="yes" checked={generalLiability[0].Venture === "yes"} onChange={(e) => { handlechange(e) }} onClick={() => { setHide2(false) }} />
                <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor="yes">Yes</label>
            </div>
            <label className="text-danger font14">
                {
                    (Alert === true && generalLiability[0].Venture === "") ? "Please select your choice" : null
                }
            </label>

            {hide2 === true ?
                <div className="col-md-12 mt-4">
                    <label htmlFor="label1" className="form-label font14 font-semibold">How long have you been in business?*</label>
                    <input type="text" className="form-control pb-2 pt-2" id="label1" name="InBusiness" value={generalLiability[0].InBusiness} onChange={(e) => { handlechange(e) }} />
                    <label className="text-danger font14">
                        {
                            (Alert === true && generalLiability[0].InBusiness === "") ? "Please enter the Required Value" : null
                        }
                    </label>
                </div> : null}
            {/* Footer */}
            <div className="col-md-12 mt-5 mb-4">
                <button type="button" className="btn btn-outline-secondary font14 mt-4 px-5 previous-button px-4 mobile-button" onClick={() => { buttonClickFunction(currentPages, "back") }}>
                    <span className="previous-button-arrow me-1">
                        <img src={previousColor} alt="auto" />
                        <img src={previousLogo} alt="auto" />
                    </span>&nbsp;Previous</button>
                <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end px-4 mobile-button" onClick={() => { validateForm2() }}>Next Step<img src={nextLogo} className="ms-2" /></button>
            </div>
            {/* Footer */}
            {/* Form-Component */}
        </React.Fragment>

        );
    }
    //pc_glf_5
    //function for coverage details page
    const coverageDetails = () => {



        return (<React.Fragment>

            {/* Steps indicator header */}
            {/* Header */}
            {/* Form-Component */}
            {/* General Info Starts here */}


            <div className="col-md-12 mt-3">
                <label htmlFor="Provide us all the details" className="form-label w-100 font14 font-semibold ">Are you currently insured?</label>
                <input type="radio" className="btn-check radio-checked" id="no" autoComplete="off" name="Insured" value="no" checked={generalLiability[0].Insured === "no"} onChange={(e) => { handlechange(e) }} onClick={() => { setHide3(false) }} />
                <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor="no">No</label>
                <input type="radio" className="btn-check radio-checked" id="yes" autoComplete="off" name="Insured" value="yes" checked={generalLiability[0].Insured === "yes"} onChange={(e) => { handlechange(e) }} onClick={() => { setHide3(true) }} />
                <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor="yes">Yes</label>
            </div>
            {hide3 === true ?
                <div>
                    <div className="col-md-12 mt-4">
                        <label htmlFor="label1" className="form-label font14 font-semibold">Current Carrier* <span className="gib-tool-carrier ">
                            <img src={formHelp} className="ms-1 gib-tool-carrier" />
                            <span className="tooltext-carrier">{"your current insurance provider"}</span>
                        </span></label>
                        <input type="text" className="form-control pb-2 pt-2" id="label1" maxLength={80} name="Carrier" value={generalLiability[0].Carrier} onChange={(e) => { handlechange(e) }} />
                        <label className="text-danger font14">
                            {
                                (Alert === true && generalLiability[0].Carrier.trim() === "") ? "Please enter the Current Carrier" : null
                            }
                        </label>
                    </div>
                    <div className="col-md-12 date-picker">
                        <label className="form-label font14 font-semibold">Previous Policy Expiration Date*</label>
                        <input type="date" className="form-control font14 text-uppercase pb-2 pt-2 datecontrol" name="ExpirationDate" value={generalLiability[0].ExpirationDate} min={disablePastDate()} onChange={(e) => { handlechange(e) }} onKeyDown={(e) => { e.preventDefault() }}
                            onFocus={(e) => setdateFieldFocused(true)}
                            placeholder={dateFieldFocused ? "" : "MM/DD/YYYY"} />
                        <img src={calender} alt="calender-icon" className="floodcalender-icon "></img>
                        <label className="text-danger font14">
                            {
                                (Alert === true && generalLiability[0].ExpirationDate === "") ? "Please enter the Previous Policy Expiry Date" : null
                            }
                        </label>
                    </div>
                    <div className="file-upload">
                        <label htmlFor="expiryDate" className="form-label font14 mb-0 font-semibold w-100 mt-3">Current Insurance Dec Page</label>
                        <label htmlFor="expiryDate" className="form-label font14 PDF-label w-100"> <span className="font-semibold">Note: </span>Please upload PDF file only with less than 5 MB</label>


                        <input type="file" className=" form-control border-0 file-upload-input w-50 mt-2 px-3 font14 font-semibold" accept=".pdf" title="" id="formFile" onChange={(e) => validateFile(e)} ref={myref} />

             

                        <span className="gib-tool-choose">
                            <label className="font14 ms-2">{(filename !== "" && filename.length < 20) ? filename + ".pdf" : (filename.length > 10 && filename !== "") ? (filename.substring(0, 20)) + "..." + ".pdf" : "No file chosen"}</label>
                            {filename !== "" ? <span className="tooltext-choose">


                                {(filename.length > 55) ? filename.substring(0, 54) + '\n' + filename.substring(55, 100) + ".pdf" : (filename + ".pdf")}</span> : null}

                        </span>

                        {fileLoading == true ?
                            <div className="clearfix ">

                                <div className="spinner-border float-end text-secondary loader-spinner" role="status">

                                    <span className="visually-hidden">Loading...</span>

                                </div>

                            </div> : null}
                        {cancelvisible === true ?
                            <button className="btn border-0  Choose-cancel-mobile w-65 float-end mt-2 " onClick={() => Cancel_buttonClick()} >
                                <img src={Cancel_button} />
                            </button> : null}

                    </div>
                    <label className="text-danger font14">
                        {(fileValid === true && size > 5242880) ? "File size limit is exceeded" : null}
                        {((fileValid === true && size === 0 ) ? "The uploaded file is empty" : null)}
                        {((formatValid === true && type !== "application/pdf" ) ? "Please upload a file in suggested format" : null)}
                    </label>

                </div> : null}

            {/* Footer */}
            <div className="col-md-12 mt-5 mb-4">
                <button type="button" className="btn btn-outline-secondary font14 mt-4 px-5 previous-button px-4 mobile-button"
                    onClick={() => { buttonClickFunction(currentPages, "back") }}>
                    <span className="previous-button-arrow me-1">
                        <img src={previousColor} alt="auto" />
                        <img src={previousLogo} alt="auto" />
                    </span>&nbsp;Previous</button>
                <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end px-4 mobile-button" onClick={() => { validateForm3() }}>Next Step<img src={nextLogo} className="ms-2" /></button>
            </div>
            {/* Footer */}
            {/* Form-Component */}
        </React.Fragment>

        );
    }
    //pc_glf_6
    //function for employee details page
    const employeeDetails = () => {
        return (<React.Fragment>


            <div className="col-md-8 mt-3">
                <label htmlFor="Provide us all the details" className="form-label w-100 font14 font-semibold ">Do you have employees?</label>
                <input type="radio" className="btn-check radio-checked" id="no" autoComplete="off" name="Employees" value="no" checked={generalLiability[0].Employees === "no"} onChange={(e) => { handlechange(e) }} onClick={() => { setHide4(false) }} />
                <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor="no">No</label>
                <input type="radio" className="btn-check radio-checked" id="yes" autoComplete="off" name="Employees" value="yes" checked={generalLiability[0].Employees === "yes"} onChange={(e) => { handlechange(e) }} onClick={() => { setHide4(true) }} />
                <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor="yes">Yes</label>
            </div>
            {hide4 === true ?
                <div>
                    <div className="col-md-12 mt-3">
                        <label htmlFor="dls2" className="form-label font14 font-semibold">Number of Employees</label>
                        <select className="form-select font14 " aria-label="Default select example" id="dls2" name="NumberOfEmployees" value={generalLiability[0].NumberOfEmployees} onChange={(e) => { handlechange(e) }}>
                            <option value={""}>Select</option>
                            <option value={"1-9"}>1-9</option>
                            <option value={"10-19"}>10-19</option>
                            <option value={"20-49"}>20-49</option>
                            <option value={"50-249"}>50-249</option>
                            <option value={"250 or more"}>250 or more</option>
                        </select>
                    </div>
                    <div className="col-md-12 mt-3">
                        <label htmlFor="dls3" className="form-label font14 font-semibold">Total Payroll</label>
                        <select className="form-select font14 " aria-label="Default select example" id="dls3" name="TotalPayroll" value={generalLiability[0].TotalPayroll} onChange={(e) => { handlechange(e) }}>
                            <option value={""}>Select</option>
                            <option value={"10 - 149K"}>10-149K</option>
                            <option value={"150 - 499K"}>150-499K</option>
                            <option value={"500K - 1M"}>500K-1M</option>
                            <option value={"More Than 1M"}>More than 1M</option>
                        </select>
                    </div>
                </div> : null}


            {/* Footer */}
            <div className="col-md-12 mt-5 mb-4">
                <button type="button" className="btn btn-outline-secondary font14 mt-4 px-5 previous-button px-4 mobile-button"
                    onClick={() => { buttonClickFunction(currentPages, "back") }}>
                    <span className="previous-button-arrow me-1">
                        <img src={previousColor} alt="auto" />
                        <img src={previousLogo} alt="auto" />
                    </span>&nbsp;Previous</button>
                <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end px-4 mobile-button" onClick={() => { buttonClickFunction(currentPages, "next") }}>Next Step<img src={nextLogo} className="ms-2" /></button>
            </div>
            {/* Footer */}
            {/* Form-Component */}
        </React.Fragment>
        );
    }

    //pc_glf_7
    //function for revenue details page
    const revenueDetails = () => {
        return (<React.Fragment>
            <div className="col-md-12 mt-2 ">
                <label className="font20 font-primary font-semibold font-head pb-3 border-bottom w-100"></label>
            </div>
            <div className="col-md-12 mt-3">
                <label htmlFor="label1" className="form-label font14 font-semibold">Estimated 12 month gross receipts in $</label>
                <input type="text" className="form-control pb-2 pt-2" id="label1" maxLength={10} name="GrossReceipts" value={generalLiability[0].GrossReceipts} onChange={(e) => { handlechange(e) }} />
            </div>

            {/* Footer */}
            <div className="col-md-12 mt-5 mb-4">
                <button type="button" className="btn btn-outline-secondary font14 mt-4 px-5 previous-button px-4 mobile-button" onClick={() => { buttonClickFunction(currentPages, "back") }}>
                    <span className="previous-button-arrow me-1">
                        <img src={previousColor} alt="auto" />
                        <img src={previousLogo} alt="auto" />
                    </span>&nbsp;Previous</button>
                <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end px-4 mobile-button" onClick={() => postData()}>Request Quote</button>
            </div>
            {/* Footer */}
            {/* Form-Component */}
            {Loading == true ? <div id="root">
                <h1>Submitting Your Request</h1>
                <h3>Please Wait for few seconds....</h3>
                <div className="loader-wrapper">
                    <div className="loader"><span></span> <span></span> <span></span> <span></span> <span></span> </div>
                </div>
            </div> : null}
        </React.Fragment>


        );
    }
    const childToParent = (data: any) => {
        console.log(data, "page");
        setCurrentPages(data)
    }

    return (
        <div className="container mt-5">
            <div className="row mt-4">
                {/* Form starts here */}
                <div className="col-lg-8 col-md-12 col-sm-12">
                    <div className="row">
                        <div className="col-lg-11 col-md-12 col-sm-12">
                            <div className="row">
                                <Header value={Info} childToParent={(data: any) => childToParent(data)} />

                                {currentPages === 1 ?
                                    personalInfo() : null}

                                {currentPages === 2 ?
                                    industryDetails() : null}

                                {currentPages === 3 ?
                                    coverageDetails() : null}

                                {currentPages === 4 ?
                                    employeeDetails() : null}

                                {currentPages === 5 ?
                                    revenueDetails() : null}
                            </div >
                        </div >

                    </div >

                    {/* Right panel starts Here */}
                </div>
                <div className="col-md-4 right-panel-margin hide-right">
                    <div className="row mx-0">
                        {
                            (previousStepArry.length > 0) ?
                                <>
                                    <div className="col-md-12 border bg-light p-2 previous-steps-box border-bottom-0">
                                        <label className="ps-2 font-16 font-bold">Previous Steps</label>
                                    </div>
                                    <div className="col-md-12 border border-top-0 previous-steps-box px-0">
                                        {previousStepArry.map((values: any, index: any) => {

                                            return (
                                                <li className="list-unstyled px-0 font16 font-primary">
                                                    <a className="dropdown-item py-2 gib-tooltip" href="#" onClick={() => { setCurrentPages(index + 1) }} >
                                                        {values}
                                                        <span className="gib-tooltip" ><img src={edit} className="edit-icon ms-2" />
                                                            <span className="tooltiptext">{"Go to step " + " " + (index + 1)}</span>
                                                        </span>

                                                    </a>
                                                </li>)
                                        })
                                        }

                                    </div>
                                </> : null
                        }

                        {/* Need Help */}
                        <div className={(previousStepArry.length !== 0) ? "col-md-12 border bg-light previous-steps-box p-2 mt-4 border-bottom-0" : "col-md-12 border bg-light p-2 previous-steps-box border-bottom-0"}>
                            <label className="ps-2 font-16 font-bold">Need Help?</label>
                        </div>
                        <div className="col-md-12 border border-top-0 previous-steps-box">
                            <label className="ps-2 w-100 mt-3 font16 font-semibold">The Gibraltar Group</label>
                            <label className="ps-2 w-100 mt-2 mb-3 font14">(281) 681-0331</label>
                        </div>
                        {/* Need Help */}
                        {/* Home button */}
                        <button type="button" onClick={() => window.location.href = '/'} className="btn btn-primary mt-4 py-2 primary-button border-0 font14 home-button"><img src={previousLogo} alt="auto" className="me-1" /> Back to Home</button>
                        {/* Home button */}
                    </div>
                </div>
            </div>
        </div>
    );

    //gets the value of every fields in the form of json & an service call for posting the data
    async function postData() {

        var quotefield;
        let quoteField2;
        if (generalLiability[0].Quote === "Provide us all the details") {
            quotefield = null
            quoteField2 = null;
        }
        else {
            quotefield = (generalLiability[0].MeetingTime !== "") ? generalLiability[0].MeetingTime : null
            quoteField2 = (generalLiability[0].MeetingDay !== "") ? generalLiability[0].MeetingDay : null
        }
        var venturefield
        if (generalLiability[0].Venture === "yes") {
            venturefield = null
        }
        else {
            venturefield = (generalLiability[0].InBusiness !== "") ? generalLiability[0].InBusiness : null
        }
        var insuredfield
        var date
        if (generalLiability[0].Insured === "no") {
            insuredfield = null
        }
        else {
            insuredfield = (generalLiability[0].Carrier.trimStart().trimEnd() !== "") ? generalLiability[0].Carrier : null
            date = (generalLiability[0].ExpirationDate !== "") ? generalLiability[0].ExpirationDate : null
        }
        var employeefield
        if (generalLiability[0].Employees === "no") {
            employeefield = null
        }
        else {
            employeefield = (generalLiability[0].NumberOfEmployees !== "") ? generalLiability[0].NumberOfEmployees : null
            employeefield = (generalLiability[0].TotalPayroll !== "") ? generalLiability[0].TotalPayroll : null
        }


        // if (currentPages === 1) {
        //     validateForm1();
        // }

        var GLData = {
            personalDetails: {
                FirstName: generalLiability[0].FirstName.trimStart().trimEnd(),
                LastName: generalLiability[0].LastName.trimStart().trimEnd(),
                Email: generalLiability[0].Email,
                MobilePhone: generalLiability[0].Mobile,
                Street: generalLiability[0].Street.trimStart().trimEnd(),
                City: generalLiability[0].City.trimStart().trimEnd(),
                State: generalLiability[0].State,
                PostalCode: generalLiability[0].Zip,
                Preferred_Contact_Method__c: (generalLiability[0].ContactMethod !== "") ? generalLiability[0].ContactMethod : null,
                Complete_Quote__c: generalLiability[0].Quote,
                detailType: null,
                Preferred_Meeting_Time__c: quotefield,
                Preferred_Meeting_Day__c: quoteField2,
                OwnerId: OwnerId,
                parameterId:parameterId
            },
            industryDetails: {
                Name_of_Company__c: (generalLiability[0].Company !== "") ? generalLiability[0].Company : null,
                Daily_Operations_Details__c: (generalLiability[0].OperationDetails !== "") ? generalLiability[0].OperationDetails : null,
                Industry__c: (generalLiability[0].Industry !== "") ? generalLiability[0].Industry : null,
                Job_Title__c: (generalLiability[0].JobTitle.trimStart().trimEnd() !== "") ? generalLiability[0].JobTitle : null,
                New_Venture__c: (generalLiability[0].Venture !== "") ? generalLiability[0].Venture : null,
                Years_in_Industry__c: venturefield
            },
            coverageDetails: {
                Currently_Insured_for_Flood__c: (generalLiability[0].Insured !== "") ? generalLiability[0].Insured : null,
                Current_Carrier__c: insuredfield,
                Previous_Policy_Expiration_Date__c: date,
                VersionData: file === "" ? null : file,
                filename: filename === "" ? null : filename
            },
            employeeDetails: {
                Have_Employees__c: (generalLiability[0].Employees !== "") ? generalLiability[0].Employees : null,
                Number_of_Employees__c: (generalLiability[0].Employees === "no" || generalLiability[0].Employees === "") ? null : generalLiability[0].NumberOfEmployees,
                Total_Payroll__c: (generalLiability[0].Employees === "no" || generalLiability[0].Employees === "") ? null : generalLiability[0].TotalPayroll
            },
            RevenueDetails: {
                Estimated_12_Month_Gross_Receipts__c: (generalLiability[0].GrossReceipts !== "") ? generalLiability[0].GrossReceipts : null
            }
        }
        setLoading(true)

        console.table(GLData);

        let data = await postInsuranceQuoteData(GLData, 'general')

        // let data = await axios.post('https://gibraltar-swf-dev-af.azurewebsites.net/api/commercialInsuranceApi?code=wY9AF/vmRBTvio/WOsQSgSyQQXG7x4hTSAwvviZPiA84vnhaRJf1WA==&type=Commercial General Liability', GLData)

        // console.log(data.data)


        if (currentPages === 5 || currentPages === 1) {
            window.location.href = "/thankyou"
        }

        //redirects to the thank you page
    }

}