/*
   // Project: Gibraltar
   // Desc: This file contains the others form component by using this component the user will able to request quote for their others form;
   // Author:bharathwaj
   // Created Date:04/07/2022
   //File Name : others.tsx;
*/


import React, { useState } from "react";
import gibralterlogo from "../images/Blue-Logo.svg"
import rightarrow from '../images/Arrow-right.svg'
import leftarrow from "../images/Arrow-Left-White.svg"
import { postInsuranceQuoteData } from './service'
import PhoneInput from "react-number-format";





export default function OthersQuote() {

    var QueryString = new URLSearchParams(window.location.search);
    var OwnerId = QueryString.get("agentId");
    let parameterId= QueryString.get("parameterId");


    
    //PC_0F_01
    //state variables;
    const [jsonData, setJsonData] = useState([
        {
            firstName: "",
            lastName: "",
            email: "",
            mobilePhone: "",
            street: "",
            city: "",
            state: "",
            postalCode: "",
            preferred_Contact_Method: "",
        }
    ])
    //state variables for the validation
    const [valid, setvalid] = useState(false)

    //state variables for the REGEX validation 
    const [mailvalidation, setmailvalidation] = useState(false)

    const [phonevalidation, setphonevalidation] = useState(false)

    const [zipvalidation, setzipvalidation] = useState(false)

    const[Loading ,setLoading]= useState(false)



    // const[fnamevalidation ,setfnamevalidation] = useState(false)

    //PC_OF_05
    //When user clicks on submit button this function is invoked
    async function postData() {

        let uiPayLod = {
            PersonalDetails: {
                FirstName: jsonData[0].firstName.trimStart().trimEnd(),
                LastName: jsonData[0].lastName.trimStart().trimEnd(),
                Email: jsonData[0].email,
                MobilePhone: jsonData[0].mobilePhone,
                Street: jsonData[0].street.trimStart().trimEnd(),
                City: jsonData[0].city.trimStart().trimEnd(),
                State: jsonData[0].state,
                PostalCode: jsonData[0].postalCode,
                Preferred_Contact_Method__c: jsonData[0].preferred_Contact_Method,
                OwnerId : OwnerId,
                parameterId:parameterId

            }

        }

        console.log(uiPayLod)
        //PC_OF_06
        setLoading(true)
       await postInsuranceQuoteData(uiPayLod, 'Other')
       window.location.href = '/thankyou';

    }



    // function events(e: any, maxLength: number) {

    //     if (e.target.value.length <= maxLength) {

    //         onHandleChange(e)

    //     } else {

    //         return false;

    //     }

    // }

    //PC_OF_03
    // validation function validates the required text fields.
    function validation() {
        {
            var errorcount = 0
            //If firstname is equal to empty and  state variable valid is set to true and error count in increased by 1.
            if (jsonData[0].firstName.trim() === "") {
                setvalid(true);
                errorcount++
            }
            
            //If lastname is equal to empty and  state variable valid is set to true and error count in increased by 1.

            if (jsonData[0].lastName.trim()! == "") {
                setvalid(true);
                errorcount++
            }
          //  if (jsonData[0].preferred_Contact_Method === "Phone" || jsonData[0].preferred_Contact_Method === "Text")
             
                //If mobile is equal to empty and  state variable valid is set to true and error count in increased by 1.
                if (jsonData[0].mobilePhone === "") {
                    setvalid(true);
                    errorcount++
                }
            
            //PC_OF_04
            //If mobile is not  equal to empty ,the following regex validation will take place and the state variable setfristnamevalidation is set to true.
            if (jsonData[0].mobilePhone !== "") {
                var phoneFormat = /^\d{10}$/;
                if (!(jsonData[0].mobilePhone.match(phoneFormat))) {
                    setphonevalidation(true);
                    setvalid(true);
                    errorcount++
                }
                if ((jsonData[0].mobilePhone.match(phoneFormat))) {
                    setphonevalidation(false);
                }
            }
            //If street is equal to empty and  state variable valid is set to true and error count in increased by 1.
            if (jsonData[0].street.trim() === "") {
                setvalid(true);
                errorcount++
            }
            //If city is equal to empty and  state variable valid is set to true and error count in increased by 1.
            if (jsonData[0].city.trim() === "") {
                setvalid(true);
                errorcount++
            }
            //If mail is equal to empty and  state variable valid is set to true and error count in increased by 1.


            //PC_OF_06
            //If mail is not  equal to empty ,the following regex validation will take place and the state variable setfristnamevalidation is set to true.

            if (jsonData[0].email === "") {
                setvalid(true);
                errorcount++
            }

            if (jsonData[0].email !== "") {
                var mailFormat =/^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)*[\w+-]+\.[a-zA-Z]{2,4}$/;
                if (!(jsonData[0].email.match(mailFormat))) {
                    setmailvalidation(true);
                    setvalid(true);
                    errorcount++
                }
                if ((jsonData[0].email.match(mailFormat))) {
                    setmailvalidation(false);
                }
            }

            //If state is equal to empty and  state variable valid is set to true and error count in increased by 1.
            if (jsonData[0].state === "") {
                setvalid(true);
                errorcount++
            }
            //If zipcode is equal to empty and  state variable valid is set to true and error count in increased by 1.
            if (jsonData[0].postalCode === "") {
                setvalid(true);
                errorcount++
            }
            //PC_OF_07
            //If zipcode is not  equal to empty ,the following regex validation will take place and the state variable setfristnamevalidation is set to true.

            if (jsonData[0].postalCode !== "") {
                var zipFormat = /^\d{5}?$/;
                if (!(jsonData[0].postalCode.match(zipFormat))) {
                    setzipvalidation(true);
                    setvalid(true);
                    errorcount++
                }
                if ((jsonData[0].postalCode.match(zipFormat))) {
                    setzipvalidation(false);

                }
            }
            if (jsonData[0].preferred_Contact_Method === "") {
                setvalid(true);
                errorcount++
            }


        }
        if (errorcount === 0) {
            postData()
        }
    }



    //PC_OF_02
    //This function is invoked When user enters any value in any fields
    function onHandleChange(e: any) {


        //these variable(name and value) are used to store name and value from the tag that call onHandleChange function;
        // const { name, value } = e.target;

        let name = "", value = ""

        let newJsonData: any = [...jsonData];

        if (e.target) {
            name = e.target.name;

            value = e.target.value
            if (e.target.name === "postalCode") {

                value = value.replace(/[^0-9]/g, '');

            }

        }

        else {

            name = "mobilePhone"

            value = e.value;

            console.log(value, "value from if")

            newJsonData[0][name] = value;

        }

        //getting the jsonData array in the newJsonData variable;


        //setting value to the particular key in the json;
        newJsonData[0][name] = value;

        //setting the jsonData state variable;
        setJsonData(newJsonData);

        if (name === "email" || name === "mobilePhone" || name === "postalCode") {

            if (name === "email") {

                var mailFormat = /^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)*[\w+-]+\.[a-zA-Z]{2,4}$/;
                if (!(jsonData[0].email.match(mailFormat))) {
                    setmailvalidation(true);

                }
                if ((jsonData[0].email.match(mailFormat))) {
                    setmailvalidation(false);
                }

            } else if (name === "mobilePhone") {

                var phoneFormat = /^\d{10}$/;
                if (!(jsonData[0].mobilePhone.match(phoneFormat))) {
                    setphonevalidation(true);

                }
                if ((jsonData[0].mobilePhone.match(phoneFormat))) {
                    setphonevalidation(false);
                }

            } else if (name === "postalCode") {

                var zipFormat = /^\d{5}?$/;
                if (!(jsonData[0].postalCode.match(zipFormat))) {
                    setzipvalidation(true);

                }
                if ((jsonData[0].postalCode.match(zipFormat))) {
                    setzipvalidation(false);

                }


            }

        }
    }

    console.log(jsonData)


    return (
        <React.Fragment>
            <div>
                {/* Navigation starts here */}

                <div className="container">
                    <div className="row mt-4">
                        {/* Form starts here */}
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="row">
                                <div className="col-lg-11 col-md-12 col-sm-12">
                                    <div className="row ">
                                        <div className='col-md-6 '>
                                            <label className="font24 font-bold font-head">Contact Us</label>
                                        </div>
                                        <div className='col-md-6 '>
                                            <label className="float-end font16 others-required">*Required</label>
                                        </div>
                                        <div className="col-md-12 mt-2"  >
                                            <label className="font16">Looking for something else? We can help with RV's , Boats, Motorcycles, Investment
                                                Properties, Annuities and more. Just give us a few details and an agent will reach
                                                out to help you out.</label>
                                        </div>
                                        {/* Steps indicator header */}
                                        {/* Header */}
                                        {/* Form-Component */}
                                        {/* General Info Starts here */}
                                        <div className="col-md-12   mt-3">
                                            <label className="font22 font-primary font-semibold font-head pb-2 border-bottom w-100">General Info</label>
                                        </div>

                                        <div className="col-md-6 mt-3">

                                            <label htmlFor="label1" className="form-label font16 font-semibold">First Name*</label>

                                            <input
                                                type="text"
                                                name="firstName"
                                                value={jsonData[0].firstName}
                                                className="form-control pb-2 pt-2"
                                                id="label1"
                                                onChange={(e) => { onHandleChange(e) }}
                                                maxLength={40}
                                            />
                                            <label className="text-danger font14">
                                                {

                                                    (valid === true && jsonData[0].firstName.trim() === "") ? "Please enter the First Name" : null
                                                }
                                                
                                            </label>
                                        </div>


                                        <div className="col-md-6 mt-3">

                                            <label htmlFor="label2" className="form-label font16 font-semibold">Last Name*</label>
                                            <input type="text"
                                                name="lastName"
                                                value={jsonData[0].lastName}
                                                className="form-control pb-2 pt-2"
                                                id="label2"
                                                onChange={(e) => { onHandleChange(e) }}
                                                maxLength={80} />
                                            <label className="text-danger font14">
                                                {
                                                    (valid === true && jsonData[0].lastName.trim() === "") ? "Please enter the Last Name" : null
                                                }
                                            </label>
                                        </div>

                                        {/* General Info Ends Here */}
                                        {/* Communication Info Starts Here */}
                                        <div className="col-md-12  mt-2">
                                            <label className="font20 font-primary font-semibold font-head border-bottom w-100 pb-2">Communication Info</label>
                                        </div>
                                        <div className="col-md-12 mt-4">

                                            <label htmlFor="label4" className="form-label font16 font-semibold">Email*</label>
                                            <input type="email"
                                                value={jsonData[0].email}
                                                name="email" className="form-control pb-2 pt-2" id="label4"
                                                onChange={(e) => { onHandleChange(e) }}
                                                maxLength={80}
                                            />
                                            <label className="text-danger font14">
                                                {
                                                    (valid === true && jsonData[0].email === "") ? "Please enter an Email" : (valid === true && mailvalidation === true) ? "Please enter a valid Email " : null
                                                }
                                            </label>

                                        </div>
                                        <div className="col-md-12 mt-1">

                                            <label htmlFor="label5" className="form-label font16 font-semibold"
                                            >Mobile Phone*</label>

                                            {/* <input type="email" name="mobilePhone" className="form-control" id="label5"
                                                value={jsonData[0].mobilePhone}
                                                onChange={(e) => { onHandleChange(e) }}
                                                maxLength={10} /> */}
                                            {jsonData[0].mobilePhone.length < 10 ?
                                                <PhoneInput type={"tel"} className="form-control pb-2 pt-2" value={jsonData[0].mobilePhone} onValueChange={(e) => { onHandleChange(e) }} placeholder="XXXXXXXXXX" id="mobilephone" name="mobilePhone" /> :
                                                <PhoneInput type={"tel"} format={"(###) ###-####"} className="form-control pb-2 pt-2" value={jsonData[0].mobilePhone} onValueChange={(e) => { onHandleChange(e) }} placeholder="XXXXXXXXXX" id="mobilephone" name="mobilePhone" />}

                                            <label className="text-danger font14" >
                                                {

                                                    (valid === true && jsonData[0].mobilePhone === "") ? "Please enter the Mobile Number" : (valid === true && phonevalidation === true) ? "Please enter a valid Mobile Number" : null
                                                }
                                            </label>

                                        </div>

                                        <div className="col-md-12 ">

                                            <label htmlFor="label6" className="form-label font16 font-semibold">Street*</label>

                                            <textarea
                                                className="form-control text-area-input pb-2 pt-2"
                                                id="label6"
                                                name="street"
                                                value={jsonData[0].street}
                                                rows={6}
                                                maxLength={120}
                                                onChange={(e) => { onHandleChange(e) }}
                                            />
                                            <label className="text-danger font14">
                                                {

                                                    (valid === true && jsonData[0].street.trim() === "") ? "Please enter the Street" : null
                                                }
                                            </label>

                                        </div>
                                        <div className="col-md-12 mt-1">
                                            <label htmlFor="label7" className="form-label font16 font-semibold">City*</label>

                                            <input type="text" className="form-control pb-2 pt-2"
                                                id="label7"
                                                name="city"
                                                value={jsonData[0].city}
                                                onChange={(e) => { onHandleChange(e) }}
                                                maxLength={40}
                                            />
                                            <label className="text-danger font14">
                                                {

                                                    (valid === true && jsonData[0].city.trim() === "") ? "Please enter the City" : null
                                                }
                                            </label>
                                        </div>
                                        <div className="col-md-6 mt-0">
                                            <label htmlFor="label9" className="form-label font16 font-semibold ">State/Province*</label>

                                            {/* <input type="email" className="form-control" id="label8" /> */}

                                            <select
                                                name="state"
                                                id="label8"
                                                value={jsonData[0].state}
                                                onChange={(e) => { onHandleChange(e) }}
                                                className="form-select pb-2 pt-2 othermargin">
                                                <option value="">Select State/Province</option>
                                                <option value="AL">AL</option>
                                                <option value="AR">AR</option>
                                                <option value="AZ">AZ</option>
                                                <option value="CA">CA</option>
                                                <option value="CO">CO</option>
                                                <option value="CT">CT</option>
                                                <option value="DE">DE</option>
                                                <option value="FL">FL</option>
                                                <option value="GA">GA</option>
                                                <option value="HI">HI</option>
                                                <option value="IA">IA</option>
                                                <option value="ID">ID</option>
                                                <option value="IL">IL</option>
                                                <option value="IN">IN</option>
                                                <option value="KS">KS</option>
                                                <option value="KY">KY</option>
                                                <option value="LA">LA</option>
                                                <option value="MA">MA</option>
                                                <option value="MD">MD</option>
                                                <option value="ME">ME</option>
                                                <option value="MI">MI</option>
                                                <option value="MN">MN</option>
                                                <option value="MO">MO</option>
                                                <option value="MS">MS</option>
                                                <option value="MT">MT</option>
                                                <option value="NC">NC</option>
                                                <option value="ND">ND</option>
                                                <option value="NE">NE</option>
                                                <option value="NH">NH</option>
                                                <option value="NJ">NJ</option>
                                                <option value="NM">NM</option>
                                                <option value="NV">NV</option>
                                                <option value="NY">NY</option>
                                                <option value="OH">OH</option>
                                                <option value="OK">OK</option>
                                                <option value="OR">OR</option>
                                                <option value="PA">PA</option>
                                                <option value="RI">RI</option>
                                                <option value="SC">SC</option>
                                                <option value="SD">SD</option>
                                                <option value="TN">TN</option>
                                                <option value="TX">TX</option>
                                                <option value="UT">UT</option>
                                                <option value="VA">VA</option>
                                                <option value="VT">VT</option>
                                                <option value="WA">WA</option>
                                                <option value="WI">WI</option>
                                                <option value="WV">WV</option>
                                                <option value="WY">WY</option>


                                            </select>
                                            <label className="text-danger font14">
                                                {
                                                    (valid === true && jsonData[0].state === "") ? "Please select the State/Province " : null
                                                }
                                            </label>


                                        </div>
                                        <div className="col-md-6  ">
                                            <label htmlFor="label9" className="form-label font16 font-semibold">Zip/Postal Code*</label>

                                            <input type="text" className="form-control pb-2 pt-2"
                                                id="label9"
                                                onChange={(e) => { onHandleChange(e) }}
                                                name="postalCode"
                                                value={jsonData[0].postalCode}
                                                maxLength={5} />
                                            <label className="text-danger font14">
                                                {

                                                    (valid === true && jsonData[0].postalCode === "") ? "Please enter the Zip/Postal Code" : (valid === true && zipvalidation === true) ? "Pflease enter the valid Zip/Postal Code" : null
                                                }
                                            </label>
                                        </div>
                                        <div className="col-md-12 mt-2">

                                            <label htmlFor="label10" className="form-label w-100 font16 font-semibold">Preferred Contact Method *</label>


                                            <input
                                                type="radio"
                                                className="btn-check radio-checked" id="Phone"
                                                autoComplete="off" name="preferred_Contact_Method"
                                                value="Phone"
                                                checked={jsonData[0].preferred_Contact_Method === "Phone"}
                                                onClick={(e) => { onHandleChange(e) }} />

                                            <label className="btn btn-primary button-radio font14 font-bold " htmlFor="Phone">Phone</label>


                                            <input type="radio" className="btn-check radio-checked" id="Email" autoComplete="off" name="preferred_Contact_Method"
                                                value="Email" onClick={(e) => { onHandleChange(e) }}
                                                checked={jsonData[0].preferred_Contact_Method === "Email"} />

                                            <label className="btn btn-primary ms-2 button-radio font14 font-bold" htmlFor="Email">Email</label>


                                            <input type="radio" className="btn-check radio-checked" id="Text" autoComplete="off" name="preferred_Contact_Method"
                                                value="Text" onClick={(e) => { onHandleChange(e) }}
                                                checked={jsonData[0].preferred_Contact_Method === "Text"} />


                                            <label className="btn btn-primary ms-2 button-radio font14 font-bold" htmlFor="Text">Text</label>

                                            <input type="radio" className="btn-check radio-checked" id="Mail" autoComplete="off" name="preferred_Contact_Method"
                                                value="Mail" onClick={(e) => { onHandleChange(e) }}
                                                checked={jsonData[0].preferred_Contact_Method === "Mail"} />

                                            <label className="btn btn-primary ms-2 button-radio font14 font-bold" htmlFor="Mail">Mail</label>

                                            
                                        </div>

                                        <label className="text-danger font14">
                                                {

                                                    (valid === true && jsonData[0].preferred_Contact_Method === "") ? "Please select your choice" : null
                                                }
                                            </label>

                                        {/* Footer */}
                                        <div className="col-md-12 mt-4 mb-4">
                                            <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button" onClick={() => { validation() }}>Request Quote</button>
                                        </div>
                                        {/* Footer */}
                                        {/* Form-Component */}
                                    </div>
                                </div>
                            </div>
                        </div>



                        {/* Form starts here */}
                        {/* Right panel starts Here */}
                        <div className="col-md-4 hide-right">
                            <div className="row mx-0">
                                {/* Previous Steps */}
                                {/* Need Help */}
                                <div className="col-md-12 border bg-light p-2 previous-steps-box border-bottom-0">
                                    <label className="ps-2 font-14 font-bold" >Need Help?</label>
                                </div>
                                <div className="col-md-12 border border-top-0 previous-steps-box">
                                    <label className="ps-2 w-100 mt-3 font16 font-semibold">The Gibraltar Group</label>
                                    <label className="ps-2 w-100 mt-2 mb-3 font14">(281) 681-0331</label>
                                </div>
                                {/* Need Help */}
                                {/* Home button */}
                                <button onClick={() => window.location.href = './'} type="button" className="btn btn-primary mt-4 py-2 primary-button border-0 font14 home-button"><img src={leftarrow}
                                    alt="auto" className="me-1" /> Back to Home</button>
                                {/* Home button */}
                            </div>
                        </div>
                        {/* Right panel starts Here */}
                    </div>
                </div>
            </div>
            {Loading == true ? <div id="root">

                <h1>Submitting Your Request</h1>

                <h3>Please Wait for few seconds....</h3>

                <div className="loader-wrapper">

                    <div className="loader"><span></span> <span></span> <span></span> <span></span> <span></span> </div>

                </div>

            </div> : null}

        </React.Fragment>
    )
}






