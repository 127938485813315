/*
   // Project: Gibraltar
   // Desc: This file contains the Life insurance component by using this component the user will able to request quote for their Life insurance;
   // Author:Kavya M
   // Created Date:04/07/2022
   //Updated Date:04/21/2022
   //File Name : Life.tsx;
*/


//Pseudocode ID:PC-LIF-1;
//Importing useState and useEffect  from react to use state variable in the component;
//Importing all the needed images

import react from 'react';
import { useState, useEffect } from 'react';
import '../App.css'
import LifeInsurance from '../images/Life-Insurance.svg';
import Arrowright from '../images/Arrow-right.svg';
import Arrowleft from '../images/Arrow-Left.svg';
import PhoneInput from "react-number-format";
import ArrowLeftWhite from '../images/Arrow-Left-White.svg';
import Edit from '../images/Edit.svg';
import plus from '../images/Plus-icon.svg';
import plusIconLight from '../images/Plus-Icon-Light.svg'
import Header from './header';
import calender from '../images/Calender.svg'
import { postInsuranceQuoteData } from './service';
import axios from 'axios';


//Pseudocode ID :PC-LIF-2;
// State variable function
export default function Lifetest() {

  var QueryString = new URLSearchParams(window.location.search);
    var OwnerId = QueryString.get("agentId");
    let parameterId= QueryString.get("parameterId");

  const [Loading, setLoading] = useState(false)
  const [firstNameErrorIndex, setFirstNameErrorIndex] = useState(-1);
  const [lastNameErrorIndex, setLastNameIndex] = useState(-1);
  const [validCount, setValidCount] = useState(false);
  
  const [DateOfBirth, setDateOfBirth] = useState(new Date());
  const [Lifeinsurance, setLifeinsurance] = useState([{
    //State variable for Personal detail page  
    FirstName: "",
    LastName: "",
    DateOfBirth: "",
    Email: "",
    MobileNo: "",
    Street: "",
    City: "",
    State: "",
    Zipcode: "",
    PreContact: "",

    //statevariable for otherform in Policy death benifits page 
    First_name: "",
    Last_name: "",
    Dob: "",
    gender: "",
    Relationship: "",


    policy_death_benifit: "",
    chkno: "",
    coverageneeded: "",

    //state variable for additional member page 
    addno: ""
  }])



  //This state variable is for which form is opened 
  const [openedMember, setOpenedMember] = useState(0);



  // Previous pages state variable 
  const [pages, setPages] = useState([
    "Personal Details",
    "Policy Details Death Benefits",
    "Additional Members"
  ]);
  const [previousStepsArray, setPreviousStepsArray] = useState([]);


  // state variable for mobile placeholder 
  const[dateFieldFocused,setdateFieldFocused] = useState(false)
  const[Otherdob,setOtherdob] = useState(false)
  const[AddmemDob,setAddmemDob] = useState(false)



  //state variable for adding additonal member in the form 
  const [field, setfield] = useState([
    {
      First_name: "",
      Last_name: "",
      Dateofbirth: "",
      Gender: "",
      Relationship: "",
      Desieredcoverage: "",
      isSaved: false
    }
  ]);


  //While clicking no in the policy utilize utilizepolicy state varible is invoked 

  const [utilizepolicy, setutilizepolicy] = useState([])
  const [utilizepolicystring, setutilizepolicystring] = useState("");

  //Additional members page state variable 
  const [addform, setaddform] = useState("");

  //currentpage 
  const [currentpage, setCurrentpage] = useState(1);

  //validation alert is true 
  const [ValidationALert, setValidationAlert] = useState(false);

  //regards validation
  const [emailReg, setemailReg] = useState(false);
  const [phonereg, setphonereg] = useState(false);
  const [zipcodereg, setzipcodereg] = useState(false);

  //This variable is invoked when the user enter te data in the add member form , it is stord in the array format and it is sent to the postdata function

  let resultArray: any = [{
    First_name: Lifeinsurance[0].First_name,
    Last_name: Lifeinsurance[0].Last_name,
    Dateofbirth: Lifeinsurance[0].Dob,
    Gender: Lifeinsurance[0].gender,
    Relationship: Lifeinsurance[0].Relationship,
    Desieredcoverage: Lifeinsurance[0].coverageneeded,
  }]

  // Pseudocode ID:PC-LIF-24
  //previousstep function
  function previousStepsBinder(page: any, type: any) {

    if (type === "back") {
      setCurrentpage(currentpage - 1)
    }

    let spreadPreviousPageArray: any = [...previousStepsArray];
    if (!spreadPreviousPageArray.includes(pages[page - 1])) {
      spreadPreviousPageArray.push(pages[page - 1])
    }
    setPreviousStepsArray(spreadPreviousPageArray)
  }

  // Pseudocode-ID :PC-LIF-25
  //All data are posted using postdata function and this function hits the service.ts file and form the service file it is stored in the database 

  async function postData() {
    debugger
    var newarray = [];
    var checkbox

    if (Lifeinsurance[0].chkno === "Yes") {
      checkbox = null
    }
    else {
      checkbox = (utilizepolicystring !== "") ? utilizepolicystring : null
    }

    if(Lifeinsurance[0].policy_death_benifit === "Self"){
      resultArray=[{First_name:null,
        Last_name:null,
        Dateofbirth: null,
        Gender: null,
        Relationship: null,
        Desieredcoverage: ( Lifeinsurance[0].coverageneeded!=="")?Lifeinsurance[0].coverageneeded:null

      }]
    }
    if (Lifeinsurance[0].policy_death_benifit === "Other") {
      for (let i = 0; i < resultArray.length; i++) {
        newarray.push(resultArray[i])
      }
      if (Lifeinsurance[0].addno === "Yes") {
        for (let j = 0; j < field.length; j++) {
          newarray.push(field[j])
        }
      }
      else {
        newarray = [...newarray, {
          First_name: null,
          Last_name: null,
          Dateofbirth: null,
          Gender: null,
          Relationship: null,
          Desieredcoverage: null
        }]
      }
    }
    else {
      if( Lifeinsurance[0].coverageneeded!==""){
      for (let i = 0; i < resultArray.length; i++) {
        newarray.push(resultArray[i])
      }
    }
      if (Lifeinsurance[0].addno === "Yes") {
        for (let j = 0; j < field.length; j++) {
          newarray.push(field[j])
        }
      }
      else {
        newarray = [{
          First_name: null,
          Last_name: null,
          Dateofbirth: null,
          Gender: null,
          Relationship: null,
          Desieredcoverage:( Lifeinsurance[0].coverageneeded!=="")?Lifeinsurance[0].coverageneeded:null
        }]

      }
    }

    let newmaparray = newarray.map((value, index) => {
      return ({
        "First_Name__c": (value.First_name !== ""&&value.First_name !== null) ? value.First_name.trimStart().trimEnd() : null,
        "Last_Name__c": (value.Last_name !== ""&&value.Last_name!==null) ? value.Last_name.trimStart().trimEnd() : null,
        "Date_of_Birth__c": (value.Dateofbirth !== "") ? value.Dateofbirth : null,
        "Gender__c": (value.Gender !== "") ? value.Gender : null,
        "Relationship__c": (value.Relationship !== ""&&value.Relationship !== null) ? value.Relationship.trimStart().trimEnd() : null,
        "Desired_Coverage__c": (value.Desieredcoverage !== ""&&value.Desieredcoverage !== null) ? value.Desieredcoverage.trimStart().trimEnd() : null
      })
    })

    let datas: any = {
      Personaldetail: {
        FirstName: (Lifeinsurance[0].FirstName !== "") ? Lifeinsurance[0].FirstName.trimStart().trimEnd() : null,
        LastName: (Lifeinsurance[0].LastName !== "") ? Lifeinsurance[0].LastName.trimStart().trimEnd() : null,
        Birthdate__c: (Lifeinsurance[0].DateOfBirth !== "") ? Lifeinsurance[0].DateOfBirth : null,
        Email: (Lifeinsurance[0].Email !== "") ? Lifeinsurance[0].Email : null,
        MobilePhone: (Lifeinsurance[0].MobileNo !== "") ? Lifeinsurance[0].MobileNo : null,
        Street: (Lifeinsurance[0].Street !== "") ? Lifeinsurance[0].Street.trimStart().trimEnd() : null,
        City: (Lifeinsurance[0].City !== "") ? Lifeinsurance[0].City.trimStart().trimEnd() : null,
        State: (Lifeinsurance[0].State !== "") ? Lifeinsurance[0].State : null,
        PostalCode: (Lifeinsurance[0].Zipcode !== "") ? Lifeinsurance[0].Zipcode : null,
        Preferred_Contact_Method__c: (Lifeinsurance[0].PreContact !== "") ? Lifeinsurance[0].PreContact : null,
        OwnerId : OwnerId,
        parameterId:parameterId
      },
      Policydetail: {
        Policy_for_Self_or_Another_Family_Member__c: (Lifeinsurance[0].policy_death_benifit !== "") ? Lifeinsurance[0].policy_death_benifit : null,
        Need_Death_Benefit__c: (Lifeinsurance[0].chkno !== "") ? Lifeinsurance[0].chkno : null,
        Goal_for_Obtaining_Life_Policy__c: checkbox,
      },
      Additonalmember: {
        Additional_Insured__c: Lifeinsurance[0].addno,
        addmemdetail: newmaparray
      }
    }
    setLoading(true)

    console.log(datas)
    let LifeInsurance: any = await postInsuranceQuoteData(datas, "Life");
     //let data = await axios.post('https://gibraltar-swf-dev-af.azurewebsites.net/api/financialInsuranceApi?code=cSTlLuoVwZ7SgMEsJU075XFQEz0hh03q940tMIyGg4LjGWNdcykThQ==&type=Life Insurance',datas);
   window.location.href = '/thankyou';
  }

  //Pseudocode ID :PC-LIF-6
  //When the user clicks on the next button the validiton function is invoked and checks whether arll the field are entered and it checks whether it is entered in correct format 
  const Validation = (currentPages: any,) => {
    //debugger
    var errorcount = 0;
    switch (currentPages) {
      //  validation for personal detail page 
      case 1:
        //debugger
        if (Lifeinsurance[0].FirstName.trim() === "") {
          setValidationAlert(true)
          errorcount++
        }
        if (Lifeinsurance[0].LastName.trim() === "") {
          setValidationAlert(true)
          errorcount++
        }

        if (Lifeinsurance[0].DateOfBirth === "") {
          setValidationAlert(true)
          errorcount++
        }

        if (Lifeinsurance[0].Email === "") {

          setValidationAlert(true)
          errorcount++
        }
        if (Lifeinsurance[0].Email !== "") {
          var regEx = /^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)*[\w+-]+\.[a-zA-Z]{2,4}$/;
          if (!(Lifeinsurance[0].Email.match(regEx))) {
            setValidationAlert(true)
            setemailReg(true)
            errorcount++
          }
          else if ((Lifeinsurance[0].Email.match(regEx))) {
            setemailReg(false)
          }
        }
        if (Lifeinsurance[0].MobileNo === "") {
          setValidationAlert(true)
          errorcount++
        }
        if (Lifeinsurance[0].MobileNo !== "") {
          var phoneFormat = /^\d{10}$/;
          if (!(Lifeinsurance[0].MobileNo.match(phoneFormat))) {
            setValidationAlert(true)
            setphonereg(true);
            errorcount++
          }

          else if ((Lifeinsurance[0].MobileNo.match(phoneFormat))) {
            setphonereg(false);
          }
        }

        if (Lifeinsurance[0].Street.trim() === "") {
          setValidationAlert(true)
          errorcount++
        }
        if (Lifeinsurance[0].City.trim() === "") {
          setValidationAlert(true)
          errorcount++
        }
        if (Lifeinsurance[0].State === "") {
          setValidationAlert(true)
          errorcount++
        }
        if (Lifeinsurance[0].Zipcode === "") {
          setValidationAlert(true);
          errorcount++
        }

        if (Lifeinsurance[0].Zipcode !== "") {
          var zipFormat = /^\d{5}?$/;
          if (!(Lifeinsurance[0].Zipcode.match(zipFormat))) {
            setValidationAlert(true)
            setzipcodereg(true);
            errorcount++
          }
          else if ((Lifeinsurance[0].Zipcode.match(zipFormat))) {
            setzipcodereg(false);

          }
        }
        if (errorcount !== 0) {
          setValidCount(true);
        }
        if (errorcount === 0) {
          setValidationAlert(false);
          previousStepsBinder(currentpage, "");
          setCurrentpage(currentpage + 1);
        }

        break;

      //validation for policy_death_benifit page 
      case 2:
        //debugger
        if (Lifeinsurance[0].policy_death_benifit === "") {
          setValidationAlert(true);
          errorcount++
        }
        if (Lifeinsurance[0].chkno === "") {
          setValidationAlert(true);
          errorcount++
        }

        if (errorcount === 0) {
          setValidationAlert(false);
          previousStepsBinder(currentPages, "")
          setCurrentpage(currentpage + 1);
        }

        break;
      //validation for additional member page 
      case 3:
        previousStepsBinder(currentPages, "")
        setCurrentpage(currentpage + 1);
        postData()
        break;
    }
  }



  var policyarray: any = [];
  //Pseudocode -ID:PC-LIF-15
  // utlize this policy checkbox function
  function chkbox(e: any) {
    policyarray = utilizepolicy;
    if (e.target.checked === true) {
      policyarray.push(e.target.value)
    }
    else {
      let index = policyarray.indexOf(e.target.value)
      policyarray.splice(index, 1);
    }
    setutilizepolicy(policyarray)
    var policystring = policyarray.join(";");
    setutilizepolicystring(policystring)
  }

  //Pseudocode ID:PC-LIF-4
  //For handling the state variable
  let handlechange = (e: any) => {
    let name = "", value = ""
    let statevariable: any = [...Lifeinsurance];

    if (e.target) {
      name = e.target.name;
      value = e.target.value;


      if (e.target.name === "Zipcode") {
        value = value.replace(/[^0-9]/g, '');
      }

      if (e.target.name === "coverageneeded") {
        value = value.replace(/[^0-9]/g, '');
      }

      statevariable[0][name] = value;
    }
    else {
      name = "MobileNo"
      value = e.value;
      statevariable[0][name] = value;
    }

    setLifeinsurance(statevariable);


    if (Lifeinsurance[0].Email !== "") {
      var regEx = /^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)*[\w+-]+\.[a-zA-Z]{2,4}$/;;
      if (!(Lifeinsurance[0].Email.match(regEx))) {
        setemailReg(true)
      }
      else if ((Lifeinsurance[0].Email.match(regEx))) {
        setemailReg(false)
      }
    }

    if (Lifeinsurance[0].MobileNo !== "") {
      var phoneFormat = /^\d{10}$/;
      if (!(Lifeinsurance[0].MobileNo.match(phoneFormat))) {
        setphonereg(true);
      }
      else if ((Lifeinsurance[0].MobileNo.match(phoneFormat))) {
        setphonereg(false);
      }
    }

    if (Lifeinsurance[0].Zipcode !== "") {
      var zipFormat = /^\d{5}?$/;
      if (!(Lifeinsurance[0].Zipcode.match(zipFormat))) {
        setzipcodereg(true);
      }
      else if ((Lifeinsurance[0].Zipcode.match(zipFormat))) {
        setzipcodereg(false);
      }
    }



  }

  //Pseudocode ID:PC-LIF-21
  //For handling the event of add  in the Additional member page this function will be invoked 
  let onHandleChange = (i: any, e: any) => {

    try {
      //debugger;
      let variable: any = [...field]
      if (e.target.name === "Desieredcoverage") {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
      }
      if (e.target.type === "radio") {
        variable[i][e.target.placeholder] = e.target.value
      }
      else {
        variable[i][e.target.name] = e.target.value
      }
      setfield(variable);
    }
    catch (error: any) {
      console.log(error);
    }
  }


  //Pseudocode ID:PC-LIF-22
  //when user clicks on the add another member in Additional member page this function will be invoked 
  let add_Member = () => {

    setOpenedMember(field.length);
    setfield([...field, {
      First_name: "",
      Last_name: "",
      Dateofbirth: "",
      Gender: "",
      Relationship: "",
      Desieredcoverage: "",
      isSaved: false
    }])
  }

  //Pseudocode ID:PC-LIF-23
  //when the user clicks on the remove in the Additional member page  this function will be invoked 
  let remove_Member = (i: any) => {
    let newvariable = [...field];
    newvariable.splice(i, 1);
    setfield(newvariable);
    setFirstNameErrorIndex(-1);
    setLastNameIndex(-1);
    setOpenedMember(-1);
  }

  //Pseudoode ID:PC-LIF-5
  //When the user tries to enter the future date using date picker all the future date will be disabled uing this function 
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  // perviousvalidation 

  const perviousvalidation = (value: any, index: any) => {
    setCurrentpage(index + 1)
  }


  //Pseudocode ID:PC-LIF-3
  {/* 1st page Personal Detail*/ }
  //User lands on the personal detail page and this function is invoked 
  function lifeInsurance() {

    return (
      <react.Fragment>
        {/* Form-Component */}
        {/* General Info Starts here */}
        <div className="col-md-12  mt-3">
          <label className="font20 font-primary font-semibold font-head border-bottom w-100 pb-2">General Info</label>
        </div>
        <div className="col-md-6 mt-3">
          <label htmlFor="label1" className="form-label font14 font-semibold">First Name*</label>
          <input type="text" className="form-control pb-2 pt-2" id="label1"  name="FirstName" value={Lifeinsurance[0].FirstName} onChange={(e) => handlechange(e)}
            maxLength={40} />
          <label className='text-danger font14 w-100'>{(ValidationALert === true && Lifeinsurance[0].FirstName.trim() === "") ? "Please enter the First Name" : null}</label>
        </div>

        <div className="col-md-6 mt-3">
          <label htmlFor="label2" className="form-label font14 font-semibold">Last Name*</label>
          <input type="text" className="form-control pb-2 pt-2" id="label2"  name="LastName" maxLength={80} value={Lifeinsurance[0].LastName} onChange={(e) => handlechange(e)} />
          <label className='text-danger font14 w-100'>{(ValidationALert === true && Lifeinsurance[0].LastName.trim() === "") ? "Please enter the Last Name" : null}</label>
        </div>

        <div className="col-md-12 mt-0 date-picker">
          <label  className="form-label font14 font-semibold">Date of Birth*</label>
          <input type="date" className="form-control datecontrol font14 text-uppercase pb-2 pt-2 datecontrol" name="DateOfBirth" value={Lifeinsurance[0].DateOfBirth} onChange={(e) => handlechange(e)} max={disablePastDate()} onKeyDown={(e) => { e.preventDefault() }}  onFocus={(e) => setdateFieldFocused(true)}
            placeholder = {dateFieldFocused ? "" : "MM/DD/YYYY"} />
          
          <img src={calender} alt="calender-icon"  className="calender-icon "></img> 
                    
          <label className='text-danger font14 w-100'>{(ValidationALert === true && Lifeinsurance[0].DateOfBirth === "") ? "Please enter the Date of Birth" : null}</label>

         


        </div>
        {/* General Info Ends Here */}

        {/* Communication Info Starts Here */}
        <div className="col-md-12  mt-2">
          <label className="font20 font-primary font-semibold font-head pb-2 border-bottom w-100">Communication Info</label>
        </div>

        <div className="col-md-12 mt-3">
          <label htmlFor="label4" className="form-label font14 font-semibold">Email*</label>
          <input type="email" className="form-control pb-2 pt-2" id="label4"  name="Email" value={Lifeinsurance[0].Email} onChange={(e) => handlechange(e)} maxLength={80} />
          <label className='text-danger font14 w-100'>{(ValidationALert === true && Lifeinsurance[0].Email === "") ? "Please enter an Email" : (ValidationALert === true && emailReg === true) ? "Please enter a valid Email" : null}</label>
        </div>

        <div className="col-md-12 mt-0">
          <label htmlFor="MobileNo" className="form-label font14 font-semibold">Mobile Phone*</label>

          {Lifeinsurance[0].MobileNo.length < 10 ? <PhoneInput
            type={"tel"}
            className="form-control pb-2 pt-2"
            value={Lifeinsurance[0].MobileNo}
            onValueChange={(e) => { handlechange(e) }}
            id="MobileNo"
            placeholder="XXXXXXXXXX"
          /> : <PhoneInput
            type={"tel"}
            format={"(###) ###-####"}
            className="form-control pb-2 pt-2"
            value={Lifeinsurance[0].MobileNo}
            onValueChange={(e) => { handlechange(e) }}
            id="MobileNo"
            placeholder="XXXXXXXXXX"
          />}

          <span className='text-danger font14 w-100'>{(ValidationALert == true && Lifeinsurance[0].MobileNo === "") ? "Please enter the Mobile Number" : (ValidationALert === true && phonereg === true) ? "Please enter a valid Mobile Number " : null}</span>
        </div>

        <div className="col-md-12 mt-3">
          <label htmlFor="label6" className="form-label font14 font-semibold">Street*</label>
          <textarea className="form-control text-area-input pb-2 pt-2" id="label6" rows={6} defaultValue={""} name="Street" value={Lifeinsurance[0].Street} onChange={(e) => handlechange(e)} maxLength={120} />
          <label className='text-danger font14 w-100'>{(ValidationALert === true && Lifeinsurance[0].Street.trim() === "") ? "Please enter the Street" : null}</label>
        </div>

        <div className="col-md-12 mt-0">
          <label htmlFor="label7" className="form-label font14 font-semibold">City*</label>
          <input type="text" className="form-control pb-2 pt-2" id="label7"  name="City" value={Lifeinsurance[0].City} onChange={(e) => handlechange(e)} maxLength={40} />
          <label className='text-danger font14 w-100'>{(ValidationALert === true && Lifeinsurance[0].City.trim() === "") ? "Please enter the City" : null}</label>
        </div>

        <div className="col-md-6 mt-0">
          <label htmlFor="label8" className="form-label font14 font-semibold ">State/Province*</label>
          <select className="form-select pb-2 pt-2" id="label8"  name="State" value={Lifeinsurance[0].State} onChange={(e) => handlechange(e)}>
            <option value="" className="form-labelfont16 color-grey">Select State/Province</option>
            <option value="AK">AK</option>
            <option value="AL">AL</option>
            <option value="AR">AR</option>
            <option value="AZ">AZ</option>
            <option value="CA">CA</option>
            <option value="CO">CO</option>
            <option value="CT">CT</option>
            <option value="DE">DE</option>
            <option value="FL">FL</option>
            <option value="GA">GA</option>
            <option value="HI">HI</option>
            <option value="IA">IA</option>
            <option value="ID">ID</option>
            <option value="IL">IL</option>
            <option value="IN">IN</option>
            <option value="KS">KS</option>
            <option value="KY">KY</option>
            <option value="LA">LA</option>
            <option value="MA">MA</option>
            <option value="MD">MD</option>
            <option value="ME">ME</option>
            <option value="MI">MI</option>
            <option value="MN">MN</option>
            <option value="MO">MO</option>
            <option value="MS">MS</option>
            <option value="MT">MT</option>
            <option value="NC">NC</option>
            <option value="ND">ND</option>
            <option value="NE">NE</option>
            <option value="NH">NH</option>
            <option value="NJ">NJ</option>
            <option value="NM">NM</option>
            <option value="NV">NV</option>
            <option value="NY">NY</option>
            <option value="OH">OH</option>
            <option value="OK">OK</option>
            <option value="OR">OR</option>
            <option value="PA">PA</option>
            <option value="RI">RI</option>
            <option value="SC">SC</option>
            <option value="SD">SD</option>
            <option value="TN">TN</option>
            <option value="TX">TX</option>
            <option value="UT">UT</option>
            <option value="VA">VA</option>
            <option value="VT">VT</option>
            <option value="WA">WA</option>
            <option value="WI">WI</option>
            <option value="WV">WV</option>
            <option value="WY">WY</option>
          </select>
          <label className='text-danger font14 w-100'>{(ValidationALert === true && Lifeinsurance[0].State === "") ? "Please select the State/Province" : null}</label>
        </div>

        <div className="col-md-6 mt-0">
          <label htmlFor="label9" className="form-label font14 font-semibold">Zip/Postal Code*</label>
          <input
            type="text"
            className="form-control pb-2 pt-2"
            id="label9"
             name="Zipcode" value={Lifeinsurance[0].Zipcode} onChange={(e) => handlechange(e)} maxLength={5} />
          <label className='text-danger font14 w-100'>{(ValidationALert === true && Lifeinsurance[0].Zipcode === "") ? "Please enter the Zip/Postal Code" : (ValidationALert === true && zipcodereg === true) ? "Please enter the valid Zip/Postal code" : null}</label>
        </div>

        <div className="col-md-12 mt-0">
          <label htmlFor="label" className="form-label w-100 font14 font-semibold">Preferred Contact Method</label>
          <input type="radio" className="btn-check radio-checked" id="label10" autoComplete="off" name="PreContact" value="Phone" checked={Lifeinsurance[0].PreContact === "Phone"} onChange={(e) => handlechange(e)} />
          <label className="btn btn-primary button-radio font14 font-bold" htmlFor="label10" >Phone</label>
          <input type="radio" className="btn-check radio-checked" id="label11" autoComplete="off" name="PreContact" value="Email" checked={Lifeinsurance[0].PreContact === "Email"} onChange={(e) => handlechange(e)} />
          <label className="btn btn-primary ms-2 button-radio font14 font-bold" htmlFor="label11">Email</label>
          <input type="radio" className="btn-check radio-checked" id="label12" autoComplete="off" name="PreContact" value="Text" checked={Lifeinsurance[0].PreContact === "Text"} onChange={(e) => handlechange(e)} />
          <label className="btn btn-primary ms-2 button-radio font14 font-bold" htmlFor="label12">Text</label>
          <input type="radio" className="btn-check radio-checked" id="label13" autoComplete="off" name="PreContact" value="Mail" checked={Lifeinsurance[0].PreContact === "Mail"} onChange={(e) => handlechange(e)} />
          <label className="btn btn-primary ms-2 button-radio font14 font-bold" htmlFor="label13">Mail</label>
        </div>
        {/* Communication Info Ends Here */}

        {/* Footer */}
        <div className="col-md-12 mt-4 mb-4">
          <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button" onClick={() => Validation(currentpage)}>Next Step<img src={Arrowright} alt="arrowright" className="ms-2" /></button>
        </div>
        {/* Footer */}
      </react.Fragment>
    );
  }




  //Pseudocode ID:PC-LIF-11
  {/* 2nd page  Policy death benifit*/ }
  //When the user clicks on the next button the validiton function is invoked once validation is completed, it invokes the policy_Details function

  function policy_Details() {
    return (
      <react.Fragment>
        <div >
          <label className="col-md-12 border-bottom  mt-2"></label>
        </div>
        {/* Form-Component starts here*/}
        <div className="col-md-12 mt-3">
          <label htmlFor="Provide" className="form-label w-100 font14 font-semibold">Policy for self or another family member*</label>
          <input type="radio" className="btn-check radio-checked" id="Provide us all the details" autoComplete="off" value="Self" name="policy_death_benifit" checked={Lifeinsurance[0].policy_death_benifit === "Self"} onChange={(e) => handlechange(e)} />
          <label className="btn btn-primary button-radio font14 font-bold px-4 mt-2" htmlFor="Provide us all the details">Self</label>
          <input type="radio" className="btn-check radio-checked " id="Just give key points" autoComplete="off" value="Other" name="policy_death_benifit" checked={Lifeinsurance[0].policy_death_benifit === "Other"} onChange={(e) => handlechange(e)} />
          <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4 mt-2" htmlFor="Just give key points">Other</label>
          <label className='text-danger font14 w-100'>{ValidationALert === true && Lifeinsurance[0].policy_death_benifit == "" ? "Please select your choice" : null}</label>
        </div>

        {Lifeinsurance[0].policy_death_benifit === "Other" ?
          <react.Fragment>
            <div className="col-md-12">
              <label className="font20 font-primary font-semibold font-head border-bottom w-100 pb-2">Other member details</label>
            </div>
            <div className="col-md-6 mt-3">
              <label htmlFor="label9" className="form-label font14 font-semibold">First Name</label>
              <input type="text" className="form-control pb-2 pt-2" id="label9"  name='First_name' value={Lifeinsurance[0].First_name} onChange={(e) => handlechange(e)} maxLength={40} />
            </div>
            <div className="col-md-6 mt-3">
              <label htmlFor="label10" className="form-label font14 font-semibold">Last Name</label>
              <input type="text" className="form-control pb-2 pt-2" id="label10"  name='Last_name' value={Lifeinsurance[0].Last_name} onChange={(e) => handlechange(e)} maxLength={80} />
            </div>
            <div className="col-md-6 mt-3 date-picker">
              <label  className="form-label font14 font-semibold">Date of Birth</label>
              <input type="date" className="form-control font14 text-uppercase pb-2 pt-2 datecontrol"  name='Dob' value={Lifeinsurance[0].Dob} onChange={(e) => handlechange(e)} max={disablePastDate()} onKeyDown={(e) => { e.preventDefault() }} 
              onFocus={(e) => setOtherdob(true)}
              placeholder = {Otherdob ? "" : "MM/DD/YYYY"}/>
              <img src={calender} alt="calender-icon" className="calender-icon "></img> 
            </div>
            <div className="col-md-6 mt-3">
              <label htmlFor="" className="form-label font14 font-semibold w-100">Gender</label>

              <input
                type="radio"
                className="btn-check radio-checked"
                id="Male"
                autoComplete="off"
                name='gender'
                checked={Lifeinsurance[0].gender === "Male"}
                value="Male"
                onChange={(e) => handlechange(e)} />

              <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor="Male">Male</label>

              <input
                type="radio"
                className="btn-check radio-checked"
                id="Female"
                autoComplete="off"
                name='gender'
                value="Female"
                checked={Lifeinsurance[0].gender === "Female"}
                onChange={(e) => handlechange(e)} />

              <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor="Female">Female</label>
            </div>
            <div className="col-md-12 mt-3 mb-3">
              <label htmlFor="label12" className="form-label font14 font-semibold">Relationship</label>
              <input type="text" className="form-control pb-2 pt-2" id="label12"  name='Relationship' value={Lifeinsurance[0].Relationship.trim()} onChange={(e) => handlechange(e)} maxLength={50} />
            </div>
          </react.Fragment> : null}

        <div className="col-md-12">
          <label htmlFor="benefits" className="form-label w-100 font14 font-semibold">Are you looking for a policy that strictly provides a death benefit?*</label>
          <input type="radio" className="btn-check radio-checked" id="No" autoComplete="off" name="chkno" value="No" checked={Lifeinsurance[0].chkno === "No"} onChange={(e) => handlechange(e)} />
          <label className="btn btn-primary button-radio font14 font-bold px-4 mt-2" htmlFor="No">No</label>
          <input type="radio" className="btn-check radio-checked " id="Yes" autoComplete="off" name="chkno" value="Yes" checked={Lifeinsurance[0].chkno === "Yes"} onChange={(e) => handlechange(e)} />
          <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4 mt-2" htmlFor="Yes">Yes</label>
          <label className='text-danger font14 w-100'>{ValidationALert === true && Lifeinsurance[0].chkno == "" ? "Please select your choice" : null}</label>
        </div>

        {Lifeinsurance[0].chkno === "No" ?
          <react.Fragment>
            <div className="col-md-12 mb-3">
              <label className="form-check-label mb-2 font-semibold font14" htmlFor="" id="utilize">How do you plan to utilize this policy?</label>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" defaultValue="" id="Retirement planning" value="Retirement Planning" name="Utilizepolicy" onChange={(e) => { chkbox(e) }} checked={utilizepolicystring.includes("Retirement Planning")} />
                <label className="form-check-label font14" htmlFor="Retirement planning">
                  Retirement Planning
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" defaultValue="" id="Burial Expense" value="Burial Expenses" name="Utilizepolicy" onChange={(e) => { chkbox(e) }} checked={utilizepolicystring.includes("Burial Expenses")} />
                <label className="form-check-label font14" htmlFor="Burial Expense">
                  Burial Expenses
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" defaultValue="" id="Family support" value="Family Support" name="Utilizepolicy" onChange={(e) => { chkbox(e) }} checked={utilizepolicystring.includes("Family Support")} />
                <label className="form-check-label font14" htmlFor="Family support" >
                  Family Support
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" defaultValue="" id="Education support" value="Education Support" name="Utilizepolicy" onChange={(e) => { chkbox(e) }} checked={utilizepolicystring.includes("Education Support")} />
                <label className="form-check-label font14 " htmlFor="Education support" >
                  Educational Support
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" defaultValue="" id="Business loan" value="Business Loan" name="Utilizepolicy" onChange={(e) => { chkbox(e) }} checked={utilizepolicystring.includes("Business Loan")} />
                <label className="form-check-label font14" htmlFor="Business loan">
                  Business Loan
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" defaultValue="" id="Mortage Protection" value="Mortgage Protection" name="Utilizepolicy" onChange={(e) => { chkbox(e) }} checked={utilizepolicystring.includes("Mortgage Protection")} />
                <label className="form-check-label font14" htmlFor="Mortage Protection">
                  Mortgage Protection
                </label>
              </div>
            </div>
          </react.Fragment> : null}

        <div className="col-md-12">
          <label htmlFor="label8" className="form-label font14 font-semibold mt-2">How much coverage do you need?</label>
          <input type="text" className="form-control pb-2 pt-2" id="label8"  value={Lifeinsurance[0].coverageneeded} name="coverageneeded" onChange={(e) => handlechange(e)} maxLength={7} />
        </div>

        {/* Form component ends here */}

        {/* Footer */}
        <div className="col-md-12 mt-4 mb-4">
          <button type="button" className="btn btn-outline-secondary font14 mt-4 px-5 previous-button mobile-button" onClick={() => { previousStepsBinder(currentpage, "back") }}>
            <span className="previous-button-arrow me-1">
              <img src={Arrowleft} alt="Arrowleft" />
              <img src={ArrowLeftWhite} alt="auto" />
            </span>
            Previous</button>
          <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button" onClick={() => Validation(currentpage)}>Next Step<img src={Arrowright} alt="arrowright" className="ms-2" /></button>
        </div>
        {/* Footer */}
      </react.Fragment>
    );
  }


  // Pseudocode ID :PC-LIF-18
  {/*3rd page -Additional Member*/ }
  //When the user clicks on the  next button in the Policy- death - benfit this function is invoked 
  function additional_Member() {

    function saveButtonClickForAdditionalMember(index: any) {

      let errorOccured = 0;
      if (field[index].First_name.trim() === "") {
        setFirstNameErrorIndex(index);
        errorOccured++
      } if (field[index].Last_name.trim() === "") {
        setLastNameIndex(index);
        errorOccured++
      }

      if (errorOccured !== 0) {
        setOpenedMember(index);
      } else if (errorOccured === 0) {
        setFirstNameErrorIndex(-1);
        setLastNameIndex(-1);

        let newMemberNew = [...field];

        newMemberNew[index].isSaved = true;

        setfield(newMemberNew)



        setOpenedMember(-1);
      }
    }
    return (


      // Form component starts here 
      <react.Fragment>
        <div >
          <label className="col-md-12 border-bottom  mt-2"></label>
        </div>
        <div className="col-md-12 mt-3">
          <label htmlFor="Provide us all the details" className="form-label w-100 font14 font-semibold " id="add">Is there anyone else you would like to obtain the insurance for?</label>
          <input type="radio" className="btn-check radio-checked" id="anyone-else-no" autoComplete="off" name="addno" value="No" checked={Lifeinsurance[0].addno === "No"} onChange={(e) => handlechange(e)} />
          <label className="btn btn-primary button-radio font14 font-bold px-4 mt-2" htmlFor="anyone-else-no">No</label>
          <input type="radio" className="btn-check radio-checked " id="anyone-else-yes" autoComplete="off" name="addno" value="Yes" checked={Lifeinsurance[0].addno === "Yes"} onChange={(e) => handlechange(e)} />
          <label className="btn btn-primary button-radio font14 font-bold px-4 ms-2 mt-2" htmlFor="anyone-else-yes">Yes</label>
        </div>

        {Lifeinsurance[0].addno === "Yes" ?
          <react.Fragment>
            {field.map((element, index) => {
              return (
                <react.Fragment>
                  {(openedMember !== index) ?
                    <react.Fragment>
                      {(openedMember === -1)?
                        <div className="col-md-12 bg-light p-3 mt-4">
                          <label className="font14 font-semibold" onClick={() => { setOpenedMember(index) }}>{element.First_name + " " + element.Last_name}<img src={Edit} alt="edit-icon" className="ms-2 edit-icon" /></label>
                        </div>:
                      <div className="col-md-12 bg-light p-3 mt-4">
                        <label className="font14 font-semibold" >{element.First_name + " " + element.Last_name}<img src={Edit} alt="edit-icon" className="ms-2 edit-icon" /></label>
                      </div>
            }
                    </react.Fragment> : <react.Fragment>

                      {(element.isSaved === true) ?
                        <div className="col-md-12 bg-light p-3 mt-3">
                          <label className="font14 font-semibold">{element.First_name + " " + element.Last_name}<img src={Edit} alt="edit-icon" className="ms-2 edit-icon" /></label>
                        </div> : null
                      }{(element.isSaved === false) ?
                        <div className="col-md-12 p-3 mt-1">
                        </div> : null
                      }

                      <div className={(element.isSaved === false) ? "col-md-12" : "col-md-12 border border-top-0"}>
                        <div className="row border mx-2">
                          <div className="col-md-6 mt-3">
                            <label htmlFor="label-fn" className="form-label font14 font-semibold">First Name*</label>
                            <input type="text" className="form-control pb-2 pt-2" id="label-fn"  name='First_name' value={element.First_name} onChange={(e) => { onHandleChange(index, e) }} maxLength={40} />
                            <label className='text-danger font14 w-100'>{(((firstNameErrorIndex) === index && (element.First_name === "")) || ((firstNameErrorIndex) === index && (element.First_name.trim() === ""))) ? "Please enter the First Name" : null}</label>

                          </div>
                          <div className="col-md-6 mt-3 single-column-margin">
                            <label htmlFor="label-ln" className="form-label font14 font-semibold">Last Name*</label>
                            <input type="text" className="form-control pb-2 pt-2" id="label-ln"  name='Last_name' value={element.Last_name} onChange={(e) => { onHandleChange(index, e) }} maxLength={80} />
                            <label className='text-danger font14 w-100'>{(lastNameErrorIndex === index && element.Last_name.trim() === "") ? "Please enter the Last Name" : null}</label>
                          </div>
                          <div className="col-md-6 mt-0 date-picker ">
                            <label  className="form-label font14 font-semibold">Date of Birth</label>
                            <input type="date" className="form-control font14 text-uppercase pb-2 pt-2 datecontrol" name='Dateofbirth' value={element.Dateofbirth} onChange={(e) => { onHandleChange(index, e) }} max={disablePastDate()} onKeyDown={(e) => { e.preventDefault() }} 
                            onFocus={(e) => setAddmemDob(true)}
                            placeholder = {AddmemDob ? "" : "MM/DD/YYYY"}/>
                            <img src={calender} alt="calender-icon" className="calender-icon "></img> 
                          </div>
                          <div className="col-md-6 mt-0 single-margin">
                            <label className="form-label w-100 font14 font-semibold ">Gender</label>
                            <input type="radio" className="btn-check radio-checked px-4" id={"Male" + index} autoComplete="off" name={'Gender' + index}
                              placeholder="Gender"
                              value="Male"
                              checked={element.Gender === "Male"}
                              onChange={(e) => { onHandleChange(index, e) }} />
                            <label className="btn btn-primary button-radio font14 font-bold" htmlFor={"Male" + index}>Male</label>

                            <input type="radio" className="btn-check radio-checked px-4" id={"Female" + index} autoComplete="off" name={'Gender' + index} placeholder="Gender" value="Female" checked={element.Gender === "Female"} onChange={(e) => { onHandleChange(index, e) }} />
                            <label className="btn btn-primary ms-2 button-radio font14 font-bold" htmlFor={"Female" + index}>Female</label>
                          </div>
                          <div className="col-md-6 mt-4">
                            <label htmlFor="label-rs" className="form-label font14 font-semibold" >Relationship</label>
                            <input type="text" className="form-control pb-2 pt-2" id="label-rs"  name='Relationship' value={element.Relationship.trim()} onChange={(e) => { onHandleChange(index, e) }} maxLength={50} />
                          </div>
                          <div className="col-md-6 mt-4 mb-3">
                            <label htmlFor="label-DC" className="form-label font14 font-semibold">Desired Coverage</label>
                            <input type="text" className="form-control pb-2 pt-2" id="label-DC"  name='Desieredcoverage' value={element.Desieredcoverage.trim()} onChange={(e) => { onHandleChange(index, e) }} maxLength={7} />
                          </div>
                        

                          <div className="col-md-12 mb-4 mt-4">
                            <react.Fragment>
                              <button type="button" className="btn bt-save primary-button border-0 font14 float-start px-5 py-2 save-button" onClick={() => { saveButtonClickForAdditionalMember(index) }}>Save</button>
                            </react.Fragment>

                            {(field.length > 1) ?
                              <react.Fragment>
                                <button type="button" className="btn btn red-button float-start ms-2 px-5 py-2 font14 border-0" onClick={() => remove_Member(index)}>Remove</button>
                              </react.Fragment> : null}
                          </div>
                        </div>
                      </div></react.Fragment>}
                </react.Fragment>
              )
            }
            )
            }

            {
              (openedMember === -1) ? <div className="col-md-12 mt-3">
                <a className="font14 font-semibold font-primary text-decoration-none" href="#" onClick={() => add_Member()}><span><img src={plus} className="plus-icon" alt="plus" onClick={() => add_Member()} /></span> Add Another Member</a>
              </div> :
                <div className="col-md-12 mt-3">
                  <a className="font14 font-semibold font-primary-light text-decoration-none" style={{ "pointerEvents": "none" }} onClick={() => add_Member()}><span><img src={plusIconLight} className="plus-icon" alt="plus" /></span> Add Another Member</a>
                </div>}
          </react.Fragment> : null}
        {/* Form component ends here*/}

        {/* Footer */}
        <div className="col-md-12 mt-4 mb-4">
          <button type="button" className="btn btn-outline-secondary font14 mt-4 px-5 previous-button mobile-button" onClick={() => { previousStepsBinder(currentpage, "back") }}>
            <span className="previous-button-arrow me-1">
              <img src={Arrowleft} alt="Arrowleft" />
              <img src={ArrowLeftWhite} alt="auto" />
            </span>
            Previous</button>
          <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button" onClick={() => postData()} disabled={((Lifeinsurance[0].addno === "Yes") && (openedMember !== -1))}>Request Quote</button>
        </div>

        {/* Footer */}
        {Loading == true ? <div id="root">

          <h1>Submitting Your Request</h1>

          <h3>Please Wait for few seconds....</h3>

          <div className="loader-wrapper">

            <div className="loader"><span></span> <span></span> <span></span> <span></span> <span></span> </div>

          </div>

        </div> : null}

      </react.Fragment>
    );
  }

  //Header component
  const Fname = "Life Insurance"
  const tPages = 3
  const Info = { Name: Fname, totalPages: tPages, pgTopic: pages, currentPg: currentpage ,previousStepArry: previousStepsArray}

  const childToParent=(data:any)=>{
    console.log(data,"page");    
    setCurrentpage(data)    
        } 

  // Main return  
  return (
    // static div container 
    <div className="container mt-5">
      <div className="row mt-4">
        <div className="col-lg-8 col-md-12 col-sm-12">
          <div className="row">
            <div className='col-lg-11 col-md-12 col-sm-12'>
              <div className='row'>
                <Header value={Info}  childToParent={(data:any)=>childToParent(data)} />
                {currentpage === 1 ?
                  lifeInsurance() : null}
                {currentpage === 2 ?
                  policy_Details() : null}
                {currentpage === 3 ?
                  additional_Member() : null}

              </div>
            </div >
          </div >
        </div >

        {/* previous step starts here */}
        <div className="col-md-4 right-panel-margin hide-right">
          <div className="row mx-0">
            {(previousStepsArray.length !== 0) ?
              <react.Fragment>
                <div className="col-md-12 border bg-light p-2 previous-steps-box border-bottom-0">
                  <label className="ps-2 font-16 font-bold">Previous Steps</label>
                </div>
                <div className="col-md-12 border border-top-0 previous-steps-box px-0">

                  {previousStepsArray.map((value: any, index: any) => {
                    return (

                      <li className="list-unstyled px-0 font16 font-primary" >

                        <a className="dropdown-item py-2 gib-tooltip" onClick={() => setCurrentpage(index+1)} style={{ cursor: "pointer" }}>
                          {value}
                          <span className="gib-tooltip"> <img src={Edit} className="edit-icon ms-2" /><span className="tooltiptext">
                            {"Go to Step" + " " + (index + 1)}</span></span>
                        </a>
                      </li>
                    )
                  }
                  )
                  }
                </div></react.Fragment> : null
            }
            {/* perivious step ends here */}
            {/* Need Help */}
            <div className="col-md-12 border bg-light p-2 mt-4 previous-steps-box border-bottom-0">
              <label className="ps-2 font-16 font-bold">Need Help?</label>
            </div>
            <div className="col-md-12 border border-top-0 previous-steps-box">
              <label className="ps-2 w-100 mt-3 font16 font-semibold">The Gibraltar Group</label>
              <label className="ps-2 w-100 mt-2 mb-3 font14">(281) 681-0331</label>
            </div>
            {/* Need Help */}

            {/* Home button */}
            <button type="button" onClick={() => window.location.href = '/'} className="btn btn-primary mt-4 py-2 primary-button border-0 font14 home-button"><img src={ArrowLeftWhite} alt="Arrowleftwhite" className="me-1" /> Back to Home</button>
            {/* Home button */}
          </div>
        </div>
      </div>
    </div>
  );

}
