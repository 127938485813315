/*
   // Project: Gibraltar
   // Desc: This file contains the FloodInsurance  component by using this component the user will able to request quote for their Life insurance;
   // Author:Yuvaraj.G
   // Created Date:04/07/2022
   //Updated Date:04/21/2022
   //File Name : FloodInsurance.tsx;
*/

//Importing useState and useEffect  from react to use state variable in the component;
//Importing all the needed images//importing the service.ts function to post the data;
//import { postInsuranceQuoteData } from "./service";
import React, { useState, useRef, useEffect } from "react";
import nextLogo from '../images/Arrow-right.svg';
import '../App.css'
import previousLogo from '../images/Arrow-Left-White.svg';
import edit from '../images/Edit.svg';
import previouslogo from '../images/Arrow-Left.svg';
import { postInsuranceQuoteData } from "./service";
import Header from "./header";
import PhoneInput from "react-number-format";
import help from '../images/Form-Help.svg';
import axios from "axios";
import Cancel_button from "../images/Cancel-button.svg";
import calender from '../images/Calender.svg'





//PC_FIF_1

function FloodInsuranceForm() {

    var QueryString = new URLSearchParams(window.location.search);
    var OwnerId = QueryString.get("agentId");
    let parameterId= QueryString.get("parameterId");

    // var res ="Hello world";
    // var Word =res.substring(1,4)
    // console.log(Word)


    //state variables;
    // const[validCount,setvalidcount] =useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [validation, setValidation] = useState(false);
    const [validation2, setValidation2] = useState(false);
    // const [firstNameCh, setFirstNameCh] = useState(false);
    const [emailCh, setEmailCh] = useState(false);
    const [zipCh1, setZipCh1] = useState(false);
    const [phoneCh, setPhoneCh] = useState(false);
    const [zipCh, setZipCh] = useState(false);
    const [validation1, setValidation1] = useState(false);
    const [Loading, setLoading] = useState(false);


    const [file, setfile] = useState();
    const [fileValid, setfileValid] = useState(false)
    const [fileLoading, setFileLoading] = useState(false)
    const [cancelvisible, setCancelvisible] = useState(false)
    const myref: any = useRef(null);
    const [filename, setfilename] = useState("")




    const [dateFieldFocused, setdateFieldFocused] = useState(false)


    //Pages Array;
    const [pages, setPages] = useState(
        [
            "Personal Details",
            "Confirm Address",
            "Current Coverage Details"
        ]
    );
    //Finished Pages Array;
    const [finishedPages, setFinishedPages] = useState([]);
    const [valid, setValid] = useState([
        {
            venture: "", Insured: "", firstName: "", lastname: "", email: "", phone: "", street: "", city: "", state: "", zip: "", carrier: "", current: "", street1: "", city1: "", state1: "", zip1: ""
        }
    ])
    //PC_FIF_5
    //the following function disablePastDate() will return the current date in  "YYYY-MM-DD" format this date is used to hide the past or futhure dates in the calender;
    function disablePastDate() {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };
    //PC_FIF_6
    //button clicks;
    function buttonClick(page: any, type: any) {

        //getting the finished array in the variable named spreadArray;
        let spreadArray: any = [...finishedPages];
        //if the type is equal to back the currentpage is decremented by -1;
        if (type === "back") {
            setCurrentPage(currentPage - 1)
        }
        //if the spreadArray not contain the page where we click previous or next button that particular page will be pushed into the array;
        if (!(spreadArray.includes(pages[page - 1]))) {
            spreadArray.push(pages[page - 1])
            console.log(!spreadArray)
        }
        //spreadArray is setted to the finishedPages state variable;
        setFinishedPages(spreadArray);

    }

    function Cancel_buttonClick() {
        setFileLoading(false)
        setfilename("")
        myref.current.value = ""

        setCancelvisible(false)
    }

    const [size, setSize] = useState(0)
    const [type, setType] = useState("")
    const[formatValid,setformatValid]=useState(false)
    async function validateFile(e: any) {

        const convertBase64 = (files: Blob) => {

            return new Promise((resolve, reject) => {

                const fileReader = new FileReader()

                fileReader.readAsDataURL(files)



                fileReader.onload = (() => {

                    resolve(fileReader.result)

                })



                fileReader.onerror = ((error: any) => {

                    reject(error)

                })

            })

        }




        console.log(e.target.files[0])
        setSize(e.target.files[0].size)
        setType(e.target.files[0].type)


        if (e.target.files[0].type !== "application/pdf") {

            e.target.value = ""
            setFileLoading(false)
            setformatValid(true)
            setfileValid(false)
            setfilename("")
            setCancelvisible(false)

        }



        if (e.target.files[0].size <= 5242880) {
            setCancelvisible(true)

            const blobFile = e.target.files[0]

            const filename = e.target.files[0].name

            console.log(filename)

            let s = filename

            let a = s.replace('.pdf', "")

            console.log("filename-->", a)

            setfilename(a)

            setFileLoading(true)

            let base64: any = await convertBase64(blobFile)

            console.log(base64)

            let b = base64.replace('data:application/pdf;base64,', "")

            console.log("Base64", b)

            setFileLoading(false)

            setfile(b)

        }

        else if (e.target.files[0].size === 0 || e.target.files[0].size > 5242880) {

            e.target.value = '';
            setfileValid(true)
            setfilename("")
            setCancelvisible(false)
        }




    }




    //PC_FIF_2
    //a onChangefunction that maintains onChange events  for every fields.
    function onChange(e: any) {

        let validDataSpread: any = [...valid];
        console.log(validDataSpread)

        let name = "", value = ""
        if (e.target) {
            name = e.target.name;
            value = e.target.value
            if (e.target.name === "zip") {
                value = value.replace(/[^0-9]/g, '');
            }

            else if (e.target.name === "email") {
                var regEx = /^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)*[\w+-]+\.[a-zA-Z]{2,4}$/;
                if (!(value.match(regEx))) {
                    setEmailCh(true)
                }
                else if ((value.match(regEx))) {
                    setEmailCh(false)
                }
            }
            else if (e.target.name === "zip") {
                if (value.length < 5) {
                    setZipCh(true)
                }
                else {
                    setZipCh(false)
                }
            }
            else if (e.target.name === "zip1") {
                value = value.replace(/[^0-9]/g, '');
            }
            if (e.target.name === "zip1") {
                if (value.length < 5) {
                    setZipCh1(true)
                }
                else {
                    setZipCh1(false)
                }
            }
            validDataSpread[0][name] = value;

        }
        else {
            name = "phone"
            value = e.value
            validDataSpread[0][name] = value;
            var phoneFormat = /^\d{10}$/;
            if (!(value.match(phoneFormat))) {
                setPhoneCh(true);
            }
            else if ((value.match(phoneFormat))) {
                setPhoneCh(false);
            }
        }
        setValid(validDataSpread);
    }
    //pc_FIF_4
    //function for validating the form datas;
    function Validation(page: any) {
        if (page === 1) {
            //errorCount is used to count the errors if the errorCount is 0 after the validation the page is routed to next page;
            var errorcount = 0
            //validating first page datas;
            if (valid[0].firstName.trim() === "") {
                setValidation(true);
                errorcount++
            }
            //email regex validation;

            if (valid[0].lastname.trim() === "") {
                setValidation(true);
                errorcount++

            }
            if (valid[0].email === "") {
                setValidation(true);
                errorcount++

            }
            //email regex validation;
            if (valid[0].email !== "") {
                var emailformat = /^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)*[\w+-]+\.[a-zA-Z]{2,4}$/;
                if (!(valid[0].email.match(emailformat))) {
                    setValidation(true)
                    setEmailCh(true);
                    errorcount++
                }
                if ((valid[0].email.match(emailformat))) {
                    setEmailCh(false);
                }
            }

            if (valid[0].phone === "") {
                setValidation(true);
                errorcount++

            }
            //email regex validation;
            if (valid[0].phone !== "") {
                var phoneformat = /^\d{10}$/;
                if (!(valid[0].phone.match(phoneformat))) {
                    setValidation(true)
                    setPhoneCh(true);
                    errorcount++
                }
                if ((valid[0].phone.match(phoneformat))) {
                    setPhoneCh(false);
                }
            }
            if (valid[0].street.trim() === "") {
                setValidation(true);
                errorcount++
            }
            if (valid[0].city.trim() === "") {
                setValidation(true);
                errorcount++
            }
            if (valid[0].state === "") {
                setValidation(true);
                errorcount++
            }
            if (valid[0].zip === "") {
                setValidation(true);
                errorcount++
            }
            //email regex validation;
            if (valid[0].zip !== "") {
                var zipformat = /^\d{5}?$/;
                if (!(valid[0].zip.match(zipformat))) {
                    setValidation(true)
                    setZipCh(true);
                    errorcount++

                }
                if ((valid[0].zip.match(zipformat))) {
                    setZipCh(false);
                }
            }
            //if the errorCount is equal to 0 the firstPage will be hidden from the user and the currentPage is incremented by 1;
            if (errorcount == 0) {


                setCurrentPage(currentPage + 1)
                buttonClick(page, "");
            }


        }
        //If block is of page=2 the secondPage will be hidden from the user and the currentPage is incremented by 1 

        else if (page === 2) {
            var errorcount = 0;
            if (valid[0].zip1 !== "") {
                var zipformat = /^\d{5}?$/;
                if (!(valid[0].zip1.match(zipformat))) {
                    setValidation2(true)
                    setZipCh1(true);

                    errorcount++
                }
                if ((valid[0].zip1.match(zipformat))) {
                    setZipCh1(false);
                }

            }
            if (errorcount === 0) {
                setCurrentPage(currentPage + 1);
                buttonClick(page, "");
            }
        }
        //PC_FIF_8
        //validating for third page datas
        else if (page === 3) {
            console.log(valid[0].Insured, "insured check");

            if (valid[0].Insured === "No") {
                let jsonSpread = [...valid];

                jsonSpread[0].carrier = "";
                jsonSpread[0].current = "";

                setValid(jsonSpread);
            }

            if (valid[0].Insured === "Yes") {
                if (valid[0].current === "") {
                    setValidation1(true);
                    return false;
                }
                if (valid[0].carrier.trim() === "") {
                    setValidation1(true);
                    return false;
                }

                postData()
            }
            //if user clicks the no button in the coverage details the data will be posted
            else if (valid[0].Insured === "No") {
                // console.log(" i am posting the data because you selected no")
                postData()
            }
            //if user not clicking any button in the coverage details the data will be posted 
            else if (valid[0].Insured === "") {
                // console.log("I am posting data becaues you didnot select nothing")
                postData()
            }
        }
    }
    /*function for posting the data to the data base via service.ts file function*/
    //PC_FIF_10
    async function postData() {
        let array = {
            PersonalDetails: {
                FirstName: valid[0].firstName.trimStart().trimEnd(),
                LastName: valid[0].lastname.trimStart().trimEnd(),
                Email: valid[0].email,
                MobilePhone: valid[0].phone,
                Street: valid[0].street.trimStart().trimEnd(),
                City: valid[0].city.trimStart().trimEnd(),
                State: valid[0].state,
                PostalCode: valid[0].zip,
                OwnerId: OwnerId,
                parameterId:parameterId
            },
            confirmAddress: {
                Street1: (valid[0].street1 !== "") ? valid[0].street1.trimStart().trimEnd() : null,
                City1: (valid[0].city1 !== "") ? valid[0].city1.trimStart().trimEnd() : null,
                State1: (valid[0].state1 !== "") ? valid[0].state1 : null,
                PostalCode1: (valid[0].zip1 !== "") ? valid[0].zip1 : null,
                Primary_Residence__c: (valid[0].venture !== "") ? valid[0].venture : null,
            },
            currentCoverage: {
                Currently_Insured_for_Flood__c: (valid[0].Insured !== "") ? valid[0].Insured : null,
                Current_Carrier__c: (valid[0].carrier !== "") ? valid[0].carrier.trimStart().trimEnd() : null,
                Previous_Policy_Expiration_Date__c: (valid[0].current !== "") ? valid[0].current : null,
                VersionData: file === "" ? null : file,
                filename: filename === "" ? null : filename
            }


        }
        setLoading(true)
        console.log(array)
        let data = await postInsuranceQuoteData(array, "Flood");

        // console.log(array);
        window.location.href = "/thankyou"
    }

    const Fname = "Flood Insurance"
    const tPages = 3
    const Info = { Name: Fname, totalPages: tPages, pgTopic: pages, currentPg: currentPage, previousStepArry: finishedPages }

    /*Personal Details Page Block starts here*/
    /*this function will return the html to bind the firstPage here user can enter their personal details*/
    //PC_FIF_3
    function floodInsurance() {
        //return part of the personalDetailsPageBind Function;
        return (
            <React.Fragment>


                {/* General Info Starts here */}
                <div className="col-md-12 mt-3">
                    <label className="font20 font-primary font-semibold font-head pb-2 border-bottom w-100">General Info</label>
                </div>
                {/* Firstname block starts here */}
                <div className="col-md-6 mt-3">
                    <label htmlFor="label1" className="form-label font14 font-semibold">First Name*</label>
                    <input type="text" className="form-control pb-2 pt-2" id="label1" name="firstName" maxLength={40} value={valid[0].firstName} onChange={(e) => { onChange(e) }} />
                    <label className="text-danger font14">
                        {
                            (validation === true && valid[0].firstName.trim() === "") ? "Please enter the First Name" : null
                        }
                    </label>

                </div>
                {/* Firstname block ends here */}
                {/* Lastname block starts here */}
                <div className="col-md-6 mt-3 single-column-margin">
                    <label htmlFor="label2" className="form-label font14 font-semibold">Last Name*</label>
                    <input type="text" className="form-control pb-2 pt-2" id="label2" name="lastname" maxLength={80} value={valid[0].lastname} onChange={(e) => { onChange(e) }} />
                    <label className="text-danger font14">
                        {
                            (validation === true && valid[0].lastname.trim() === "") ? "Please enter the Last Name" : null
                        }
                    </label>
                </div>
                {/* Lastname Block ends here */}

                {/* General Info Ends Here */}
                {/* Communication Info Starts Here */}
                <div className="col-md-12 mt-2">
                    <label className="font20 font-primary font-semibold font-head pb-3 border-bottom w-100">Communication Info</label>
                </div>
                <div className="col-md-12 mt-3">
                    <label htmlFor="label4" className="form-label font14 font-semibold" >Email*</label>
                    <input type="email" className="form-control pb-2 pt-2" id="label4" name="email" maxLength={80} value={valid[0].email} onChange={(e) => { onChange(e) }} />
                    <label className="text-danger font14">
                        {
                            (validation === true && valid[0].email === "") ? "Please enter an Email" :
                                (validation === true && emailCh === true) ? "Please enter a valid Email" : null
                        }
                    </label>
                </div>
                <div className="col-md-12 mt-3 single-column-margin">
                    <label htmlFor="label5" className="form-label font14 font-semibold">Mobile Phone*</label>
                    {valid[0].phone.length < 10 ?
                        <PhoneInput type="tel" placeholder="XXXXXXXXXX" className="form-control pb-2 pt-2" id="label5" name="phone" value={valid[0].phone} onValueChange={(e) => { onChange(e) }} /> :
                        <PhoneInput type="tel" format={"(###) ###-####"} className="form-control pb-2 pt-2" id="label5" placeholder="XXXXXXXXXX" value={valid[0].phone} onValueChange={(e: any) => { onChange(e) }} />}
                    <label className="text-danger font14">
                        {
                            (validation === true && valid[0].phone === "") ? "Please enter the Mobile Number" :
                                (validation === true && phoneCh === true) ? "Please enter a valid Mobile Number" : null
                        }
                    </label>
                </div>
                <div className="col-md-12 mt-3 single-column-margin">
                    <label htmlFor="label6" className="form-label font14 font-semibold">Street*</label>
                    <textarea className="form-control pb-2 pt-2 text-area-input" id="label6" rows={6} defaultValue={""} name="street" maxLength={120} value={valid[0].street} onChange={(e) => { onChange(e) }} />
                    <label className="text-danger font14">
                        {
                            (validation === true && valid[0].street.trim() === "") ? "Please enter the Street" : null
                        }
                    </label>
                </div>
                <div className="col-md-12 mt-3 single-column-margin">
                    <label htmlFor="label7" className="form-label font14 font-semibold">City*</label>
                    <input type="text" className="form-control pb-2 pt-2" id="label7" name="city" maxLength={40} value={valid[0].city} onChange={(e) => { onChange(e) }} />
                    <label className="text-danger font14">
                        {
                            (validation === true && valid[0].city.trim() === "") ? "Please enter the City" : null
                        }
                    </label>
                </div>
                <div className="col-md-6 mt-3 single-column-margin">
                    <label htmlFor="dls" className="form-label font14 font-semibold">State/Province*</label>
                    <select className="form-select pb-2 pt-2 " aria-label="Default select example" id="dls" name="state" value={valid[0].state} onChange={(e) => { onChange(e) }}>
                        <option value="">Select State/Province</option>
                        <option value="AK">AK</option>
                        <option value="AL">AL</option>
                        <option value="AR">AR</option>
                        <option value="AZ">AZ</option>
                        <option value="CA">CA</option>
                        <option value="CO">CO</option>
                        <option value="CT">CT</option>
                        <option value="DE">DE</option>
                        <option value="FL">FL</option>
                        <option value="GA">GA</option>
                        <option value="HI">HI</option>
                        <option value="IA">IA</option>
                        <option value="ID">ID</option>
                        <option value="IL">IL</option>
                        <option value="IN">IN</option>
                        <option value="KS">KS</option>
                        <option value="KY">KY</option>
                        <option value="LA">LA</option>
                        <option value="MA">MA</option>
                        <option value="MD">MD</option>
                        <option value="ME">ME</option>
                        <option value="MI">MI</option>
                        <option value="MN">MN</option>
                        <option value="MO">MO</option>
                        <option value="MS">MS</option>
                        <option value="MT">MT</option>
                        <option value="NC">NC</option>
                        <option value="ND">ND</option>
                        <option value="NE">NE</option>
                        <option value="NH">NH</option>
                        <option value="NJ">NJ</option>
                        <option value="NM">NM</option>
                        <option value="NV">NV</option>
                        <option value="NY">NY</option>
                        <option value="OH">OH</option>
                        <option value="OK">OK</option>
                        <option value="OR">OR</option>
                        <option value="PA">PA</option>
                        <option value="RI">RI</option>
                        <option value="SC">SC</option>
                        <option value="SD">SD</option>
                        <option value="TN">TN</option>
                        <option value="TX">TX</option>
                        <option value="UT">UT</option>
                        <option value="VA">VA</option>
                        <option value="VT">VT</option>
                        <option value="WA">WA</option>
                        <option value="WI">WI</option>
                        <option value="WV">WV</option>
                        <option value="WY">WY</option>
                    </select>
                    <label className="text-danger font14">
                        {
                            (validation === true && valid[0].state === "") ? "Please select the State/Province" : null
                        }
                    </label>
                </div>
                <div className="col-md-6 mt-3 single-column-margin">
                    <label htmlFor="label9" className="form-label font14 font-semibold">Zip/Postal Code*</label>
                    <input type="text" className="form-control pb-2 pt-2" id="label9" name="zip" maxLength={5} value={valid[0].zip} onChange={(e) => { onChange(e) }} />
                    <label className="text-danger font14">
                        {
                            (validation === true && valid[0].zip === "") ? "Please enter the Zip/Postal Code" :
                                (validation === true && valid[0].zip.length < 5) ? "Please enter the valid Zip/Postal Code" : null
                        }
                    </label>
                </div>

                {/* Communication Info Ends Here */}

                {/* Footer */}
                <div className="col-md-12 mt-4 mb-4">

                    <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button" onClick={(e) => { Validation(currentPage) }}>Next Step<img src={nextLogo} className="ms-2" /></button>
                </div>
                {/* Footer */}

            </React.Fragment>

        );
    }
    /*Personal Details Page Block ends here*/
    /* confirmAddress page Block starts here  */
    /* function to bind the confirmAddress page the function will gets invoked if the current page is equal to 2 */
    //PC_FIF_7

    function confirmAddress() {
        //return part of confirmAddress page;
        return (

            <React.Fragment>


                <div className="col-md-12">
                    <label htmlFor="nil" className="border-bottom w-100"></label>
                </div>


                <div className="col-md-12 mt-3">
                    <label htmlFor="label6" className="form-label font14 font-semibold">Street</label>
                    <textarea className="form-control pb-2 pt-2 text-area-input" id="label6" rows={6} defaultValue={""} name="street1" maxLength={120} value={valid[0].street1} onChange={(e) => { onChange(e) }} />
                </div>
                <div className="col-md-12 mt-3">
                    <label htmlFor="label7" className="form-label font14 font-semibold">City</label>
                    <input type="text" className="form-control pb-2 pt-2" id="label7" name="city1" value={valid[0].city1} maxLength={40} onChange={(e) => { onChange(e) }} />
                </div>
                <div className="col-md-6 mt-3">
                    <label htmlFor="dls" className="form-label font14 font-semibold">State/Province</label>
                    <select className="form-select pb-2 pt-2 " aria-label="Default select example" id="dls" name="state1" value={valid[0].state1} onChange={(e) => { onChange(e) }}>
                        <option value="--select--">Select State/Province</option>
                        <option value="AK">AK</option>
                        <option value="AL">AL</option>
                        <option value="AR">AR</option>
                        <option value="AZ">AZ</option>
                        <option value="CA">CA</option>
                        <option value="CO">CO</option>
                        <option value="CT">CT</option>
                        <option value="DE">DE</option>
                        <option value="FL">FL</option>
                        <option value="GA">GA</option>
                        <option value="HI">HI</option>
                        <option value="IA">IA</option>
                        <option value="ID">ID</option>
                        <option value="IL">IL</option>
                        <option value="IN">IN</option>
                        <option value="KS">KS</option>
                        <option value="KY">KY</option>
                        <option value="LA">LA</option>
                        <option value="MA">MA</option>
                        <option value="MD">MD</option>
                        <option value="ME">ME</option>
                        <option value="MI">MI</option>
                        <option value="MN">MN</option>
                        <option value="MO">MO</option>
                        <option value="MS">MS</option>
                        <option value="MT">MT</option>
                        <option value="NC">NC</option>
                        <option value="ND">ND</option>
                        <option value="NE">NE</option>
                        <option value="NH">NH</option>
                        <option value="NJ">NJ</option>
                        <option value="NM">NM</option>
                        <option value="NV">NV</option>
                        <option value="NY">NY</option>
                        <option value="OH">OH</option>
                        <option value="OK">OK</option>
                        <option value="OR">OR</option>
                        <option value="PA">PA</option>
                        <option value="RI">RI</option>
                        <option value="SC">SC</option>
                        <option value="SD">SD</option>
                        <option value="TN">TN</option>
                        <option value="TX">TX</option>
                        <option value="UT">UT</option>
                        <option value="VA">VA</option>
                        <option value="VT">VT</option>
                        <option value="WA">WA</option>
                        <option value="WI">WI</option>
                        <option value="WV">WV</option>
                        <option value="WY">WY</option>
                    </select>
                </div>
                <div className="col-md-6 mt-3">
                    <label htmlFor="label9" className="form-label font14 font-semibold">Zip/Postal Code</label>
                    <input type="text" className="form-control pb-2 pt-2" id="label9" name="zip1" maxLength={5} value={valid[0].zip1} onChange={(e) => { onChange(e) }} />

                    <label className="text-danger font14">
                        {

                            (
                                (valid[0].zip1 !== "") && (valid[0].zip1.length !== 5) && validation2 === true) ? "Please enter the valid Zip/Postal Code" : null
                        }
                    </label>
                </div>


                <div className="col-md-12">
                    <label htmlFor="nil" className="border-bottom w-100"></label>
                </div>
                <div className="col-md-12 mt-3">
                    <label htmlFor="Provide us  details" className="form-label w-100 font14 font-semibold ">Is this your primary residence?</label>
                    <input type="radio" className="btn-check radio-checked" id="Provide us all the details" autoComplete="off" value="No" name="venture" checked={valid[0].venture === "No"}
                        onClick={(e) => { onChange(e) }} />
                    <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor="Provide us all the details">No</label>
                    <input type="radio" className="btn-check radio-checked" id="Just give key points" autoComplete="off" value="Yes" name="venture" checked={valid[0].venture === "Yes"}
                        onClick={(e) => { onChange(e) }} />
                    <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor="Just give key points">Yes</label>
                </div>
                {/* Footer */}
                <div className="col-md-12 mt-4 mb-4">
                    <button type="button" className="btn btn-outline-secondary font14 mt-4 px-5 previous-button mobile-button" onClick={() => { buttonClick(currentPage, "back") }}>

                        <span className="previous-button-arrow me-1">
                            <img src={previouslogo} alt="auto" />
                            <img src={previousLogo} alt="auto" />
                        </span>
                        Previous</button>
                    <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button" onClick={(e) => { Validation(currentPage) }}>Next Step<img src={nextLogo} className="ms-2" /></button>
                </div>
                {/* Footer */}

            </React.Fragment>


        );
    }

    /* confirmAddresspage Block ends here  */

    /* coverageDetails page Block starts here  */
    /* function to bind the coverageDetails page the function will gets invoked if the current page is equal to 3 */
    //PC_FIF_8
    function coverageDetails() {
        //return part of coverageDetails page;
        return (
            <div>


                <div className="col-md-12">
                    <label htmlFor="nil" className="border-bottom w-100"></label>
                </div>
                <div className="col-md-8 mt-3">
                    <label htmlFor="Provide us all the details" className="form-label w-100 font14 font-semibold ">Are you currently insured for flood?</label>
                    <input type="radio" className="btn-check radio-checked" id="Provide us all  details" autoComplete="off" value="No" name="Insured" checked={valid[0].Insured === "No"}
                        onClick={(e) => { onChange(e) }} />
                    <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor="Provide us all  details">No</label>
                    <input type="radio" className="btn-check radio-checked" id="Just give key " autoComplete="off" value="Yes" name="Insured" checked={valid[0].Insured === "Yes"}
                        onClick={(e) => { onChange(e) }} />
                    <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor="Just give key ">Yes</label>
                </div>

                {(valid[0].Insured === "Yes") ?

                    <React.Fragment> <div className="col-md-12 mt-3">
                        <label htmlFor="label7" className="form-label font14 font-semibold">Current Carrier*<span className="gib-tool-carrier">
                            <img src={help} className="ms-1 gib-tool-carrier" />

                            <span className="tooltext-carrier">{"your current insurance provider"}</span>

                        </span></label>
                        <input type="text" className="form-control pb-2 pt-2" id="label7" name="carrier" maxLength={255} value={valid[0].carrier} onChange={(e) => { onChange(e) }} />
                        <label className="text-danger font14">
                            {
                                (validation1 === true && valid[0].carrier.trim() === "") ? "Please enter the Current Carrier" : null
                            }
                        </label>
                    </div>
                        <div className="col-md-12 mt-3 date-picker">
                            <label className="form-label font14 font-semibold">When does your current policy expire?*</label>
                            <input type="date" className="form-control pb-2 pt-2 text-uppercase datecontrol" name="current" value={valid[0].current} min={disablePastDate()} onChange={(e) => { onChange(e) }} onKeyDown={(e) => { e.preventDefault() }}
                                onFocus={(e) => setdateFieldFocused(true)}
                                placeholder={dateFieldFocused ? "" : "MM/DD/YYYY"} />
                            <img src={calender} alt="calender-icon" className="floodcalender-icon "></img>
                            <label className="text-danger font14">
                                {
                                    (validation1 === true && valid[0].current === "") ? "Please enter the Current Policy Expiry Date" : null
                                }

                            </label>

                        </div>
                        <div className="file-upload">
                            <label htmlFor="expiryDate" className="form-label font14 mb-0 font-semibold w-100 mt-4">Current Insurance Dec Page</label>
                            <label htmlFor="expiryDate" className="form-label font14 PDF-label w-100"> <span className="font-semibold">Note: </span>Please upload PDF file only with less than 5 MB</label>

                            <input type="file" className=" form-control border-0 file-upload-input w-50 mt-2 px-3 font14 font-semibold" title="" accept=".pdf" id="formFile" onChange={(e) => validateFile(e)} ref={myref} />
                            {/* <input type="file" className=" form-control border-0 file-upload-input w-50" accept=".pdf" id="formFile" onChange={(e) => validateFile(e)} ref={myref}  /> */}
                            {/* <input type="file" className="px-3"  accept=".pdf" id="actual-btn" hidden onChange={(e) => validateFile(e)} ref={myref} />
                                 <label htmlFor="actual-btn" className="file-upload-input mt-2 px-3 font14 font-semibold">Choose File</label> */}
                            <span className="gib-tool-choose">
                                <label className="font14 ms-2">{(filename !== "" && filename.length < 20) ? filename + ".pdf" : (filename.length > 10 && filename !== "") ? (filename.substring(0, 20)) + "..." + ".pdf" : "No file chosen"}</label>
                                {filename !== "" ? <span className="tooltext-choose">


                                    {(filename.length > 55) ? filename.substring(0, 54) + '\n' + filename.substring(55, 100) + ".pdf" : (filename + ".pdf")}</span> : null}

                            </span>



                            {fileLoading == true ?
                                <div className="clearfix ">

                                    <div className="spinner-border float-end text-secondary loader-spinner" role="status">

                                        <span className="visually-hidden">Loading...</span>

                                    </div>

                                </div> : null}
                            {cancelvisible === true ?
                                <button className="btn border-0  Choose-cancel-mobile w-65 float-end mt-2 " onClick={() => Cancel_buttonClick()} >
                                    <img src={Cancel_button} />
                                </button> : null}

                        </div>
                        <label className="text-danger font14">
                            {(fileValid === true && size > 5242880) ? "File size limit is exceeded" : null}
                            {((fileValid === true && size === 0) ? "The uploaded file is empty" : null)}
                            {((formatValid === true && type !== "application/pdf") ? "Please upload a file in suggested format" : null)}
                        </label>
                    </React.Fragment> : null}

                {/* Footer */}
                <div className="col-md-12 mt-4 mb-4">
                    <button type="button" className="btn btn-outline-secondary font14 mt-4 px-5 previous-button mobile-button" onClick={() => { buttonClick(currentPage, "back") }}>
                        <span className="previous-button-arrow me-1">
                            <img src={previouslogo} alt="auto" />
                            <img src={previousLogo} alt="auto" />
                        </span>
                        Previous</button>
                    <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button" onClick={(e) => { Validation(currentPage) }}>Request Quote</button>

                </div>
                {/* Footer */}
                {/* Form-Component */}
                {Loading == true ? <div id="root">

                    <h1>Submitting Your Request</h1>

                    <h3>Please Wait for few seconds....</h3>

                    <div className="loader-wrapper">

                        <div className="loader"><span></span> <span></span> <span></span> <span></span> <span></span> </div>

                    </div>

                </div> : null}
            </div>


        )

    }

    const childToParent = (data: any) => {
        console.log(data, "page");
        setCurrentPage(data)
    }
    /* coverageDetailsPage Block ends here  */
    /*complete component return part starts here*/
    return (
        <React.Fragment>

            <div className="container mt-5">
                <div className="row mt-4">
                    <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="row">
                            <div className="col-lg-11 col-md-12 col-sm-12">
                                <div className="row">
                                    <Header value={Info} childToParent={(data: any) => childToParent(data)} />
                                    {
                                        (currentPage === 1) ?
                                            floodInsurance() : null
                                    }
                                    {
                                        (currentPage === 2) ?
                                            confirmAddress() : null
                                    }
                                    {
                                        (currentPage === 3) ?
                                            coverageDetails() : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 right-panel-margin hide-right">
                        <div className="row mx-0">
                            {

                                /* if finished pages length is not equal to zero means the prevoius steps will be hidden */
                                (finishedPages.length > 0) ?
                                    <React.Fragment>
                                        {/* Previous Steps */}
                                        <div className="col-md-12 border bg-light p-2 previous-steps-box border-bottom-0">
                                            <label className="ps-2 font-16 font-bold">Previous Steps</label>
                                        </div>
                                        <div className="col-md-12 border border-top-0 previous-steps-box px-0">

                                            {/*map will map all the elements in the array */}
                                            {finishedPages.map((values: any, index: any) => {

                                                return (
                                                    <li className="list-unstyled px-0 font16 font-primary">
                                                        {/* index always started in 0 but current page is start at the 1 so we always increase index by  1 */}
                                                        <a className="dropdown-item py-2 gib-tooltip" href="#" onClick={() => setCurrentPage(index + 1)} >
                                                            {values}
                                                            <span className="gib-tooltip"><img src={edit} className="edit-icon ms-2" />
                                                                <span className="tooltiptext">{`Go to Step ${index + 1}`}</span>
                                                            </span>
                                                        </a>
                                                    </li>
                                                )
                                            }
                                            )
                                            }
                                        </div>
                                    </React.Fragment> : null
                            }
                            {/* Previous Steps */}

                            {/* Need Help */}
                            <div className={(finishedPages.length !== 0) ? "col-md-12 border bg-light previous-steps-box p-2 mt-4 border-bottom-0" : "col-md-12 border bg-light p-2 previous-steps-box border-bottom-0"}>
                                <label className="ps-2 font-16 font-bold">Need Help?</label>
                            </div>
                            <div className="col-md-12 border border-top-0 previous-steps-box">
                                <label className="ps-2 w-100 mt-3 font16 font-semibold">The Gibraltar Group</label>
                                <label className="ps-2 w-100 mt-2 mb-3 font14">(281) 681-0331</label>
                            </div>
                            {/* Need Help */}
                            {/* Home button */}
                            <button type="button" onClick={() => window.location.href = '/'} className="btn btn-primary mt-4 py-2 primary-button border-0 font14 home-button"><img src={previousLogo} alt="auto" className="me-1" /> Back to Home</button>
                            {/* Home button */}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default FloodInsuranceForm;
