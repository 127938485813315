//homeInsurance;


//Commpleted Home Before Current Page;


/*
   // Project: Gibraltar
   // Desc: This file contains the Home insurance component by using this component the user will able to request quote for their Home insurance;
   // Author:Pavithra R
   // Created Date:04/07/2022
   //File Name : homeForm.tsx;
*/

import React, { useEffect, useState } from 'react';
import { postInsuranceQuoteData } from './service';
import nextLogo from '../images/Arrow-right.svg';
import PhoneInput from "react-number-format";
import helpicon from '../images/Form-Help.svg'

import '../App.css';

import previousLogo from '../images/Arrow-Left-White.svg';

import edit from '../images/Edit.svg';
import info from '../images/Info-Icon.svg';
import infoicon from '../images/Info-Icon.svg'


import previouslogo from '../images/Arrow-Left.svg'

import Header from "./header";
import axios from 'axios';
import calender from '../images/Calender.svg'
//*declaring state variables  for all the fields in the form*//

export default function Home(props: object) {

    var errorcount = 0;
    const currDate = disablePastDate();

    var QueryString = new URLSearchParams(window.location.search);
    var OwnerId = QueryString.get("agentId");
    let parameterId = QueryString.get("parameterId");

    const [emailReg, setemailReg] = useState(false);
    const [phonereg, setphonereg] = useState(false);
    const [zipcodereg, setzipcodereg] = useState(false);
    const [ValidationALert, setValidationAlert] = useState(false);
    const [currentpage, setCurrentpage] = useState(1);
    const [validationChild, setValidationChild] = useState(false);
    const [validcross, setValidcross] = useState(false);
    //const [previousbuttonmobile, setpreviousbuttonmobile] = useState([]);
    const [crossedpages, setCrossedPages] = useState(0);//* to the previous step number
    const [Loading, setLoading] = useState(false)
    const [counter, setCounter] = useState(0)

    const [dateFieldFocused, setdateFieldFocused] = useState(false)
    const [dateFieldspouse, setdateFieldspouse] = useState(false)
    const [dateclosingdate, setdateclosingdate] = useState(false)
    const [datecurrentpolicy, setdatecurrentpolicy] = useState(false)


    const [samePageRender, setSamePageRender] = useState(false)
    const [currInsuranceValidation, setCurrInsuranceValidation] = useState(false)
    const [leadId, setLeadId] = useState("")





    // previous step flow for provideusall && construction  details yes flow
    const [pagesForProvideUsAlldetailsYes, setPagesForProvideUsAllDetailsYes] = useState(
        [
            "Personal Details", //0 < 1 bind //   0<2
            "Spouse Details",//1 === 1 not bind
            "Purchase Details",
            "Home Details",
            "Construction Details",
            "Current Insurance",
            "Policy Details",
            "Property Details",
            "Pet Details",
            "Security Alarm"
        ]
    )



    // previous step flow for  provide us all && construction details details no flow
    const [pagesForProvideUsAlldetailsNo, setPagesForProvideUsAllDetailsNo] = useState(
        [
            "Personal Details",
            "Spouse Details",
            "Purchase Details",
            "Home Details",
            "Construction Details",
            "Roof Details",
            "Current Insurance",
            "Policy Details",
            "Property Details",
            "Pet Details",
            "Security Alarm"
        ]
    );
    // previous step flow for just give points  flow
    const [jsuTGive, setJustGive] = useState(
        [
            "Personal Details",
            "Purchase Details",
            "Current Insurance Details"
        ]
    );

    const [pagesforpreviousyes, setpagesforpreviousyes] = useState([
        "Personal Details",
        "Spouse Details",
        "Purchase Details",
        "Home Details",
        "Construction Details",
        "Roof Details",
        "Property Details",
        "Pet Details",
        "Security Alarm"
    ])

    const [pagesforperviousno, setpagesforperviousno] = useState([
        "Personal Details",
        "Spouse Details",
        "Purchase Details",
        "Home Details",
        "Construction Details",
        "Property Details",
        "Pet Details",
        "Security Alarm"
    ])

    const [justGives, setJustGives] = useState([
        "Personal Details",
        "Purchase Details"
    ])


    debugger

    function backButtonClick() {


        if (currentpage === 2) {
            if (crossedpages < 2) {
                setCrossedPages(2)
            }
        } else if (currentpage === 3) {
            if (crossedpages < 3) {
                setCrossedPages(3)
            }
        } else if (currentpage === 4) {
            if (crossedpages < 4) {
                setCrossedPages(4)
            }
        } else if (currentpage === 5) {
            if (crossedpages < 5) {
                setCrossedPages(5)
            }
        }

        //condition for crossed pages for flowChange;

        if (jsonData[0].constNew === "Yes" || jsonData[0].constNew === "" && jsonData[0].purchaseDetails === "No") {

            if (currentpage === 7) {
                if (crossedpages < 6) {
                    setCrossedPages(6)
                }
            } else if (currentpage === 8) {
                if (crossedpages < 7) {
                    setCrossedPages(7)
                }
            } else if (currentpage === 9) {
                if (crossedpages < 8) {
                    setCrossedPages(8)
                }
            } else if (currentpage === 10) {
                if (crossedpages < 9) {
                    setCrossedPages(9)
                }
            } else if (currentpage === 11) {
                if (crossedpages < 10) {
                    setCrossedPages(10)
                }
            }
        } else if (jsonData[0].constNew === "No" && jsonData[0].purchaseDetails === "No") {

            if (currentpage === 6) {
                if (crossedpages < 6) {
                    setCrossedPages(6)
                }
            } else if (currentpage === 7) {
                if (crossedpages < 7) {
                    setCrossedPages(7)
                }
            } else if (currentpage === 8) {
                if (crossedpages < 8) {
                    setCrossedPages(8)
                }
            } else if (currentpage === 9) {
                if (crossedpages < 9) {
                    setCrossedPages(9)
                }
            } else if (currentpage === 10) {
                if (crossedpages < 10) {
                    setCrossedPages(10)
                }
            } else if (currentpage === 11) {
                if (crossedpages < 11) {
                    setCrossedPages(11)
                }
            }
        }

        if (jsonData[0].constNew === "Yes" || jsonData[0].constNew === "" && jsonData[0].purchaseDetails === "Yes") {

            if (currentpage === 7) {
                if (crossedpages < 6) {
                    setCrossedPages(6)
                }
            } else if (currentpage === 8) {
                if (crossedpages < 7) {
                    setCrossedPages(7)
                }
            } else if (currentpage === 9) {
                if (crossedpages < 6) {
                    setCrossedPages(6)
                }
            } else if (currentpage === 10) {
                if (crossedpages < 7) {
                    setCrossedPages(7)
                }
            } else if (currentpage === 11) {
                if (crossedpages < 8) {
                    setCrossedPages(8)
                }
            }
        } else if (jsonData[0].constNew === "No" && jsonData[0].purchaseDetails === "Yes") {

            if (currentpage === 6) {
                if (crossedpages < 6) {
                    setCrossedPages(6)
                }
            } else if (currentpage === 7) {
                if (crossedpages < 7) {
                    setCrossedPages(7)
                }
            } else if (currentpage === 8) {
                if (crossedpages < 8) {
                    setCrossedPages(8)
                }
            } else if (currentpage === 9) {
                if (crossedpages < 7) {
                    setCrossedPages(7)
                }
            } else if (currentpage === 10) {
                if (crossedpages < 8) {
                    setCrossedPages(8)
                }
            } else if (currentpage === 11) {
                if (crossedpages < 9) {
                    setCrossedPages(9)
                }
            }
        }















































        if (currentpage === 7 && (jsonData[0].constNew === "Yes" || jsonData[0].constNew === "")) {
            setCurrentpage(currentpage - 2)
        }
        else if (currentpage === 9) {

            if (jsonData[0].constNew === "Yes" && jsonData[0].purchaseDetails === "Yes") {
                setCurrentpage(currentpage - 4);
            } else if (jsonData[0].constNew === "" && jsonData[0].purchaseDetails === "Yes") {
                setCurrentpage(currentpage - 4);
            }
            else if (jsonData[0].constNew === "No" && jsonData[0].purchaseDetails === "Yes") {
                setCurrentpage(currentpage - 3);
            } else {
                setCurrentpage(currentpage - 1);
            }
        }
        else {
            setCurrentpage(currentpage - 1)
        }
        console.log('back clicked')


    }

    const childToParent = (data: any) => {
        console.log(data, "page");
        setCurrentpage(data)
    }
    const [jsonData, setJsonData] = useState([
        {
            firstName: "",
            lastName: "",
            dateOfBirth: "",
            email: "",
            mobilePhone: "",
            street: "",
            city: "",
            state: "",
            postalCode: "",
            preferredContact: "",
            //changed from Provide us all details to Just give key points
            preferredDetails: "Just give key points",
            spouse: "",
            spouseName: "",
            spousedob: "",
            purchaseDetails: "",
            closingDate: "",
            proStreet: "",
            proCity: "",
            proState: "",
            proPostalCode: "",
            Years: "",
            constNew: "",
            constructType: "",
            squareFeet: "",
            stories: "",
            bathroom: "",
            halfbath: "",
            roof: "",
            currentInsurance: "",
            policyExpire: "",
            renewalpremium: "",
            lapseTime: "",
            poolDetails: "",
            divingBoard: "",
            trampoline: "",
            pets: "",
            petType: "",
            petNumber: "",
            securityAlarm: ""

        }]);

    useEffect(() => { clearFields() }, [jsonData[0].preferredDetails])



    //it clear all the fields when user complete quote is changed
    function clearFields() {


        jsonData[0].spouse = "";
        jsonData[0].spouseName = "";
        jsonData[0].spousedob = "";
        jsonData[0].purchaseDetails = "";
        jsonData[0].closingDate = "";
        jsonData[0].proStreet = "";
        jsonData[0].proCity = "";
        jsonData[0].proState = "";
        jsonData[0].proPostalCode = "";
        jsonData[0].Years = "";
        jsonData[0].constNew = "";
        jsonData[0].constructType = "";
        jsonData[0].squareFeet = "";
        jsonData[0].stories = "";
        jsonData[0].bathroom = "";
        jsonData[0].halfbath = "";
        jsonData[0].roof = "";
        jsonData[0].renewalpremium = "";
        jsonData[0].currentInsurance = "";
        jsonData[0].policyExpire = "";
        jsonData[0].lapseTime = "";
        jsonData[0].poolDetails = "";
        jsonData[0].trampoline = "";
        jsonData[0].divingBoard = "";
        jsonData[0].pets = "";
        jsonData[0].petType = "";
        jsonData[0].petNumber = "";
        jsonData[0].securityAlarm = "";
        setCrossedPages(0);
    }
    // to post the data in the database
    async function postData() {

        var petnum;
        var pool;
        var pet;
        var squareFeet;
        var constype;
        var story;
        var sname;
        var sdob;
        var bathroom;
        var roof;
        var ClosingDate;
        var ProStreet;
        var ProCity;
        var ProState;
        var ProPostalCode;
        var years;
        var currins;
        var poldetail;
        var lapse_Time;
        var renewpem;
        var hbath;




        if (jsonData[0].spouse === "No") {
            sname = null
            sdob = null
        }

        else {
            sname = (jsonData[0].spouseName.trimStart().trimEnd() !== "") ? jsonData[0].spouseName : null
            sdob = (jsonData[0].spousedob !== "") ? jsonData[0].spousedob : null
        }

        if (jsonData[0].pets === "No") {
            pet = null
            petnum = null
        }

        else {
            pet = (jsonData[0].petType !== "") ? jsonData[0].petType : null
            petnum = (jsonData[0].petNumber !== "") ? jsonData[0].petNumber : null
        }
        if (jsonData[0].poolDetails === "No") {
            pool = null
        }
        else {
            pool = (jsonData[0].divingBoard !== "") ? jsonData[0].divingBoard : null
        }

        if (jsonData[0].purchaseDetails === "No" && jsonData[0].constNew === "Yes" || jsonData[0].purchaseDetails === "Yes" && jsonData[0].constNew === "Yes") {
            roof = null
        }
        else {
            roof = (jsonData[0].roof !== "") ? jsonData[0].roof : null
        }

        if (jsonData[0].purchaseDetails === "No") {
            ClosingDate = null
        }
        else {
            // eslint-disable-next-line
            ClosingDate = (jsonData[0].closingDate !== "") ? jsonData[0].closingDate : null,
                ProStreet = (jsonData[0].proStreet !== "") ? jsonData[0].proStreet : null,
                ProCity = (jsonData[0].proCity !== "") ? jsonData[0].proCity : null,
                ProState = (jsonData[0].proState !== "") ? jsonData[0].proState : null,
                ProPostalCode = (jsonData[0].proPostalCode !== "") ? jsonData[0].proPostalCode : null
        }


        if (jsonData[0].purchaseDetails === "Yes") {
            years = null
        }
        else {
            // eslint-disable-next-line
            years = (jsonData[0].Years !== "") ? jsonData[0].Years : null,
                ProStreet = (jsonData[0].proStreet !== "") ? jsonData[0].proStreet : null,
                ProCity = (jsonData[0].proCity !== "") ? jsonData[0].proCity : null,
                ProState = (jsonData[0].proState !== "") ? jsonData[0].proState : null,
                ProPostalCode = (jsonData[0].proPostalCode !== "") ? jsonData[0].proPostalCode : null
        }

        if (jsonData[0].purchaseDetails === "Yes") {
            if (jsonData[0].constNew === "Yes" || jsonData[0].constNew === "No") {
                currins = null
                poldetail = null
                renewpem = null
            }
        }
        else {
            if (jsonData[0].constNew === "Yes" || jsonData[0].constNew === "No" || jsonData[0].purchaseDetails === "") {
                currins = (jsonData[0].currentInsurance !== "") ? jsonData[0].currentInsurance : null
                poldetail = (jsonData[0].policyExpire !== "") ? jsonData[0].policyExpire : null
                renewpem = (jsonData[0].renewalpremium !== "") ? jsonData[0].renewalpremium : null
            }
        }

        if (jsonData[0].purchaseDetails === "No" || jsonData[0].purchaseDetails === "") {

            if (jsonData[0].constNew === "Yes" && jsonData[0].currentInsurance === 'Yes' || jsonData[0].constNew === "") {
                lapse_Time = null
            }
            else {
                lapse_Time = (jsonData[0].lapseTime !== "") ? jsonData[0].lapseTime : null
            }

            if (jsonData[0].constNew === "Yes" && jsonData[0].currentInsurance === 'No' || jsonData[0].constNew === "") {
                poldetail = null
                renewpem = null
            }
            else {
                poldetail = (jsonData[0].policyExpire !== "") ? jsonData[0].policyExpire : null
                renewpem = (jsonData[0].renewalpremium !== "") ? jsonData[0].renewalpremium : null
            }


            if (jsonData[0].constNew === "No" && jsonData[0].currentInsurance === 'Yes') {
                lapse_Time = null
            }
            else {
                lapse_Time = (jsonData[0].lapseTime !== "") ? jsonData[0].lapseTime : null
            }

            if (jsonData[0].constNew === "No" && jsonData[0].currentInsurance === 'No') {
                poldetail = null
                renewpem = null
            }
            else {
                poldetail = (jsonData[0].policyExpire !== "") ? jsonData[0].policyExpire : null
                renewpem = (jsonData[0].renewalpremium !== "") ? jsonData[0].renewalpremium : null
            }
        }

        else {
            currins = null
            poldetail = null
            lapse_Time = null

        }







        if (jsonData[0].constNew === "No") {
            constype = null
            squareFeet = null
            story = null
            bathroom = null
            hbath = null
        }
        else {
            constype = (jsonData[0].constructType !== "") ? jsonData[0].constructType : null
            squareFeet = (jsonData[0].squareFeet !== "") ? jsonData[0].squareFeet : null
            story = (jsonData[0].stories !== "") ? jsonData[0].stories : null
            bathroom = (jsonData[0].bathroom !== "") ? jsonData[0].bathroom : null
            hbath = (jsonData[0].halfbath !== "") ? jsonData[0].halfbath : null
        }




        if (jsonData[0].preferredDetails === "Just give key points" && currentpage === 3) {
            currins = (jsonData[0].currentInsurance !== "") ? jsonData[0].currentInsurance : null
            if (jsonData[0].currentInsurance === "") {
                setValidationAlert(true)
                return false;

            }
        }

        {
            /**
             * Change for CR
             */
        }

        if (jsonData[0].dateOfBirth > currDate) {

            debugger;

            console.log("inside , iff")

            return false

        }

        debugger

        if (jsonData[0].preferredDetails === "Just give key points" && jsonData[0].purchaseDetails === "No") {
            currins = (jsonData[0].currentInsurance !== "") ? jsonData[0].currentInsurance : null
            if (jsonData[0].currentInsurance === "") {
                setValidationAlert(true)
                return false;

            }
        }




        let uiPayload = {
            PersonalDetails: {
                FirstName: (jsonData[0].firstName.trimStart().trimEnd() !== "") ? jsonData[0].firstName : null,
                LastName: (jsonData[0].lastName.trimStart().trimEnd() !== "") ? jsonData[0].lastName : null,
                person_Combined_Age_c: (jsonData[0].dateOfBirth !== "") ? jsonData[0].dateOfBirth : null,
                Email: (jsonData[0].email !== "") ? jsonData[0].email : null,
                MobilePhone: (jsonData[0].mobilePhone !== "") ? jsonData[0].mobilePhone : null,
                Street: (jsonData[0].street.trimStart().trimEnd() !== "") ? jsonData[0].street : null,
                City: (jsonData[0].city.trimStart().trimEnd() !== "") ? jsonData[0].city : null,
                State: (jsonData[0].state !== "") ? jsonData[0].state : null,
                PostalCode: (jsonData[0].postalCode !== "") ? jsonData[0].postalCode : null,
                Preferred_Contact_Method__c: (jsonData[0].preferredContact !== "") ? jsonData[0].preferredContact : null,
                Complete_Quote__c: (jsonData[0].preferredDetails !== "") ? jsonData[0].preferredDetails : null,
                OwnerId: OwnerId,
                parameterId: parameterId
            },
            SpouseDetails: {
                hasspouse: (jsonData[0].spouse !== "") ? jsonData[0].spouse : null,
                spouse: {
                    Spouse_Name__c: sname,
                    Spouse_Date_of_Birth__c: sdob,
                }
            },
            purchaseDetails: {
                New_Purchase__c: (jsonData[0].purchaseDetails !== "") ? jsonData[0].purchaseDetails : null,
            },
            HomeDetails: {

                Closing_Date__c: ClosingDate,
                Property_Street__c: ProStreet,
                Property_City__c: ProCity,
                Property_State__c: ProState,
                Property_Zip_Postal_Code__c: ProPostalCode,
                Number_of_Years_of_Owning_Home__c: years
            },
            ConstructionDetails: {
                New_Construction__c: (jsonData[0].constNew !== "") ? jsonData[0].constNew : null,
                newConstruction: {
                    Construction_Type__c: constype,
                    Square_Feet__c: squareFeet,
                    Number_of_Stories__c: story,
                    Number_of_Bathrooms__c: bathroom,
                    Year_Roof_replaced__c: roof,
                    Number_of_Half_Bathrooms__c: hbath
                }
            },
            currentInsurance: {
                Current_Coverage__c: currins,
            },
            policyDetails:
            {
                Previous_Policy_Expiration_Date__c: poldetail,
                Lapse_Time__c: lapse_Time,
                Renewal_Premium__c: renewpem
            },

            PoolDetails: {
                Has_Pool__c: (jsonData[0].poolDetails !== "") ? jsonData[0].poolDetails : null,
                Diving_Board_or_Slide__c: pool,
                Has_Trampoline__c: (jsonData[0].trampoline !== "") ? jsonData[0].trampoline : null,
            },

            petDetails: {
                Have_Pets__c: (jsonData[0].pets !== "") ? jsonData[0].pets : null,
                Type_of_Pets__c: pet,
                Number_of_Pets__c: petnum,
            },
            alarmDetails: {
                Monitored_Fire_or_Burglar_Alarm__c: (jsonData[0].securityAlarm !== "") ? jsonData[0].securityAlarm : null,
            }

        }
        if (jsonData[0].preferredDetails === "Just give key points" || (jsonData[0].preferredDetails === "Provide us all the details" && currentpage !== 1)) setLoading(true);


        let data

        debugger;

        data = await postInsuranceQuoteData({ ...uiPayload, currentpage, leadId }, "Home");
        debugger

        if (data?.leadId) setLeadId(data.leadId);

        console.log(data, "after api hit")
        console.log(uiPayload);

        if (jsonData[0].preferredDetails === "Just give key points" || (jsonData[0].preferredDetails === "Provide us all the details" && currentpage !== 1)) window.location.href = '/thankyou';
        else (setLoading(false));

    }

    function disablePastDate() {

        const today = new Date();

        const dd = String(today.getDate()).padStart(2, "0");

        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!

        const yyyy = today.getFullYear();

        return yyyy + "-" + mm + "-" + dd;

    };

    //PC_02  for validating the required fields in each page by using by using switch case*//
    const validation = () => {

        setCounter(1)

        errorcount = 0

        switch (currentpage) {


            case 1:
                if ((jsonData[0].firstName.trim() === "")) {
                    setValidationAlert(true)
                    errorcount++
                }

                if ((jsonData[0].dateOfBirth > currDate)) {
                    setValidationAlert(true)
                    errorcount++
                }


                if ((jsonData[0].lastName.trim() === "")) {
                    setValidationAlert(true)
                    errorcount++
                }

                if ((jsonData[0].email === "")) {

                    setValidationAlert(true)
                    errorcount++
                }
                if (jsonData[0].email !== "") {
                    var mailFormat = /^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)*[\w+-]+\.[a-zA-Z]{2,4}$/;
                    if (!(jsonData[0].email.match(mailFormat))) {
                        setValidationAlert(true)
                        setemailReg(true);
                        errorcount++
                    }
                    if ((jsonData[0].email.match(mailFormat))) {
                        setemailReg(false);

                    }
                }

                if ((jsonData[0].mobilePhone === "")) {
                    setValidationAlert(true)
                    errorcount++
                }

                if (jsonData[0].mobilePhone !== "") {
                    var phoneFormat = /^\d{10}$/;
                    if (!(jsonData[0].mobilePhone.match(phoneFormat))) {
                        setValidationAlert(true)
                        setphonereg(true);
                        errorcount++
                    }
                    if ((jsonData[0].mobilePhone.match(phoneFormat))) {
                        setphonereg(false);
                    }
                }

                if ((jsonData[0].street.trim() === "")) {
                    setValidationAlert(true)
                    errorcount++
                }
                if ((jsonData[0].city.trim() === "")) {
                    setValidationAlert(true)
                    errorcount++
                }
                if ((jsonData[0].state === "")) {
                    setValidationAlert(true)
                    errorcount++
                }

                if (jsonData[0].postalCode === "") {
                    setValidationAlert(true)
                    errorcount++
                }


                //If zipcode is not  equal to empty ,the following regex validation will take place and the state variable setfristnamevalidation is set to true.

                if (jsonData[0].postalCode !== "") {
                    var zipFormat = /^\d{5}?$/;
                    if (!(jsonData[0].postalCode.match(zipFormat))) {
                        setzipcodereg(true);
                        setValidationAlert(true)
                        errorcount++
                    }
                    if ((jsonData[0].postalCode.match(zipFormat))) {
                        setzipcodereg(false);
                    }
                }


                if (jsonData[0].preferredDetails === "") {

                    setValidationAlert(true);
                    errorcount++
                }
                if (errorcount === 0) {
                    setValidationAlert(false);
                    jsonData[0].preferredDetails !== "Just give key points" && setCurrentpage(currentpage + 1);

                    errorcount = 0

                    if (jsonData[0].preferredDetails === "Just give key points") {

                        debugger

                        setSamePageRender(true)
                    }

                    if (crossedpages < 1) {
                        setCrossedPages(1);
                    }


                }

                break;


            //* validating the spouse details page*//

            case 2:

                debugger

                if (jsonData[0].preferredDetails === "Provide us all the details") {

                    if (jsonData[0].spouse === "") {
                        setValidationAlert(true)
                        errorcount++
                    }
                    if (jsonData[0].spouse === "Yes") {


                        if (jsonData[0].spouseName.trim() === "") {
                            setValidationChild(true)
                            errorcount++
                        }
                        if (jsonData[0].spousedob === "") {
                            setValidationChild(true)
                            errorcount++
                        }
                    }
                }
                if (errorcount === 0 && jsonData[0].preferredDetails === "Provide us all the details") {
                    setValidationAlert(false);
                    setCurrentpage(currentpage + 1);

                    if (crossedpages < 2) {
                        setCrossedPages(2);
                    }

                }
                else if (errorcount === 0) {

                    setValidationAlert(false);
                }
                break;

            case 3:

                debugger

                setCurrentpage(currentpage + 1);
                if (crossedpages < 3) {
                    setCrossedPages(3);
                }
                break;

            case 4:

                if (jsonData[0].proPostalCode !== "") {

                    let zipFormat = /^\d{5}?$/;
                    if (!(jsonData[0].proPostalCode.match(zipFormat))) {
                        setValidationChild(true);

                        errorcount++
                    }
                    else {
                        if ((jsonData[0].proPostalCode.match(zipFormat))) {
                            setValidationChild(false);
                        }

                    }
                }
                if (errorcount === 0) {
                    setValidationChild(false);
                    setCurrentpage(currentpage + 1);
                    setValidcross(false);



                    if (crossedpages < 4) {
                        setCrossedPages(4);
                    }
                } if (errorcount !== 0) {
                    setValidcross(true);
                }
                break;

            case 5:


                if (jsonData[0].purchaseDetails === "Yes") {
                    if (jsonData[0].constNew === "Yes" || jsonData[0].constNew === "") {

                        if (jsonData[0].constNew === "Yes") {
                            if (jsonData[0].constNew === "Yes" && jsonData[0].purchaseDetails === "Yes") {
                                //if both 3 and 5 yes ;
                                setCurrentpage(currentpage + 4)
                            } else {
                                //directly go to 9th page if it is yes or empty in 5th page
                                setCurrentpage(currentpage + 1);
                            }
                        } else if (jsonData[0].constNew === "") {
                            setCurrentpage(currentpage + 4);
                        }
                    } else if (jsonData[0].constNew === "No") {
                        if (jsonData[0].purchaseDetails === "Yes" && jsonData[0].constNew === "No") {
                            setCurrentpage(currentpage + 1);
                        } else {
                            setCurrentpage(currentpage + 1);
                        }
                    }
                } else if (jsonData[0].purchaseDetails === "No" || jsonData[0].purchaseDetails === "") {
                    if (jsonData[0].constNew === "Yes" || jsonData[0].constNew === "") {
                        setCurrentpage(currentpage + 2)
                    } else if (jsonData[0].constNew === "No") {
                        setCurrentpage(currentpage + 1);
                    }
                }

                if (crossedpages < 5) {
                    setCrossedPages(5);
                }
                break;

            case 6:

                if (jsonData[0].roof !== "") {
                    if (jsonData[0].roof.length < 4) {
                        setValidationAlert(true)
                        errorcount++
                    }
                }
                if (errorcount === 0) {
                    setValidationAlert(false);

                    if (jsonData[0].constNew === "No" && jsonData[0].purchaseDetails === "Yes") {
                        setCurrentpage(currentpage + 3);
                    } else {
                        setCurrentpage(currentpage + 1);
                    }
                }
                if (crossedpages < 6) {
                    setCrossedPages(6);
                }
                break;
            case 7:


                if (jsonData[0].currentInsurance === "") {
                    setValidationAlert(true)
                    errorcount++
                }

                if (errorcount === 0) {
                    setValidationAlert(false);
                    setCurrentpage(currentpage + 1);
                }


                if ((jsonData[0].constNew === "Yes" || jsonData[0].constNew === "") && (errorcount === 0)) {

                    if (crossedpages < 6) {
                        setCrossedPages(6);
                    }

                } else if ((jsonData[0].constNew === "No") && (errorcount === 0)) {
                    if (crossedpages < 7) {
                        setCrossedPages(7)
                    }
                }
                break;
            case 8:

                setCurrentpage(currentpage + 1);

                if (jsonData[0].constNew === "Yes" || jsonData[0].constNew === "") {
                    if (crossedpages < 7) {
                        setCrossedPages(7);
                    }
                }
                else {
                    if (crossedpages < 8) {
                        setCrossedPages(8);
                    }



                }
                break;
            case 9:

                setCurrentpage(currentpage + 1);



                if (jsonData[0].purchaseDetails === "Yes") {

                    if (jsonData[0].constNew === "Yes" || jsonData[0].constNew === "") {

                        if (jsonData[0].constNew === "Yes" && jsonData[0].purchaseDetails === "Yes") {
                            if (crossedpages < 6) {
                                setCrossedPages(6);
                            }
                        } else if (jsonData[0].constNew === "" && jsonData[0].purchaseDetails === "Yes") {
                            if (crossedpages < 6) {
                                setCrossedPages(6);
                            }
                        }
                        else {
                            if (crossedpages < 8) {
                                setCrossedPages(8);
                            }
                        }

                    }
                    else if (jsonData[0].constNew === "No") {

                        if (jsonData[0].constNew === "No" && jsonData[0].purchaseDetails === "Yes") {
                            if (crossedpages < 7) {
                                setCrossedPages(7);
                            }
                        } else {
                            if (crossedpages < 8) {
                                setCrossedPages(8);
                            }
                        }

                    }
                } else if (jsonData[0].purchaseDetails === "No" || jsonData[0].purchaseDetails === "") {

                    if (jsonData[0].constNew === "Yes" || jsonData[0].constNew === "") {
                        if (crossedpages < 8) {
                            setCrossedPages(8);
                        }
                    } else if (jsonData[0].constNew === "No") {
                        if (crossedpages < 9) {
                            setCrossedPages(9);
                        }
                    }

                }

                else {
                    if (crossedpages < 9) {
                        setCrossedPages(9);
                    }
                }
                break;
            case 10:
                setCurrentpage(currentpage + 1);

                if (jsonData[0].purchaseDetails === "Yes") {
                    if (jsonData[0].constNew === "Yes" || jsonData[0].constNew === "") {
                        if (jsonData[0].constNew === "Yes" && jsonData[0].purchaseDetails === "Yes") {
                            if (crossedpages < 7) {
                                setCrossedPages(7);
                            }
                        } else if (jsonData[0].constNew === "" && jsonData[0].purchaseDetails === "Yes") {
                            if (crossedpages < 7) {
                                setCrossedPages(7);
                            }
                        } else {
                            if (crossedpages < 9) {
                                setCrossedPages(9);
                            }
                        }
                    } else if (jsonData[0].constNew === "No") {

                        if (jsonData[0].constNew === "No" && jsonData[0].purchaseDetails === "Yes") {
                            if (crossedpages < 8) {
                                setCrossedPages(8);
                            }
                        } else {
                            if (crossedpages < 9) {
                                setCrossedPages(9);
                            }
                        }

                    }
                } else if (jsonData[0].purchaseDetails === "No" || jsonData[0].purchaseDetails === "") {
                    if (jsonData[0].constNew === "Yes" || jsonData[0].constNew === "") {
                        if (crossedpages < 9) {
                            setCrossedPages(9);
                        }
                    } else if (jsonData[0].constNew === "No") {
                        if (crossedpages < 10) {
                            setCrossedPages(10);
                        }
                    }
                }
                else {
                    if (crossedpages < 10) {
                        setCrossedPages(10);
                    }
                }
                break;
        }
    }

    //This function is invoked when user enters any values in any fields
    function onHandleChange(e: any) {

        debugger;
        //these variable(name and value) are used to store name and value from the tag that call onHandleChange function;
        //const { name, value } = e.target;
        let name = "", value = ""



        let newJsonData: any = [...jsonData];
        function dotcheck(value: string) {

            var count = 0;
            var valuearr = value.split('');
            for (let i = 0; i < valuearr.length; i++) {
                if (valuearr[i] === '.') {
                    count++
                }
            }
            if (count > 1) {
                return true
            }
        }
        // console.log(e);
        if (e.target) {


            name = e.target.name;

            value = e.target.value;

            {
                /**
                 * Changes for Date in CR
                 */
            }



            // if (name === 'dateOfBirth' && value <= currDate) {

            //     setValidationAlert(false);
            //     errorcount = 0
            // }
            // else {

            //     setValidationAlert(true);
            //     errorcount++
            // }

            if (e.target.name === "postalCode") {

                value = value.replace(/[^0-9]/g, '');

            } if (e.target.name === "bathroom" || e.target.name === "halfbath" || e.target.name === "renewalpremium") {
                value = value.replace(/[^0-9]/g, '');
            }
            if (e.target.name === "stories") {
                value = value.replace(/[^0-9]/g, '');
            }
            if (e.target.name === "petNumber") {
                value = value.replace(/[^0-9]/g, '');
            }
            if (e.target.name === "squareFeet") {
                if (jsonData[0].squareFeet.includes('.')) {
                    if (dotcheck(value)) {
                        value = value.replace(value, jsonData[0].squareFeet)
                    }
                    else {
                        value = value.replace(/[^0-9\.]/g, '')
                    }
                }
                else {
                    value = value.replace(/[^0-9\.]/g, '')
                }
            }

            if (e.target.name === "roof") {
                value = value.replace(/[^0-9]/g, '');
            }
            if (e.target.name === "proPostalCode") {
                value = value.replace(/[^0-9]/g, '');
            }
            if (e.target.name === "Years") {
                value = value.replace(/[^0-9]/g, '');
            }




            if (e.target.name === "lapseTime") {
                value = value.replace(/[^0-9]/g, '');

            }
            else if (name === "email") {

                let emailRegex = /^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)*[\w+-]+\.[a-zA-Z]{2,4}$/;

                if (!(value.match(emailRegex))) {

                    setemailReg(true);

                }
                else {
                    setemailReg(false)
                }

            }


            else if (name === "postalCode") {


                if ((value).length < 5) {

                    setzipcodereg(true);

                }

                else {
                    setzipcodereg(false)
                }


            }


            else if (name === "proPostalCode") {


                if (validcross === true) {
                    if ((value).length < 5) {

                        setValidationChild(true);

                    }

                    else {
                        setValidationChild(false)
                    }
                }
            }




            console.log(value, "value from else")

            newJsonData[0][name] = value;

        }

        else {

            name = "mobilePhone"

            value = e.value;

            console.log(value, "value from if")

            newJsonData[0][name] = value;

            let mobileRegex = /^\d{10}$/;
            // console.log(jsonData[0].mobilePhone, "Postal")

            if (!(value.match(mobileRegex))) {

                //console.log(" postal !!!")
                setphonereg(true);


            }
            else {
                setphonereg(false)
            }
        }


        //setting value to the particular key in the json;

        //setting the jsonData state variable;
        setJsonData(newJsonData);

    }

    // function events(e:any,maxLength:number){




    let totalPage;

    if (jsonData[0].preferredDetails === "Provide us all the details") {

        totalPage = ((jsonData[0].purchaseDetails === "" || jsonData[0].purchaseDetails === "No") && (jsonData[0].constNew === "" || jsonData[0].constNew === "Yes")) ? 10 : ((jsonData[0].purchaseDetails === "" || jsonData[0].purchaseDetails === "No") && (jsonData[0].constNew === "No")) ? 11 : (jsonData[0].purchaseDetails === "Yes" && (jsonData[0].constNew === "")) ?
            8 : (jsonData[0].purchaseDetails === "Yes" && (jsonData[0].constNew === "Yes")) ? 8 : (jsonData[0].purchaseDetails === "Yes" && (jsonData[0].constNew === "No")) ? 9 : null;
    } else if (jsonData[0].preferredDetails === "Just give key points") {
        totalPage = (jsonData[0].purchaseDetails === "No") ? 3 : 2
    }




    let pageTopic;

    if (jsonData[0].preferredDetails === "Provide us all the details") {

        if (jsonData[0].purchaseDetails === "Yes") {
            if (jsonData[0].constNew === "Yes" || jsonData[0].constNew === "") {
                pageTopic = pagesforperviousno

            }
            else {
                pageTopic = pagesforpreviousyes
            }
        }


        else {
            if (jsonData[0].constNew === "Yes" || jsonData[0].constNew === "") {
                pageTopic = pagesForProvideUsAlldetailsYes

            }
            else {
                pageTopic = pagesForProvideUsAlldetailsNo
            }
        }
    }
    else {
        if (jsonData[0].preferredDetails === "Just give key points") {
            pageTopic = jsuTGive
        }
    }

    let currentpg1;
    if (jsonData[0].preferredDetails === "Provide us all the details") {
        if (jsonData[0].purchaseDetails === "Yes") {
            if (jsonData[0].constNew === "Yes" || jsonData[0].constNew === "") {
                if (currentpage >= 8) {
                    currentpg1 = currentpage - 3
                }

                else {
                    currentpg1 = currentpage
                }

            }
            else if (jsonData[0].constNew === "No") {
                if (currentpage <= 6) {
                    currentpg1 = currentpage
                }
                else if (currentpage >= 9) {
                    currentpg1 = currentpage - 2
                }
                else {
                    currentpg1 = currentpage
                }
            }
            else {
                currentpg1 = currentpage
            }
        }
        else {
            if (jsonData[0].constNew === "No" && currentpage >= 7) {
                currentpg1 = currentpage
            }
            else if ((jsonData[0].constNew === "Yes" || jsonData[0].constNew === "") && currentpage >= 7) {
                currentpg1 = currentpage - 1
            }
            else {
                currentpg1 = currentpage
            }

        }

    }
    else {
        currentpg1 = currentpage
    }


    let flowName = "";




    if (jsonData[0].preferredDetails === "Provide us all the details") {

        flowName = ((jsonData[0].purchaseDetails === "" || jsonData[0].purchaseDetails === "No") && (jsonData[0].constNew === "" || jsonData[0].constNew === "Yes")) ? "purchageNoConstYes" : ((jsonData[0].purchaseDetails === "" || jsonData[0].purchaseDetails === "No") && (jsonData[0].constNew === "No")) ? "purchageNoConstNo" : (jsonData[0].purchaseDetails === "Yes" && (jsonData[0].constNew === "")) ?
            "purchaseYesConstNewYes" : (jsonData[0].purchaseDetails === "Yes" && (jsonData[0].constNew === "Yes")) ? "purchaseYesConstNewYes" : (jsonData[0].purchaseDetails === "Yes" && (jsonData[0].constNew === "No")) ? "purchaseYesConstNo" : "justgive";
    } else if (jsonData[0].preferredDetails === "Just give key points") {
        flowName = (jsonData[0].purchaseDetails === "No") ? "pursaceNo" : "pursceYes"
    }


    console.log(flowName, "Flow")

    /*
    
    purchageNoConstYes : 10
purchageNoConstNo:11
purchaseYesConstEmpty:10
purchaseYesConstNewYes:9
purchaseYesConstNo:8
pursaceNo:3
pursceYes:2
    */

    let arrayToSend: string[] = [];

    if (flowName === "purchageNoConstYes") {

        arrayToSend = pagesForProvideUsAlldetailsYes;

        if (crossedpages == 9 && currentpage == 10) setCrossedPages(11);

    } else if (flowName === "purchageNoConstNo") {
        arrayToSend = pagesForProvideUsAlldetailsNo

        if (crossedpages == 10 && currentpage == 10) setCrossedPages(12);

    } else if (flowName === "purchaseYesConstEmpty") {
        arrayToSend = pagesForProvideUsAlldetailsYes;

    } else if (flowName === "purchaseYesConstNewYes") {
        arrayToSend = pagesforperviousno

    } else if (flowName === "purchaseYesConstNo") {
        arrayToSend = pagesforpreviousyes
    }

    else if (flowName === "pursaceNo") {
        arrayToSend = jsuTGive

    } else if (flowName === "pursceYes") {
        arrayToSend = justGives

    }




    const fName = "Home Insurance"

    //const tTwopages = 3
    const Info = {

        pageFlow: flowName,

        Name: fName,

        totalPages: totalPage,

        pgTopic: pageTopic,

        currentPg: currentpg1,

        previousStepArry: arrayToSend,

        crossedpage: crossedpages


    }
    console.log(currentpage, "currentpage")


    /*New Page Flow to header */

    console.log(totalPage);

    console.log(currentpage, "currentpage", crossedpages, "crosssedpages")






    //pc_04 function for homepage  to provide user details 
    //*home insurance page  to display user personal details fields*//
    const homePage = () => {

        return (

            <React.Fragment>
                <div className="col-md-12 border-bottom mt-3">
                    <label className="font20 font-primary font-semibold font-head pb-2">General Info</label>
                </div>


                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6 mt-3">
                            <label htmlFor="label1" className="form-label font14 font-semibold">First Name*</label>
                            <input type="text" className="form-control pb-2 pt-2" id="label1" maxLength={40} name="firstName" value={jsonData[0].firstName}
                                onChange={(e) => { onHandleChange(e) }} />

                            <label className="text-danger font14 w-100">{(ValidationALert === true && jsonData[0].firstName.trim() === "") ? "Please enter the First Name" : null}</label>

                            {/* <input type="text" className="form-control" id="label1"  /> */}
                        </div>
                        <div className="col-md-6 mt-3 single-column-margin">
                            <label htmlFor="label2" className="form-label font14 font-semibold">Last Name*</label>
                            <input type="text" className="form-control pb-2 pt-2" id="label2" maxLength={80} name="lastName" value={jsonData[0].lastName} onChange={(e) => { onHandleChange(e) }} />
                            <label className="text-danger font14 w-100">{(ValidationALert === true && jsonData[0].lastName.trim() === "") ? "Please enter the Last Name" : null}</label>
                            {/* <input type="text" className="form-control" id="label2" /> */}
                        </div>
                        <div className="col-md-12 mt-3 date-picker single-column-margin">
                            <label className="form-label font14 font-semibold">Date of Birth</label>
                            <input type="date" className="form-control font14 text-uppercase pb-2 pt-2 datecontrol" name="dateOfBirth" value={jsonData[0].dateOfBirth} onChange={(e) => { onHandleChange(e) }} max={disablePastDate()} onFocus={(e) => setdateFieldFocused(true)}
                                placeholder={dateFieldFocused ? "" : "MM/DD/YYYY"} />
                            <img src={calender} alt="calender-icon" className="calender-icon "></img>
                            {
                                currDate < jsonData[0].dateOfBirth ?
                                    <label className="text-danger font14 w-100">{(jsonData[0].dateOfBirth) ? "Please enter valid Date of Birth" : null}</label> : null
                            }

                        </div>
                        {/* General Info Ends Here */}
                        {/* Communication Info Starts Here */}
                        <div className="col-md-12 mt-4">
                            <label className="font20 font-primary font-semibold font-head border-bottom w-100 pb-2">Communication Info</label>
                        </div>
                        <div className="col-md-12 mt-3 single-column-margin">
                            <label htmlFor="label4" className="form-label font14 font-semibold">Email*</label>
                            <input type="text" className="form-control pb-2 pt-2" id="label4" maxLength={80} name="email" value={jsonData[0].email}
                                onChange={(e) => { onHandleChange(e) }} />
                            <label className="text-danger font14 w-100">{(ValidationALert === true && jsonData[0].email === "") ? "Please enter an Email" : (ValidationALert === true && emailReg === true) ? "Please enter a valid Email" : null}</label>

                        </div>
                        <div className="col-md-12 mt-3 single-column-margin">
                            <label htmlFor="mobilephone" className="form-label font14 font-semibold">Mobile Phone*</label>
                            {/* {jsonData[0].mobilePhone.length < 10 ? <PhoneInput type={"tel"} className="form-control pb-2 pt-2" value={jsonData[0].mobilePhone} onValueChange={(e) => { onHandleChange(e) }} id="mobilephone" placeholder='XXXXXXXXXX' /> :
                                <PhoneInput type={"tel"} className="form-control pb-2 pt-2" format={"(###)-###-####"} value={jsonData[0].mobilePhone} onValueChange={(e) => { onHandleChange(e) }} id="mobilephone" placeholder='XXXXXXXXXX' />} */}

                            <PhoneInput
                                className="form-control pb-2 pt-2"
                                format={
                                    (jsonData[0].mobilePhone.length < 10) ?
                                        "##########" : '(###) ###-####'
                                }
                                value={jsonData[0].mobilePhone}
                                onValueChange={(e) => { onHandleChange(e) }}
                                id="mobilephone"
                                placeholder='XXXXXXXXXX' />



                            <label className="text-danger font14 w-100">{(ValidationALert === true && jsonData[0].mobilePhone === "") ? "Please enter the  Mobile Number" : (ValidationALert === true && phonereg === true) ? "Please enter a valid Mobile Number " : null}</label>


                        </div>
                        <div className="col-md-12 mt-3 single-column-margin">
                            <label htmlFor="label6" className="form-label font14 font-semibold">Street*</label>
                            <textarea className="form-control text-area-input" id="label6" maxLength={120} rows={1} defaultValue={""} name="street" value={jsonData[0].street}
                                onChange={(e) => { onHandleChange(e) }} />
                            <label className="text-danger font14 w-100">{(ValidationALert == true && jsonData[0].street.trim() === "") ? "Please enter the Street" : null}</label>
                        </div>
                        <div className="col-md-12 mt-3 single-column-margin">
                            <label htmlFor="label7" className="form-label font14 font-semibold">City*</label>
                            <input type="text" className="form-control pb-2 pt-2" id="label7" maxLength={40} name="city" value={jsonData[0].city}
                                onChange={(e) => { onHandleChange(e) }} />
                            <label className="text-danger font14 w-100">{(ValidationALert === true && jsonData[0].city.trim() === "") ? "Please enter the City" : null}</label>

                        </div>
                        <div className="col-md-6 mt-3 single-column-margin">
                            <label htmlFor="stateper" className="form-label font14 font-semibold">State/Province*</label>

                            <select className="form-select pb-2 pt-2" name="state" id="stateper" value={jsonData[0].state} onChange={(e) => onHandleChange(e)}>
                                <option value="">Select State/Province</option>
                                <option value="AK">AK</option>
                                <option value="AL">AL</option>
                                <option value="AR">AR</option>
                                <option value="AZ">AZ</option>
                                <option value="CA">CA</option>
                                <option value="CO">CO</option>
                                <option value="CT">CT</option>
                                <option value="DE">DE</option>
                                <option value="FL">FL</option>
                                <option value="GA">GA</option>
                                <option value="HI">HI</option>
                                <option value="IA">IA</option>
                                <option value="ID">ID</option>
                                <option value="IL">IL</option>
                                <option value="IN">IN</option>
                                <option value="KS">KS</option>
                                <option value="KY">KY</option>
                                <option value="LA">LA</option>
                                <option value="MA">MA</option>
                                <option value="MD">MD</option>
                                <option value="ME">ME</option>
                                <option value="MI">MI</option>
                                <option value="MN">MN</option>
                                <option value="MO">MO</option>
                                <option value="MS">MS</option>
                                <option value="MT">MT</option>
                                <option value="NC">NC</option>
                                <option value="ND">ND</option>
                                <option value="NE">NE</option>
                                <option value="NH">NH</option>
                                <option value="NJ">NJ</option>
                                <option value="NM">NM</option>
                                <option value="NV">NV</option>
                                <option value="NY">NY</option>
                                <option value="OH">OH</option>
                                <option value="OK">OK</option>
                                <option value="OR">OR</option>
                                <option value="PA">PA</option>
                                <option value="RI">RI</option>
                                <option value="SC">SC</option>
                                <option value="SD">SD</option>
                                <option value="TN">TN</option>
                                <option value="TX">TX</option>
                                <option value="UT">UT</option>
                                <option value="VA">VA</option>
                                <option value="VT">VT</option>
                                <option value="WA">WA</option>
                                <option value="WI">WI</option>
                                <option value="WV">WV</option>
                                <option value="WY">WY</option>
                            </select>

                            <label className="text-danger font14 w-100">{(ValidationALert === true && jsonData[0].state == "") ? "Please select the State/Province " : null}</label>
                            {/* <input type="email" className="form-control" id="label8"  /> */}
                        </div>
                        <div className="col-md-6 mt-3 single-column-margin">
                            <label htmlFor="label9" className="form-label font14 font-semibold">Zip/Postal Code*</label>

                            <input type="text" className="form-control pb-2 pt-2" id="label9" name="postalCode" maxLength={5} value={jsonData[0].postalCode}
                                onChange={(e) => { onHandleChange(e) }}
                            />
                            <label className="text-danger font14 w-100">{(ValidationALert == true && jsonData[0].postalCode == "") ? "Please enter the Zip/Postal Code" : (ValidationALert == true && jsonData[0].postalCode.length < 5) ? "Please enter the valid Zip/Postal Code" : null}
                            </label>

                        </div>
                        <div className="col-md-12 mt-3 single-column-margin">

                            <label htmlFor="label10" className="form-label w-100 font14 font-semibold">Preferred Contact Method</label>


                            <input
                                type="radio"
                                className="btn-check radio-checked" id="Phone"
                                autoComplete="off" name="preferredContact"
                                value="Phone"
                                checked={jsonData[0].preferredContact === "Phone"}
                                onClick={(e) => { onHandleChange(e) }} />

                            <label className="btn btn-primary button-radio font14 font-bold" htmlFor="Phone">Phone</label>


                            <input type="radio" className="btn-check radio-checked" id="Email" autoComplete="off" name="preferredContact"
                                value="Email" onClick={(e) => { onHandleChange(e) }}
                                checked={jsonData[0].preferredContact === "Email"} />

                            <label className="btn btn-primary ms-2 button-radio font14 font-bold" htmlFor="Email">Email</label>


                            <input type="radio" className="btn-check radio-checked" id="Text" autoComplete="off" name="preferredContact"
                                value="Text" onClick={(e) => { onHandleChange(e) }}
                                checked={jsonData[0].preferredContact === "Text"} />


                            <label className="btn btn-primary ms-2 button-radio font14 font-bold" htmlFor="Text">Text</label>
                            {
                                /**
                                 * Commented this for CR
                                 */
                            }

                            {/* <input type="radio" className="btn-check radio-checked" id="Mail" autoComplete="off" name="preferredContact"
                                value="Mail" onClick={(e) => { onHandleChange(e) }}
                                checked={jsonData[0].preferredContact === "Mail"} />

                            <label className="btn btn-primary ms-2 button-radio font14 font-bold" htmlFor="Mail">Mail</label> */}
                        </div>

                        <div className="col-md-12 mt-4">
                            {/* <input
                                type="checkbox"
                                name="preferredDetails"
                                // className="btn-check radio-checked px-4"
                                id="Provide us all the details"
                                autoComplete="off"
                                value={jsonData[0].preferredDetails}
                                onChange={(e) => {
                                    if (e.target.checked) setJsonData(data => ([{ ...data[0], preferredDetails: 'Provide us all the details' }]));
                                    else setJsonData(data => ([{ ...data[0], preferredDetails: 'Just give key points' }]));
                                    setSamePageRender(false);
                                }} />
                            <label htmlFor="Provide us all the details" className="form-label w-100 font14 font-semibold border-top pt-3 W-100 ">Would you like to?*</label> */}
                            <div className="form-check form-switch border-top pt-3 W-100 ps-0">
                                <label className="form-check-label me-2 font14 font-semibold" htmlFor="flexSwitchCheckDefault">Would you like to provide us all the details?</label>
                                <input className="form-check-input ms-0 float-none" type="checkbox" id="flexSwitchCheckDefault"
                                    name="preferredDetails"
                                    autoComplete="off"
                                    value={jsonData[0].preferredDetails}
                                    onChange={(e) => {
                                        if (e.target.checked) setJsonData(data => ([{ ...data[0], preferredDetails: 'Provide us all the details' }]));
                                        else setJsonData(data => ([{ ...data[0], preferredDetails: 'Just give key points' }]));
                                        setSamePageRender(false);
                                    }} />

                            </div>
                            {
                                //commenting the code for CR change
                            }
                            {/* <input
                                type="radio"
                                name="preferredDetails"
                                className="btn-check radio-checked px-4"
                                id="Provide us all the details"
                                autoComplete="off"
                                value="Provide us all the details"
                                checked={jsonData[0].preferredDetails === "Provide us all the details"} onChange={(e) => { onHandleChange(e); setSamePageRender(false) }} />
                            <label className="btn btn-primary button-radio font14 font-bold px-4 button-radio2" htmlFor="Provide us all the details">Provide us all the details</label>
                            <input
                                type="radio"
                                name="preferredDetails"
                                className="btn-check radio-checked px-4"
                                id="Just give key points"
                                autoComplete="off"
                                checked={jsonData[0].preferredDetails === "Just give key points"}
                                value="Just give key points"
                                onChange={(e) => {
                                    onHandleChange(e); setSamePageRender(true);
                                }} />
                            <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4 button-radio2 button-just" htmlFor="Just give key points">Just give key points</label>
                            <label className="text-danger font14 w-100">{(ValidationALert == true && jsonData[0].preferredDetails == "") ? "Please enter complete quote !" : null}</label> */}
                        </div>


                    </div>
                </div>
                {/* Footer */}

                {/**!samePageRender || */}

                <div className="col-md-12 mt-4 mb-4">
                    {
                        jsonData[0].preferredDetails != 'Just give key points' ? <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button mb-4" onClick={() => {
                            validation();
                            if (!errorcount) postData()
                        }}>Submit and Continue<img src={nextLogo} alt="arrowright" className="ms-2" /></button> : null
                    }

                </div>

                {
                    jsonData[0].preferredDetails == 'Just give key points' ? <React.Fragment>
                        <div className="col-md-12 mt-8">

                            {/* <div className="col-md-12 mt-3">
                                <div className="alert alert-light d-flex align-items-center " role="alert">
                                    <img src={infoicon} alt="info-icon" />
                                    <div className="font14 ms-2 font-primary">
                                        We get it! You're busy, just give us the basics and let us know the best way and
                                        time to get in touch with you and we will make this as easy as possible
                                    </div>
                                </div>
                            </div> */}

                            {/** Commented for CR update*/}

                            {/* <label htmlFor="Provide us all the details" className="form-label w-100 font14 font-semibold  ">Is this a new purchase?</label>
                            <input type="radio" className="btn-check radio-checked" id="label10" autoComplete="off" name="purchaseDetails" value='No' checked={jsonData[0].purchaseDetails === 'No'} onChange={(e) => { onHandleChange(e) }} />
                            <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor="label10">No</label>
                            <input type="radio" className="btn-check radio-checked" id="label11" autoComplete="off" name="purchaseDetails" value='Yes' checked={jsonData[0].purchaseDetails === 'Yes'} onChange={(e) => { onHandleChange(e); jsonData[0].currentInsurance = ""; setCurrInsuranceValidation(false); }} />
                            <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor="label11">Yes</label> */}
                        </div>
                        {/* Footer */}
                        <div className="col-md-12 mt-4 mb-4">
                            {/* {
                                !samePageRender && <button type="button" className="btn btn-outline-secondary font14 mt-4 px-5 previous-button mobile-button" onClick={() => backButtonClick()}>
                                    <span className="previous-button-arrow me-1">
                                        <img src={previouslogo} alt="auto" />
                                        <img src={previousLogo} alt="auto" />
                                    </span>
                                    Previous</button>
                            } */}

                            {(jsonData[0].purchaseDetails === "Yes" || jsonData[0].purchaseDetails === "") ?
                                <button type="button"
                                    className="btn btn-primary mt-4 mb-4 primary-button border-0 font14 home-button px-5 float-end mobile-button"
                                    onClick={() => {
                                        validation();
                                        if (!errorcount) postData()
                                    }}>Submit to an Agent Now</button> : null}

                        </div>
                        {(Loading == true && (jsonData[0].purchaseDetails === "Yes" || jsonData[0].purchaseDetails === "")) ? <div id="root">

                            <h1>Submitting Your Request</h1>

                            <h3>Please Wait for few seconds....</h3>

                            <div className="loader-wrapper">

                                <div className="loader"><span></span> <span></span> <span></span> <span></span> <span></span> </div>

                            </div>

                        </div> : null}

                    </React.Fragment> : null
                }

                {
                    jsonData[0].preferredDetails === "Just give key points" && jsonData[0].purchaseDetails === "No" ?

                        <React.Fragment>
                            <div className="col-md-12 mt-4">
                                <label htmlFor="Provide us all the details" className="form-label w-100 font14 font-semibold ">Is your home currently insured?*</label>
                                <input type="radio" className="btn-check radio-checked" id="anyone-else-yes" autoComplete="off" name="currentInsurance" value='Yes' checked={jsonData[0].currentInsurance === 'Yes'} onChange={(e) => { onHandleChange(e); setCurrInsuranceValidation(false); }} />
                                <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor="anyone-else-yes">Yes</label>
                                <input type="radio" className="btn-check radio-checked" id="anyone-else-no" autoComplete="off" name="currentInsurance" value='No' checked={jsonData[0].currentInsurance === 'No'} onChange={(e) => { onHandleChange(e); setCurrInsuranceValidation(false); }} />
                                <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor="anyone-else-no">No</label>
                                <label className="text-danger font14 w-100">{currInsuranceValidation ? "Please select your choice" : null}</label>
                            </div>


                            <div className="col-md-12 mt-4 mb-4">

                                {(jsonData[0].preferredDetails === "Just give key points") ? <button type="button" className="btn btn-primary mt-4 mb-4 primary-button border-0 font14 home-button px-5 float-end mobile-button" onClick={() => {
                                    validation();

                                    if (!jsonData[0].currentInsurance) return setCurrInsuranceValidation(true)
                                    else setCurrInsuranceValidation(false)

                                    if (!currInsuranceValidation && !errorcount) postData()
                                }}>Submit to an Agent Now</button>
                                    : (jsonData[0].preferredDetails === "Provide us all the details") ?
                                        <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button" onClick={() => validation()}>Next Step<img src={nextLogo} className="ms-2" /></button> : null}
                            </div>
                            {Loading == true ? <div id="root">

                                <h1>Submitting Your Request</h1>

                                <h3>Please Wait for few seconds....</h3>

                                <div className="loader-wrapper">

                                    <div className="loader"><span></span> <span></span> <span></span> <span></span> <span></span> </div>

                                </div>

                            </div> : null}
                        </React.Fragment> : null
                }

            </React.Fragment>
        );
    }




    //PC_05 function  to bind the fields  if user selects all   diplays spouse and if no displays purchase  
    const PurchaseSpouse = () => {
        {
            /**
             * Commented for the CR
             */
        }
        // if (jsonData[0].preferredDetails === "Just give key points") {
        //     return (
        //         <React.Fragment>
        //             <div className="col-md-12 mt-4">

        //                 <div className="col-md-12 mt-3">
        //                     <div className="alert alert-light d-flex align-items-center " role="alert">
        //                         <img src={infoicon} alt="info-icon" />
        //                         <div className="font14 ms-2 font-primary">
        //                             We get it! You're busy, just give us the basics and let us know the best way and
        //                             time to get in touch with you and we will make this as easy as possible
        //                         </div>
        //                     </div>
        //                 </div>

        //                 <label htmlFor="Provide us all the details" className="form-label w-100 font14 font-semibold  ">Is this a new purchase?</label>
        //                 <input type="radio" className="btn-check radio-checked" id="label10" autoComplete="off" name="purchaseDetails" value='No' checked={jsonData[0].purchaseDetails === 'No'} onChange={(e) => { onHandleChange(e) }} />
        //                 <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor="label10">No</label>
        //                 <input type="radio" className="btn-check radio-checked" id="label11" autoComplete="off" name="purchaseDetails" value='Yes' checked={jsonData[0].purchaseDetails === 'Yes'} onChange={(e) => { onHandleChange(e) }} />
        //                 <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor="label11">Yes</label>
        //             </div>
        //             {/* Footer */}
        //             <div className="col-md-12 mt-4 mb-4">
        //                 <button type="button" className="btn btn-outline-secondary font14 mt-4 px-5 previous-button mobile-button" onClick={() => backButtonClick()}>
        //                     <span className="previous-button-arrow me-1">
        //                         <img src={previouslogo} alt="auto" />
        //                         <img src={previousLogo} alt="auto" />
        //                     </span>
        //                     Previous</button>
        //                 {(jsonData[0].purchaseDetails === "Yes" || jsonData[0].purchaseDetails === "") ?
        //                     <button type="button"
        //                         className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button"
        //                         onClick={() => postData()}>Request Quote</button> : (jsonData[0].purchaseDetails === "No") ?
        //                         <button type="button"
        //                             className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button"
        //                             onClick={() => validation()}>Next Step<img src={nextLogo} className="ms-2" /></button> : null}

        //             </div>
        //             {Loading == true ? <div id="root">

        //                 <h1>Submitting Your Request</h1>

        //                 <h3>Please Wait for few seconds....</h3>

        //                 <div className="loader-wrapper">

        //                     <div className="loader"><span></span> <span></span> <span></span> <span></span> <span></span> </div>

        //                 </div>

        //             </div> : null}

        //         </React.Fragment>
        //     );

        // }
        if (jsonData[0].preferredDetails === "Provide us all the details")

            return (
                <React.Fragment>
                    <div className="col-md-12 mt-4">
                        <label htmlFor="Provide us all the details" className="form-label w-100 font14 font-semibold ">Do you have a spouse?*</label>
                        <input type="radio" className="btn-check radio-checked" id="label10" autoComplete="off" name="spouse" value='No' checked={jsonData[0].spouse === 'No'} onChange={(e) => { onHandleChange(e) }} />
                        <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor="label10">No</label>
                        <input type="radio" className="btn-check radio-checked" id="label11" autoComplete="off" name="spouse" value='Yes' checked={jsonData[0].spouse === 'Yes'} onChange={(e) => { onHandleChange(e) }} />
                        <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor="label11">Yes</label>
                        <label className="text-danger font14 w-100">{ValidationALert == true && jsonData[0].spouse == "" ? "Please select your choice" : null} </label>
                    </div>

                    {jsonData[0].spouse === "Yes" ?
                        <div>
                            <label htmlFor="label9" className="form-label font14 font-semibold">Spouse Name*</label>
                            <input type="text" className="form-control pb-2 pt-2" id="label9" maxLength={120} name="spouseName" value={jsonData[0].spouseName} onChange={(e) => { onHandleChange(e) }} />
                            <label className="text-danger font14 w-100">{(validationChild == true && jsonData[0].spouseName.trim() === "") ? "Please enter the Spouse Name" : null}</label>
                            <label className="form-label font14 font-semibold">Spouse Date of Birth*</label>
                            <div className='date-picker'>
                                <input type="date" className="form-control font14 text-uppercase pb-2 pt-2 datecontrol" name="spousedob" value={jsonData[0].spousedob} onChange={(e) => { onHandleChange(e) }} max={disablePastDate()} onKeyDown={(e) => { e.preventDefault() }} onFocus={(e) => setdateFieldspouse(true)}
                                    placeholder={dateFieldspouse ? "" : "MM/DD/YYYY"} />
                                <img src={calender} alt="calender-icon" className="spousecalender-icon "></img>
                                <label className="text-danger font14 w-100">{(validationChild == true && jsonData[0].spousedob == "") ? "Please enter the Spouse Date of Birth" : null}</label>
                            </div>

                        </div> : null}
                    {/* Footer */}
                    <div className="col-md-12 mt-4 mb-4">
                        {/* <button type="button" className="btn btn-outline-secondary font14 mt-4 px-5 previous-button mobile-button" onClick={() => backButtonClick()}>
                            <span className="previous-button-arrow me-1">
                                <img src={previouslogo} alt="auto" />
                                <img src={previousLogo} alt="auto" />
                            </span>
                            Previous</button> */}


                        <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button" onClick={() => validation()}>Next Step<img src={nextLogo} className="ms-2" /></button>
                    </div>

                </React.Fragment>



            );

    }
    //pc_06 function for purchase details page
    const purchaseDetails = () => {
        return (

            <React.Fragment>
                <div className="col-md-12 mt-4">


                    <label htmlFor="Provide us all the details" className="form-label w-100 font14 font-semibold ">Is this a new purchase ?</label>
                    <input type="radio" className="btn-check radio-checked" id="No" autoComplete="off" name="purchaseDetails" value='No' checked={jsonData[0].purchaseDetails === 'No'} onChange={(e) => { onHandleChange(e) }} />
                    <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor="No">No</label>
                    <input type="radio" className="btn-check radio-checked" id="newpurchase_no" autoComplete="off" name="purchaseDetails" value='Yes' checked={jsonData[0].purchaseDetails === 'Yes'} onChange={(e) => { onHandleChange(e) }} />
                    <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor="newpurchase_no">Yes</label>
                </div>
                {/* Footer */}
                <div className="col-md-12 mt-4 mb-4">
                    <button type="button" className="btn btn-outline-secondary font14 mt-4 px-5 previous-button mobile-button" onClick={() => backButtonClick()}>
                        <span className="previous-button-arrow me-1">
                            <img src={previouslogo} alt="auto" />
                            <img src={previousLogo} alt="auto" />
                        </span>
                        Previous</button>
                    <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button" onClick={() => validation()}>Next Step<img src={nextLogo} className="ms-2" /></button>
                </div>
                {/* Footer */}
                {/* Form-Component */}
            </React.Fragment>
        );

    }



    //pc_07 funtion to bind the fields for homedetails page
    const homeDetails = () => {

        if (jsonData[0].purchaseDetails === "") {
            return (

                <React.Fragment>

                    {/* Insurance Type */}
                    <div>
                    </div>
                    {/* Steps indicator header */}
                    <div className="col-md-6 mt-2 w-100">
                        <label className="font20 font-bold font-head border-bottom w-100">Home Details</label>
                    </div>

                    <div className="col-md-12 mt-3 ">
                        <label htmlFor="label9" className="form-label font14 font-semibold ">Property Street</label>
                        < textarea className="form-control pb-2 pt-2" style={{ "resize": "none" }} id="label9" rows={6} defaultValue={""} maxLength={120} name="proStreet" value={jsonData[0].proStreet} onChange={(e) => { onHandleChange(e) }} />

                    </div>
                    <div className="col-md-12 mt-3">
                        <label htmlFor="label7" className="form-label font14 font-semibold">Property City</label>
                        <input type="text" className="form-control pb-2 pt-2" id="label7" maxLength={40} name="proCity" value={jsonData[0].proCity}
                            onChange={(e) => { onHandleChange(e) }} />

                    </div>
                    <div className='row'>
                        <div className="col-md-6 mt-3">
                            <label htmlFor="state" className="form-label font14 font-semibold">Property State</label>

                            <select className="form-select pb-2 pt-2 " name="proState" id="state" value={jsonData[0].proState} onChange={(e) => onHandleChange(e)} >
                                <option value="--select--">Select State</option>
                                <option value="AK">AK</option>
                                <option value="AL">AL</option>
                                <option value="AR">AR</option>
                                <option value="AZ">AZ</option>
                                <option value="CA">CA</option>
                                <option value="CO">CO</option>
                                <option value="CT">CT</option>
                                <option value="DE">DE</option>
                                <option value="FL">FL</option>
                                <option value="GA">GA</option>
                                <option value="HI">HI</option>
                                <option value="IA">IA</option>
                                <option value="ID">ID</option>
                                <option value="IL">IL</option>
                                <option value="IN">IN</option>
                                <option value="KS">KS</option>
                                <option value="KY">KY</option>
                                <option value="LA">LA</option>
                                <option value="MA">MA</option>
                                <option value="MD">MD</option>
                                <option value="ME">ME</option>
                                <option value="MI">MI</option>
                                <option value="MN">MN</option>
                                <option value="MO">MO</option>
                                <option value="MS">MS</option>
                                <option value="MT">MT</option>
                                <option value="NC">NC</option>
                                <option value="ND">ND</option>
                                <option value="NE">NE</option>
                                <option value="NH">NH</option>
                                <option value="NJ">NJ</option>
                                <option value="NM">NM</option>
                                <option value="NV">NV</option>
                                <option value="NY">NY</option>
                                <option value="OH">OH</option>
                                <option value="OK">OK</option>
                                <option value="OR">OR</option>
                                <option value="PA">PA</option>
                                <option value="RI">RI</option>
                                <option value="SC">SC</option>
                                <option value="SD">SD</option>
                                <option value="TN">TN</option>
                                <option value="TX">TX</option>
                                <option value="UT">UT</option>
                                <option value="VA">VA</option>
                                <option value="VT">VT</option>
                                <option value="WA">WA</option>
                                <option value="WI">WI</option>
                                <option value="WV">WV</option>
                                <option value="WY">WY</option>
                            </select>




                        </div>
                        <div className="col-md-6 mt-3">
                            <label htmlFor="procode" className="form-label font14 font-semibold">Property Zip/Postal Code</label>

                            <input type="text" className="form-control pb-2 pt-2" id="procode" name="proPostalCode" maxLength={5} value={jsonData[0].proPostalCode}
                                onChange={(e) => { onHandleChange(e) }}
                            />
                            <label className="text-danger font14 w-100">{(validationChild == true && jsonData[0].proPostalCode.length < 5 && jsonData[0].proPostalCode !== "") ? "Please enter the valid Zip/Postal Code" : null}</label>

                        </div></div>

                    {/* Footer */}
                    <div className="col-md-12 mt-4 mb-4">
                        <button type="button" className="btn btn-outline-secondary font14 mt-4 px-5 previous-button mobile-button" onClick={() => backButtonClick()}>
                            <span className="previous-button-arrow me-1">
                                <img src={previouslogo} alt="auto" />
                                <img src={previousLogo} alt="auto" />
                            </span>
                            Previous</button>
                        <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button" onClick={() => validation()}>Next Step<img src={nextLogo} className="ms-2" /></button>
                    </div>
                </React.Fragment>
            );

        }
        if (jsonData[0].purchaseDetails === "Yes") {
            return (
                <React.Fragment>
                    <div>
                    </div>
                    <div className="col-md-12  border-bottom border-top mt-4 py-1" >
                        <label className="font20 font-bold font-head"> Tell us about your new home</label>
                    </div>
                    <div className="col-md-12 mt-3 date-picker">
                        <label className="form-label font14 font-semibold" >Closing Date
                            <span className='gib-tooltip5'>
                                <img src={helpicon} className="ms-1" />
                                <span className='tooltiptext'>{"The date your home sale will be finalized"}</span>
                            </span>
                        </label>

                        <input type="date" className="form-control font14 text-uppercase pb-2 pt-2 datecontrol" name="closingDate" value={jsonData[0].closingDate} onChange={(e) => { onHandleChange(e) }} onKeyDown={(e) => { e.preventDefault() }} onFocus={(e) => setdateclosingdate(true)}
                            placeholder={dateclosingdate ? "" : "MM/DD/YYYY"} />
                        <img src={calender} alt="calender-icon" className="closingdate_icon "></img>
                    </div>

                    <div className="col-md-12 mt-3">
                        <label htmlFor="label9" className="form-label font14 font-semibold">Property Street</label>
                        < textarea className="form-control pb-2 pt-2" style={{ "resize": "none" }} id="label9" rows={6} defaultValue={""} maxLength={120} name="proStreet" value={jsonData[0].proStreet} onChange={(e) => { onHandleChange(e) }} />
                    </div>
                    <div className="col-md-12 mt-3">
                        <label htmlFor="label7" className="form-label font14 font-semibold">Property City</label>
                        <input type="text" className="form-control pb-2 pt-2" id="label7" maxLength={40} name="proCity" value={jsonData[0].proCity}
                            onChange={(e) => { onHandleChange(e) }} />
                    </div>
                    <div className='row'>
                        <div className="col-md-6 mt-3">
                            <label htmlFor="stateyes" className="form-label font14 font-semibold">Property State</label>

                            <select className="form-select pb-2 pt-2" name="proState" id="stateyes" value={jsonData[0].proState} onChange={(e) => onHandleChange(e)} >


                                <option value="--select--">Select State</option>
                                <option value="AK">AK</option>
                                <option value="AL">AL</option>
                                <option value="AR">AR</option>
                                <option value="AZ">AZ</option>
                                <option value="CA">CA</option>
                                <option value="CO">CO</option>
                                <option value="CT">CT</option>
                                <option value="DE">DE</option>
                                <option value="FL">FL</option>
                                <option value="GA">GA</option>
                                <option value="HI">HI</option>
                                <option value="IA">IA</option>
                                <option value="ID">ID</option>
                                <option value="IL">IL</option>
                                <option value="IN">IN</option>
                                <option value="KS">KS</option>
                                <option value="KY">KY</option>
                                <option value="LA">LA</option>
                                <option value="MA">MA</option>
                                <option value="MD">MD</option>
                                <option value="ME">ME</option>
                                <option value="MI">MI</option>
                                <option value="MN">MN</option>
                                <option value="MO">MO</option>
                                <option value="MS">MS</option>
                                <option value="MT">MT</option>
                                <option value="NC">NC</option>
                                <option value="ND">ND</option>
                                <option value="NE">NE</option>
                                <option value="NH">NH</option>
                                <option value="NJ">NJ</option>
                                <option value="NM">NM</option>
                                <option value="NV">NV</option>
                                <option value="NY">NY</option>
                                <option value="OH">OH</option>
                                <option value="OK">OK</option>
                                <option value="OR">OR</option>
                                <option value="PA">PA</option>
                                <option value="RI">RI</option>
                                <option value="SC">SC</option>
                                <option value="SD">SD</option>
                                <option value="TN">TN</option>
                                <option value="TX">TX</option>
                                <option value="UT">UT</option>
                                <option value="VA">VA</option>
                                <option value="VT">VT</option>
                                <option value="WA">WA</option>
                                <option value="WI">WI</option>
                                <option value="WV">WV</option>
                                <option value="WY">WY</option>
                            </select>



                        </div>
                        <div className="col-md-6 mt-3">
                            <label htmlFor="procode" className="form-label font14 font-semibold">Property Zip/Postal Code</label>

                            <input type="text" className="form-control pb-2 pt-2" id="procode" name="proPostalCode" maxLength={5} value={jsonData[0].proPostalCode}
                                onChange={(e) => { onHandleChange(e) }}
                            />
                            <label className="text-danger font14 w-100">{(validationChild == true && jsonData[0].proPostalCode.length < 5 && jsonData[0].proPostalCode !== "") ? "Please enter the valid Zip/Postal Code" : null}</label>

                        </div></div>

                    {/* Footer */}
                    <div className="col-md-12 mt-4 mb-4">
                        <button type="button" className="btn btn-outline-secondary font14 mt-4 px-5 previous-button mobile-button" onClick={() => backButtonClick()}>
                            <span className="previous-button-arrow me-1">
                                <img src={previouslogo} alt="auto" />
                                <img src={previousLogo} alt="auto" />
                            </span>
                            Previous</button>
                        <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button" onClick={() => validation()}>Next Step<img src={nextLogo} className="ms-2" /></button>
                    </div>
                </React.Fragment>
            )
        }

        if (jsonData[0].purchaseDetails === "No") {
            return (

                <React.Fragment>

                    {/* Insurance Type */}
                    <div>
                    </div>
                    {/* Steps indicator header */}
                    <div className="col-md-6 mt-2">
                        <label className="font20 font-bold font-head">Home Details</label>
                    </div>


                    <div className="col-md-12 mt-3">
                        <label htmlFor="label9" className="form-label font14 font-semibold">Property Street</label>
                        < textarea className="form-control pb-2 pt-2" style={{ "resize": "none" }} id="label9" rows={6} defaultValue={""} maxLength={120} name="proStreet" value={jsonData[0].proStreet} onChange={(e) => { onHandleChange(e) }} />

                    </div>


                    <div className="col-md-12 mt-3">
                        <label htmlFor="label7" className="form-label font14 font-semibold">Property City</label>
                        <input type="text" className="form-control pb-2 pt-2" id="label7" maxLength={40} name="proCity" value={jsonData[0].proCity}
                            onChange={(e) => { onHandleChange(e) }} />

                    </div>
                    <div className='row'>
                        <div className="col-md-6 mt-3">
                            <label htmlFor="stateno" className="form-label font14 font-semibold"> Property State</label>

                            <select className="form-select pb-2 pt-2" name="proState" id="state" value={jsonData[0].proState} onChange={(e) => onHandleChange(e)} >

                                <option value="--select--">Select State</option>
                                <option value="AK">AK</option>
                                <option value="AL">AL</option>
                                <option value="AR">AR</option>
                                <option value="AZ">AZ</option>
                                <option value="CA">CA</option>
                                <option value="CO">CO</option>
                                <option value="CT">CT</option>
                                <option value="DE">DE</option>
                                <option value="FL">FL</option>
                                <option value="GA">GA</option>
                                <option value="HI">HI</option>
                                <option value="IA">IA</option>
                                <option value="ID">ID</option>
                                <option value="IL">IL</option>
                                <option value="IN">IN</option>
                                <option value="KS">KS</option>
                                <option value="KY">KY</option>
                                <option value="LA">LA</option>
                                <option value="MA">MA</option>
                                <option value="MD">MD</option>
                                <option value="ME">ME</option>
                                <option value="MI">MI</option>
                                <option value="MN">MN</option>
                                <option value="MO">MO</option>
                                <option value="MS">MS</option>
                                <option value="MT">MT</option>
                                <option value="NC">NC</option>
                                <option value="ND">ND</option>
                                <option value="NE">NE</option>
                                <option value="NH">NH</option>
                                <option value="NJ">NJ</option>
                                <option value="NM">NM</option>
                                <option value="NV">NV</option>
                                <option value="NY">NY</option>
                                <option value="OH">OH</option>
                                <option value="OK">OK</option>
                                <option value="OR">OR</option>
                                <option value="PA">PA</option>
                                <option value="RI">RI</option>
                                <option value="SC">SC</option>
                                <option value="SD">SD</option>
                                <option value="TN">TN</option>
                                <option value="TX">TX</option>
                                <option value="UT">UT</option>
                                <option value="VA">VA</option>
                                <option value="VT">VT</option>
                                <option value="WA">WA</option>
                                <option value="WI">WI</option>
                                <option value="WV">WV</option>
                                <option value="WY">WY</option>
                            </select>


                        </div>
                        <div className="col-md-6 mt-3">
                            <label htmlFor="procode" className="form-label font14 font-semibold"> Property Zip/Postal Code</label>

                            <input type="text" className="form-control pb-2 pt-2" id="procode" name="proPostalCode" maxLength={5} value={jsonData[0].proPostalCode}
                                onChange={(e) => { onHandleChange(e) }}
                            />
                            <label className="text-danger font14 w-100">{(validationChild == true && jsonData[0].proPostalCode.length < 5 && jsonData[0].proPostalCode !== "") ? "Please enter the valid Zip/Postal Code" : null}</label>

                        </div></div>
                    <div className=" mt-2  col-md-12  border-bottom">
                        <label className="font20 font-bold font-head">Years at Address</label>
                    </div>

                    <div className="col-md-12 mt-3">
                        <label htmlFor="years" className="form-label font14 font-semibold">How many years lived in home?</label>
                        <input type="text" className="form-control pb-2 pt-2" id="label8" name="Years" maxLength={3} value={jsonData[0].Years}
                            onChange={(e) => { onHandleChange(e) }} />

                    </div>

                    {/* Footer */}
                    <div className="col-md-12 mt-4 mb-4">
                        <button type="button" className="btn btn-outline-secondary font14 mt-4 px-5 previous-button mobile-button" onClick={() => backButtonClick()}>
                            <span className="previous-button-arrow me-1">
                                <img src={previouslogo} alt="auto" />
                                <img src={previousLogo} alt="auto" />
                            </span>
                            Previous</button>
                        <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button" onClick={() => validation()}>Next Step<img src={nextLogo} className="ms-2" /></button>
                    </div>
                </React.Fragment>
            );

        }
    }
    //PC_08 function to bind the fields in constructionDetails page
    const constructionDetails = () => {

        return (
            <React.Fragment>



                <div className="col-md-12 mt-4">
                    <label htmlFor="Provide us all the details" className="form-label w-100 font14 font-semibold "> New Construction?</label>
                    <input type="radio" className="btn-check radio-checked px-4" id="label10" autoComplete="off" name="constNew" value='Yes' checked={jsonData[0].constNew === 'Yes'} onChange={(e) => { onHandleChange(e) }} />
                    <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor="label10">Yes</label>
                    <input type="radio" className="btn-check radio-checked px-4" id="label11" autoComplete="off" name="constNew" value='No' checked={jsonData[0].constNew === 'No'} onChange={(e) => { onHandleChange(e) }} />
                    <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor="label11">No</label>

                </div>

                {jsonData[0].constNew === "Yes" ?
                    <React.Fragment>
                        <div className="col-md-12 border-bottom mt-4 border-top ">
                            <label className="font20 font-primary font-semibold font-head pb-2">New Construction</label>
                        </div>
                        <div className='row'>
                            <div className="col-md-6 mt-3">
                                <label htmlFor="state" className="form-label font14 font-semibold">Construction Type</label>

                                <select className="form-select pb-2 pt-2" name="constructType" id="state" value={jsonData[0].constructType} onChange={(e) => onHandleChange(e)} >

                                    <option value="">Select Type</option>
                                    <option value="Mostly Brick">Mostly Brick</option>
                                    <option value="Mostly hardiplank">Mostly hardiplank</option>
                                    <option value="Mostly Wood">Mostly Wood</option>
                                    <option value="Mostly Stucco">Mostly Stucco</option>
                                    <option value="Other">Other</option>

                                </select>
                            </div>
                            <div className="col-md-6 mt-3">
                                <label htmlFor="procode" className="form-label font14 font-semibold">Square Feet</label>
                                <input type="text" className="form-control pb-2 pt-2" id="procode" name="squareFeet" maxLength={5} value={jsonData[0].squareFeet}
                                    onChange={(e) => { onHandleChange(e) }} />

                            </div>

                            <div className="col-md-6 mt-3">
                                <label htmlFor="label8" className="form-label font14 font-semibold">Number of Stories</label>
                                <input type="text" className="form-control pb-2 pt-2" id="label8" name="stories" maxLength={2} value={jsonData[0].stories}
                                    onChange={(e) => { onHandleChange(e) }} />

                            </div>
                            <div className="col-md-6 mt-3">
                                <label htmlFor="bathroom" className="form-label font14 font-semibold">Number of Full Bathrooms</label>
                                <input type="text" className="form-control pb-2 pt-2" id="bathroom" name="bathroom" maxLength={2} value={jsonData[0].bathroom}
                                    onChange={(e) => { onHandleChange(e) }} />
                            </div>

                            <div className="col-md-6 mt-3">
                                <label htmlFor="Half-bathroom" className="form-label font14 font-semibold">Number of Half Bathrooms</label>
                                <input type="text" className="form-control pb-2 pt-2" id="Half-bathroom" name="halfbath" maxLength={2} value={jsonData[0].halfbath}
                                    onChange={(e) => { onHandleChange(e) }} />
                            </div>
                        </div>

                    </React.Fragment> : null}
                {/* Footer */}



                <div className="col-md-12 mt-4 mb-4">

                    <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button" onClick={() => validation()}>Next Step<img src={nextLogo} className="ms-2" /></button>


                    <button type="button" className="btn btn-outline-secondary font14 mt-4 px-5 previous-button mobile-button" onClick={() => backButtonClick()}>
                        <span className="previous-button-arrow me-1">
                            <img src={previouslogo} alt="auto" />
                            <img src={previousLogo} alt="auto" />
                        </span>
                        Previous</button>
                </div>


            </React.Fragment>

        );
    }

    //PC_10,pc_09  for binding the fields in  the currentinsurence page/roof details
    const roofDetails = () => {
        if (jsonData[0].constNew === "No" || jsonData[0].constNew === "Yes") {
            return (<React.Fragment>
                <div className="col-md-12 mt-4">
                    <label htmlFor="roofdetail" className="form-label font14 font-semibold">Year Roof Replaced</label>
                    <input type="text" className="form-control pb-2 pt-2" id="roofdetail" name="roof" maxLength={4} value={jsonData[0].roof}
                        onChange={(e) => { onHandleChange(e) }} />

                    <label className="text-danger font14 w-100">{(ValidationALert === true && jsonData[0].roof.length < 4 && jsonData[0].roof !== "") ? "Please enter a valid Year" : null}</label>
                </div>
                <div className="col-md-12 mt-4 mb-4">
                    <button type="button" className="btn btn-outline-secondary font14 mt-4 px-5 previous-button mobile-button" onClick={() => backButtonClick()}>
                        <span className="previous-button-arrow me-1">
                            <img src={previouslogo} alt="auto" />
                            <img src={previousLogo} alt="auto" />
                        </span>
                        Previous</button>
                    <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button" onClick={() => validation()}>Next Step<img src={nextLogo} className="ms-2" /></button>
                </div>
            </React.Fragment>


            );
        }
    }

    const currentInsurance = () => {
        return (<React.Fragment>
            <div className="col-md-12 mt-4">
                <label htmlFor="Provide us all the details" className="form-label w-100 font14 font-semibold ">Is your home currently insured?*</label>
                <input type="radio" className="btn-check radio-checked" id="anyone-else-yes" autoComplete="off" name="currentInsurance" value='Yes' checked={jsonData[0].currentInsurance === 'Yes'} onChange={(e) => { onHandleChange(e) }} />
                <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor="anyone-else-yes">Yes</label>
                <input type="radio" className="btn-check radio-checked" id="anyone-else-no" autoComplete="off" name="currentInsurance" value='No' checked={jsonData[0].currentInsurance === 'No'} onChange={(e) => { onHandleChange(e) }} />
                <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor="anyone-else-no">No</label>
                <label className="text-danger font14 w-100">{(ValidationALert == true && jsonData[0].currentInsurance == "") ? "Please select your choice" : null}</label>
            </div>


            <div className="col-md-12 mt-4 mb-4">

                <button type="button" className="btn btn-outline-secondary font14 mt-4 px-5 previous-button mobile-button" onClick={() => { backButtonClick() }}>
                    <span className="previous-button-arrow me-1">
                        <img src={previouslogo} alt="auto" />
                        <img src={previousLogo} alt="auto" />
                    </span>
                    Previous</button>

                {(jsonData[0].preferredDetails === "Just give key points") ? <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button" onClick={() => postData()}>Request Quote</button>
                    : (jsonData[0].preferredDetails === "Provide us all the details") ?
                        <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button" onClick={() => validation()}>Next Step<img src={nextLogo} className="ms-2" /></button> : null}



            </div>
            {Loading == true ? <div id="root">

                <h1>Submitting Your Request</h1>

                <h3>Please Wait for few seconds....</h3>

                <div className="loader-wrapper">

                    <div className="loader"><span></span> <span></span> <span></span> <span></span> <span></span> </div>

                </div>

            </div> : null}
        </React.Fragment>

        );

    }


    //PC_11 function to bind fields in policy details page 
    const policyDetails = () => {
        if (jsonData[0].currentInsurance === 'Yes' || jsonData[0].currentInsurance === '') {
            return (
                <React.Fragment>

                    <div className="col-md-12 mt-3">
                        <label className="form-label font14 font-semibold" htmlFor="renewal-premium">Renewal Premium</label>

                        <input type="text" className="form-control" id="renewal-premium" name="renewalpremium" maxLength={18} value={jsonData[0].renewalpremium}
                            onChange={(e) => { onHandleChange(e) }} />

                    </div>

                    <div className="col-md-12 mt-3 date-picker">
                        <label className="form-label font14 font-semibold">When does your current policy expire?</label>

                        <input type="date" className="form-control font14 text-uppercase datecontrol" name="policyExpire" value={jsonData[0].policyExpire} onChange={(e) => { onHandleChange(e) }} min={disablePastDate()} onKeyDown={(e) => { e.preventDefault() }} onFocus={(e) => setdatecurrentpolicy(true)}
                            placeholder={datecurrentpolicy ? "" : "MM/DD/YYYY"} />

                        <img src={calender} alt="calender-icon" className="currentpolicy_detail "></img>
                    </div>

                    <div className="col-md-12 mt-4 mb-4">
                        <button type="button" className="btn btn-outline-secondary font14 mt-4 px-5 previous-button mobile-button" onClick={() => backButtonClick()}>
                            <span className="previous-button-arrow me-1">
                                <img src={previouslogo} alt="auto" />
                                <img src={previousLogo} alt="auto" />
                            </span>
                            Previous</button>
                        <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button" onClick={() => validation()}>Next Step<img src={nextLogo} className="ms-2" /></button>
                    </div>
                </React.Fragment>

            );
        }

        if (jsonData[0].currentInsurance === 'No') {
            return (
                <React.Fragment>
                    <div className="col-md-12 mt-4">
                        <label htmlFor="howmany" className="form-label font14 font-semibold">How many days has your policy been expired?</label>
                        <input type="text" className="form-control pb-2 pt-2" id="howmany" name="lapseTime" maxLength={10} value={jsonData[0].lapseTime}
                            onChange={(e) => { onHandleChange(e) }}
                        />
                    </div>
                    <div className="col-md-12 mt-4 mb-4">
                        <button type="button" className="btn btn-outline-secondary font14 mt-4 px-5 previous-button mobile-button" onClick={() => backButtonClick()}>
                            <span className="previous-button-arrow me-1">
                                <img src={previouslogo} alt="auto" />
                                <img src={previousLogo} alt="auto" />
                            </span>
                            Previous</button>
                        <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button" onClick={() => validation()}>Next Step<img src={nextLogo} className="ms-2" /></button>
                    </div>
                </React.Fragment>
            );
        }
    }
    //PC_12 function to bind the fields in the pooldetails page
    const poolDetails = () => {
        return (
            <React.Fragment>
                <div className="col-md-12 mt-4">
                    <label htmlFor="Provide us all the details" className="form-label w-100 font14 font-semibold ">Do you have a pool?</label>
                    <input type="radio" className="btn-check radio-checked " id="anyone-else-yes" autoComplete="off" name="poolDetails" value='No' checked={jsonData[0].poolDetails === 'No'} onChange={(e) => { onHandleChange(e) }} />
                    <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor="anyone-else-yes">No</label>
                    <input type="radio" className="btn-check radio-checked " id="anyone-else-no" autoComplete="off" name="poolDetails" value='Yes' checked={jsonData[0].poolDetails === 'Yes'} onChange={(e) => { onHandleChange(e) }} />
                    <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor="anyone-else-no">Yes</label>
                </div>
                {(jsonData[0].poolDetails === 'Yes') ?
                    <div className="col-md-12 mt-3">

                        <label htmlFor="label10" className="form-label w-100 font14 font-semibold">Do you have a diving board or slide?</label>


                        <input
                            type="radio"
                            className="btn-check radio-checked" id="Diving Board"
                            autoComplete="off" name="divingBoard"
                            value="Diving Board"
                            checked={jsonData[0].divingBoard === "Diving Board"}
                            onClick={(e) => { onHandleChange(e) }} />

                        <label className="btn btn-primary me-2 mt-2 button-radio font14 font-bold" htmlFor="Diving Board">Diving Board</label>


                        <input type="radio" className="btn-check radio-checked" id="slide" autoComplete="off" name="divingBoard"
                            value="slide" onClick={(e) => { onHandleChange(e) }}
                            checked={jsonData[0].divingBoard === "slide"} />

                        <label className="btn btn-primary me-2 mt-2  button-radio font14 font-bold" htmlFor="slide">Slide</label>


                        <input type="radio" className="btn-check radio-checked" id="both" autoComplete="off" name="divingBoard"
                            value="both" onClick={(e) => { onHandleChange(e) }}
                            checked={jsonData[0].divingBoard === "both"} />


                        <label className="btn btn-primary me-2 mt-2 button-radio font14 font-bold" htmlFor="both">Both</label>

                        <input type="radio" className="btn-check radio-checked" id="neither" autoComplete="off" name="divingBoard"
                            value="Neither" onClick={(e) => { onHandleChange(e) }}
                            checked={jsonData[0].divingBoard === "Neither"} />

                        <label className="btn btn-primary me-2 mt-2 button-radio font14 font-bold" htmlFor="neither">Neither</label>
                    </div> : null
                }
                <div className="col-md-12 mt-4">
                    <label htmlFor="Provide us all the details" className="form-label w-100 font14 font-semibold ">Do you have a trampoline?</label>

                    <input type="radio" className="btn-check radio-checked " id="tramp-no" autoComplete="off" name="trampoline" value='No' checked={jsonData[0].trampoline === 'No'} onChange={(e) => { onHandleChange(e) }} />
                    <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor="tramp-no">No</label>

                    <input type="radio" className="btn-check radio-checked " id="tramp-yes" autoComplete="off" name="trampoline" value='Yes' checked={jsonData[0].trampoline === 'Yes'} onChange={(e) => { onHandleChange(e) }} />
                    <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor="tramp-yes">Yes</label>

                </div>

                <div className="col-md-12 mt-4 mb-4">
                    <button type="button" className="btn btn-outline-secondary font14 mt-4 px-5 previous-button mobile-button" onClick={() => backButtonClick()}>
                        <span className="previous-button-arrow me-1">
                            <img src={previouslogo} alt="auto" />
                            <img src={previousLogo} alt="auto" />
                        </span>
                        Previous</button>
                    <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button" onClick={() => validation()}>Next Step<img src={nextLogo} className="ms-2" /></button>
                </div>

            </React.Fragment>
        );

    }




    //PC_13 function to bind the data in the pet details page
    const petDetails = () => {

        return (
            <React.Fragment>
                <div className="col-md-12 mt-4">
                    <label htmlFor="Provide us all the details" className="form-label w-100 font14 font-semibold ">Do you have any pets?</label>
                    <input type="radio" className="btn-check radio-checked" id="petdetails_no" autoComplete="off" name="pets" value='No' checked={jsonData[0].pets === 'No'} onChange={(e) => { onHandleChange(e) }} />
                    <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor="petdetails_no" >No</label>
                    <input type="radio" className="btn-check radio-checked" id="petdetails_yes" autoComplete="off" name="pets" value='Yes' checked={jsonData[0].pets === 'Yes'} onChange={(e) => { onHandleChange(e) }} />
                    <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor="petdetails_yes">Yes</label>

                </div>

                {jsonData[0].pets === "Yes" ?
                    <div className="mt-4">
                        <label htmlFor="label9" className="form-label font14 font-semibold">Type of Pets</label>
                        <input type="text" className="form-control pb-2 pt-2" id="label9" name="petType" maxLength={255} value={jsonData[0].petType} onChange={(e) => { onHandleChange(e) }} />


                        <div className='mt-3'>

                            <label htmlFor="petcount" className="form-label font14 font-semibold"> How many pets do you have ?</label>

                            <input type="text" className="form-control pb-2 pt-2" id="petcount" maxLength={3} name="petNumber" value={jsonData[0].petNumber} onChange={(e) => { onHandleChange(e) }} />
                        </div>

                    </div> : null}
                {/* Footer */}
                <div className="col-md-12 mt-4 mb-4">
                    <button type="button" className="btn btn-outline-secondary font14 mt-4 px-5 previous-button mobile-button" onClick={() => backButtonClick()}>
                        <span className="previous-button-arrow me-1">
                            <img src={previouslogo} alt="auto" />
                            <img src={previousLogo} alt="auto" />
                        </span>
                        Previous</button>
                    <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button" onClick={() => validation()}>Next Step<img src={nextLogo} className="ms-2" /></button>
                </div>
                {/* Footer */}
                {/* Form-Component */}
            </React.Fragment>

        );


    }

    //pc_14 function to bind the data in the securityalarm page
    const securityAlarm = () => {

        return (

            <React.Fragment>

                <div className="col-md-12 mt-4">
                    <label htmlFor="Provide us all the details" className="form-label w-100 font14 font-semibold ">Do you have now or plan on activating a monitored fire or burglar alarm?</label>
                    <input type="radio" className="btn-check radio-checked" id="petdetails_no" autoComplete="off" name="securityAlarm" value='No' checked={jsonData[0].securityAlarm === 'No'} onChange={(e) => { onHandleChange(e) }} />
                    <label className="btn btn-primary button-radio font14 font-bold px-4" htmlFor="petdetails_no" >No</label>
                    <input type="radio" className="btn-check radio-checked" id="petdetails_yes" autoComplete="off" name="securityAlarm" value='Yes' checked={jsonData[0].securityAlarm === 'Yes'} onChange={(e) => { onHandleChange(e) }} />
                    <label className="btn btn-primary ms-2 button-radio font14 font-bold px-4" htmlFor="petdetails_yes">Yes</label>

                </div>


                <div className="col-md-12 mt-4 mb-4">
                    <button type="button" className="btn btn-outline-secondary font14 mt-4 px-5 previous-button mobile-button" onClick={() => backButtonClick()}>
                        <span className="previous-button-arrow me-1">
                            <img src={previouslogo} alt="auto" />
                            <img src={previousLogo} alt="auto" />
                        </span>
                        Previous</button>
                    <button type="button" className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button" onClick={() => postData()}>Request Quote</button>



                </div>
                {Loading == true ? <div id="root">

                    <h1>Submitting Your Request</h1>

                    <h3>Please Wait for few seconds....</h3>

                    <div className="loader-wrapper">

                        <div className="loader"><span></span> <span></span> <span></span> <span></span> <span></span> </div>

                    </div>

                </div> : null}

            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
            <div className="container">
                <div className="row mt-4">
                    <div className="col-lg-8 col-md-12 col-sm-12" >
                        <div className="row">


                            <div className="col-lg-11 col-md-12 col-sm-12">

                                {/* Header */}
                                {/* Insurance Type */}

                                {/* Insurance Type */}
                                {/* Steps indicator header */}
                                <div className="row">

                                    <Header counter={counter} value={Info} childToParent={(data: any) => childToParent(data)} currentPage={currentpage} />

                                    {currentpage == 1 && <p className='font14  font-primary'>We can tailor your home policy to your needs, so you get maximum protection and value.Since some coverages are standard and others are optional, the more details you provide the better our agents will be able to safeguard your biggest investment.</p>}

                                </div>
                                {/* Steps indicator header */}
                                {/* Header */}
                                {/* Form-Component */}

                                {currentpage === 1 ?
                                    homePage() : null}
                                {/**
                                     * Changing condition in currentpage === 2 for CR
                                     */}
                                {
                                    (currentpage === 2 && jsonData[0].preferredDetails === "Just give key points") ?
                                        homePage() : null
                                }
                                {currentpage === 2 ?
                                    PurchaseSpouse() : null}
                                {(currentpage === 3 && jsonData[0].preferredDetails === "Provide us all the details") ?
                                    purchaseDetails() : null}
                                {/* {(currentpage === 3 && jsonData[0].preferredDetails === "Just give key points") ?
                                    currentInsurance() : null} */}
                                {currentpage === 4 ?
                                    homeDetails() : null}
                                {currentpage === 5 ?
                                    constructionDetails() : null}
                                {currentpage === 6 ?
                                    roofDetails() : null}
                                {currentpage === 7 ?
                                    currentInsurance() : null}
                                {currentpage === 8 ?
                                    policyDetails() : null}
                                {currentpage === 9 ?
                                    poolDetails() : null}
                                {currentpage === 10 ?
                                    petDetails() : null}
                                {currentpage === 11 ?
                                    securityAlarm() : null}
                            </div>

                        </div>
                    </div>



                    <div className="col-md-4 right-panel-margin hide-right">
                        <div className="row mx-0">
                            {/* Previous Steps */}

                            {(crossedpages > 1) ?
                                <React.Fragment>
                                    <div className="col-md-12 border bg-light p-2 previous-steps-box border-bottom-0">
                                        <label className="ps-2 font-16 font-bold">Previous Steps</label>
                                    </div>
                                    <div className="col-md-12 border border-top-0 previous-steps-box px-0">
                                        {
                                            arrayToSend.map(
                                                (values: any, index: any) => {
                                                    return (
                                                        (index <= Number(crossedpages) - 1 && values !== 'Personal Details') ?
                                                            < li className="list-unstyled px-0 font16 font-primary " onClick={() => {
                                                                setCurrentpage(

                                                                    (flowName === "purchageNoConstNo" && values === "Personal Details") ? 1 :
                                                                        (flowName === "purchageNoConstNo" && values === "Spouse Details") ? 2 :
                                                                            (flowName === "purchageNoConstNo" && values === "Purchase Details") ? 3 :
                                                                                (flowName === "purchageNoConstNo" && values === "Home Details") ? 4 :
                                                                                    (flowName === "purchageNoConstNo" && values === "Construction Details") ? 5 :
                                                                                        (flowName === "purchageNoConstNo" && values === "Roof Details") ? 6 :
                                                                                            (flowName === "purchageNoConstNo" && values === "Current Insurance") ? 7 :
                                                                                                (flowName === "purchageNoConstNo" && values === "Policy Details") ? 8 :
                                                                                                    (flowName === "purchageNoConstNo" && values === "Property Details") ? 9 :
                                                                                                        (flowName === "purchageNoConstNo" && values === "Pet Details") ? 10 :
                                                                                                            (flowName === "purchageNoConstNo" && values === "Security Alarm") ? 11 :
                                                                                                                ((flowName === "purchageNoConstYes" || flowName === "purchaseYesConstEmpty") && values === "Personal Details") ? 1 :
                                                                                                                    ((flowName === "purchageNoConstYes" || flowName === "purchaseYesConstEmpty") && values === "Spouse Details") ? 2 :
                                                                                                                        ((flowName === "purchageNoConstYes" || flowName === "purchaseYesConstEmpty") && values === "Purchase Details") ? 3 :
                                                                                                                            ((flowName === "purchageNoConstYes" || flowName === "purchaseYesConstEmpty") && values === "Home Details") ? 4 :
                                                                                                                                ((flowName === "purchageNoConstYes" || flowName === "purchaseYesConstEmpty") && values === "Construction Details") ? 5 :
                                                                                                                                    ((flowName === "purchageNoConstYes" || flowName === "purchaseYesConstEmpty") && values === "Current Insurance") ? 7 :
                                                                                                                                        ((flowName === "purchageNoConstYes" || flowName === "purchaseYesConstEmpty") && values === "Policy Details") ? 8 :
                                                                                                                                            ((flowName === "purchageNoConstYes" || flowName === "purchaseYesConstEmpty") && values === "Property Details") ? 9 :
                                                                                                                                                ((flowName === "purchageNoConstYes" || flowName === "purchaseYesConstEmpty") && values === "Pet Details") ? 10 :
                                                                                                                                                    ((flowName === "purchageNoConstYes" || flowName === "purchaseYesConstEmpty") && values === "Security Alarm") ? 11 :
                                                                                                                                                        (flowName === "pursaceNo" && values === "Personal Details") ? 1 :
                                                                                                                                                            (flowName === "pursaceNo" && values === "Purchase Details") ? 2 :
                                                                                                                                                                (flowName === "pursaceNo" && values === "Current Insurance Details") ? 3 :
                                                                                                                                                                    (flowName === "pursceYes" && values === "Personal Details") ? 1 :
                                                                                                                                                                        (flowName === "pursceYes" && values === "Purchase Details") ? 2 :
                                                                                                                                                                            (flowName === "purchaseYesConstNewYes" && values === "Personal Details") ? 1 :
                                                                                                                                                                                (flowName === "purchaseYesConstNewYes" && values === "Spouse Details") ? 2 :
                                                                                                                                                                                    (flowName === "purchaseYesConstNewYes" && values === "Purchase Details") ? 3 :
                                                                                                                                                                                        (flowName === "purchaseYesConstNewYes" && values === "Home Details") ? 4 :
                                                                                                                                                                                            (flowName === "purchaseYesConstNewYes" && values === "Construction Details") ? 5 :
                                                                                                                                                                                                (flowName === "purchaseYesConstNewYes" && values === "Roof Details") ? 6 :
                                                                                                                                                                                                    (flowName === "purchaseYesConstNewYes" && values === "Property Details") ? 9 :
                                                                                                                                                                                                        (flowName === "purchaseYesConstNewYes" && values === "Pet Details") ? 10 :
                                                                                                                                                                                                            (flowName === "purchaseYesConstNewYes" && values === "Security Alarm") ? 11 :
                                                                                                                                                                                                                (flowName === "purchaseYesConstNo" && values === "Personal Details") ? 1 :
                                                                                                                                                                                                                    (flowName === "purchaseYesConstNo" && values === "Spouse Details") ? 2 :
                                                                                                                                                                                                                        (flowName === "purchaseYesConstNo" && values === "Purchase Details") ? 3 :
                                                                                                                                                                                                                            (flowName === "purchaseYesConstNo" && values === "Home Details") ? 4 :
                                                                                                                                                                                                                                (flowName === "purchaseYesConstNo" && values === "Construction Details") ? 5 :

                                                                                                                                                                                                                                    (flowName === "purchaseYesConstNo" && values === "Roof Details") ? 6 :
                                                                                                                                                                                                                                        (flowName === "purchaseYesConstNo" && values === "Property Details") ? 9 :
                                                                                                                                                                                                                                            (flowName === "purchaseYesConstNo" && values === "Pet Details") ? 10 :
                                                                                                                                                                                                                                                (flowName === "purchaseYesConstNo" && values === "Security Alarm") ? 11 : 1

                                                                )
                                                            }}




                                                                style={{ cursor: "pointer" }}>
                                                                <a className="dropdown-item py-2 gib-tooltip" href="#">

                                                                    {values}

                                                                    <span className="gib-tooltip"> <img src={edit} className="edit-icon ms-2" /><span className="tooltiptext">
                                                                        {"Go to Step" + " " + (index + 1)}</span></span>

                                                                </a>
                                                            </li> : null
                                                    )
                                                }
                                            )
                                        }

                                        {/* previous steps for provide yes */}
                                        {/* {(jsonData[0].preferredDetails === "Provide us all the details" && jsonData[0].constNew === "No" && (jsonData[0].purchaseDetails === "No" || jsonData[0].purchaseDetails === "")) ? pagesForProvideUsAlldetailsNo.map((values: any, index: any) => {
                                            return (

                                                (index < crossedpages) ?
                                                    < li className="list-unstyled px-0 font16 font-primary " onClick={() => { setCurrentpage(index + 1) }} style={{ cursor: "pointer" }}>
                                                        <a className="dropdown-item py-2 gib-tooltip" href="#">
                                                            {values}
                                                            <span className="gib-tooltip"> <img src={edit} className="edit-icon ms-2" /><span className="tooltiptext">
                                                                {"Go to Step" + " " + (index + 1)}</span></span>
                                                        </a>
                                                    </li> : null
                                            )
                                        }
                                        )
                                            :
                                            (jsonData[0].preferredDetails === "Provide us all the details" && (jsonData[0].constNew === "Yes" || jsonData[0].constNew === "") && (jsonData[0].purchaseDetails === "No" || jsonData[0].purchaseDetails === "")) ? pagesForProvideUsAlldetailsYes.map((values: any, index: any) => {
                                                return (

                                                    (index < crossedpages) ?
                                                        < li className="list-unstyled px-0 font16 font-primary" onClick={() => {
                                                            (index >= 5) ?
                                                                setCurrentpage(index + 2) : setCurrentpage(index + 1)
                                                        }}
                                                        >
                                                            <a className="dropdown-item py-2 gib-tooltip" href="#">
                                                                {values}
                                                                <span className="gib-tooltip"> <img src={edit} className="edit-icon ms-2" /><span className="tooltiptext">
                                                                    {"Go to Step" + " " + (index + 1)}</span></span>
                                                            </a>

                                                        </li> : null
                                                )
                                            }
                                            ) :
                                                (jsonData[0].preferredDetails === "Provide us all the details" && (jsonData[0].constNew === "Yes" || jsonData[0].constNew === "") && (jsonData[0].purchaseDetails === "Yes")) ? pagesforperviousno.map((values: any, index: any) => {
                                                    return (
                                                        (index < crossedpages) ?
                                                            < li className="list-unstyled px-0 font16 font-primary" onClick={() => {
                                                                (index >= 4) ?
                                                                    setCurrentpage(index + 1) :

                                                                    setCurrentpage(index + 1)
                                                            }}
                                                            >
                                                                <a className="dropdown-item py-2 gib-tooltip" href="#">
                                                                    {values}
                                                                    <span className="gib-tooltip"> <img src={edit} className="edit-icon ms-2" /><span className="tooltiptext">
                                                                        {"Go to Step" + " " + (index + 1)}</span></span>
                                                                </a>

                                                            </li> : null
                                                    )
                                                }
                                                )
                                                    :

                                                    (jsonData[0].preferredDetails === "Provide us all the details" && (jsonData[0].constNew === "No") && (jsonData[0].purchaseDetails === "Yes")) ? pagesforpreviousyes.map((values: any, index: any) => {
                                                        return (
                                                            (index < crossedpages) ?
                                                                < li className="list-unstyled px-0 font16 font-primary" onClick={() => {
                                                                    (index >= 4) ?
                                                                        setCurrentpage(index + 1) : setCurrentpage(index + 1)
                                                                }}
                                                                >
                                                                    <a className="dropdown-item py-2 gib-tooltip" href="#">
                                                                        {values}
                                                                        <span className="gib-tooltip"> <img src={edit} className="edit-icon ms-2" /><span className="tooltiptext">
                                                                            {"Go to Step" + " " + (index + 1)}</span></span>
                                                                    </a>

                                                                </li> : null
                                                        )
                                                    }
                                                    )

                                                        : (jsonData[0].preferredDetails === "Just give key points" && jsonData[0].purchaseDetails === "No") ? jsuTGive.map(
                                                            (values: any, index: any) => {
                                                                return (

                                                                    (index < crossedpages) ?
                                                                        < li className="list-unstyled px-0 font16 font-primary" onClick={() => {
                                                                            setCurrentpage(index + 1)
                                                                        }} title={"Go to step" + (index + 1)} style={{ cursor: "pointer" }}>
                                                                            <a className="dropdown-item py-2 gib-tooltip" href="#" >
                                                                                {values}
                                                                                <span className="gib-tooltip"> <img src={edit} className="edit-icon ms-2" /><span className="tooltiptext">
                                                                                    {"Go to Step" + " " + (index + 1)}</span></span>
                                                                            </a>

                                                                        </li> : null
                                                                )

                                                            }
                                                        ) : (jsonData[0].preferredDetails === "Just give key points" && (jsonData[0].purchaseDetails === "" || jsonData[0].purchaseDetails === "Yes")) ? justGives.map(
                                                            (values: any, index: any) => {
                                                                return (

                                                                    (index < crossedpages) ?
                                                                        < li className="list-unstyled px-0 font16 font-primary" onClick={() => {
                                                                            setCurrentpage(index + 1)
                                                                        }} title={"Go to step" + (index + 1)} style={{ cursor: "pointer" }}>
                                                                            <a className="dropdown-item py-2 gib-tooltip" href="#" >
                                                                                {values}
                                                                                <span className="gib-tooltip"> <img src={edit} className="edit-icon ms-2" /><span className="tooltiptext">
                                                                                    {"Go to Step" + " " + (index + 1)}</span></span>
                                                                            </a>

                                                                        </li> : null
                                                                )

                                                            }
                                                        ) : null
                                        } */}


                                    </div></React.Fragment> : null
                            }
                            {/* Previous Steps */}
                            {/* Need Help */}
                            <div
                                className={(crossedpages > 0) ? "col-md-12 border bg-light previous-steps-box border-bottom-0 p-2 mt-4" : "col-md-12 border bg-light p-2 previous-steps-box border-bottom-0"}>
                                <label className="ps-2 font-16 font-bold">Need Help <span>?</span></label>

                            </div>
                            <div className="col-md-12 border border-top-0 previous-steps-box">
                                <label className="ps-2 w-100 mt-3 font16 font-semibold">The Gibraltar Group</label>
                                <label className="ps-2 w-100 mt-2 mb-3 font14">(281) 681-0331</label>
                            </div>
                            {/* Need Help */}
                            {/* Home button */}
                            <button type="button" onClick={() => window.location.href = '/'} className="btn btn-primary mt-4 py-2 primary-button border-0 font14 home-button mb-4"><img src={previousLogo} alt="auto" className="me-1" /> Back to Home</button>
                            {/* Home button */}
                        </div>
                    </div>
                    {/* Right panel starts Here */}

                </div>
            </div>
        </React.Fragment>

        //privious policy


    );
}

