/*
   // Project: Gibraltar
   // Desc: This file contains the  Navigation component which will be binded in every component;
   // Author:Harshanth Raaj
   // Created Date:04/18/2022
   //File Name : mainHeader.tsx;
*/

import React, { useEffect, useState } from 'react'
import gibLogo from '../images/Blue-Logo.svg'
import Hamlogo from '../images/Ham-Menu.svg';
import mobile_help from "../images/Mobile-help-icon.svg"

export default function MainHeader() {

  const [mainmenu, setmainmenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.screen.width);

  useEffect(() => { checkScreen() }, [screenWidth]);



  console.log(screenWidth);

  function onClick() {

    if (mainmenu === true) {
      setmainmenu(false);
    } else if (mainmenu === false ) {
      setmainmenu(true);
    }
  }


  function checkScreen() {
    if (screenWidth > 998) {
      setmainmenu(false);
    } else {
      console.log("fine")
    }
  }

  // if (window.screen.width > 998) {
  //   setmainmenu(false);
  // }








  return (
    <React.Fragment>
      {window.location.pathname != "/" ?
        <div>
          {/* Navigation starts here */}
          <nav className="navbar navbar-expand-lg navbar-light">
            {/* Container wrapper */}
            <div className="container-fluid nav-wrapper py-2 px-0 mb-4">

              <a className="navbar-brand" href="https://www.thegibraltargrp.com/">
                <img
                  src={gibLogo}
                  alt=""
                  className="d-inline-block align-text-top gibraltar-logo"
                />
              </a>
              <div className='float-end'>
                <a className="navbar-toggler gib-tooltip-mobile" type="button" data-mdb-toggle="collapse" data-mdb-target="#navbarRightAlignExample" aria-controls="navbarRightAlignExample" aria-expanded="false" aria-label="Toggle navigation">
                  <img src={mobile_help} alt="ham-menu" />

                  <span className='.gib-tooltip-mobile'>
                    <div className='tooltiptext-mobile'>
                      <div className="col-md-12 border bg-light p-2 text-start mt-4 border-bottom-0">
                        <label className="ps-2 font-16 font-bold insurance-type">Need Help?</label>
                      </div>
                      <div className="col-md-12 border border-top-0 bg-white text-start" >
                        <label className="ps-2 w-100 mt-3 font16 font-semibold">The Gibraltar Group</label>
                        <label className="ps-2 w-100 mt-2 mb-3 font14">(281) 681-0331</label>
                      </div>
                    </div>
                  </span>
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-mdb-toggle="collapse"
                  data-mdb-target="#navbarRightAlignExample"
                  aria-controls="navbarRightAlignExample1"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                // onClick={() => setmainmenu(true)}
                >
                  <img src={Hamlogo} alt="ham-menu" onClick={() => {mainmenu===false?setmainmenu(true):setmainmenu(false)} }/>
                </button>
              </div>

              <div className={mainmenu === false ?`collapse navbar-collapse pt-3 `: 'collapse navbar-collapse show pt-3'} id="navbarNavDropdown">
          {/* Left links */}
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-lg-flex d-block align-items-center">
            <li className="nav-item mobile-nav-item ">
              <a
                className="nav-link text-uppercase text-decoration-none font14 font-primary font-semibold"
                href="https://www.thegibraltargrp.com/insurance-solutions"
              >
                insurance
              </a>
            </li>
            <li className="nav-item mobile-nav-item ">
              <a
                className="nav-link text-uppercase text-decoration-none font14 font-primary font-semibold"
                href="https://www.gibraltarlending.com/"
              >
                mortgage
              </a>
            </li>
            <li className="nav-item mobile-nav-item ">
              <a
                className="nav-link text-uppercase font14 font-primary font-semibold"
                href="https://www.thegibraltargrp.com/title"
              >
                title
              </a>
            </li>
            <li className="nav-item mobile-nav-item ">
              <a
                className="nav-link text-uppercase font14 font-primary font-semibold"
                href="https://www.thegibraltargrp.com/medicare"
              >
                medicare
              </a>
            </li>
            <li className="nav-item mobile-nav-item ">
              <a
                className="nav-link text-uppercase font14 font-primary  font-semibold"
                href="https://www.thegibraltargrp.com/resources"
              >
                resources
              </a>
            </li>
            <li className="nav-item mobile-nav-item ">
              <a
                className="nav-link text-uppercase font14 font-primary  font-semibold"
                href="https://www.thegibraltargrp.com/our-difference"
              >
                About Us
              </a>
            </li>
            <li className="nav-item mobile-nav-item ">
              <a
                className="nav-link text-uppercase font14 font-primary  font-semibold"
                href="https://www.thegibraltargrp.com/careers"
              >
                careers
              </a>
            </li>
            <li className="nav-list-item mobile-nav-item landingPagebefore"><div className="nav-contact-btn-container"><a href="https://www.thegibraltargrp.com/contact" className="nav-link-contact-white ps-0 w-inline-block font14 font-primary  font-semibold text-uppercase">
              <div className="white-btn-text">Contact Us</div>
            </a>
            </div>
            </li>
          </ul>
          {/* Left links */}
        </div>
              
            </div>
            </nav>
            </div> : null}
            </React.Fragment>
  )}
