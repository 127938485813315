export const JsonApiqa ={



    "commercialinsuranceApi" :`https://gibraltar-swf-qa-af.azurewebsites.net/api/commercialInsuranceApi?code=5Aiq90sZZj6z256xa9cac3BgbU7bgFYuoJLWO/aai1U9QN4KfbSG4Q==`,



    "FinancialinsruanceAPi":`https://gibraltar-swf-qa-af.azurewebsites.net/api/financialInsuranceApi?code=0e98rqCLwUs2CS0BKZkyEUS5RJhYJHjklksYYzjqt0FYJ3ad/ZbQfQ==`,



    "personalInsuranceApi":`https://gibraltar-swf-qa-af.azurewebsites.net/api/personalInsuranceApi?code=Vzj9hcOzrwP7DTKfnwZ7QvVszcjNC/GELK6FfD6mOwpq7iaqhY0X9w==`,



    "autoInsuranceApi":  `https://gibraltar-swf-qa-af.azurewebsites.net/api/autoInsuranceApi?code=fQ5hLS4oQgSL6TudYFKn51pPIqatSAdeudmPSmOjEMQ3r2rJYyY31Q==`

}

export const JsonApi ={



    "commercialinsuranceApi" :`https://gibraltar-swf-prod-azurefunc.azurewebsites.net/api/commercialInsuranceApi?code=o3hPjb4qkmDeylqD8bvQGK_H57mp4PfJvFvalceo2oXuAzFuijMWQQ==`,



    "FinancialinsruanceAPi":`https://gibraltar-swf-prod-azurefunc.azurewebsites.net/api/financialInsuranceApi?code=pNi9lbZT_TpZUQ6h7RNpCV0M8QMTgSrMyrzQEwSdd4AaAzFunv-_nQ==`,



    "personalInsuranceApi":`https://gibraltar-swf-prod-azurefunc.azurewebsites.net/api/personalInsuranceApi?code=rkrBiiUUc4psxp7BJcAWf6WuKfb--ET8i9f9oRWEKqw6AzFuB8cMHQ==`,



    "autoInsuranceApi":  `https://gibraltar-swf-prod-azurefunc.azurewebsites.net/api/autoInsuranceApi?code=q4QJgvKLQ9M9U2LBmJ_GrfDQZcxW5ECMhMii-sKYXzrZAzFu6xZJWw==`

}

export const JsonApiDev={



    "commercialinsuranceApi": `https://gibraltar-swf-dev-af.azurewebsites.net/api/commercialInsuranceApi?code=wY9AF/vmRBTvio/WOsQSgSyQQXG7x4hTSAwvviZPiA84vnhaRJf1WA==`,





    "FinancialinsruanceAPi": `https://gibraltar-swf-dev-af.azurewebsites.net/api/financialInsuranceApi?code=cSTlLuoVwZ7SgMEsJU075XFQEz0hh03q940tMIyGg4LjGWNdcykThQ==`,





    "personalInsuranceApi": `https://gibraltar-swf-dev-af.azurewebsites.net/api/personalInsuranceApi?code=E9kZZIOGIWrejoxT2TYEgePMAwV98dn2tIy7Ey3xwaapKjqcKcWfuQ==`,



    "autoInsuranceApi": `https://gibraltar-swf-dev-af.azurewebsites.net/api/autoInsuranceApi?code=9p1erS1Bm0t4D5JvwZwauPObCAOBzooxmsfaz0SYFZjkS6C44Dq1vA==`



}