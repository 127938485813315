/*
   // Project: Gibraltar
   // Desc: This file contains the Advisor Contact Case form insurance component by using this component the user will able to request quote ;
   // Author:Harshanth Raaj
   // Created Date:04/04/2022
   //File Name : advisorContactCase.tsx;
*/

//importing usestate
import { useState } from "react"
import '../App.css';
import arrowLeftLogo from '../images/Arrow-Left-White.svg';
import arrowRightLogo from '../images/Arrow-right.svg';
import { postInsuranceQuoteData } from "./service"
import axios from "axios";

//declaring a main component & exporting it
export default function ContactCaseForm() {

  var QueryString = new URLSearchParams(window.location.search);
  let parameterId = QueryString.get("parameterId");
  let client = QueryString.get("client");

  //PC_ACL_01
  /*declaring state variables for every fields as empty strings
  ie., for First Name, Last Name, Email, Help dropdown
  */
  const [stateArr, setStateArr] = useState([{ firstName: "", lastName: "", email: "", zip: "", helpDDL: "", message: "" }])

  //declaring this state variable to display a buffer during the routing period to thank you page
  const [Loading, setLoading] = useState(false)

  //declaring a common single state variable with boolean value 'false' 
  const [valid, setValid] = useState(false)

  //these two state variables for regular expression validation
  const [emailRE, setEmailRE] = useState(false)
  const [zipRE, setZipRE] = useState(false)

  //PC_ACL_02
  /**function that maintains onChange events  for every fields
   value of 'stateArr' gets stored in a constant list.
   */
  function handleServiceChange(e: any) {
    const list: any = { ...stateArr };
    const val = e.target.name;
    //now assigning the value of state variable to list
    list[0][e.target.name] = e.target.value;

    if (e.target.name === "zip") {

      list[0][e.target.name] = list[0][e.target.name].replace(/[^0-9]/g, '');

      setZipRE(false)

      if (list[0].zip.length < 5) {

        setZipRE(true)

      }

      else {

        setZipRE(false)

      }

    }
    if (e.target.name === 'email') {
      var regExEmail = /^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)*[\w+-]+\.[a-zA-Z]{2,4}$/;
      if (!(stateArr[0].email.match(regExEmail))) {

        setEmailRE(true)
      }
      if (stateArr[0].email.match(regExEmail)) {
        setEmailRE(false)
      }
    }
    setStateArr(list);
  }



  //PC_ACL_03
  /*declaring a function that does normal validation as well as regular expression validation
  if the condition is not met,error pops up
  */
  function validation() {
    var error = 0

    //setting the state variable 'valid' to true if the first name/last name/email/help is empty & incrementing the error value
    if (stateArr[0].firstName.trim() === "") {
      setValid(true)
      error++
    }
    if (stateArr[0].lastName.trim() === "") {
      setValid(true)
      error++
    }

    if (stateArr[0].email === "") {
      setValid(true)
      error++
    }
    if (stateArr[0].email !== "") {
      var regExEmail = /^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)*[\w+-]+\.[a-zA-Z]{2,4}$/;
      if (!(stateArr[0].email.match(regExEmail))) {
        setValid(true)
        setEmailRE(true)
        error++
      }
      if (stateArr[0].email.match(regExEmail)) {
        setEmailRE(false)
      }
    }

    if (stateArr[0].zip === "") {
      setValid(true)
      error++
    }

    if (stateArr[0].helpDDL === "") {
      setValid(true)
      error++
    }

    //PC_ACL_04

    /*checking if email is not empty & declared a variable which stores the regular expression pattern for email
     & also checking if the email is not matching with variable 'regExEmail' & setting 'emailRE'to true*/

    /*checking if zip is not empty & declared a variable which stores the regular expression pattern for zip code
     & also checking if the zip is not matching with variable 'regExZip' & setting 'zipRE'to true*/
    if (stateArr[0].zip !== "") {
      var regExZip = /^\d{5}(-\d{4})?$/;
      if (!(stateArr[0].zip.match(regExZip))) {
        setValid(true)
        setZipRE(true)
        error++
      }
      if (stateArr[0].zip.match(regExZip)) {
        setZipRE(false)
      }
    }
    //"dataPost()" is triggered by ckecking if every field is non-empty & variable 'error' is '0' 
    if (stateArr[0].firstName !== "" && stateArr[0].lastName !== "" && stateArr[0].email != "" && stateArr[0].zip != "" && stateArr[0].helpDDL != "") {
      if (error == 0) {
        datapost()
      }
    }

  }

  //PC_ACL_05
  /*gets the value of every fields in the form of json & an service call for posting the data
  */
  async function datapost() {
    let arr = {
      FirstName: stateArr[0].firstName.trimStart().trimEnd(),
      LastName: stateArr[0].lastName.trimStart().trimEnd(),
      Email: stateArr[0].email,
      help: stateArr[0].helpDDL,
      Zipcode: stateArr[0].zip,
      Description: (stateArr[0].message !== "") ? stateArr[0].message : null,
      parameterId: parameterId
    }
    setLoading(true)

    //PC_ACL_06
    let datas = await postInsuranceQuoteData(arr, 'Advisor Contact 2')
    // let datas = await axios.post(`https://gibraltar-swf-dev-af.azurewebsites.net/api/commercialInsuranceApi?code=wY9AF/vmRBTvio/WOsQSgSyQQXG7x4hTSAwvviZPiA84vnhaRJf1WA==&type=Advisor Contact 2`,arr)
    // console.log(datas.data)

    //PC_ACL_07
    //navigates to 'Thank you' page after successfull validation 
    window.location.href = '/thankyou'
  }



  return (
    <div>

      <div className="container mt-5">
        <div className="row mt-4">
          {/* Form starts here */}
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="row">
              {/* Header */}

              <div className="col-lg-11 col-md-12 col-sm-12">
                <div className="row">
                  {/* Steps indicator header */}
                  <div className="col-md-12 mt-2">
                    <div className="float-start w-100">
                      <label className="font24 font-bold font-head">Have an advisor get in touch.</label>
                    </div>
                    <div className="float-end w-25 advisory-required"><label className="font14 float-end others-required">*Required</label></div>
                  </div>

                  {/* Steps indicator header */}
                  {/* Header */}
                  {/* Form-Component */}
                  {/* General Info Starts here */}
                  <div className="col-md-12  mt-2">
                    <label className="font16 font-primary w-100">Just want someone to give you a quick call, fill out the form below. Let us know how
                    </label>
                    <label className="font16 font-primary border-bottom pb-3 w-100">we can help and one of our licensed agents will give you a call</label>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label htmlFor="label1" className="form-label font16 font-semibold">First Name*</label>
                    <input type="text" className="form-control pb-2 pt-2" id="label1" name="firstName" maxLength={40}
                      value={stateArr[0].firstName}
                      onChange={(e) => { handleServiceChange(e) }}
                    />
                    <label className="text-danger font14">{(valid === true && stateArr[0].firstName.trim() === "") ? "Please enter the First Name" : null}</label>
                    {/* <label className="text-danger font14">{(nameRE ===true)?"Enter Valid Name" : null}</label> */}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label htmlFor="label2" className="form-label font16 font-semibold">Last Name*</label>
                    <input type="text" className="form-control pb-2 pt-2" id="label2" name="lastName" maxLength={80}
                      value={stateArr[0].lastName}
                      onChange={(e) => { handleServiceChange(e) }}
                    />
                    <label className="text-danger font14">{(valid === true && stateArr[0].lastName.trim() === "") ? "Please enter the Last Name" : null}</label>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label htmlFor="label8" className="form-label font16 font-semibold">Email*</label>
                    <input type="email" className="form-control pb-2 pt-2" id="label8" name="email" maxLength={80}
                      value={stateArr[0].email}
                      onChange={(e) => { handleServiceChange(e) }}
                    />
                    <label className="text-danger font14">{(valid === true && stateArr[0].email === "") ? "Please enter an Email" :
                      (valid === true && emailRE === true) ? "Please enter a valid Email" : null}</label>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label htmlFor="label9" className="form-label font16 font-semibold">Zip Code*</label>
                    <input type="text" className="form-control pb-2 pt-2" id="label9" name="zip" maxLength={5}
                      value={stateArr[0].zip}
                      onChange={(e) => { handleServiceChange(e) }}
                    />
                    <label className="text-danger font14">{(valid === true && stateArr[0].zip === "") ? "Please enter the Zip/Postal Code" :
                      (valid === true && zipRE === true) ? "Please enter the valid Zip/Postal code" : null}</label>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label htmlFor="label3" className="form-label font16 font-semibold">How can we help?*</label>
                    {/* <input type="date" className="form-control font14 text-uppercase" id="label3"/> */}
                    <select className="form-control form-select font14  pb-2 pt-2" id="label3"
                      name="helpDDL"
                      value={stateArr[0].helpDDL}
                      onChange={(e) => { handleServiceChange(e) }}
                    >
                      <option value={""}>-Select an Item-</option>
                      <option value={"General Billing Questions"}>General Billing Questions</option>
                      <option value={"General Change Request"}>General Change Request   </option>
                      <option value={"Mortgage Change Request"}>Mortgage Change Request  </option>
                      <option value={"Proof of Insurance"}>Proof of Insurance</option>
                      <option value={"Quote Request"}>Quote Request</option>
                      <option value={"Underwriting"}>Underwriting</option>
                      <option value={"Client Cancel Request"}>Client Cancel Request </option>
                    </select>
                    <label className="text-danger font14">{(valid === true && stateArr[0].helpDDL === "") ? "Please select your choice" : null}</label>
                  </div>
                  {/* General Info Ends Here */}
                  {/* Communication Info Starts Here */}

                  <div className="col-md-12 mt-3">
                    <label htmlFor="label6" className="form-label font16 font-semibold">Message</label>
                    <textarea className="form-control text-area-input" id="label6" rows={6}
                      name="message"
                      value={stateArr[0].message}
                      onChange={(e) => { handleServiceChange(e) }}
                      maxLength={255}
                    // defaultValue={""} 
                    />
                  </div>

                  {/* Communication Info Ends Here */}
                  {/* <div className="col-md-12 border-bottom mt-5 mb-2">
                  </div> */}
                  <div><label className="col-md-12 border-bottom mt-4 mb-2 pb-3 w-100"></label></div>

                  {/* Footer */}
                  <div className="col-md-12 mt-4 mb-4">

                    <button type="button" className="btn btn-primary mt-1 primary-button border-0 font14 home-button px-5 float-end mobile-button"
                      onClick={() => { validation() }}>
                      Submit Request</button>
                  </div>
                  {/* Footer */}
                  {/* Form-Component */}
                  {Loading == true ? <div id="root">

                    <h1>Submitting Your Request</h1>

                    <h3>Please Wait for few seconds....</h3>

                    <div className="loader-wrapper">

                      <div className="loader"><span></span> <span></span> <span></span> <span></span> <span></span> </div>

                    </div>

                  </div> : null}
                </div>
              </div>
            </div>
          </div>
          {/* Form starts here */}
          {/* Right panel starts Here */}
          {/* <div className="col-md-4 right-panel-margin"> */}
          <div className="col-md-4 hide-right">
            <div className="row mx-0">

              {/* Need Help */}
              <div className="col-md-12 border bg-light p-2 mt-4 previous-steps-box border-bottom-0">
                <label className="ps-2 font-16 font-bold">Need Help?</label>
              </div>
              <div className="col-md-12 border border-top-0 previous-steps-box">
                <label className="ps-2 w-100 mt-2 font16 font-semibold">The Gibraltar Group</label>
                <label className="ps-2 w-100 mt-2 mb-3 font14">(281) 681-0331</label>
              </div>
              {/* Need Help */}
              {/* Home button */}
              <button type="button" className="btn btn-primary mt-4 py-2 primary-button border-0 font14 home-button" onClick={() => { if (client) window.location.href = '/'; else window.location.href = `https://www.thegibraltargrp.com/insurance-solutions` }}><img src={arrowLeftLogo} alt="auto" className="me-1" /> Back to Home</button>
              {/* Home button */}
            </div>
          </div>
          {/* Right panel starts Here */}
        </div>
      </div>
    </div>
  )
}