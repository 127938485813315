/*
   // Project: Gibraltar
   // Desc: This file contains the various forms by using this component the user will able to request quote for their opinion;
   // Author:bharathwaj
   // Created Date:04/07/2022
   //File Name : landingpage.tsx;
*/

import react, { useState } from "react";
import homeWhite from "../images/Home-white.svg";
import homeBg from "../images/Home.svg";
import autoWhite from "../images/Auto-White.svg";
import medicareLogoBlack from '../images/medicareLogoBlack.png'
import medicareLogoWhite from '../images/medicareWhite.png'
import floodWhite from "../images/Flood-white.svg";
import lifeWhite from "../images/Life-white.svg";
import commercialWhite from "../images/Commercial-white.svg";
import otherlWhite from "../images/Other-white.svg";
import existing_client_hover from "../images/existing_client-hover.svg";
import existing_client from "../images/existing_client.svg";
import autoIcon from "../images/Auto.svg";
import floodIcon from "../images/Flood.svg";
import lifeIcon from "../images/Life.svg";
import commercialIcon from "../images/Commercial.svg";
import othesrIcon from "../images/Other.svg";
import gibraltarlogo from "../images/Gibraltar-Logo.svg";
import backicon from "../images/Back-icon.svg";
import whitemenu from "../images/White-ham-menu.svg";
import React from "react";

export default function Landingpage() {
  const [options, setOptions] = useState("basic");

  var QueryString = new URLSearchParams(window.location.search);
  let OwnerId = QueryString.get("agentId");
  let parameterId = QueryString.get("parameterId");

  const [mainmenu, setmainmenu] = useState(true);
  // const[queryString,setqueryString]=useState("")
  let queryString: any = "";
  if (OwnerId != null) {
    queryString = `/?agentId=${OwnerId}`;
  } else if (parameterId != null) {
    queryString = `/?parameterId=${parameterId}`;
  }

  function FormDisplay() {
    debugger;
    setOptions("commercial");
  }
  function FormDisplay1() {
    setOptions("basic");
  }
  function onClick() {
    if (mainmenu === false) {
      setmainmenu(true);
    } else if (mainmenu === true) {
      setmainmenu(false);
    }
  }

  return (
    <>
      {options === "basic" ? (
        <div className="bg-color">
          <nav className="navbar navbar-expand-lg navbar-light">
            {/* Container wrapper */}
            <div className="container-fluid nav-wrapper mt-4">
              <a
                className="navbar-brand"
                href="https://www.thegibraltargrp.com/"
              >
                <img
                  src={gibraltarlogo}
                  alt=""
                  className="d-inline-block align-text-top gibraltar-logo"
                />
              </a>
              <button
                className="navbar-toggler float-end"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#navbarRightAlignExample"
                aria-controls="navbarRightAlignExample"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => onClick()}
              >
                <img src={whitemenu} alt="ham-menu" />
              </button>

              {/* Collapsible wrapper */}

              <div
                className={
                  mainmenu == true
                    ? `collapse navbar-collapse `
                    : "collapse navbar-collapse show"
                }
                id="navbarNavDropdown"
              >
                {/* Left links */}
                {/* LP_PS_01*/}
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-lg-flex d-block align-items-center">
                  <li className="nav-item mobile-nav-item ">
                    <a
                      className="nav-link link-white active text-white text-uppercase font14 font-semibold font-primary"
                      aria-current="page"
                      href="https://www.thegibraltargrp.com/insurance-solutions"
                    >
                      insurance
                    </a>
                  </li>
                  <li className="nav-item mobile-nav-item ">
                    <a
                      className="nav-link link-white text-white text-uppercase font14  font-semibold font-primary"
                      href="https://www.gibraltarlending.com/"
                    >
                      Mortgage
                    </a>
                  </li>
                  <li className="nav-item mobile-nav-item ">
                    <a
                      className="nav-link link-white text-white text-uppercase font14  font-semibold font-primary"
                      href="https://www.thegibraltargrp.com/title"
                    >
                      title
                    </a>
                  </li>
                  <li className="nav-item mobile-nav-item ">
                    <a
                      className="nav-link link-white text-white text-uppercase font14  font-semibold font-primary"
                      href="https://www.thegibraltargrp.com/medicare"
                    >
                      Medicare
                    </a>
                  </li>
                  <li className="nav-item mobile-nav-item ">
                    <a
                      className="nav-link link-white text-white text-uppercase font14  font-semibold font-primary"
                      href="https://www.thegibraltargrp.com/resources"
                    >
                      resources
                    </a>
                  </li>
                  <li className="nav-item mobile-nav-item ">
                    <a
                      className="nav-link link-white text-white text-uppercase font14  font-semibold font-primary"
                      href="https://www.thegibraltargrp.com/our-difference"
                    >
                      About Us
                    </a>
                  </li>
                  <li className="nav-item mobile-nav-item ">
                    <a
                      className="nav-link link-white text-white text-uppercase font14  font-semibold font-primary"
                      href="https://www.thegibraltargrp.com/careers"
                    >
                      careers
                    </a>
                  </li>
                  <li className="nav-list-item contact-desktop">
                    <div className="nav-contact-btn-container">
                      <a
                        href="https://www.thegibraltargrp.com/contact"
                        className="nav-link  text-uppercase font14  font-semibold font-primary"
                      >
                        <div className="white-btn-text-landing">Contact Us</div>
                      </a>
                      <div className="w-embed"></div>
                    </div>
                  </li>
                </ul>
                {/* Left links */}
              </div>

              {/* Collapsible wrapper */}
            </div>

            {/* Container wrapper */}
          </nav>

          {/* Types of Insurance */}

          {/* LP_PS_02 */}
          <div className="container">
            <div className="row justify-content-center landing-container">
              <div className="col-md-10 col-sm-12 col-lg-8 options-box text-center bg-white p-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row justify-content-center">
                      <label className="font-bold font-primary font32 text-center mb-3 insure-head">
                        What are we insuring today?
                      </label>
                      <div className="col-10 col-md-4 col-sm-6 mt-3">
                        <button
                          onClick={() =>
                            (window.location.href =
                              queryString != ""
                                ? `./autoInsurance` + queryString
                                : `./autoInsurance`)
                          }
                          type="button"
                          className="btn-lg btn btn-outline-primary  text-start col-md-12  landing-button"
                        >
                          <span className="icon-container me-3">
                            <img src={autoIcon} alt="auto" />
                            <img src={autoWhite} alt="auto" />
                          </span>
                          Auto
                        </button>
                      </div>
                      <div className="col-10 col-md-4 col-sm-6 mt-3">
                      <button onClick={() => window.location.href = '/medicare'} type="button" className="btn-lg btn btn-outline-primary  text-start col-md-12  landing-button">
                        <span className="icon-container me-3">
                          <img src={medicareLogoBlack} alt="medicare" />
                          <img src={medicareLogoWhite} alt="medicare" />
                        </span>Medicare</button>
                    </div>
                      <div className=" col-10 col-md-4 col-sm-6 mt-3">
                        <button
                          onClick={() =>
                            (window.location.href =
                              queryString !== ""
                                ? `./homeInsurance` + queryString
                                : `./homeInsurance`)
                          }
                          type="button"
                          className="btn-lg btn btn-outline-primary col-md-12  text-start  landing-button"
                        >
                          <span className="icon-container me-3">
                            <img src={homeBg} alt="auto" />
                            <img src={homeWhite} alt="auto" />
                          </span>
                          Home
                        </button>
                      </div>
                      <div className="col-10 col-md-4 col-sm-6 mt-3">
                        <button
                          onClick={() =>
                            (window.location.href =
                              queryString !== ""
                                ? `./floodInsurance` + queryString
                                : `./floodInsurance`)
                          }
                          type="button"
                          className="btn-lg btn btn-outline-primary col-md-12  text-start  landing-button"
                        >
                          <span className="icon-container me-3">
                            <img src={floodIcon} alt="auto" />
                            <img src={floodWhite} alt="auto" />
                          </span>
                          Flood
                        </button>
                      </div>
                      <div className="col-10 col-md-4 col-sm-6 mt-3">
                        <button
                          onClick={() =>
                            (window.location.href =
                              queryString !== ""
                                ? `./lifeInsurance` + queryString
                                : `./lifeInsurance`)
                          }
                          type="button"
                          className="btn-lg btn btn-outline-primary col-md-12 text-start  landing-button"
                        >
                          <span className="icon-container me-3">
                            <img src={lifeIcon} alt="auto" />
                            <img src={lifeWhite} alt="auto" />
                          </span>
                          Life
                        </button>
                      </div>

                      {/* LP_PS_04 */}
                      <div className="col-10 col-md-4 col-sm-6 mt-3">
                        <button
                          onClick={() => {
                            FormDisplay();
                          }}
                          type="button"
                          className="btn-lg btn btn-outline-primary  col-md-12 text-start  landing-button"
                        >
                          <span className="icon-container me-3">
                            <img src={commercialIcon} alt="auto" />
                            <img src={commercialWhite} alt="auto" />
                          </span>
                          Commercial
                        </button>
                      </div>
                      <div className="col-10 col-md-4 col-sm-6 mt-3">
                        {/* <button onClick={()=> window.location.href = (queryString !== "")? `./othersInsurance`+queryString : `./othersInsurance`} type="button" className="btn-lg btn btn-outline-primary col-md-12 text-start  landing-button"> */}
                        {/**
                         * Added the anchor tag for CR change
                         */}
                        <button
                          onClick={() =>
                            (window.location.href =
                              queryString !== ""
                                ? `./getInTouch/?client=true` +
                                  queryString.replace("/?", "&")
                                : `./getInTouch/?client=true`)
                          }
                          type="button"
                          className="btn-lg btn btn-outline-primary col-md-12 text-start  landing-button"
                        >
                          <span className="icon-container me-3">
                            <img src={existing_client} alt="auto" />
                            <img src={existing_client_hover} alt="auto" />
                            {/* </span>Other</button>  */}
                          </span>
                          Existing Client
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {options === "commercial" ? (
        <>
          <div className="bg-color">
            <nav className="navbar navbar-expand-lg navbar-light">
              {/* Container wrapper */}
              <div className="container-fluid nav-wrapper mt-4">
                <a className="navbar-brand" href="#">
                  <img
                    src={gibraltarlogo}
                    alt=""
                    className="d-inline-block align-text-top gibraltar-logo"
                  />
                </a>
                {/* Toggle button */}

                <button
                  className="navbar-toggler float-end"
                  type="button"
                  data-mdb-toggle="collapse"
                  data-mdb-target="#navbarRightAlignExample"
                  aria-controls="navbarRightAlignExample"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <img
                    src={whitemenu}
                    alt="ham-menu"
                    onClick={() => onClick()}
                  />
                </button>

                {/* Collapsible wrapper */}
                <React.Fragment>
                  {/* Left links */}
                  <div
                    className={
                      mainmenu == true
                        ? `collapse navbar-collapse `
                        : "collapse navbar-collapse show"
                    }
                    id="navbarNavDropdown"
                  >
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-lg-flex d-block align-items-center ">
                      <li className="nav-item mobile-nav-item">
                        <a
                          className="nav-link link-white active text-white text-uppercase font14 font-semibold font-primary"
                          aria-current="page"
                          href="https://www.thegibraltargrp.com/insurance-solutions"
                        >
                          insurance
                        </a>
                      </li>
                      <li className="nav-item mobile-nav-item">
                        <a
                          className="nav-link link-white text-white text-uppercase font14 font-semibold font-primary"
                          href="https://www.gibraltarlending.com/"
                        >
                          Mortgage
                        </a>
                      </li>
                      <li className="nav-item mobile-nav-item">
                        <a
                          className="nav-link link-white text-white text-uppercase font14 font-semibold font-primary"
                          href="https://www.thegibraltargrp.com/title"
                        >
                          title
                        </a>
                      </li>
                      <li className="nav-item mobile-nav-item">
                        <a
                          className="nav-link link-white text-white text-uppercase font14  font-semibold font-primary"
                          href="https://www.thegibraltargrp.com/medicare"
                        >
                          Medicare
                        </a>
                      </li>
                      <li className="nav-item mobile-nav-item">
                        <a
                          className="nav-link link-white text-white text-uppercase font14 font-semibold font-primary"
                          href="https://www.thegibraltargrp.com/resources"
                        >
                          resources
                        </a>
                      </li>
                      <li className="nav-item mobile-nav-item">
                        <a
                          className="nav-link link-white text-white text-uppercase font14 font-semibold font-primary"
                          href="https://www.thegibraltargrp.com/our-difference"
                        >
                          About Us
                        </a>
                      </li>
                      <li className="nav-item mobile-nav-item">
                        <a
                          className="nav-link link-white text-white text-uppercase font14 font-semibold font-primary"
                          href="https://www.thegibraltargrp.com/careers"
                        >
                          careers
                        </a>
                      </li>
                      <li className="nav-list-item contact-desktop">
                        <div className="nav-contact-btn-container">
                          <a
                            href="https://www.thegibraltargrp.com/contact"
                            className="nav-link  text-uppercase font14  font-semibold font-primary"
                          >
                            <div className="white-btn-text-landing">
                              Contact Us
                            </div>
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </React.Fragment>

                {/* Left links */}

                {/* Collapsible wrapper */}
              </div>
              {/* Container wrapper */}
            </nav>
            <div>
              {/* LP_PS_04 */}
              <div className="container">
                <div className="row justify-content-center landing-container">
                  <div className="col-md-10 col-sm-12 col-lg-8 options-box text-center bg-white p-5">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row justify-content-center">
                          <label className="font-bold  font32 text-center mb-3 insure-head mobile-font me-3">
                            {" "}
                            <span>
                              <img
                                src={backicon}
                                alt="back"
                                className="me-3"
                                onClick={() => {
                                  FormDisplay1();
                                }}
                              />
                            </span>
                            How can we help protect your business?
                          </label>
                          <div className="col-10 col-md-5 col-lg-5 col-sm-6 mt-3">
                            <button
                              onClick={() =>
                                (window.location.href =
                                  queryString !== ""
                                    ? `./generalLiabilityInsurance` +
                                      queryString
                                    : `./generalLiabilityInsurance`)
                              }
                              type="button"
                              className="btn-lg btn btn-outline-primary col-md-12 text-start landing-button"
                            >
                              <span className="icon-container commercial-icon pe-3">
                                <img src={commercialIcon} alt="auto" />
                                <img src={commercialWhite} alt="auto" />
                              </span>
                              General Liability
                            </button>
                          </div>
                          <div className="col-10 col-md-5 col-lg-5 col-sm-6 mt-3">
                            <button
                              onClick={() =>
                                (window.location.href =
                                  queryString !== ""
                                    ? `./commercialAutoInsurance` + queryString
                                    : `./commercialAutoInsurance`)
                              }
                              type="button"
                              className="btn-lg btn btn-outline-primary col-md-12 text-start landing-button"
                            >
                              <span className="icon-container me-3">
                                <img src={autoIcon} alt="auto" />
                                <img src={autoWhite} alt="auto" />
                              </span>
                              Commercial Auto
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
