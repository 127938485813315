/*
   // Project: Gibraltar
   // Desc: This file contains the  Header component which will be binded in every component;
   // Author:Harshanth Raaj
   // Created Date:04/18/2022
   //File Name : header.tsx;
*/

import react, { useState } from 'react';
import '../App.css';
import homeLogo from '../images/Home-Insurance.svg'
import comAutoLogo from '../images/Auto-Insurance.svg'
import floodLogo from '../images/Flood-Insurance.svg'
import lifeLogo from '../images/Life-Insurance.svg'
import genLiaLogo from '../images/General-Liability-Insurance.svg'
import autoLogo from '../images/Auto-Insurance.svg'
import React from 'react';
import { profileEnd } from 'console';
import infoicon from '../images/Info-Icon.svg'

export default function Header(props: any) {
  let image = homeLogo
  const [isOpen, setOpen] = useState(false)

  console.log(props, "QWERTYUIOPLKJHGFDSAZXCVBNM")

  if (props.value.Name === 'Flood Insurance') {
    image = floodLogo
  }
  if (props.value.Name === 'Life Insurance') {
    image = lifeLogo
  }
  if (props.value.Name === 'General Liability Insurance') {
    image = genLiaLogo
  }
  if (props.value.Name === 'Auto Insurance') {
    image = autoLogo
  }
  if (props.value.Name === 'Home Insurance') {
    image = homeLogo
  }
  if (props.value.Name === 'Commercial Auto Insurance') {
    image = comAutoLogo

  }

  console.log(props.value)

  { console.log(props.value.previousStepArry.length, "HEADER") }
  const PreviousClose = (index: any, values: any) => {

    if (props.value.Name === 'Home Insurance') {

      props.childToParent(
        (props.value.pageFlow === "purchageNoConstNo" && values === "Personal Details") ? 1 :
          (props.value.pageFlow === "purchageNoConstNo" && values === "Spouse Details") ? 2 :
            (props.value.pageFlow === "purchageNoConstNo" && values === "Purchase Details") ? 3 :
              (props.value.pageFlow === "purchageNoConstNo" && values === "Home Details") ? 4 :
                (props.value.pageFlow === "purchageNoConstNo" && values === "Construction Details") ? 5 :
                  (props.value.pageFlow === "purchageNoConstNo" && values === "Roof Details") ? 6 :
                    (props.value.pageFlow === "purchageNoConstNo" && values === "Current Insurance") ? 7 :
                      (props.value.pageFlow === "purchageNoConstNo" && values === "Policy Details") ? 8 :
                        (props.value.pageFlow === "purchageNoConstNo" && values === "Property Details") ? 9 :
                          (props.value.pageFlow === "purchageNoConstNo" && values === "Pet Details") ? 10 :
                            (props.value.pageFlow === "purchageNoConstNo" && values === "Security Alarm") ? 11 :
                              ((props.value.pageFlow === "purchageNoConstYes" || props.value.pageFlow === "purchaseYesConstEmpty") && values === "Personal Details") ? 1 :
                                ((props.value.pageFlow === "purchageNoConstYes" || props.value.pageFlow === "purchaseYesConstEmpty") && values === "Spouse Details") ? 2 :
                                  ((props.value.pageFlow === "purchageNoConstYes" || props.value.pageFlow === "purchaseYesConstEmpty") && values === "Purchase Details") ? 3 :
                                    ((props.value.pageFlow === "purchageNoConstYes" || props.value.pageFlow === "purchaseYesConstEmpty") && values === "Home Details") ? 4 :
                                      ((props.value.pageFlow === "purchageNoConstYes" || props.value.pageFlow === "purchaseYesConstEmpty") && values === "Construction Details") ? 5 :
                                        ((props.value.pageFlow === "purchageNoConstYes" || props.value.pageFlow === "purchaseYesConstEmpty") && values === "Current Insurance") ? 7 :
                                          ((props.value.pageFlow === "purchageNoConstYes" || props.value.pageFlow === "purchaseYesConstEmpty") && values === "Policy Details") ? 8 :
                                            ((props.value.pageFlow === "purchageNoConstYes" || props.value.pageFlow === "purchaseYesConstEmpty") && values === "Property Details") ? 9 :
                                              ((props.value.pageFlow === "purchageNoConstYes" || props.value.pageFlow === "purchaseYesConstEmpty") && values === "Pet Details") ? 10 :
                                                ((props.value.pageFlow === "purchageNoConstYes" || props.value.pageFlow === "purchaseYesConstEmpty") && values === "Security Alarm") ? 11 :
                                                  (props.value.pageFlow === "pursaceNo" && values === "Personal Details") ? 1 :
                                                    (props.value.pageFlow === "pursaceNo" && values === "Purchase Details") ? 2 :
                                                      (props.value.pageFlow === "pursaceNo" && values === "Current Insurance Details") ? 3 :
                                                        (props.value.pageFlow === "pursceYes" && values === "Personal Details") ? 1 :
                                                          (props.value.pageFlow === "pursceYes" && values === "Purchase Details") ? 2 :
                                                            (props.value.pageFlow === "purchaseYesConstNewYes" && values === "Personal Details") ? 1 :
                                                              (props.value.pageFlow === "purchaseYesConstNewYes" && values === "Spouse Details") ? 2 :
                                                                (props.value.pageFlow === "purchaseYesConstNewYes" && values === "Purchase Details") ? 3 :
                                                                  (props.value.pageFlow === "purchaseYesConstNewYes" && values === "Home Details") ? 4 :
                                                                    (props.value.pageFlow === "purchaseYesConstNewYes" && values === "Construction Details") ? 5 :
                                                                      (props.value.pageFlow === "purchaseYesConstNewYes" && values === "Roof Details") ? 6 :
                                                                        (props.value.pageFlow === "purchaseYesConstNewYes" && values === "Property Details") ? 9 :
                                                                          (props.value.pageFlow === "purchaseYesConstNewYes" && values === "Pet Details") ? 10 :
                                                                            (props.value.pageFlow === "purchaseYesConstNewYes" && values === "Security Alarm") ? 11 :
                                                                              (props.value.pageFlow === "purchaseYesConstNo" && values === "Personal Details") ? 1 :
                                                                                (props.value.pageFlow === "purchaseYesConstNo" && values === "Spouse Details") ? 2 :
                                                                                  (props.value.pageFlow === "purchaseYesConstNo" && values === "Purchase Details") ? 3 :
                                                                                    (props.value.pageFlow === "purchaseYesConstNo" && values === "Home Details") ? 4 :
                                                                                      (props.value.pageFlow === "purchaseYesConstNo" && values === "Construction Details") ? 5 :
                                                                                        (props.value.pageFlow === "purchaseYesConstNo" && values === "Roof Details") ? 6 :
                                                                                          (props.value.pageFlow === "purchaseYesConstNo" && values === "Property Details") ? 9 :
                                                                                            (props.value.pageFlow === "purchaseYesConstNo" && values === "Pet Details") ? 10 :
                                                                                              (props.value.pageFlow === "purchaseYesConstNo" && values === "Security Alarm") ? 11 : 1
      )
    }
    else {
      props.childToParent(index + 1)
    }
    setOpen(false)
  }
  return (

    <react.Fragment>

      {/* Insurance Type */}
      <div className='d-none d-lg-block'>
        <div className="col-md-12">
          <span><img src={image} alt="home" className="type-icon" /></span>
          <label className="ps-2 font14 font-primary font-bold insurance-type">{props.value.Name}</label>
        </div>
        {/* Insurance Type */}
        <div>
          {(props.value.Name == 'Life Insurance' && props.value.currentPg == 1) ? <p className="font14 float-end">
            Each life policy is as unique as you so we are going to get some info from you now and a rep will contact you to make sure we
            know what your needs are

          </p> : null}
        </div>
        {/* Steps indicator header */}
        <div className='row'>
          <div className="col-md-8 mt-2">
            {/**
             * commented the code here to hide the Step Label from header CR
             */}
            {
              props.value.Name === 'Home Insurance' ?
                props.value.currentPg === 1 ? <>

                  <label className="font20 font-bold font-head">{props.value.pgTopic[(props.value.currentPg) - 1]}</label>

                  {/* <div className="col-md-12 mt-3">
                  <div className="alert alert-light d-flex align-items-center " role="alert">
                    <img src={infoicon} alt="info-icon" />
                     <div className="font14  font-primary ms-2">
                      We get it! You're busy, just give us the basics and let us know the best way and
                      time to get in touch with you and we will make this as easy as possible
                    </div>
                  </div>
                </div> */}


                </>
                  : <label className="font20 font-bold font-head">Step {props.value.currentPg} /{props.value.totalPages}  - {props.value.pgTopic[(props.value.currentPg) - 1]}</label> : <label className="font20 font-bold font-head">Step {props.value.currentPg} /{props.value.totalPages}  - {props.value.pgTopic[(props.value.currentPg) - 1]}</label>

            }
          </div>

          <div className="col-md-4 align-self-center mt-2 pe-0">
            <label className="font14 float-end">*Required</label>
          </div>
        </div>



      </div>
      {/* Steps indicator header */}

      <div className='col-md-12 col-sm-12 col-lg-8 bg-light py-3 mobile-header mobile-margin w-100'>

        <div className='row'>

          {/* <!-- Insurance Type --> */}

          <div className='col-md-12'>

            <span><img src={image} alt="home" className="type-icon" /></span>

            <label className='ps-2 font14 font-primary font-bold insurance-type'>{props.value.Name}</label>

          </div>

          <div>
            {(props.value.Name == 'Life Insurance' && props.value.currentPg == 1) ? <p className="font14 float-end">
              Each life policy is as unique as you so we are going to get some info from you now and a rep will contact you to make sure we
              know what your needs are

            </p> : null}
          </div>

          {/* <!-- Insurance Type --> */}

          <div>



          </div>

          {/* <!-- Steps indicator header --> */}

          <div className="col-sm-12 col-md-12 align-self-center mt-2">

            <div className='float-start'>
              {
                props.value.Name === 'Home Insurance' &&
                  props.value.currentPg === 1 ?

                  <label className="font20 font-bold font-head">{props.value.pgTopic[(props.value.currentPg) - 1]}</label> :
                  <label className="font14 font-bold font-head">Step {props.value.currentPg}/{props.value.totalPages} - {props.value.pgTopic[(props.value.currentPg) - 1]}</label>

              }

              <label className="font14 w-50 mt-1">*Required</label>
            </div>
            {(props.value.previousStepArry.length > 0) ?
              <React.Fragment>
                <div className='float-end'>
                  {/* <button type="button" className="btn btn-primary primary-button font14  py-2 px-3 border-0" onClick={() => { setOpen(true) }} >Previous Steps</button> */}
                  {(props.value.Name === 'Home Insurance' && props.value.crossedpage <= 1) || (props.value.Name !== 'Home Insurance' && props.value.crossedpage == 0) ? null : <button type="button" className="btn btn-primary primary-button font14  py-2 px-3 border-0" onClick={() => { setOpen(true) }} >Previous Steps</button>}
                  {(isOpen === true) ?
                    <React.Fragment>
                      <div id="mySidenav" className="sidenav">
                        <div className="sidenav-header px-3 py-2">
                          <div className="float-start">
                            <label className="text-white font16 font-bold">Previous Steps</label>
                          </div>
                          <div className="float-end">
                            <a href="javascript:void(0)" className="closebtn" onClick={() => { setOpen(false) }}>&times;</a>
                          </div>
                        </div>

                        {(props.value.Name === 'Home Insurance') ?
                          <React.Fragment>
                            <div className='Horizantal-scroll'>
                              {/* <button type="button" className="btn btn-primary primary-button font14  py-2 px-3 border-0" onClick={() => { setOpen(true) }} >Previous Steps</button> */}
                              {props.value.previousStepArry.map((values: any, index: any) => {

                                return (
                                  ((index <= Number(props.value.crossedpage) - 1) && values !== 'Personal Details') ?
                                    <div className="mobile-previous-steps px-3">
                                      <a href="#" className='font14 font-semibold' onClick={() => PreviousClose(index, values)}>{values}</a>
                                    </div> : null)
                              })}
                            </div>
                          </React.Fragment>
                          :
                          <React.Fragment>
                            <div>
                              {props.value.previousStepArry.map((values: any, index: any) => {
                                return (
                                  <div className="mobile-previous-steps px-3">
                                    <a href="#" className='font14 font-semibold' onClick={() => PreviousClose(index, values)}>{values}</a>
                                  </div>)
                              })}
                            </div>
                          </React.Fragment>}
                      </div>
                    </React.Fragment> : null}
                </div>
              </React.Fragment> : null}

            {/* 
            <label className="font14 float-start w-75 mt-1">*Required</label> */}

          </div>
        </div>
      </div>

    </react.Fragment>



  );

}


