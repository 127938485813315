import React, { ChangeEvent, useState } from "react";
import "../App.css";
import Arrowright from "../images/Arrow-right.svg";
import ArrowLeftWhite from "../images/Arrow-Left-White.svg";
import calender from "../images/Calender.svg";
import PhoneInput, { NumberFormatValues } from "react-number-format";
import PatternFormat from "react-number-format";
import medicareLogo from "../images/Medicare-Insurance.svg";
import { postInsuranceQuoteData } from "./service";
// The main form React functional component (PS_MC_03)
const Medicare = () => {
  // Define initial state of the form inputs with all values empty (PS_MC_02)
  const initialForm = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    dob: "",
    availableDate: "",
    availableTime: "",
    preferredContactMethod: "",
    medicareClaimType: "",
    description: "",
    parameterId: null,
  };
  // PS_MC_03 state variable creation for collecting form details and to display error message
  const [userInputs, setUserInputs] = useState(initialForm);
  const [displayValidation, setDisplayValidation] = useState(initialForm);
  const [Loading, setLoading] = useState(false);

  // Event handler for input changes; updates the state and clears validation errors for the changed field (PS_MC_04, PS_MC_06, PS_MC_07)
  // const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement|HTMLSelectElement>) => {
  //   const { name, value } = event.target;
  //   setUserInputs((prev) => ({ ...prev, [name]: value }));
  //   setDisplayValidation((prev) => ({ ...prev, [name]: "" }));
  // };
  const handleInputChange = (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
      | NumberFormatValues
  ) => {
    let name: string;
    let value: string;

    if ("target" in event) {
      // This is a regular input event
      name = event.target.name;
      value = event.target.value;
    } else {
      // This is from PhoneInput
      name = "phone";
      value = event.formattedValue || event.value;
    }

    setUserInputs((prev) => ({ ...prev, [name]: value }));
    setDisplayValidation((prev) => ({ ...prev, [name]: "" }));
  };
  // Validation function invoked on input change or form submit; checks regex patterns and mandatory fields (PS_MC_08, PS_MC_10, PS_MC_11, PS_MC_12, PS_MC_13, PS_MC_14)
  const handleValidation = (
    event?:
      | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
      | NumberFormatValues
  ): boolean => {
    const NamePattern = /^[a-zA-Z\s\W]+$/;
    const phonePattern = /^\(\d{3}\)\s\d{3}-\d{4}$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let isValid = true;
    if (event) {
      let name: string;
      let value: string;

      if ("target" in event) {
        // This is a regular input event
        name = event.target.name;
        value = event.target.value;
      } else {
        // This is from PhoneInput
        name = "phone";
        value = event.formattedValue || event.value;
      }
      if (name === "firstName") {
        isValid = NamePattern.test(value);
        if (value === "") {
          setDisplayValidation((prev) => ({ ...prev, firstName: "" }));
        } else if (!isValid) {
          setDisplayValidation((prev) => ({
            ...prev,
            [name]: "Please enter Valid First Name",
          }));
        }
      } else if (name === "lastName") {
        isValid = NamePattern.test(value.trim());
        if (value === "") {
          setDisplayValidation((prev) => ({ ...prev, lastName: "" }));
        } else if (!isValid) {
          setDisplayValidation((prev) => ({
            ...prev,
            [name]: "Please enter Valid Last Name",
          }));
        }
      } else if (name === "phone") {
        isValid = phonePattern.test(value.trim());
        if (value === "") {
          setDisplayValidation((prev) => ({ ...prev, phone: "" }));
        } else if (!isValid) {
          setDisplayValidation((prev) => ({
            ...prev,
            [name]: "Please enter Valid Phone Number",
          }));
        }
      } else if (name === "email") {
        isValid = emailPattern.test(value.trim());
        if (value === "") {
          setDisplayValidation((prev) => ({ ...prev, email: "" }));
        } else if (!isValid) {
          setDisplayValidation((prev) => ({
            ...prev,
            [name]: "Please enter Valid email address",
          }));
        }
      } else if (name === 'dob') {
        if (value === "") {
          setDisplayValidation(prev => ({ ...prev, [name]: "Please enter valid Birthdate" }));
          isValid = false;
        } else {
          const [year,month,day] = value.split('-');
          const numMonth = parseInt(month, 10);
          const numDay = parseInt(day, 10);
          const numYear = parseInt(year, 10);
      
          if (
            month === '00' || day === '00' ||
            year === '0000' || 
            year.startsWith('0') ||
            numDay > 31 ||
            numMonth > 12 ||
            numYear < 1910 || numYear > new Date().getFullYear() ||
            isNaN(numMonth) || isNaN(numDay) || isNaN(numYear)
          ) {
            setDisplayValidation(prev => ({ ...prev, [name]: "Please enter valid Birthdate" }));
            isValid = false;
          } else {
            const date = new Date(numYear, numMonth - 1, numDay);
            if (
              date.getFullYear() !== numYear ||
              date.getMonth() !== numMonth - 1 ||
              date.getDate() !== numDay
            ) {
              setDisplayValidation(prev => ({ ...prev, [name]: "Please enter valid Birthdate" }));
              isValid = false;
            } else {
              const today = new Date();
              let age = today.getFullYear() - numYear;
              const monthDiff = today.getMonth() - (numMonth - 1);
              if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < numDay)) {
                age--;
              }
              if (age < 18) {
                setDisplayValidation(prev => ({ ...prev, [name]: "You must be at least 18 years old" }));
                isValid = false;
              } else {
                // Clear validation message if date is valid and age is 18 or above
                setDisplayValidation(prev => ({ ...prev, [name]: "" }));
              }
            }
          }
        }
      }
      // else if (name === 'availableDate') {
      //   const selectedDate = new Date(value);
      //   const today = new Date();
      //   today.setHours(0, 0, 0, 0); // Set to beginning of day for accurate comparison

      //   if (selectedDate < today) {
      //     setDisplayValidation(prev => ({ ...prev, [name]: "Please select valid available date" }));
      //     isValid = false;
      //   }
      // }
    } else {
      // Mandatory field validation for each field individually
      if (userInputs.firstName.trim() === "") {
        setDisplayValidation((prev) => ({
          ...prev,
          firstName: "Please enter First Name",
        }));
        isValid = false;
      }
      if (userInputs.lastName.trim() === "") {
        setDisplayValidation((prev) => ({
          ...prev,
          lastName: "Please enter Last Name",
        }));
        isValid = false;
      }
      if (userInputs.phone.trim() === "") {
        setDisplayValidation((prev) => ({
          ...prev,
          phone: "Please enter the Phone",
        }));
        isValid = false;
      }
      if (userInputs.email.trim() === "") {
        setDisplayValidation((prev) => ({
          ...prev,
          email: "Please enter the Email",
        }));
        isValid = false;
      }
      if (userInputs.dob.trim() === "") {
        setDisplayValidation((prev) => ({
          ...prev,
          dob: "Please select a Birthdate",
        }));
        isValid = false;
      }
      if (userInputs.preferredContactMethod.trim() === "") {
        setDisplayValidation((prev) => ({
          ...prev,
          preferredContactMethod: "Please select contact method",
        }));
        isValid = false;
      }
      if (userInputs.medicareClaimType.trim() === "") {
        setDisplayValidation((prev) => ({
          ...prev,
          medicareClaimType: "Please select the Medicare claim type",
        }));
        isValid = false;
      }
    }
    return isValid;
  };
  // Event handler for form submission that also ensures validation passes without any errors (PS_MC_15, PS_MC_16, PS_MC_19)
  const handleSubmit = async () => {
    if (
      handleValidation() &&
      !Object.values(displayValidation).find((el) => el != "")
    ) {
      try {
        const request = { PersonalDetails: userInputs };
        setLoading(true);
        const response = await postInsuranceQuoteData(request, "Medicare");
        // if (response.status === "success") {
        window.location.href = "/thankyou";
        // }
      } catch (error) {
        console.error("Error submitting the form", error);
      }
      // console.log(userInputs)
    }
  };
  return (
    <>
      {Loading == true ? (
        <div>
          <h1>Submitting Your Request</h1>
          <h3>Please Wait for few seconds....</h3>
          <div className="loader-wrapper">
            <div className="loader">
              <span></span> <span></span> <span></span> <span></span>{" "}
              <span></span>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="row">
                <div className="col-lg-11 col-md-12 col-sm-12">
                  <div className="col-md-12">
                    <span>
                      <img
                        src={medicareLogo}
                        alt="home"
                        className="type-icon"
                      />
                    </span>
                    <label className="ps-2 font14 font-primary font-bold insurance-type">
                      Medicare
                    </label>
                  </div>
                  <div>
                    <p className="font14 float-end">
                      Unlock personalized Medicare coverage tailored just for
                      you! Quick info now, then our expert guides you to your
                      perfect plan.
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-md-12  mt-3">
                      <label className="font20 font-primary font-semibold font-head border-bottom w-100 pb-2">
                        General Info
                      </label>
                    </div>
                    {/* Form fields */}
                    <div className="col-md-6 mt-3">
                      <label
                        htmlFor="name"
                        className="form-label font14 font-semibold"
                      >
                        First Name*
                      </label>
                      <input
                        type="text"
                        className="form-control pb-2 pt-2"
                        id="name"
                        name="firstName"
                        value={userInputs.firstName}
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                        maxLength={40}
                        placeholder="Enter First Name "
                      />
                      <span className="text-danger font14 w-100">
                        {displayValidation.firstName}
                      </span>
                    </div>
                    <div className="col-md-6 mt-3">
                      <label
                        htmlFor="name"
                        className="form-label font14 font-semibold"
                      >
                        Last Name*
                      </label>
                      <input
                        type="text"
                        className="form-control pb-2 pt-2"
                        id="name"
                        name="lastName"
                        value={userInputs.lastName}
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                        maxLength={80}
                        placeholder="Enter Last Name "
                      />
                      <span className="text-danger font14 w-100">
                        {displayValidation.lastName}
                      </span>
                    </div>
                    <div className="col-md-6 mt-3">
                      <label
                        htmlFor="phoneNumber"
                        className="form-label font14 font-semibold"
                      >
                        MobilePhone*
                      </label>
                      {/* <input type="tel" className="form-control pb-2 pt-2" id="phoneNumber" name="phone" value={userInputs.phone} onChange={(e)=>{
                      handleInputChange(e)
                      handleValidation(e)
                    }} maxLength={10} placeholder='Enter Phone'/> */}
                      {/* <PhoneInput
                    type={"tel"}
                    format={"(###) ###-####"}
                    className="form-control pb-2 pt-2"
                    value={userInputs.phone}
                    onValueChange={(e) => { 
                      handleInputChange(e)
                      // handleValidation(e)
                    }}
                    id="phoneNumber"
                    name='phone'
                    placeholder="XXXXXXXXXX"
                    /> */}
                      <PatternFormat
                        format="(###) ###-####"
                        className="form-control pb-2 pt-2"
                        value={userInputs.phone}
                        onValueChange={(values: NumberFormatValues) => {
                          handleInputChange(values);
                        }}
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                          handleValidation(e);
                        }}
                        id="MobileNo"
                        name="phone"
                        placeholder="Enter Phone"
                      />
                      <span className="text-danger font14 w-100">
                        {displayValidation.phone}
                      </span>
                    </div>
                    <div className="col-md-6 mt-3">
                      <label
                        htmlFor="email"
                        className="form-label font14 font-semibold"
                      >
                        Email*
                      </label>
                      <input
                        type="email"
                        className="form-control pb-2 pt-2"
                        id="email"
                        name="email"
                        value={userInputs.email}
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                        maxLength={80}
                        placeholder="Enter Email"
                        onBlur={(e) => {
                          handleValidation(e);
                        }}
                      />
                      <span className="text-danger font14 w-100">
                        {displayValidation.email}
                      </span>
                    </div>
                    <div className="col-md-6 mt-3 date-picker">
                      <label
                        htmlFor="dob"
                        className="form-label font14 font-semibold"
                      >
                        Birth Date*
                      </label>
                      <input
                        type="date"
                        className="form-control font14 text-uppercase pb-2 pt-2 datecontrol"
                        id="dob"
                        name="dob"
                        value={userInputs.dob}
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                        placeholder="MM/DD/YYY"
                        max={(() => {
                          const today = new Date();
                          today.setFullYear(today.getFullYear() - 18);
                          return today.toISOString().split("T")[0];
                        })()}
                        onBlur={(e) => {
                          handleValidation(e);
                        }}
                      />
                      <img
                        src={calender}
                        alt="calender-icon"
                        className="calender-icon"
                      />
                      <span className="text-danger font14 w-100">
                        {displayValidation.dob}
                      </span>
                    </div>
                    <div className="col-md-6 mt-3">
                      <label
                        htmlFor="label8"
                        className="form-label font14 font-semibold "
                      >
                        Medicare claim Type*
                      </label>
                      <select
                        className="form-select pb-2 pt-2"
                        id="label8"
                        name="medicareClaimType"
                        value={userInputs.medicareClaimType}
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                      >
                        <option
                          value=""
                          className="form-labelfont16 color-grey"
                        >
                          {" "}
                          -- select --
                        </option>
                        <option value="Seniors 65+ years">
                          Seniors 65+ years
                        </option>
                        <option value="Disabled">Disabled</option>
                        <option value="End-stage Renal disease">
                          End-stage Renal disease
                        </option>
                        <option value="Unmarried Disabled child (20)">
                          Unmarried Disabled child (20)
                        </option>
                      </select>
                      <span className="text-danger font14 w-100">
                        {displayValidation.medicareClaimType}
                      </span>
                    </div>
                    <div className="col-md-6 mt-3 date-picker">
                    <label htmlFor="availableFrom" className="form-label font14 font-semibold">Available Date</label>
                    <input type="date" className="form-control font14 text-uppercase pb-2 pt-2 datecontrol" id="availableDate" name="availableDate" value={userInputs.availableDate} onChange={(e)=>{
                      handleInputChange(e)
                      handleValidation(e)
                    }} min={new Date(Date.now() + 86400000).toISOString().split('T')[0]} onKeyDown={(e) => e.preventDefault()}/>
                    <img src={calender} alt="calender-icon" className="calender-icon" />
                    <span className='text-danger font14 w-100'>{displayValidation.availableDate}</span>
                  </div>
                    <div className="col-md-6 mt-3">
                      <label
                        htmlFor="label8"
                        className="form-label font14 font-semibold "
                      >
                        Available Time
                      </label>
                      <select
                        className="form-select pb-2 pt-2"
                        id="label8"
                        name="availableTime"
                        value={userInputs.availableTime}
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                      >
                        <option
                          value=""
                          className="form-labelfont16 color-grey"
                        >
                          {" "}
                          -- select --
                        </option>
                        <option value="08:00AM - 10:00AM">
                          08:00AM - 10:00AM
                        </option>
                        <option value="10:00AM - 12:00PM">
                          10:00AM - 12:00PM
                        </option>
                        <option value="12:00PM - 02:00PM">
                          12:00PM - 02:00PM
                        </option>
                        <option value="02:00PM - 04:00PM">
                          02:00PM - 04:00PM
                        </option>
                        <option value="04:00PM - 06:00PM">
                          04:00PM - 06:00PM
                        </option>
                        <option value="06:00PM - 08:00PM">
                          06:00PM - 08:00PM
                        </option>
                      </select>
                      <span className="text-danger font14 w-100">
                        {displayValidation.availableTime}
                      </span>
                    </div>
                    <div className="col-md-12 mt-3">
                      <label
                        htmlFor="label8"
                        className="form-label w-100 font14 font-semibold"
                      >
                        Preferred Contact Method*
                      </label>
                      {/* <select className="form-select pb-2 pt-2" id="label8"  name="preferredContactMethod" value={userInputs.preferredContactMethod} onChange={(e)=>{
                      handleInputChange(e)
                    }}>
                      <option value="" className="form-labelfont16 color-grey"> -- select --</option>
                      <option value="Phone">Phone</option>
                      <option value="Email">Email</option>
                      <option value="Text">Text</option>
                      <option value="Mail">Mail</option>
                    </select> */}
                      <input
                        type="radio"
                        className="btn-check radio-checked"
                        id="label10"
                        autoComplete="off"
                        name="preferredContactMethod"
                        value="Phone"
                        checked={userInputs.preferredContactMethod === "Phone"}
                        onChange={(e) => handleInputChange(e)}
                      />
                      <label
                        className="btn btn-primary button-radio font14 font-bold"
                        htmlFor="label10"
                      >
                        Phone
                      </label>
                      <input
                        type="radio"
                        className="btn-check radio-checked"
                        id="label11"
                        autoComplete="off"
                        name="preferredContactMethod"
                        value="Email"
                        checked={userInputs.preferredContactMethod === "Email"}
                        onChange={(e) => handleInputChange(e)}
                      />
                      <label
                        className="btn btn-primary ms-2 button-radio font14 font-bold"
                        htmlFor="label11"
                      >
                        Email
                      </label>
                      <input
                        type="radio"
                        className="btn-check radio-checked"
                        id="label12"
                        autoComplete="off"
                        name="preferredContactMethod"
                        value="Text"
                        checked={userInputs.preferredContactMethod === "Text"}
                        onChange={(e) => handleInputChange(e)}
                      />
                      <label
                        className="btn btn-primary ms-2 button-radio font14 font-bold"
                        htmlFor="label12"
                      >
                        Text
                      </label>
                      <input
                        type="radio"
                        className="btn-check radio-checked"
                        id="label13"
                        autoComplete="off"
                        name="preferredContactMethod"
                        value="Mail"
                        checked={userInputs.preferredContactMethod === "Mail"}
                        onChange={(e) => handleInputChange(e)}
                      />
                      <label
                        className="btn btn-primary ms-2 button-radio font14 font-bold"
                        htmlFor="label13"
                      >
                        Mail
                      </label>
                      <p className="text-danger font14 w-100">
                        {displayValidation.preferredContactMethod}
                      </p>
                    </div>
                    {/* <div className="col-md-6 mt-3">
                    <label htmlFor="label8" className="form-label font14 font-semibold ">Medicare claim Type*</label>
                    <select className="form-select pb-2 pt-2" id="label8"  name="medicareClaimType" value={userInputs.medicareClaimType} onChange={(e)=>{
                      handleInputChange(e)
                    }}>
                      <option value="" className="form-labelfont16 color-grey"> -- select --</option>
                      <option value="Senior 60+">Senior 60+</option>
                      <option value="Disabled">Disabled</option>
                      <option value="End-stage Renal disease">End-stage Renal disease</option>
                      <option value="Unmarried disabled child">Unmarried disabled child</option>
                    </select>
                    <span className='text-danger font14 w-100'>{displayValidation.medicareClaimType}</span>
                  </div> */}
                    <div className="col-md-12 mt-3">
                      <label
                        htmlFor="label6"
                        className="form-label font14 font-semibold"
                      >
                        Please feel free to add any other details
                      </label>
                      <textarea
                        className="form-control text-area-input pb-2 pt-2"
                        id="label6"
                        rows={6}
                        name="description"
                        maxLength={3200}
                        value={userInputs.description}
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                        placeholder="-- write to us --"
                      />
                    </div>
                    {/* <div className="col-md-6 mt-3 date-picker">
                    <label htmlFor="availableTo" className="form-label font14 font-semibold">Available To*</label>
                    <input type="date" className="form-control font14 text-uppercase pb-2 pt-2 datecontrol" id="availableTo" name="availableTo" />
                    <img src={calender} alt="calender-icon" className="calender-icon" />
                  </div> */}
                    <div className="col-md-12 mt-4 mb-4">
                      <button
                        type="submit"
                        className="btn btn-primary mt-4 primary-button border-0 font14 home-button px-5 float-end mobile-button"
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Right side div */}
            <div className="col-md-4 right-panel-margin hide-right">
              <div className="row mx-0">
                <div className="col-md-12 border bg-light p-2 previous-steps-box border-bottom-0">
                  <label className="ps-2 font-16 font-bold">Need Help?</label>
                </div>
                <div className="col-md-12 border border-top-0 previous-steps-box">
                  <label className="ps-2 w-100 mt-3 font16 font-semibold">
                    The Gibraltar Group
                  </label>
                  <label className="ps-2 w-100 mt-2 mb-3 font14">
                    (281) 681-0331
                  </label>
                </div>
                {/* Home button */}
                <button
                  type="button"
                  onClick={() =>
                    (window.location.href =
                      "/")
                  }
                  className="btn btn-primary mt-4 py-2 primary-button border-0 font14 home-button"
                >
                  <img
                    src={ArrowLeftWhite}
                    alt="Arrowleftwhite"
                    className="me-1"
                  />{" "}
                  Back to Home
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Medicare;
