/*
   // Project: Gibraltar
   // Desc: This file contains the Advisor Contact Lead form insurance component by using this component the user will able to request quote ;
   // Author:Harshanth Raaj
   // Created Date:04/04/2022
   //File Name : advisorContactLead.tsx;
*/


//importing usestate
import axios from "axios";
import { useState } from "react"
import '../App.css';
import arrowLeftLogo from '../images/Arrow-Left-White.svg';
import arrowRightLogo from '../images/Arrow-right.svg';
// import AdvHeader from "./advHeader";
import {postInsuranceQuoteData} from "./service"

export default function ContactLeadForm(props:any){

  var QueryString = new URLSearchParams(window.location.search);
    var OwnerId = QueryString.get("agentId");
    let parameterId= QueryString.get("parameterId");

    console.log(parameterId)

    //PC_ACC_01
    /*declaring state variables for every fields as empty strings in a json format
    ie., for First Name, Last Name, Email,Zip, Help dropdown, message
    */
    const[stateArr,setStateArr] = useState([{firstName:"",lastName:"",email:"",zip:"",helpDDL:""}])

    //declaring this state variable to display a buffer during the routing period to thank you page
    const[Loading,setLoading] =useState(false)
    
    //declaring a common single state variable with boolean value 'false' 
    const[valid,setValid] = useState(false)

    //this state variables for regular expression validation
    const[emailRE,setEmailRE] = useState(false)

    //PC_ACL_02
    /**function that maintains onChange events  for every fields
     value of 'stateArr' gets stored in a constant list.
     */
    function handleServiceChange(e:any){
        const list:any = {...stateArr};
        const val = e.target.name;        
        list[0][e.target.name] = e.target.value;
        if(e.target.name === 'email'){
          var regExEmail =  /^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)*[\w+-]+\.[a-zA-Z]{2,4}$/;
          if(!(stateArr[0].email.match(regExEmail))){
              setEmailRE(true)                 
          }
          if(stateArr[0].email.match(regExEmail)){
            setEmailRE(false)
          }  
        }
        setStateArr(list);
    }

    //PC_ACC_03
    /*declaring a function that does normal validation as well as regular expression validation
    if the condition is not met,error pops up
    */
    function validation(){
        var error = 0
        debugger

        // console.log("trimmed",stateArr[0].firstName.trimStart().trimEnd())
        console.log(stateArr[0].firstName.trim())
        //setting the state variable 'valid' to true if the first name/last name/email/help is empty & incrementing the error value.
        if(stateArr[0].firstName.trim() == "" ){
          // debugger;
            setValid(true)
            error ++
        }
        if(stateArr[0].lastName.trim() === ""){
            setValid(true)
            error ++
        }
        if(stateArr[0].email === ""){
            setValid(true)
            error ++                         
        }     
        if(stateArr[0].helpDDL === "" ){
            setValid(true)
            error ++
        }

        //PC_ACC_04
       
        /*checking if email is not empty & declared a variable which stores the regular expression pattern for email
         & also checking if the email is not matching with variable 'regExEmail' & setting 'emailRE'to true*/
        if(stateArr[0].email !== ""){
            var regExEmail =  /^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)*[\w+-]+\.[a-zA-Z]{2,4}$/;
            if(!(stateArr[0].email.match(regExEmail))){
              setValid(true)
                setEmailRE(true)  
                error ++              
            }     
            if(stateArr[0].email.match(regExEmail)){
              setEmailRE(false) 
          }                                                
        }
      

        //"dataPost()" is triggered by ckecking if every field is non-empty & variable 'error' is '0' 
        if(stateArr[0].firstName!=="" && stateArr[0].lastName!=="" && stateArr[0].email!=""  && stateArr[0].helpDDL!= ""){
          if(error ==0){
              datapost()
          }            
      }      
    }


    //PC_ACL_05
    /*gets the value of every fields in the form of json & an service call for posting the data
    */
   async function datapost(){

        let arr = {
            FirstName : stateArr[0].firstName.trimStart().trimEnd(),
            LastName : stateArr[0].lastName.trimStart().trimEnd(),
            Email : stateArr[0].email,
            help : stateArr[0].helpDDL,
            Company : "N/A" ,
            "OwnerId" : OwnerId,
            "parameterId":parameterId,
            "windowValue":props.name
        }    

        //PC_ACC_06
        setLoading(true)
        let datas = await postInsuranceQuoteData(arr,'Advisor Contact 1')
       
        console.log(datas.data)

        //PC_ACC_07
        //navigates to 'Thank you' page after successfull validation 
        window.location.href = '/thankyou'
    }

    return(
        // <div className="common-border">
          <div className="container">
            <div className="row mt-4">
              {/* Form starts here */}
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="row mx-0">

                  {/* Header */}
                  <div className="col-lg-11 col-md-12 col-sm-12">
                    <div className="row">
                  
                  {/* Steps indicator header */}
                  <div className="col-md-12 mt-2">
                    <div className="float-start w-100">
                    <label className="font24 font-bold font-head">Have an advisor contact you</label>
                    </div>
                   <div className="float-end w-25 advisory-required"><label className="font14 float-end others-required">*Required</label></div>
                  </div>
                  
                  {/* Steps indicator header */}
                  {/* Header */}
                  {/* Form-Component */}
                  {/* General Info Starts here */}
                  <div >
                    <label className="col-md-12 border-bottom mt-2"></label>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label htmlFor="label1" className="form-label font16 font-semibold">First Name*</label>
                    <input type="text" className="form-control pb-2 pt-2" id="label1" name="firstName" maxLength={40} value={stateArr[0].firstName}
                    onChange={(e)=>{handleServiceChange(e)}}
                    />
                    <label className="text-danger font14">{(valid === true && stateArr[0].firstName.trim()=="")?"Please enter the First Name" : null}</label>
                    
                  </div>
                  
                  <div className="col-md-6 mt-3">
                    <label htmlFor="label2" className="form-label font16 font-semibold">Last Name*</label>
                    <input type="text" className="form-control pb-2 pt-2" id="label2" name="lastName" maxLength={80}
                    value={stateArr[0].lastName}
                    onChange={(e)=>{handleServiceChange(e)}}
                    />
                    <label className="text-danger font14">{(valid === true && stateArr[0].lastName.trim()==="")?"Please enter the Last Name" : null}</label>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label htmlFor="label8" className="form-label font16 font-semibold">Email*</label>
                    <input type="email" className="form-control pb-2 pt-2" id="label8" name="email" maxLength={80}
                    value={stateArr[0].email}
                    onChange={(e)=>{handleServiceChange(e)}}                
                    />
                    
                    <label className="text-danger font14">{(valid ===true && stateArr[0].email ==="")?"Please enter an Email" : 
                    (valid ===true && emailRE ===true)?"Please enter a valid Email" : null}</label>
                  </div>

                  <div className="col-md-6 mt-3 mb-5">
                    <label htmlFor="label3" className="form-label font16 font-semibold">How can we help?*</label>
                    {/* <input type="date" className="form-control font14 text-uppercase" id="label3"/> */}
                    <select className="form-select pb-2 pt-2" id="label3"
                    name="helpDDL"
                    value={stateArr[0].helpDDL}
                    onChange={(e)=>{handleServiceChange(e)}}
                    >
                      {

                      
                      props.name==='connectWithUs'? 
                      <><option value={""}>-Select an Item-</option>
                      <option value={"Insurance Questions"}>Insurance Questions</option>
                      <option value={"Mortgage Questions"}>Mortgage Questions</option>
                      <option value={"Title Questions"}> Title Questions </option>
                      <option value={"General Questions"}>  General Questions </option></>
   
                    :  
                   <> <option value={""}>-Select an Item-</option>
                    <option value={"Personal"}>Personal Insurance</option>
                    <option value={"Commercial"}>Commercial Insurance</option>
                    <option value={"Financial"}>Life and Financial Services </option>
                    </>
                    }
                   
                </select>
                <label className="text-danger font14">{(valid ===true && stateArr[0].helpDDL ==="")?"Please select your choice" : null}</label> 
                  </div>
                  
                  {/* Communication Info Ends Here */}
                  <div>
                    <label className="col-md-12 border-bottom mt-5 mb-2"></label>
                  </div>
                  
                  {/* Footer */}
                  <div className="col-md-12 mt-2 mb-4">
                                                     
                    <button type="button" className="btn btn-primary mt-2 primary-button border-0 font14 home-button px-5 float-end mobile-button"
                    onClick={()=>{validation()}}>
                      Submit Request</button>  
                                                
                  </div>
                  {Loading == true ? <div id="root">

                <h1>Submitting Your Request</h1>

                <h3>Please Wait for few seconds....</h3>

                <div className="loader-wrapper">

                    <div className="loader"><span></span> <span></span> <span></span> <span></span> <span></span> </div>

                </div>

            </div> : null}
                  {/* Footer */}
                  {/* Form-Component */}
                </div>
              </div>
              </div>
              </div>
              {/* Form starts here */}
              {/* Right panel starts Here */}
              {/* <div className="col-md-4 right-panel-margin"> */}
              <div className="col-md-4 hide-right">
                <div className="row mx-0">
                  
                  {/* Need Help */}
                  <div className="col-md-12 border bg-light p-2 mt-4 previous-steps-box border-bottom-0">
                    <label className="ps-2 font-16 font-bold">Need Help?</label>
                  </div>
                  <div className="col-md-12 border border-top-0 previous-steps-box">
                    <label className="ps-2 w-100 mt-2 font16 font-semibold">The Gibraltar Group</label>
                    <label className="ps-2 w-100 mt-2 mb-3 font14">(281) 681-0331</label>                      
                  </div>
                  {/* Need Help */}
                  {/* Home button */}
                  <button type="button" className="btn btn-primary mt-4 py-2 primary-button border-0 font14 home-button" onClick={()=> {window.location.href=(props.name==='connectWithUs'?`https://www.thegibraltargrp.com/`:`https://www.thegibraltargrp.com/insurance-solutions`)}}><img src={arrowLeftLogo} alt="auto" className="me-1" /> Back to Home</button>
                  {/* Home button */}
                </div>
              </div>
              {/* Right panel starts Here */}
            </div>
          </div>
        // </div>
    )
        
}